import React, { useState } from 'react';
import { Box, Typography, Grid, Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip, Checkbox
 } from '@mui/material';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { configIndexImg, configIndexImg2 } from '../../constant';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

export default function ConfigurationIndex() {
  const [selectedModule, setSelectedModule] = useState("");
  const [selectedItem, setSelectedItem] = useState("");

  const quesCols = ["", "Question Category", "Question Sub-Category", "Question ID", "Question Type", "Question", "Response type", "Status", "Created By"];
  const quesData = [
    {"Question Category":"Category 1", "Question Sub-Category":"Sub-Category 1", "Question ID":"Qi 1", "Question Type":"Qt 1", "Question":"Q1", "Response type":"Res 1", "Status":"Active", "Created By":"Dipansu"},
    {"Question Category":"Category 2", "Question Sub-Category":"Sub-Category 2", "Question ID":"Qi 2", "Question Type":"Qt 2", "Question":"Q2", "Response type":"Res 2", "Status":"Active", "Created By":"Dipansu"},
    {"Question Category":"Category 3", "Question Sub-Category":"Sub-Category 3", "Question ID":"Qi 3", "Question Type":"Qt 3", "Question":"Q3", "Response type":"Res 3", "Status":"Active", "Created By":"Dipansu"},
    {"Question Category":"Category 4", "Question Sub-Category":"Sub-Category 4", "Question ID":"Qi 4", "Question Type":"Qt 4", "Question":"Q4", "Response type":"Res 4", "Status":"Active", "Created By":"Dipansu"},
    {"Question Category":"Category 5", "Question Sub-Category":"Sub-Category 5", "Question ID":"Qi 5", "Question Type":"Qt 5", "Question":"Q5", "Response type":"Res 5", "Status":"Active", "Created By":"Dipansu"}
  ]
  return (
    <>
      <Box mb={2}>
        <Typography
          sx={{ fontSize: "20px", fontWeight: 600, color: "#2E2C34" }}
        >
          Configuration
        </Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Box
            sx={{
              backgroundColor: "#FAFAFA",
              width: "100%",
              minHeight: "75vh",
              borderRadius: "8px",
              padding: '12px',
            }}
          >
            {selectedModule?.length > 0 ? (
              <>
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  gap={1}
                  mb={2.5}
                  sx={{ cursor: 'pointer' }}
                  onClick={() => {setSelectedModule(""); setSelectedItem("");}}
                >
                  <ArrowBackIosIcon sx={{ color: 'black', fontSize: '14px' }} />
                  <Typography sx={{ color: '#424242', fontSize: '14px', fontWeight: '600' }}>
                    {selectedModule}
                  </Typography>
                </Box>
                {[
                  "Category / Sub - Category",
                  "Audit Questions",
                  "Manage Audit Template",
                  "Manage Audit Protocol",
                  "Manage Responses",
                ].map((item, index) => (
                  <Box
                    key={index}
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    gap={1}
                    sx={{
                      width: '100%',
                      mb: 1,
                      padding: '12px 8px',
                      cursor: 'pointer',
                      backgroundColor: '#ffffff',
                      borderRadius: '4px',
                      '&:hover': { backgroundColor: '#F2F7FF' },
                    }}
                    onClick={() => setSelectedItem(item)}
                  >
                    <InsertDriveFileIcon sx={{ color: 'black', fontSize: '20px' }} />
                    <Typography sx={{ fontSize: '14px', color: '#636363', fontWeight: '600' }}>
                      {item}
                    </Typography>
                  </Box>
                ))}
              </>
            ) : (
              [
                "Incident Management",
                "Loss Control",
                "CAPA",
                "Audit Management",
              ].map((item, index) => (
                <Box
                  key={index}
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  gap={1}
                  sx={{
                    width: '100%',
                    mb: 1,
                    padding: '12px 8px',
                    cursor: 'pointer',
                    backgroundColor: '#ffffff',
                    borderRadius: '4px',
                    '&:hover': { backgroundColor: '#F2F7FF' },
                  }}
                  onClick={() => setSelectedModule(item)}
                >
                  <InsertDriveFileIcon sx={{ color: 'black', fontSize: '20px' }} />
                  <Typography sx={{ fontSize: '14px', color: '#636363', fontWeight: '600' }}>
                    {item}
                  </Typography>
                </Box>
              ))
            )}

          </Box>
        </Grid>
        <Grid item xs={9}>
          <Box
            sx={{
              backgroundColor: "#FFFFFF",
              width: "100%",
              minHeight: "75vh",
              borderRadius: "8px",
              padding: 0,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
         { selectedItem.length > 0 ? (
          <TableContainer>
            <Table>
              <TableHead
                sx={{
                  backgroundColor: "#FCFCFC",
                  borderTop: "1px solid #D1D1D1",
                  borderBottom: "1px solid #D1D1D1",
                }}
              >
                <TableRow>
                  {quesCols?.map((item, index) => (
                    <TableCell
                      key={index}
                      sx={{
                        // whiteSpace: "nowrap",
                        color: "#222",
                        fontSize: "14px",
                        fontWeight: "600",
                      }}
                    >
                      {item}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody sx={{ border: "none !important" }}>
                {quesData?.map((currentDataItem, index) => (
                  <TableRow key={index}>
                    <TableCell
                          sx={{
                            color: "#222",
                            fontSize: "12px",
                          }}
                        >
                    <Checkbox
                      sx={{
                        '& .MuiSvgIcon-root': { fontSize: 20 }, // Adjust size if needed
                        color: '#000', // Default color
                        '&.Mui-checked': {
                          color: '#09A2E3', // Checked color
                        },
                        borderRadius: 0, // Square shape
                      }}
                    /></TableCell>
                    {quesCols?.map((currentCol, index1) => (
                      currentCol !== "" &&
                      (<TableCell
                        key={index1}
                        sx={{
                          color: "#222",
                          fontSize: "12px",
                        }}
                      >
                        {currentDataItem[currentCol]?.length >
                        18 ? (
                          <Tooltip
                            title={
                              currentDataItem[currentCol]
                            }
                          >
                            <span>{`${currentDataItem[
                              currentCol
                            ].slice(0, 18)}..`}</span>
                          </Tooltip>
                        ) : (
                          currentDataItem[currentCol]
                        )}
                      </TableCell>)
                    ))}
                  </TableRow>
    
                ))}
              </TableBody>
            </Table>
          </TableContainer>
         ) : selectedModule.length > 0 ? (
          <img
            src={configIndexImg2}
            alt="Example"
            style={{ height: "400px", width: "400px" }}
          />) : (
            <img
              src={configIndexImg}
              alt="Example"
              style={{ height: "400px", width: "400px" }}
            />
          )}
          </Box>
        </Grid>
      </Grid>
    </>
  )
}
