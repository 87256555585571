import {
  INCIDENTFWORKLOW_UPDATE_FORM_FIELD,
  INCIDENTFWORKLOW_UPDATE_DATE_TIME,
  INCIDENTFWORKLOW_SET_CAPA_DETAILS,
  INCIDENTFWORKLOW_ONCHANGE_DRAWER_FORM,
  INCIDENTFWORKLOW_ADD_CAPA_DETAIL,
  INCIDENTFWORKLOW_UPDATE_SWITCH_STATE,
  INCIDENTFWORKLOW_EDIT_CAPA_DETAIL,
  INCIDENTFWORKLOW_DELETE_CARD_DETAIL,
  INCIDENTFWORKLOW_UPDATE_EVIDENCE_FILE,
  INCIDENTFWORKLOW_RESET_STATE,
  INCIDENTFWORKLOW_REMOVE_INVESTIGATION_MEMBER,
  INCIDENTFWORKLOW_SET_REVIEW_DATA,
  INCIDENTFWORKLOW_SET_EDIT_CAPA
} from './constant';

const initialState = {
  allReportsData: [],
  PersonnelDetail: [],
  CAPADetail: [],
  EvidenceDetail: [],
  Return:{},
  Review:{},
  Assign_Investigation:{},
  switchStates: {
    Investigation: 'Yes',
    CAPA: 'Yes',
  },
  formEdit: {
    Evidence:false,
    CAPA:false,
    Equipment:false,
    Vehicle:false,
    Personnel:false,
  },
  CAPA:{},
  

};
const incidentWorkFlowReducer = (state = initialState, action) => {
  switch (action.type) {
    case INCIDENTFWORKLOW_RESET_STATE:
      return {
        ...initialState, // Resets state to initialState
      };

      case INCIDENTFWORKLOW_SET_EDIT_CAPA:
      return {
        ...state,
        CAPA: action.payload,
      };


      case INCIDENTFWORKLOW_SET_REVIEW_DATA:
      return {
        ...state,
        Review: {
          ...state.Review,
          ...action.payload,
        },
      };

    case INCIDENTFWORKLOW_UPDATE_EVIDENCE_FILE:
      return {
        ...state,
        Evidence: {
          ...state.Evidence,
          file: Array.isArray(state.Evidence.file)
            ? [...state.Evidence.file, action.payload] // Append the new string to the existing array
            : [action.payload], // If file isn't already an array, initialize it as an array with the new string
        },
      };
  
    case INCIDENTFWORKLOW_UPDATE_FORM_FIELD:
      return {
        ...state,
        [action.payload.fieldName]: action.payload.value,  // Dynamically update field
      };

   


    case INCIDENTFWORKLOW_UPDATE_DATE_TIME:
      return {
        ...state,
        [action.payload.title]: {
          ...state[action.payload.title],
          [action.payload.fieldName]: action.payload.value, // Use fieldName dynamically as key
        }
      };

    // Dynamic case for adding/updating PERSONNEL detail
    case INCIDENTFWORKLOW_ONCHANGE_DRAWER_FORM:
      return {
        ...state,
        [action.payload.title]: {
          ...state[action.payload.title],
          [action.payload.fieldName]: action.payload.value, // Use fieldName dynamically as key
        }
      };


      case INCIDENTFWORKLOW_SET_CAPA_DETAILS:
      return {
        ...state,
        CAPADetail: [...action.payload], // store the new CAPADetail array
      };


      case INCIDENTFWORKLOW_ADD_CAPA_DETAIL: {
        // Find the highest ID in the current CAPADetail array
        const highestId = state.CAPADetail.reduce((maxId, detail) => 
          Math.max(detail.id, maxId), 0); // Get the max id, or 0 if the array is empty
      
        const existingIndex = state.CAPADetail.findIndex(detail => detail.id === action.payload.id);
      
        let updatedCAPADetail;
      
        if (existingIndex !== -1) {
          // Replace the object at the found index with the new object
          updatedCAPADetail = state.CAPADetail.map((detail, index) =>
            index === existingIndex ? { ...detail, ...action.payload } : detail
          );
        } else {
          // Add a new object with id as the highestId + 1
          updatedCAPADetail = [
            ...state.CAPADetail,
            {
              id: highestId + 1, // Increment the highest existing ID
              ...action.payload
            }
          ];
        }
      
        return {
          ...state,
          CAPADetail: updatedCAPADetail,
          formEdit: { ...state.formEdit, CAPA: false }, // Reset form edit state for CAPA
          CAPA: {} // Reset CAPA object
        };
      }


      case INCIDENTFWORKLOW_REMOVE_INVESTIGATION_MEMBER:
      return {
        ...state,
        Assign_Investigation: {
          ...state.Assign_Investigation,
          "Investigation Members": state.Assign_Investigation["Investigation Members"].filter(member => member !== action.payload),
        },
      };
      
  
      case INCIDENTFWORKLOW_UPDATE_SWITCH_STATE:
        return {
          ...state,
          switchStates: {
            ...state.switchStates,
            [action.payload.sectionName]: action.payload.value, // Update the specific switch state
          },
        };
  
      case INCIDENTFWORKLOW_EDIT_CAPA_DETAIL: {
        const { id, title } = action.payload;
        let detailArray;
        switch (title) {
          case 'CAPA':
            detailArray = state.CAPADetail;
            break;
          case 'Personnel':
            detailArray = state.PersonnelDetail;
            break;
         
          case 'Evidence':
            detailArray = state.EvidenceDetail;
            break;
          default:
            detailArray = [];
            break;
        }
  
        const detailObject = detailArray.find(detail => detail.id === id);
  
        return {
          ...state,
          formEdit:{...state.formEdit, [title]:true},
          [title]: title === "Personnel" 
            ? {
                ...state[title],     // Keep the existing data of Personnel
                ...detailObject      // Merge the new data from detailObject
              }
            : detailObject || {}    // For other titles, simply set the detailObject
        };
      };
      
  
   
        case INCIDENTFWORKLOW_DELETE_CARD_DETAIL: {
          const { id, title } = action.payload;
          let updatedDetailArray;
        
          // Determine which detail array to modify based on the title
          switch (title) {
            case 'CAPA':
              updatedDetailArray = state.CAPADetail.filter(detail => detail.id !== id);
              return {
                ...state,
                CAPADetail: updatedDetailArray, // Update CAPADetail
              };
        
            case 'Personnel':
              updatedDetailArray = state.PersonnelDetail.filter(detail => detail.id !== id);
              return {
                ...state,
                PersonnelDetail: updatedDetailArray, // Update PersonnelDetail
              };
        
            case 'Evidence':
              updatedDetailArray = state.EvidenceDetail.filter(detail => detail.id !== id);
              return {
                ...state,
                EvidenceDetail: updatedDetailArray, // Update EvidenceDetail
              };
        
            default:
              return state; // Return the current state if the title doesn't match any case
          }
        }

    default:
      return state;
  }
};

export default incidentWorkFlowReducer;
