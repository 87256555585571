import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  IconButton,
  Paper,
  Button,
  Divider,
  Accordion,
  AccordionSummary,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { keyframes } from '@mui/system';
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import GridViewIcon from "@mui/icons-material/GridView";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import EastIcon from "@mui/icons-material/East";
import TourBegin from "./TourBegin";
import { disciplineCover } from "../../constant";
import { useSelector, useDispatch } from "react-redux";
import { addNewCheckpoints, deleteCheckpoint, editCheckpoint, addSurveyDate } from "../../redux/LossControl/actions";
import AddPointModal from "./AddPointModal";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from "@mui/icons-material/Close";
import AlertModel from "../AlertModel";



const CenteredIconBox = ({
  children,
  backgroundColor = "rgba(0, 159, 245, 0.10)",
  borderRadius = "6px",
  padding = "8px",
  ...props
}) => {
  return (
    <Box
      sx={{
        backgroundColor: backgroundColor,
        borderRadius: borderRadius,
        padding: padding,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
      }}
      {...props}
    >
      {children}
    </Box>
  );
};

const blinkAnimation = keyframes`
  0%, 100% { opacity: 1; }
  50% { opacity: 0; }
`;

export default function Observations({ handleNext, handleBack,draftID,draftedData }) {
  const localData = localStorage.getItem("loginData");
  const data = localData ? JSON.parse(localData):{};
  const userFullName = `${data?.firstName || ""} ${data?.lastName || ""}`;
  const [selectedCardIndex, setSelectedCardIndex] = useState(0);
  const [listView, setListView] = useState(true);
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [selectedID, setSelectedID] = useState(null);
  
  const [beginClicked, setBeginClicked] = useState(false);
  const [editDateMode, setEditDateMode] = useState(false);
  const [initialCardsCount, setInitialCardsCount] = useState(null);
  const[editCardId, setEditCardId] = useState(null);
  const[editStatement, setEditStatement] = useState("");
  const [cards, setCards] = useState([]);
  const date = new Date();
  const today = dayjs();
  const sevenDaysBeforeToday = today.subtract(7, "day");
  const [surveyDate, setSurveyDate] = useState(today);

  const dispatch = useDispatch();
  const tourData = useSelector((state) => state.lossControlReducer?.tours);

  useEffect(() => {
    if (tourData?.questionnaire) {
      setInitialCardsCount(tourData.questionnaire?.length);
    }
  }, []);

  useEffect(() => {
    if (Array.isArray(tourData.questionnaire)) {
      setCards(tourData.questionnaire);
    }
  }, [tourData.questionnaire]);

  

  

  useEffect(()=>{
    // Convert the surveyDate to epoch before dispatching
    const epochDate = surveyDate.valueOf();
    dispatch(addSurveyDate(epochDate));
  },[surveyDate])


  if(draftedData && draftedData?.draftID){
    return (
      <>
      <Box
        sx={{
          backgroundColor: "#ffffff",
          borderRadius: "8px",
          padding: "16px",
          minHeight: "auto",
        }}
      >
        <TourBegin
        handleNext={handleNext}
        initialCardsCount={tourData.questionnaire?.length}
        draftID={draftedData.draftID}
        draftedData={draftedData}
        byDraft={true}
      />
      </Box>
      </>
      
    );
  }

  const handleDateChange = (value) => {
    setSurveyDate(value);
    setEditDateMode(false);
  }
  

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const addCard = (newCard) => {
    dispatch(addNewCheckpoints(newCard))
    setCards([...cards, newCard]);

  };


  const handleChange = (index) => {
    setSelectedCardIndex(selectedCardIndex === index ? 0 : index);
  };

  const handleEditDiscipline = () => {
    handleBack();
  }

  const deleteCard = (id) => {
    setSelectedID(id);
    setOpen2(true);
  };

  const handleProceed = async () => {
    if(selectedID){
      dispatch(deleteCheckpoint(selectedID));
      setSelectedID(null);
      setOpen2(false); 
    }
   
  }

  const handleClose2 = () => {
    setOpen2(false); // Close dialog on cancel
  };

  //------------Edit New Added Points----------------------
  
  const editCard = (id, quesName) => {
    setEditCardId(id);
    setEditStatement(quesName);
  };
  const handleEditCardSave = () => {
    dispatch(editCheckpoint(editCardId, editStatement));
    setEditCardId(null);
    setEditStatement("");
  };

// console.log("sddd",initialCardsCount);
  return (
    <>
      <Box
        sx={{
          backgroundColor: "#ffffff",
          borderRadius: "8px",
          padding: "16px",
          minHeight: "auto",
        }}
      >
        {!beginClicked ? (
          <Grid container spacing={0} sx={{ display: "flex" }}>
            <Grid
              item
              xs={12}
              md={6}
              sx={{ display: "flex", flexDirection: "column" }}
            >
              <Box
                sx={{
                  borderRadius: "8px",
                  background: "#FFF",
                  boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.16)",
                  padding: "16px",
                  flexGrow: 1,
                  height: "530px",
                  width: "98%",
                  alignItems: "flex-start",
                  gap: "10px",
                }}
              >
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Box>
                    <Box
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      gap={0}
                    >
                      <Typography
                        sx={{
                          color: "#222222",
                          fontSize: "18px",
                          fontWeight: "600",
                        }}
                      >
                        {tourData.disciplineName}
                      </Typography>
                      <IconButton>
                        <ModeEditOutlineOutlinedIcon
                          onClick={handleEditDiscipline}
                          fontSize="small"
                          sx={{ color: "#009FF5" }}
                        />
                      </IconButton>
                    </Box>
                    <Typography
                      variant="body2"
                      sx={{ color: "#525966", fontSize: "14px" }}
                    >
                      Loss Control Tour
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    gap={1}
                  >
                    <CenteredIconBox
                      backgroundColor={
                        listView ? "#009FF5" : "rgba(0, 159, 245, 0.10)"
                      }
                      onClick={() => setListView(1)}
                    >
                      <FormatListBulletedIcon
                        fontSize="small"
                        sx={{ color: listView ? "white" : "#009FF5" }}
                      />
                    </CenteredIconBox>
                    <CenteredIconBox
                      backgroundColor={
                        !listView ? "#009FF5" : "rgba(0, 159, 245, 0.10)"
                      }
                      onClick={() => setListView(0)}
                    >
                      <GridViewIcon
                        fontSize="small"
                        sx={{ color: !listView ? "white" : "#009FF5" }}
                      />
                    </CenteredIconBox>
                  </Box>
                </Box>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  gap={1}
                  sx={{ marginY: "16px" }}
                >
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    gap={0.5}
                  >
                    <PersonOutlineOutlinedIcon
                      sx={{ color: "#525966", width: "20px", height: "20px" }}
                    />
                    <Typography
                      sx={{
                        color: "#525966",
                        fontSize: "12px",
                        fontWeight: "400",
                      }}
                    >
                      {userFullName}
                    </Typography>
                  </Box>
                  {!editDateMode ? (
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    gap={0.5}
                    sx={{cursor:'pointer'}}
                    onClick={()=>setEditDateMode(true)}
                  >
                    <CalendarMonthOutlinedIcon
                      sx={{ color: "#525966", width: "20px", height: "20px" }}
                    />
                    <Typography
                      sx={{
                        color: "#525966",
                        fontSize: "12px",
                        fontWeight: "400",
                      }}
                    >
                      {surveyDate
                        ? new Date(surveyDate).toLocaleDateString('en-GB', {
                            day: '2-digit',
                            month: '2-digit',
                            year: 'numeric',
                          })
                        : 'N/A'}
                    </Typography>
                  </Box>) : (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      renderInput={(params) => (
                        <TextField {...params} fullWidth variant="outlined" />
                      )}
                      value={surveyDate}
                      format="DD-MM-YYYY"
                      minDate={sevenDaysBeforeToday}
                      maxDate={today}
                      onChange={(newValue) => handleDateChange(newValue)}
                      slotProps={{
                        textField: {
                          size: "small",
                          sx: {
                            "& .MuiOutlinedInput-input": {
                              height: "0.7rem", // Adjust height as needed
                              padding: "0.5rem", // Adjust padding for proper alignment
                            },
                          },
                        },
                      }}
                      sx={{
                        width: "30px", // Adjust the width as needed
                      }}
                    />
                  </LocalizationProvider>)}
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    gap={0.5}
                  >
                    <LocationOnOutlinedIcon
                      sx={{ color: "#525966", width: "20px", height: "20px" }}
                    />
                    <Typography
                      sx={{
                        color: "#525966",
                        fontSize: "12px",
                        fontWeight: "400",
                      }}
                    >
                      {tourData.siteLocation}
                    </Typography>
                  </Box>
                </Box>

                {/* Grid View */}
                {listView === 0 && (
                  <Box>
                    {/* Detailed View Section */}
                    <Paper
                      elevation={1}
                      sx={{
                        padding: "12px",
                        mb: 2,
                        boxShadow: "0px 3px 20px 1px rgba(0, 0, 0, 0.1)",
                        borderRadius: "0px 8px 8px 0px",
                        borderTop: "0.5px solid #09A2E3",
                        borderRight: "0.5px solid #09A2E3",
                        borderBottom: "0.5px solid #09A2E3",
                        borderLeft: "4px solid #09A2E3",
                        background: "rgba(230, 246, 254, 0.40)",
                      }}
                    >
                      <Grid
                        container
                        sx={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                        }}
                      >
                        <Grid
                          item
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            sx={{
                              backgroundColor: "#09A2E3",
                              borderRadius: "50%",
                              width: "24px",
                              height: "24px",
                            }}
                          >
                            <Typography
                              sx={{
                                color: "#ffffff",
                                fontSize: "14px",
                                fontWeight: "700",
                              }}
                            >
                              {cards[selectedCardIndex].id}
                            </Typography>
                          </Box>

                          <Typography
                            variant="caption"
                            sx={{ color: "#525966", marginLeft: "8px" }}
                          >
                            {cards[selectedCardIndex].quesName}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Paper>

                    <Divider sx={{ mb: 2 }} />

                    {/* Cards Grid */}
                    <div
                      style={{
                        maxHeight: "calc(5 * 40px)",
                        overflowY: "auto",
                        padding: "4px",
                      }}
                    >
                      <Grid container spacing={1}>
                        {cards?.map((card, index) => (
                          <Grid item xs={3} sm={2} md={1.5} key={card.id}>
                            <Box
                              onClick={() => handleChange(index)}
                              sx={{
                                cursor: "pointer",
                                color:
                                  index === selectedCardIndex
                                    ? "#009FF5"
                                    : "black",
                                fontWeight: "700",
                                borderRadius: "4px",
                                backgroundColor:
                                  index === selectedCardIndex
                                    ? "rgba(0, 159, 245, 0.10)"
                                    : "white",
                                border:
                                  index === selectedCardIndex
                                    ? "0.5px solid #009FF5"
                                    : "none",
                                boxShadow:
                                  "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                                padding: "8px 16px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              {card.id}
                            </Box>
                          </Grid>
                        ))}
                      </Grid>
                    </div>
                  </Box>
                )}

                {/* List View */}
                {listView !== 0 && (
                  <Box>
                    <Divider sx={{ mb: 2 }} />
                    <div
                      style={{ maxHeight: "calc(8 * 40px)", overflowY: "auto" }}
                    >
                      <Grid container spacing={1}>
                        {cards?.map((card, index) => (
                          <Grid item xs={12} key={card.id}>
                            <Accordion
                              expanded={selectedCardIndex === index}
                              onChange={() => handleChange(index)}
                              sx={{
                                borderRadius: "8px",
                                backgroundColor: "#F9FAFC",
                                border: "0.5px solid rgba(9, 162, 227, 0.20)",
                                boxShadow: "none",
                                overflow: "hidden",
                                transition: "max-height 0.3s ease",
                                paddingY: "0px !important",
                                
                              }}
                            >
                              <AccordionSummary
                                sx={{
                                  height: "40px",
                                  padding: 0,
                                  justifyContent: "flex-start",
                                  position:'relative',
                                  "& .MuiAccordionSummary-expandIconWrapper": {
                                    display: "none",
                                  },
                                }}
                              >
                                  <Box
                                    display="flex"
                                    flexDirection="row"
                                    alignItems="center"
                                    gap={1}
                                  >
                                    <Box
                                      display="flex"
                                      alignItems="center"
                                      justifyContent="center"
                                      sx={{
                                        backgroundColor: "#E6F6FE",
                                        borderRadius: "50%",
                                        width: "16px",
                                        height: "16px",
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          color: "#09A2E3",
                                          fontSize: "10px",
                                          fontWeight: "700",
                                        }}
                                      >
                                        {card.id}
                                      </Typography>
                                    </Box>
                                    <Typography
                                      variant="body2"
                                      sx={{ color: "#525966" }}
                                    >
                                      {selectedCardIndex === index
                                        ? card.quesName // Show full text if expanded
                                        : card?.quesName?.length > 20
                                          ? `${card.quesName.substring(0, 30)}...` // Show truncated text if not expanded
                                          : card?.quesName}
                                    </Typography>
                                  </Box>
                                  <Box sx={{position:'absolute', right:2, top: 3}}>
                                    {card.id > initialCardsCount ? (
                                      <Box display='flex' flexDirection='row' alignItems='center' gap={1}>
                                        <IconButton
                                          sx={{
                                            fontSize: 16,
                                            borderRadius:'50%',
                                            color: "#4D94FF95",
                                            backgroundColor: '#4D94FF10', 
                                          }}
                                          onClick={(event) => {event.stopPropagation(); editCard(card.id, card.quesName)}}
                                        >
                                          <EditIcon />
                                        </IconButton>
                                        <IconButton
                                          sx={{
                                            fontSize: 16,
                                            color: "#FF4D4D95",
                                            borderRadius:'50%',
                                            backgroundColor:'#FF4D4D10',
                                          }}
                                          onClick={(event) => {event.stopPropagation(); deleteCard(card.id)}}
                                        >
                                          <DeleteIcon />
                                        </IconButton>
                                      </Box>
                                    ) : (
                                      <></>
                                    )}
                                  </Box>
                              </AccordionSummary>
                            </Accordion>
                          </Grid>
                        ))}
                      </Grid>
                    </div>
                  </Box>
                )}
                <Box display="flex" justifyContent="flex-end" mt={1} mb={1}>
                  <Button
                    variant="contained"
                    onClick={handleOpen}
                    sx={{
                      fontSize: "14px",
                      fontWeight: "700",
                      backgroundColor: "#5fc8f5",
                      color: "#ffffff",
                      paddingY: "8px",
                      paddingX: "32px",
                      borderRadius: "24px",
                    }}
                  >
                    Add More Points
                  </Button>
                  <AddPointModal
                    open={open}
                    handleClose={handleClose}
                    surveyDate = {surveyDate}
                    addCard={addCard}
                  />
                </Box>
              </Box>
            </Grid>

            {/* Start Tour Grid */}
            <Grid
              item
              xs={12}
              md={6}
              sx={{ display: "flex", flexDirection: "column" }}
            >
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                sx={{
                  padding: "16px",
                  flexGrow: 1,
                  textAlign: "center",
                  backgroundImage: `url(${disciplineCover})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  height: "433px",
                  width: "100%",
                  borderRadius: "12px",
                  border: "0.5px solid rgba(9, 162, 227, 0.20)",
                  backdropFilter: "blur(7px)",
                }}
              >
                <Typography
                  sx={{ color: "#323232", fontSize: "28px", fontWeight: "600" }}
                >
                  Ready to start the tour?
                </Typography>

                <Button
                  sx={{
                    borderRadius: "24px",
                    border: "1px solid #09A2E3",
                    backgroundColor: "rgba(9, 162, 227, 0.10)",
                    width: "200px",
                    padding: "8px 16px",
                    marginTop: "20px",
                    fontSize: "18px",
                    letterSpacing: "0.3px",
                    fontWeight: "400",
                    
                  }}
                  onClick={() => setBeginClicked(true)}
                >
                  Click to begin  <EastIcon
                    sx={{
                      ml: 2,
                      animation: `${blinkAnimation} 1s infinite`
                    }}
                  />
                </Button>
              </Box>
            </Grid>
          </Grid>
        ) : (
          <TourBegin handleNext={handleNext} initialCardsCount={initialCardsCount} draftID={draftID} byDraft={false}  />
        )}

        <Dialog open={editCardId !== null} onClose={()=>{setEditCardId(null); setEditStatement("");}} fullWidth maxWidth="sm">
          <DialogTitle>
            Edit Point
            <IconButton
              aria-label="close"
              onClick={()=>{setEditCardId(null); setEditStatement("");}}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <TextField
              fullWidth
              multiline
              rows={4}
              value={editStatement}
              onChange={(e) => setEditStatement(e.target.value)}
              variant="outlined"
              // label="Statement"
            />
          </DialogContent>
          <DialogActions>
            <Button disabled={editStatement?.length < 6} onClick={handleEditCardSave} variant="contained" sx={{color:'white'}}>
              Save
            </Button>
          </DialogActions>
        </Dialog>

        <AlertModel
        open={open2}
        onClose={handleClose2}
        onProceed={handleProceed}
        // title={title}
        description={"Are you Sure wants to delete this point ?"}
      />
      </Box>
    </>
  );
}
