import React, { useEffect, useState } from "react";
import { 
  Box, 
  Typography, 
  TextField, 
  MenuItem, 
  Button, 
  Select,
  Grid,
  IconButton,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CloseIcon from "@mui/icons-material/Close"
import { createTicket, createTicketbyAdmin, getModuleSections, getUsersForOrganization } from "../../Apis/apiCall";
import Compressor from 'compressorjs';
import { useNavigate } from "react-router-dom";
import CompletionModal from "../../Components/LossControl/CompletionModal";
import Loader from "../../Components/Loader/Loader";
import { useSelector, useDispatch } from "react-redux";
import { START_LOADING, STOP_LOADING } from "../../redux/Loader/constant"
import ConfirmationModal from "../../Components/LossControl/ConfirmationModal";

const CreateTicket = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.loadingReducer.loading);

  const [selectedUserName,setSelectedUserName] = useState(null); //stores id of selected user
  const [userNameOptions,setUserNameOptions] = useState([]);

  //in case of other user
  const [ifNoUser, setIfNoUser] = useState(0);
  const [otherName, setOtherName] = useState(null);
  const [otherEmail, setOtherEmail] = useState(null);
  const [otherMobile, setOtherMobile] = useState(null);

  const [module,setModule] = useState(null);
  const [subSectionOptions,setSubSectionOptions] = useState([]);

  const [uploadedFiles,setUploadedFiles] = useState([]);
  const [open, setOpen] = useState(false);

  const [flow, setFlow] = useState(null);
  
  const logindata = JSON.parse(localStorage.getItem("loginData"));
  const role = logindata.config.permissionDetails.applicationRoles[0].name;

  useEffect(() => {
    if (role === "Support Agent") {
      setFlow(2);
    }
    else if (role === "Support Admin") {
      setFlow(3);
    }
    else{
      setFlow(1);
    }
  }, []);

  useEffect(() => {
    if(selectedUserName === "Other"){
      setIfNoUser(1);
    }
  },[selectedUserName])

  useEffect(() => {
    fetchModuleSections(module);
  },[module])
  
  useEffect(() => {
    if(flow===2 || flow===3){
      fetchUserNames();
    }
  },[flow])

  const fetchModuleSections = async(module) => {
    try {
      const res = await getModuleSections(module);
      setSubSectionOptions(res.data.data);
    } catch (error) {
      console.error("Error while fetching module sections",error);
    }
  }
  
  const fetchUserNames = async() => {
    try {
      const res = await getUsersForOrganization();
      setUserNameOptions(res.data.data);
    } catch (error) {
      console.error("Error while fetching user names",error);
    }
  }

  // Dropdown options
  const moduleOptions = [
    { value: 1, label: "Incident Management" },
    { value: 2, label: "Loss Control" },
    { value: 3, label: "CAPA Management" },
  ];

  const priorityOptions = [
    { value: "high", label: "High" },
    { value: "medium", label: "Medium" },
    { value: "low", label: "Low" }
  ];

  const [formData, setFormData] = useState({
    module: "",
    section: "",
    priority: "",
    issueSummary: "",
    issueDescription: "",
    attachments: []
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    if(name === 'module'){
      setModule(value);
    }
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };


  const handleEvidenceUpload = (event) => {
    const files = Array.from(event.target.files);
    const filePromises = files.map((file) => {
      return new Promise((resolve, reject) => {
        // Check if the file is an image
        if (file.type.startsWith("image/")) {
          // Compress image files
          new Compressor(file, {
            quality: 0.2,
            success(compressedFile) {
              const reader = new FileReader();
              reader.onload = (e) => {
                resolve({
                  base64: e.target.result.split(",")[1],
                  name: compressedFile.name,
                  type: compressedFile.type,
                });
              };
              reader.onerror = reject;
              reader.readAsDataURL(compressedFile);
            },
            error(err) {
              reject(err);
            },
          });
        } else {
          // Directly read non-image files as base64 without compression
          const reader = new FileReader();
          reader.onload = (e) => {
            resolve({
              base64: e.target.result.split(",")[1],
              name: file.name,
              type: file.type,
            });
          };
          reader.onerror = reject;
          reader.readAsDataURL(file);
        }
      });
    });
  
    Promise.all(filePromises)
      .then((newFiles) => {
        const updatedFiles = [
          ...uploadedFiles,
          ...newFiles.filter(
            (newFile) =>
              !uploadedFiles.some(
                (existingFile) => existingFile.name === newFile.name
              )
          ),
        ];
        setUploadedFiles(updatedFiles);
      })
      .catch((error) => {
        console.error("File upload/compression failed:", error);
      });
  };

  const handleRemoveFile = (index) => {
    setFormData(prev => ({
      ...prev,
      attachments: prev.attachments.filter((_, i) => i !== index)
    }));
    setUploadedFiles(prev => prev.filter((_, i) => i !== index));
  };

  const handleConfirmationOpen = () => {
    setOpen(true);
  }

  const handleConfirmationClose = () => {
    setOpen(false);
  }

  const handleYes = () => {
    handleSubmit();
    handleConfirmationClose();
  }

  const handleSubmit = async() => {
    if(flow === 2){
      if(selectedUserName === "Other"){
        const payload = {
          moduleID: formData.module,
          section: formData.section, 
          priority: formData.priority, 
          issueSummary: formData.issueSummary,
          issueDescription: formData.issueDescription, 
          attachments: uploadedFiles?.map((file) => ({
            name: file.name,
            type: file.type,
            base64: file.base64,
          })),
          ifNoUser : 1,
          name:otherName,
          email:otherEmail,
          mobileNumber: otherMobile
        };
        dispatch({type : START_LOADING})
        try {
          await createTicket(payload);
          navigate(`/support`)
        } catch (error) {
          console.error("error while crreating ticket")
        }
        finally{
          dispatch({ type: STOP_LOADING }); 
        }
      } else {
        const payload = {
          moduleID: formData.module,
          section: formData.section, 
          priority: formData.priority, 
          issueSummary: formData.issueSummary,
          issueDescription: formData.issueDescription, 
          attachments: uploadedFiles?.map((file) => ({
            name: file.name,
            type: file.type,
            base64: file.base64,
          })),
          ifNoUser : 0,
          userID: selectedUserName
        };
        dispatch({type : START_LOADING})
        try {
          await createTicket(payload);
          navigate(`/support`)
        } catch (error) {
          console.error("error while crreating ticket")
        }
        finally{
          dispatch({ type: STOP_LOADING }); 
        }
      }
    }
    else if(flow === 3){
      if(selectedUserName === "Other"){
        const payload = {
          moduleID: formData.module,
          section: formData.section, 
          priority: formData.priority, 
          issueSummary: formData.issueSummary,
          issueDescription: formData.issueDescription, 
          attachments: uploadedFiles?.map((file) => ({
            name: file.name,
            type: file.type,
            base64: file.base64,
          })),
          ifNoUser : 1,
          name:otherName,
          email:otherEmail,
          mobileNumber: otherMobile
        };
        dispatch({type : START_LOADING})
        try {
          await createTicketbyAdmin(payload);
          navigate(`/support`)
        } catch (error) {
          console.error("error while crreating ticket")
        }
        finally{
          dispatch({ type: STOP_LOADING }); 
        }
      } else {
        const payload = {
          moduleID: formData.module,
          section: formData.section, 
          priority: formData.priority, 
          issueSummary: formData.issueSummary,
          issueDescription: formData.issueDescription, 
          attachments: uploadedFiles?.map((file) => ({
            name: file.name,
            type: file.type,
            base64: file.base64,
          })),
          ifNoUser : 0,
          userID: selectedUserName
        };
        dispatch({type : START_LOADING})
        try {
          await createTicket(payload);
          navigate(`/support`)
        } catch (error) {
          console.error("error while crreating ticket")
        }
        finally{
          dispatch({ type: STOP_LOADING }); 
        }
      }
    }
    else {
      const payload = {
        moduleID: formData.module,
        section: formData.section, 
        priority: formData.priority, 
        issueSummary: formData.issueSummary,
        issueDescription: formData.issueDescription, 
        attachments: uploadedFiles?.map((file) => ({
          name: file.name,
          type: file.type,
          base64: file.base64,
        })), 
      };
      dispatch({type : START_LOADING})
      try {
        await createTicket(payload);
        navigate(`/support`)
      } catch (error) {
        console.error("error while crreating ticket")
      }
      finally{
        dispatch({ type: STOP_LOADING }); 
      }
    }
  };

  const handleBack = () => {
    navigate(`/support`)
  }

  return (
    <>
    {loading && ( 
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            zIndex: 1000,
          }}
        >
          <Loader />
        </Box>
      )}
    <Box>
      <Typography sx={{ fontSize: "20px", color: "#2E2C34", fontWeight: 600 }}>
        New Ticket
      </Typography>

      <Box
        sx={{
          p: 2,
          width: "100%",
          mx: "auto",
          borderRadius: 2,
          backgroundColor: "#fff",
          mt: 2,
        }}
      >
        <Typography
          sx={{ fontSize: "16px", color: "#2E2C34", fontWeight: 600, mb: 2 }}
          gutterBottom
        >
          Create Ticket
        </Typography>

        <form onSubmit={handleSubmit}>
          <Box sx={{ display: "flex", justifyContent: 'space-between', mb: 2 }}>
            <Grid container spacing={2}>
              {(flow === 2 || flow === 3) && (
                <Grid item xs={12}>
                <Typography
                  sx={{ fontSize: "14px", color: "#2E2C34", fontWeight: 600 }}
                  gutterBottom
                >
                  User Name
                </Typography>
                  <Select
                    name="user"
                    value={selectedUserName}
                    onChange={(e)=>setSelectedUserName(e.target.value)}
                    displayEmpty
                    fullWidth
                    IconComponent={(props) => (
                      <KeyboardArrowDownIcon
                        {...props}
                        sx={{ fontSize: "20px", color: "#B0B0B0" }}
                      />
                    )}
                    sx={{
                      fontSize: "14px",
                      alignItems: "center",
                      borderRadius: "4px",
                      border: "0.794px solid #E7E7E7",
                      height: "34px",
                      minHeight: "unset",
                      color: "#9A9FA5",
                      padding: '0px !important'
                    }}
                  >
                    {userNameOptions?.map((option, index) => (
                      <MenuItem key={index} value={option.id} sx={{padding: '0px !important'}}>
                        {option.name}
                      </MenuItem>
                    ))}
                    <MenuItem value="Other" sx={{padding: '0px !important'}}>
                      Other User
                    </MenuItem>
                  </Select>
                </Grid>
              )}
              {selectedUserName === "Other" && (
                <>
                <Grid item xs={4}>
                  <Typography
                    sx={{ fontSize: "14px", color: "#2E2C34", fontWeight: 600, mb: 1 }}
                    gutterBottom
                  >
                    Name
                  </Typography>
                  <TextField
                    fullWidth
                    name="otherName"
                    value={otherName}
                    onChange={(e)=>setOtherName(e.target.value)}
                    placeholder="Enter Name"
                    sx={{mb:1}}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    sx={{ fontSize: "14px", color: "#2E2C34", fontWeight: 600, mb: 1 }}
                    gutterBottom
                  >
                    Email
                  </Typography>
                  <TextField
                    fullWidth
                    name="otherEmail"
                    value={otherEmail}
                    onChange={(e)=>setOtherEmail(e.target.value)}
                    placeholder="Enter Email"
                    sx={{mb:1}}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    sx={{ fontSize: "14px", color: "#2E2C34", fontWeight: 600, mb: 1 }}
                    gutterBottom
                  >
                    Mobile Number
                  </Typography>
                  <TextField
                    fullWidth
                    name="otherMobile"
                    value={otherMobile}
                    onChange={(e)=>setOtherMobile(e.target.value)}
                    placeholder="Enter Mobile Number"
                    sx={{mb:1}}
                  />
                </Grid>
                </>
              )}
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <Typography
                  sx={{ fontSize: "14px", color: "#2E2C34", fontWeight: 600 }}
                  gutterBottom
                >
                  Modules
                </Typography>
                <Select
                  name="module"
                  value={formData.module}
                  onChange={handleChange}
                  displayEmpty
                  fullWidth
                  IconComponent={(props) => (
                    <KeyboardArrowDownIcon
                      {...props}
                      sx={{ fontSize: "20px", color: "#B0B0B0" }}
                    />
                  )}
                  sx={{
                    fontSize: "14px",
                    alignItems: "center",
                    borderRadius: "4px",
                    border: "0.794px solid #E7E7E7",
                    height: "34px",
                    minHeight: "unset",
                    color: "#9A9FA5",
                    padding: '0px !important'
                  }}
                >
                  {moduleOptions?.map((option) => (
                    <MenuItem key={option.value} value={option.value} sx={{padding: '0px !important'}}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>

              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <Typography
                  sx={{ fontSize: "14px", color: "#2E2C34", fontWeight: 600 }}
                  gutterBottom
                >
                  Sub-Section
                </Typography>
                <Select
                  name="section" // Updated to match formData
                  value={formData.section}
                  onChange={handleChange}
                  displayEmpty
                  fullWidth
                  disabled={!formData.module}
                  sx={{
                    fontSize: "14px",
                    alignItems: "center",
                    borderRadius: "4px",
                    border: "0.794px solid #E7E7E7",
                    height: "34px",
                    minHeight: "unset",
                    color: "#9A9FA5",
                    padding: '0px !important'
                  }}
                >
                  {subSectionOptions?.map((option) => (
                    <MenuItem key={option.section} value={option.section} sx={{padding: '0px !important'}}>
                      {option.section}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>

              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <Typography
                  sx={{ fontSize: "14px", color: "#2E2C34", fontWeight: 600 }}
                  gutterBottom
                >
                  Priority
                </Typography>
                <Select
                  name="priority"
                  value={formData.priority}
                  onChange={handleChange}
                  displayEmpty
                  fullWidth
                  IconComponent={(props) => (
                    <KeyboardArrowDownIcon
                      {...props}
                      sx={{ fontSize: "20px", color: "#B0B0B0" }}
                    />
                  )}
                  sx={{
                    fontSize: "14px",
                    alignItems: "center",
                    borderRadius: "4px",
                    border: "0.794px solid #E7E7E7",
                    height: "34px",
                    minHeight: "unset",
                    color: "#9A9FA5",
                    padding: '0px !important'
                  }}
                >
                  {priorityOptions?.map((option) => (
                    <MenuItem key={option.value} value={option.value} sx={{padding: '0px !important'}}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>
          </Box>

          {/* Issue Summary */}
          <Typography
            sx={{ fontSize: "14px", color: "#2E2C34", fontWeight: 600, mb: 1 }}
            gutterBottom
          >
            Issue Summary
          </Typography>
          <TextField
            fullWidth
            name="issueSummary" // Updated to match formData
            value={formData.issueSummary}
            onChange={handleChange}
            placeholder="Type ticket issue here..."
            sx={{mb:1}}
          />

          {/* Issue Description */}
          <Typography
            sx={{ fontSize: "14px", color: "#2E2C34", fontWeight: 600 }}
            gutterBottom
          >
            Issue Description
          </Typography>
          <TextField
            fullWidth
            name="issueDescription" // Updated to match formData
            value={formData.issueDescription}
            onChange={handleChange}
            multiline
            rows={1}
            placeholder="Issue description"
            sx={{mb:1}}
          />

          {/* Attachments Section */}
          <Typography
            sx={{ fontSize: "14px", color: "#2E2C34", fontWeight: 600, mb: 2 }}
            gutterBottom
          >
            Attachments
          </Typography>
          <Box
            sx={{
              width: "50%",
              border: "2px dashed #09A2E3",
              borderRadius: 1,
              p: 3,
              mb: 2,
              textAlign: "center",
              cursor: "pointer",
              "&:hover": {
                borderColor: "primary.main",
              },
            }}
            onClick={() => document.getElementById("file-input").click()}
          >
            <input
              id="file-input"
              type="file"
              multiple
              accept=".png,.jpg,.doc,.pdf,.mp4"
              onChange={handleEvidenceUpload}
              style={{ display: "none" }}
            />
            <CloudUploadIcon sx={{ fontSize: 40, color: "black", mb: 1 }} />
            <Typography
              variant="body2"
              color="#09A2E3"
              sx={{ textDecoration: "underline" }}
            >
              Click to upload
            </Typography>
            <Typography variant="caption" color="text.secondary" display="block">
              PNG, JPG, Doc, Pdf and Mp4 (max. 10 MB)
            </Typography>
          </Box>

          {/* File Preview Section */}
          {uploadedFiles.length > 0 && (
  <Box sx={{ mb: 3, display: 'flex', gap: 2, flexWrap: 'wrap' }}>
    {uploadedFiles.map((file, index) => (
      <Box
        key={index}
        sx={{
          border: '1px solid #e0e0e0',
          borderRadius: '4px',
          width: '80px',
          height: '80px',
          position: 'relative',
          overflow: 'hidden',
          textAlign: 'center',
        }}
      >
        <IconButton
          edge="end"
          onClick={() => handleRemoveFile(index)}
          sx={{
            position: 'absolute',
            top: 0,
            right: 0,
            color: 'error.main',
          }}
        >
          <CloseIcon sx={{ fontSize: '16px' }} />
        </IconButton>
        {file.type.startsWith('image/') ? (
          <img
            src={`data:${file.type};base64,${file.base64}`}
            alt={file.name}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}
          />
        ) : (
          <Typography
            variant="caption"
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
              color: '#757575',
              fontSize: '12px',
              textAlign: 'center',
              padding: 1,
            }}
          >
            {file.name}
          </Typography>
        )}
      </Box>
    ))}
  </Box>
)}


          {/* Action Buttons */}
          <Box sx={{ display: "flex", gap: 2, justifyContent: "flex-end" }}>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleBack}
            >
              Back
            </Button>
            <Button
              variant="contained"
              sx={{ backgroundColor: "#09A2E3", color: "white" }}
              onClick={handleConfirmationOpen}
             >
              Submit
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
    <ConfirmationModal
      open={open}
      title="Are you sure?"
      message="Are you sure you want to raise a ticket?"
      onCancel={handleConfirmationClose}
      onConfirm={handleYes}
    />
    </>
  );
};

export default CreateTicket;