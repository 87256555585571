import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import { useDispatch, useSelector } from "react-redux";
import { setCommentsData } from "../../../redux/Questionnaire/actions";

const CommentDrawer = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const [observation, setObservation] = useState("");
  const [CommentData, setCommentData] = useState([]);

  //   const CardCommentData = useSelector(
  //     (state) => state.QuestionnaireReducer.comment_data
  //   );

  const comments = useSelector(
    (state) => state.QuestionnaireReducer.comment_data
  );

  useEffect(() => {
    if (comments) {
      setCommentData(comments);
      console.log("comments---->>>>>.", comments);
    }
  }, [comments]);

  console.log("CommentData.", CommentData);

  const handleSave = () => {
    if (observation.trim()) {
      dispatch(setCommentsData(observation));
      setObservation("");
    }
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      sx={{
        padding: 2,
        boxShadow: "-4px 0px 28px 0px rgba(0, 0, 0, 0.20)",
        "& .MuiDrawer-paper": {
          width: "469px", // Ensure the paper within the drawer also has the same width
        },
      }}
    >
      <>
        <Box>
          <Box
            sx={{
              marginLeft: "-16px",
              marginTop: "-16px",
              borderBottom: "1px solid rgba(171, 171, 171, 0.50)",
              background: "#FFF",
              boxShadow: "0px 2px 4px 0px #ABABAB",
              padding: "16px 24px",
              width: "500px",
            }}
          >
            <Typography
              variant="h6"
              sx={{ color: "#000", fontSize: "20px", fontWeight: "600" }}
            >
              Comments
            </Typography>
          </Box>

          <Box sx={{ padding: 3 }}>
            <Box
              sx={{
                "& .MuiTextField-root": {
                  m: 1, // Margin
                  width: "430px", // Adjust width for proper alignment
                  ml: -2,
                },
              }}
            >
              <TextField
                fullWidth
                id="outlined-required"
                label="Observation"
                value={observation}
                onChange={(e) => setObservation(e.target.value)}
                variant="outlined" // To ensure the same style
                multiline // To achieve a taller text box
                rows={6} // Adjust the number of rows to match the height
                sx={{
                  mb: 2,
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "4px", // Border radius
                    "& fieldset": {
                      border: "1px solid #C1C1C1", // Border color and style
                    },
                    "&:hover fieldset": {
                      borderColor: "#949494", // Border color on hover
                    },
                    "&.Mui-focused fieldset": {
                      border: "1px solid #C1C1C1", // Border color and style, // Border color when focused
                    },
                  },
                  "& .MuiInputBase-input": {
                    color: "#949494", // Input text color
                    fontWeight: "500",
                    fontSize: "12px",
                    height: "2.4375em",
                  },
                  "& .MuiFormLabel-root": {
                    color: "#525966",
                    fontWeight: "400",
                    letterSpacing: "0.15px",
                  },
                }}
              />
            </Box>

            <Button
              variant="contained"
              color="primary"
              onClick={handleSave}
              sx={{
                background: "#367CFF",
                color: "#FFFFFF",
                fontSize: "16px",
                fontWeight: "600",
                borderRadius: "60px",
                border: "1px solid #367cff",
                "&:hover": {
                  background: "#367CFF", // Remove the hover background
                  border: "1px solid #367CFF", // Keep the border as it is
                  color: "#FFFFFF", // Maintain the text color
                },
              }}
            >
              Add
            </Button>

            <Box
              sx={{
                width: 420, // Adjust width as needed
                maxHeight: 350, // Set the height limit for the scrollable container
                overflowY: "auto", // Enable vertical scrolling
                overflowX: "hidden", // Prevent horizontal scrolling
                // margin: "16px",
                padding: "8px",
              }}
            >
              {comments.map((comment, index) => (
                <Box
                  key={index} // Add unique key to each item
                  sx={{
                    marginBottom: 2, // Spacing between cards
                    borderRadius: 4,
                    overflow: "hidden",
                    boxShadow: "0px 2.318px 4.636px 0px rgba(0, 0, 0, 0.16)",
                  }}
                >
                  <Box
                    sx={{
                      padding: "16px",
                      backgroundColor: "#fff",
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        color: "#625B71",
                        fontWeight: "400",
                        fontSize: "20px",
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                      }}
                    >
                      <InfoOutlinedIcon /> Comment
                    </Typography>
                    <Divider variant="middle" />
                  </Box>
                  <Typography sx={{ padding: 2, color: "#333" }}>
                    {comment}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </>

      {/* Drawer Content */}
    </Drawer>
  );
};

export default CommentDrawer;
