import React from 'react';
import { Button, Box } from '@mui/material';

const ButtonInput = ({ buttonNames, selected, onSelect, name }) => {
    return (
        <Box
            display="flex"
            justifyContent="flex-start"
            flexWrap="wrap"
            gap={1}
           
        >
            {buttonNames?.map((buttonName, index) => (
                <Button
                    size="medium"
                    key={index}
                    sx={{
                        fontSize: "12px",
                        textTransform: 'none',
                        color: selected === buttonName ? '#FFF' : '#1E1E1E',
                        fontWeight: '600',
                        border: selected === buttonName ? "none" : '1px solid #B7C8E5',
                        backgroundColor: selected === buttonName ? '#0AA2E3' : 'transparent', // Background color for selected
                        '&:hover': {
                            backgroundColor: selected === buttonName ? '#0AA2E3' : '#f0f0f0' // Hover effect
                        },
                        boxShadow:"none",


                    }}
                    onClick={() => onSelect(buttonName)}
                    variant={selected === buttonName ? 'contained' : 'outlined'}
                >

                    {buttonName === "UA" ? "Unsafe Act" :

                        buttonName === "UC" ? "Unsafe Condition" :

                            buttonName === "NM" ? "Near Miss" :
                                buttonName === "Minor" ? "Minor" :
                                    buttonName === "MR" ? "Minor Reportable" :

                                        buttonName === "DO" ? "Dangerous Occurrence" :

                                            buttonName === "SI" ? "Serious Injury"

                                                : buttonName
                    }

                </Button>
            ))}
        </Box>
    );
};

export default ButtonInput;
