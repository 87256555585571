import React, { useState, useEffect } from "react";
import { Box, Paper, Typography, Grid, Chip, Tooltip, IconButton, Button, Switch } from "@mui/material";
import Slider from "react-slick";
import EditIcon from "@mui/icons-material/Edit";
import { styled } from "@mui/system";
import { DeleteOutline } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { editDetail, deleteCardDetail } from "../redux/incidentReport/actions";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { cardHeadBG } from "../constant";
import TwoWheelerIcon from "@mui/icons-material/TwoWheeler";
import PersonPinIcon from "@mui/icons-material/PersonPin";
import EditNoteIcon from "@mui/icons-material/EditNote";
import BuildOutlinedIcon from "@mui/icons-material/BuildOutlined";
import ContactPageOutlinedIcon from "@mui/icons-material/ContactPageOutlined";
import DateRangeOutlinedIcon from "@mui/icons-material/DateRangeOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ConstructionOutlinedIcon from '@mui/icons-material/ConstructionOutlined';
import DirectionsCarOutlinedIcon from '@mui/icons-material/DirectionsCarOutlined';
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ContactPageIcon from '@mui/icons-material/ContactPage';
import DateRangeIcon from '@mui/icons-material/DateRange';
import PhotoCameraBackIcon from '@mui/icons-material/PhotoCameraBack';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import ArticleIcon from '@mui/icons-material/Article';
import YardIcon from '@mui/icons-material/Yard';
import MenuBookOutlinedIcon from "@mui/icons-material/MenuBookOutlined";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import GppMaybeOutlinedIcon from "@mui/icons-material/GppMaybeOutlined";
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';
import { CalendarIcon } from "@mui/x-date-pickers";
import { convertMillisecondsToDate } from "../utils/helper";


const CustomBox = ({ children, size = "30px", bgColor = "#F6F6F6" }) => {
    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: size,
                height: size,
                borderRadius: "50%",
                backgroundColor: bgColor,
            }}
        >
            {children}
        </Box>
    );
};


function CardDrawer1({ cardData, title, subtitle }) {
    const [selectedWhyId, setSelectedWhyId] = useState(1);
    const [currentIndex, setCurrentIndex] = useState(0);
    console.log("pp", cardData);

    const prevSlide = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? cardData.length - 1 : prevIndex - 1
        );
    };

    const nextSlide = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === cardData.length - 1 ? 0 : prevIndex + 1
        );
    };
    // Function to handle chip click
    const handleClick = (id) => {
        setSelectedWhyId(id); // Update selected why ID
    };

    // Slider settings
    const settings = {
        dots: true, // Show dots below the slider
        infinite: false, // Infinite looping of slides
        speed: 400, // Transition speed
        slidesToShow: 1, // Show 2 slides if subtitle, otherwise 1
        slidesToScroll: 1,
        arrows: false
    };


    const StyledBox2 = styled(Box)({
        ".slick-dots": {
            position: "absolute",
            bottom: "-10px",
        },
        ".slick-slide": {
            paddingRight: "12px",
            maxHeight: "140px",

        },
        ".slick-list": {
            overflowX: "hidden",
            overflowY: 'visible',
            minHeight: '140px',

        },
        ".slick-track": {
            width: "100%",
        },
        maxWidth: "100%",
        maxHeight: "100%",
        // boxSizing: "border-box",
    });

    const renderFile = ({ link, category }) => {
        // console.log(link);

        switch (category) {
            case 'Photo':

                return (
                    <img
                        src={link}
                        alt='Display image'
                        style={{ width: '100%', height: '130px', borderRadius: '4px', cursor: 'pointer' }}
                    //onClick={() => handleClickImage(link)}  // Open dialog on click
                    />
                );
            case 'Video':

                return (
                    <video
                        controls
                        style={{ width: '100%', height: '130px', borderRadius: '4px' }}
                    >
                        <source src={link} />
                        Your browser does not support the video tag.
                    </video>
                );
            case 'Document':
                return (
                    <a href={link} target="_blank" rel="noopener noreferrer" style={{ display: 'block', width: '100%', height: '130px', borderRadius: '4px' }}>
                        <button style={{ width: '100%', height: '100%', borderRadius: '4px' }}>Open PDF</button>
                    </a>
                );
            default:
                return null; // Handle unsupported file types if necessary
        }
    };


    const renderCard = (person, index, title) => (
        <>
            {title === "CAPA" ? (
                <>
                    <Box
                        key={index} // Use index as key or person.id if available
                        sx={{
                            borderRadius: "8px",
                            background: "#FFFFFF",
                            border: "1px solid rgba(0, 0, 0, 0.1)",
                            boxShadow: "0px 0px 8px 0px rgba(0, 0, 0, 0.20)",
                            minWidth: "370px",
                            minHeight: "235px"
                        }}
                    >
                        <Box
                            position="relative"
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            sx={{
                                backgroundColor: "#09A2E3",
                                minHeight: "56px",
                                borderRadius: "8px 8px 0 0",
                                padding: "8px",
                                overflow: "hidden",
                            }}
                        >
                            <Box display="flex" alignItems="center">
                                {/* Profile image */}
                                <Box
                                    sx={{
                                        width: "40px",
                                        height: "40px",
                                        borderRadius: "50%",
                                        backgroundColor: "none",
                                        marginRight: "8px",
                                        overflow: "hidden",
                                    }}
                                >
                                    {person?.image ? (
                                        <Box
                                            component="img"
                                            src={person?.image}
                                            alt="Profile"
                                            sx={{
                                                width: "100%",
                                                height: "100%",
                                                objectFit: "cover",
                                            }}
                                        />
                                    ) : (
                                        <AccountCircleIcon
                                            sx={{ width: "100%", height: "100%", color: "#ffffff" }}
                                        />
                                    )}
                                </Box>
                                {/* Title and ID */}
                                <Box>
                                    <Box display="flex" alignItems="center" gap={1}>
                                        <Typography
                                            sx={{
                                                fontSize: "20px",
                                                fontWeight: "600",
                                                color: "#ffffff",
                                            }}
                                        >
                                            {person?.["CAPA Type"]}
                                        </Typography>
                                        <Chip
                                            label={person?.["CAPA Priority"]}
                                            color="error"
                                            sx={{
                                                fontSize: "10px",
                                                fontWeight: "400",
                                                height: "20px",
                                                padding: "4px 4px",
                                            }}
                                        />
                                    </Box>
                                    <Typography
                                        sx={{
                                            fontSize: "12px",
                                            fontWeight: "400",
                                            color: "#ffffff",
                                        }}
                                    >
                                        {`Owner: ${person?.["CAPA Owner"] ? person?.["CAPA Owner"] : "N/A"}`}
                                    </Typography>
                                </Box>
                            </Box>
                            <img
                                src={cardHeadBG}
                                alt="bg"
                                style={{
                                    position: "absolute",
                                    bottom: "-410px",
                                    left: "-360px",
                                    width: "727px",
                                    height: "560px",
                                    zIndex: 0,
                                }}
                            />
                        </Box>
                        <Box sx={{ padding: "12px" }}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Box display="flex" alignItems="center" gap={1}>
                                        <CustomBox>
                                            <DateRangeOutlinedIcon
                                                sx={{ width: "20px", height: "20px" }}
                                            />
                                        </CustomBox>
                                        <Box display="flex" flexDirection="column" >
                                            <Typography
                                                sx={{
                                                    fontSize: "12px",
                                                    fontWeight: "600",
                                                    color: "#222222",
                                                }}
                                            >
                                                Due date
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontSize: "12px",
                                                    fontWeight: "400",
                                                    color: "#4D4D4D",
                                                }}
                                            >
                                                {person?.["Due Date"]
                                                    ? convertMillisecondsToDate(person?.["Due Date"])
                                                    : "N/A"}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>

                                <Grid item sm={6}>
                                    <Box display="flex" alignItems="center" gap={1}>
                                        <CustomBox>
                                            <LocationOnOutlinedIcon
                                                sx={{ width: "20px", height: "20px" }}
                                            />
                                        </CustomBox>
                                        <Box display="flex" flexDirection="column" >
                                            <Typography
                                                sx={{
                                                    fontSize: "12px",
                                                    fontWeight: "600",
                                                    color: "#222222",
                                                }}
                                            >
                                                Area
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontSize: "12px",
                                                    fontWeight: "400",
                                                    color: "#4D4D4D",
                                                }}
                                            >
                                                {person?.Area ? person?.Area : "N/A"}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item sm={6}>
                                    <Box display="flex" flexDirection="column" >
                                        <Typography
                                            sx={{
                                                fontSize: "12px",
                                                fontWeight: "600",
                                                color: "#222222",
                                            }}
                                        >
                                            Sub-Area
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontSize: "12px",
                                                fontWeight: "400",
                                                color: "#4D4D4D",
                                            }}
                                        >
                                            {person?.["Sub Area"] ? person?.["Sub Area"] : "N/A"}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item sm={12}>
                                    <Box display="flex" alignItems="center" gap={1}>
                                        <CustomBox>
                                            <DescriptionOutlinedIcon
                                                sx={{ width: "20px", height: "20px" }}
                                            />
                                        </CustomBox>
                                        <Box display="flex" flexDirection="column" >
                                            <Typography
                                                sx={{
                                                    fontSize: "12px",
                                                    fontWeight: "600",
                                                    color: "#222222",
                                                }}
                                            >
                                                Description
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontSize: "12px",
                                                    fontWeight: "400",
                                                    color: "#4D4D4D",
                                                }}
                                            >
                                                <Tooltip title={person?.["CAPA Description"] || "N/A"}>
                                                    <span>
                                                        {person?.["CAPA Description"]
                                                            ? person?.["CAPA Description"].length > 22
                                                                ? `${person?.["CAPA Description"].substring(0, 22)}...`
                                                                : person?.["CAPA Description"]
                                                            : "N/A"}
                                                    </span>
                                                </Tooltip>
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </>
            ) : title === "Resources" ? (
                <>

                    <React.Fragment key={index}>
                        {person.hasOwnProperty("Staff Type") && person?.["Staff Type"] ? (
                            <Box
                                key={person.id || index} // Use person.id if available; fallback to index
                                sx={{
                                    borderRadius: "8px",
                                    background: "#FFFFFF",
                                    border: "1px solid rgba(0, 0, 0, 0.1)",
                                    boxShadow: "0px 0px 8px 0px rgba(0, 0, 0, 0.20)",
                                    minWidth: "370px",
                                    minHeight: "230px"
                                }}
                            >
                                <Box
                                    position="relative"
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="space-between"
                                    sx={{
                                        backgroundColor: "#09A2E3",
                                        minHeight: "56px",
                                        borderRadius: "8px 8px 0 0",
                                        padding: "8px",
                                        overflow: "hidden",
                                    }}
                                >
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="space-between"
                                    >
                                        <Box display="flex" alignItems="center">
                                            {/* Profile image */}
                                            <Box
                                                sx={{
                                                    width: "40px",
                                                    height: "40px",
                                                    borderRadius: "50%",
                                                    backgroundColor: "none",
                                                    marginRight: "8px",
                                                    overflow: "hidden",
                                                }}
                                            >
                                                {person.image ? (
                                                    <Box
                                                        component="img"
                                                        src={person.image}
                                                        alt="Profile"
                                                        sx={{
                                                            width: "100%",
                                                            height: "100%",
                                                            objectFit: "cover",
                                                        }}
                                                    />
                                                ) : (
                                                    <AccountCircleIcon
                                                        sx={{
                                                            width: "100%",
                                                            height: "100%",
                                                            color: "#ffffff",
                                                        }}
                                                    />
                                                )}
                                            </Box>
                                            {/* Title and ID */}
                                            <Box>
                                                <Box display="flex" alignItems="center" gap={1}>
                                                    <Typography
                                                        sx={{
                                                            fontSize: "20px",
                                                            fontWeight: "600",
                                                            color: "#ffffff",
                                                        }}
                                                    >
                                                        {person.Name}
                                                    </Typography>
                                                </Box>
                                                <Typography
                                                    sx={{
                                                        fontSize: "12px",
                                                        fontWeight: "600",
                                                        color: "#ffffff",
                                                    }}
                                                >
                                                    {person.Designation}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                    <img
                                        src={cardHeadBG}
                                        alt="bg"
                                        style={{
                                            position: "absolute",
                                            bottom: "-410px",
                                            left: "-360px",
                                            width: "727px",
                                            height: "560px",
                                            zIndex: 0,
                                        }}
                                    />
                                    <Box
                                        sx={{
                                            backgroundColor: "rgba(255, 255, 255, 0.12)",
                                            padding: "4px",
                                            borderRadius: "4px",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: "12px",
                                                fontWeight: "500",
                                                color: "#ffffff",
                                            }}
                                        >
                                            Personnel
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box sx={{ padding: "16px" }}>
                                    <Grid container spacing={0.5}>
                                        <Grid item xs={5}>
                                            <Box display="flex" alignItems="center" gap={1}>
                                                <CustomBox>
                                                    <ContactPageOutlinedIcon
                                                        sx={{ width: "20px", height: "20px" }}
                                                    />
                                                </CustomBox>
                                                <Box display="flex" flexDirection="column" >
                                                    <Typography
                                                        sx={{
                                                            fontSize: "12px",
                                                            fontWeight: "600",
                                                            color: "#222222",
                                                        }}
                                                    >
                                                        Gender
                                                    </Typography>
                                                    <Typography
                                                        sx={{
                                                            fontSize: "12px",
                                                            fontWeight: "400",
                                                            color: "#4D4D4D",
                                                        }}
                                                    >
                                                        {person.Gender ? person.Gender : "N/A"}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Grid>

                                        <Grid item sm={4}>
                                            <Box display="flex" flexDirection="column" >
                                                <Typography
                                                    sx={{
                                                        fontSize: "12px",
                                                        fontWeight: "600",
                                                        color: "#222222",
                                                    }}
                                                >
                                                    Age
                                                </Typography>
                                                <Typography
                                                    sx={{
                                                        fontSize: "12px",
                                                        fontWeight: "400",
                                                        color: "#4D4D4D",
                                                    }}
                                                >
                                                    {person.Age ? person.Age : "N/A"}
                                                </Typography>
                                            </Box>
                                        </Grid>

                                        <Grid item sm={3}>
                                            <Box display="flex" flexDirection="row" alignItems='center' >
                                                <Typography
                                                    sx={{
                                                        fontSize: "12px",
                                                        fontWeight: "600",
                                                        color: "#222222",
                                                    }}
                                                >
                                                    LTI
                                                </Typography>
                                                <Switch
                                                    checked={person.LTI === 1}
                                                    disabled
                                                    sx={{
                                                        "& .MuiSwitch-switchBase.Mui-checked": {
                                                            color: "rgba(33, 150, 243, 1)", // Customize the color when ON (checked)
                                                        },
                                                        "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
                                                        {
                                                            backgroundColor: "#4caf50", // Customize the track color when ON
                                                        },
                                                        "& .MuiSwitch-track": {
                                                            backgroundColor: "rgba(33, 150, 243, 1)", // Customize the track color when OFF
                                                        },
                                                    }}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <Box display="flex" alignItems="center" gap={1}>
                                                <CustomBox>
                                                    <DateRangeOutlinedIcon
                                                        sx={{ width: "20px", height: "20px" }}
                                                    />
                                                </CustomBox>
                                                <Box display="flex" flexDirection="column" >
                                                    <Typography
                                                        sx={{
                                                            fontSize: "12px",
                                                            fontWeight: "600",
                                                            color: "#222222",
                                                        }}
                                                    >
                                                        IME Date
                                                    </Typography>
                                                    <Typography
                                                        sx={{
                                                            fontSize: "12px",
                                                            fontWeight: "400",
                                                            color: "#4D4D4D",
                                                        }}
                                                    >
                                                        {person?.["IME Date"] ? convertMillisecondsToDate(person?.["IME Date"]) : "N/A"}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Box display="flex" flexDirection="column" >
                                                <Typography
                                                    sx={{
                                                        fontSize: "12px",
                                                        fontWeight: "600",
                                                        color: "#222222",
                                                    }}
                                                >
                                                    PME Date
                                                </Typography>
                                                <Typography
                                                    sx={{
                                                        fontSize: "12px",
                                                        fontWeight: "400",
                                                        color: "#4D4D4D",
                                                    }}
                                                >
                                                    {person?.["PME Date"] ? convertMillisecondsToDate(person?.["PME Date"]) : "N/A"}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Box display="flex" flexDirection="column" >
                                                <Typography
                                                    sx={{
                                                        fontSize: "12px",
                                                        fontWeight: "600",
                                                        color: "#222222",
                                                    }}
                                                >
                                                    VTC Date
                                                </Typography>
                                                <Typography
                                                    sx={{
                                                        fontSize: "12px",
                                                        fontWeight: "400",
                                                        color: "#4D4D4D",
                                                    }}
                                                >
                                                    {person?.["VTC Date"] ? convertMillisecondsToDate(person?.["VTC Date"]) : "N/A"}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <Box display="flex" alignItems="center" gap={1}>
                                                <CustomBox>
                                                    <EmojiPeopleIcon
                                                        sx={{ width: "20px", height: "20px" }}
                                                    />
                                                </CustomBox>
                                                <Box display="flex" flexDirection="column" >
                                                    <Typography
                                                        sx={{
                                                            fontSize: "12px",
                                                            fontWeight: "600",
                                                            color: "#222222",
                                                        }}
                                                    >
                                                        Injury Code
                                                    </Typography>
                                                    <Typography
                                                        sx={{
                                                            fontSize: "12px",
                                                            fontWeight: "400",
                                                            color: "#4D4D4D",
                                                        }}
                                                    >
                                                        {person["Injury Code"]
                                                            ? person["Injury Code"]
                                                            : "N/A"}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={7}>
                                            <Box display="flex" flexDirection="column" >
                                                <Typography
                                                    sx={{
                                                        fontSize: "12px",
                                                        fontWeight: "600",
                                                        color: "#222222",
                                                    }}
                                                >
                                                    Body Parts
                                                </Typography>


                                                <Typography
                                                    sx={{
                                                        fontSize: "12px",
                                                        fontWeight: "400",
                                                        color: "#4D4D4D",
                                                    }}
                                                >
                                                    <Tooltip title={(person["Body Parts Involved"] && person["Body Parts Involved"].length > 0)
                                                        ? person["Body Parts Involved"].join(", ")
                                                        : "N/A"}>
                                                        <span>
                                                            {person["Body Parts Involved"] && person["Body Parts Involved"].length > 0
                                                                ? person["Body Parts Involved"].join(", ").length > 15
                                                                    ? `${person["Body Parts Involved"].join(", ").substring(0, 15)}...`
                                                                    : person["Body Parts Involved"].join(", ")
                                                                : "N/A"}
                                                        </span>
                                                    </Tooltip>
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <Box display="flex" alignItems="center" gap={1}>
                                                <CustomBox>
                                                    <AccessTimeIcon
                                                        sx={{ width: "20px", height: "20px" }}
                                                    />
                                                </CustomBox>
                                                <Box display="flex" flexDirection="column" >
                                                    <Typography
                                                        sx={{
                                                            fontSize: "12px",
                                                            fontWeight: "600",
                                                            color: "#222222",
                                                        }}
                                                    >
                                                        Outcome
                                                    </Typography>
                                                    <Typography
                                                        sx={{
                                                            fontSize: "12px",
                                                            fontWeight: "400",
                                                            color: "#4D4D4D",
                                                        }}
                                                    >
                                                        {person["Outcome"] ? person["Outcome"] : "N/A"}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Box display="flex" flexDirection="column" >
                                                <Typography
                                                    sx={{
                                                        fontSize: "12px",
                                                        fontWeight: "600",
                                                        color: "#222222",
                                                    }}
                                                >
                                                    Form A No.
                                                </Typography>
                                                <Typography
                                                    sx={{
                                                        fontSize: "12px",
                                                        fontWeight: "400",
                                                        color: "#4D4D4D",
                                                    }}
                                                >
                                                    {person["Form A No."]
                                                        ? person["Form A No."]
                                                        : "N/A"}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={4}>

                                            <Box display="flex" flexDirection="column" >
                                                <Typography
                                                    sx={{
                                                        fontSize: "12px",
                                                        fontWeight: "600",
                                                        color: "#222222",
                                                    }}
                                                >
                                                    Hours at work
                                                </Typography>
                                                <Typography
                                                    sx={{
                                                        fontSize: "12px",
                                                        fontWeight: "400",
                                                        color: "#4D4D4D",
                                                    }}
                                                >
                                                    {person["Hours at work"]
                                                        ? person["Hours at work"]
                                                        : "N/A"}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>
                        ) : null}

                        {person.hasOwnProperty("Equipment Name") &&
                            person["Equipment Name"] ? (
                            <Box
                                sx={{
                                    borderRadius: "8px",
                                    background: "#FFFFFF",
                                    border: "1px solid rgba(0, 0, 0, 0.1)",
                                    boxShadow: "0px 0px 8px 0px rgba(0, 0, 0, 0.20)",
                                    minWidth: "370px",
                                    minHeight: "235px",
                                }}
                            >
                                <Box
                                    position="relative"
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="space-between"
                                    sx={{
                                        backgroundColor: "#09A2E3",
                                        minHeight: "56px",
                                        borderRadius: "8px 8px 0 0",
                                        padding: "8px",
                                        overflow: "hidden",
                                    }}
                                >
                                    <Box display="flex" alignItems="center">
                                        <Box
                                            sx={{
                                                width: "40px",
                                                height: "40px",
                                                borderRadius: "50%",
                                                backgroundColor: "none",
                                                marginRight: "8px",
                                                overflow: "hidden",
                                            }}
                                        >
                                            {person.image ? (
                                                <Box
                                                    component="img"
                                                    src={person.image}
                                                    alt="Profile"
                                                    sx={{
                                                        width: "100%",
                                                        height: "100%",
                                                        objectFit: "cover",
                                                    }}
                                                />
                                            ) : (
                                                <ConstructionOutlinedIcon
                                                    sx={{
                                                        width: "100%",
                                                        height: "100%",
                                                        color: "#ffffff",
                                                    }}
                                                />
                                            )}
                                        </Box>

                                        <Box>
                                            <Typography
                                                sx={{
                                                    fontSize: "20px",
                                                    fontWeight: "600",
                                                    color: "#ffffff",
                                                }}
                                            >
                                                {person["Equipment Name"]}
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontSize: "12px",
                                                    fontWeight: "400",
                                                    color: "#ffffff",
                                                }}
                                            >
                                                {person["Equipment Type"]}
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <img
                                        src={cardHeadBG}
                                        alt="bg"
                                        style={{
                                            position: "absolute",
                                            bottom: "-410px",
                                            left: "-360px",
                                            width: "727px",
                                            height: "560px",
                                            zIndex: 0,
                                        }}
                                    />
                                    <Box
                                        sx={{
                                            backgroundColor: "rgba(255, 255, 255, 0.12)",
                                            padding: "4px",
                                            borderRadius: "4px",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: "12px",
                                                fontWeight: "500",
                                                color: "#ffffff",
                                            }}
                                        >
                                            Equipment
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box sx={{ padding: "16px" }}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <Box display="flex" alignItems="center" gap={1}>
                                                <CustomBox>
                                                    <BuildOutlinedIcon
                                                        sx={{ width: "20px", height: "20px" }}
                                                    />
                                                </CustomBox>
                                                <Box display="flex" flexDirection="column" >
                                                    <Typography
                                                        sx={{
                                                            fontSize: "12px",
                                                            fontWeight: "600",
                                                            color: "#222222",
                                                        }}
                                                    >
                                                        Equipment Type
                                                    </Typography>
                                                    <Typography
                                                        sx={{
                                                            fontSize: "12px",
                                                            fontWeight: "400",
                                                            color: "#4D4D4D",
                                                        }}
                                                    >
                                                        {person["Equipment Type"]
                                                            ? person["Equipment Type"]
                                                            : "N/A"}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Grid>

                                        <Grid item xs={6}>
                                            <Box display="flex" flexDirection="column" >
                                                <Typography
                                                    sx={{
                                                        fontSize: "12px",
                                                        fontWeight: "600",
                                                        color: "#222222",
                                                    }}
                                                >
                                                    Equipment Damage
                                                </Typography>
                                                <Typography
                                                    sx={{
                                                        fontSize: "12px",
                                                        fontWeight: "400",
                                                        color: "#4D4D4D",
                                                    }}
                                                >
                                                    {person["Equipment Damage"]
                                                        ? person["Equipment Damage"]
                                                        : "N/A"}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box display="flex" alignItems="center" gap={1}>
                                                <CustomBox>
                                                    <EditNoteIcon
                                                        sx={{ width: "20px", height: "20px" }}
                                                    />
                                                </CustomBox>
                                                <Box display="flex" flexDirection="column" >
                                                    <Typography
                                                        sx={{
                                                            fontSize: "12px",
                                                            fontWeight: "600",
                                                            color: "#222222",
                                                        }}
                                                    >
                                                        Equipment Incident Details
                                                    </Typography>
                                                    <Typography
                                                        sx={{
                                                            fontSize: "12px",
                                                            fontWeight: "400",
                                                            color: "#4D4D4D",
                                                        }}
                                                    >
                                                        <Tooltip title={person["Equipment Incident Details"] || "N/A"}>
                                                            <span>
                                                                {person["Equipment Incident Details"]
                                                                    ? person["Equipment Incident Details"].length > 22
                                                                        ? `${person["Equipment Incident Details"].substring(
                                                                            0,
                                                                            22
                                                                        )}...`
                                                                        : person["Equipment Incident Details"]
                                                                    : "N/A"}
                                                            </span>
                                                        </Tooltip>
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>
                        ) : null}

                        {person.hasOwnProperty("Vehicle No.") &&
                            person["Vehicle No."] ? (
                            <Box
                                sx={{
                                    borderRadius: "8px",
                                    background: "#FFFFFF",
                                    border: "1px solid rgba(0, 0, 0, 0.1)",
                                    boxShadow: "0px 0px 8px 0px rgba(0, 0, 0, 0.20)",
                                    minWidth: "370px",
                                    minHeight: "235px"
                                }}
                            >
                                <Box
                                    position="relative"
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="space-between"
                                    sx={{
                                        backgroundColor: "#09A2E3",
                                        minHeight: "56px",
                                        borderRadius: "8px 8px 0 0",
                                        padding: "8px",
                                        overflow: "hidden",
                                    }}
                                >
                                    <Box display="flex" alignItems="center">
                                        <Box
                                            sx={{
                                                width: "40px",
                                                height: "40px",
                                                borderRadius: "50%",
                                                backgroundColor: "none",
                                                marginRight: "8px",
                                                overflow: "hidden",
                                            }}
                                        >
                                            {person.image ? (
                                                <Box
                                                    component="img"
                                                    src={person.image}
                                                    alt="Profile"
                                                    sx={{
                                                        width: "100%",
                                                        height: "100%",
                                                        objectFit: "cover",
                                                    }}
                                                />
                                            ) : (
                                                <DirectionsCarOutlinedIcon
                                                    sx={{
                                                        width: "100%",
                                                        height: "100%",
                                                        color: "#ffffff",
                                                    }}
                                                />
                                            )}
                                        </Box>

                                        <Box>
                                            <Typography
                                                sx={{
                                                    fontSize: "20px",
                                                    fontWeight: "600",
                                                    color: "#ffffff",
                                                }}
                                            >
                                                {person["Vehicle Type"]}
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontSize: "12px",
                                                    fontWeight: "400",
                                                    color: "#ffffff",
                                                }}
                                            >
                                                {person["Vehicle Owner"]}
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <img
                                        src={cardHeadBG}
                                        alt="bg"
                                        style={{
                                            position: "absolute",
                                            bottom: "-410px",
                                            left: "-360px",
                                            width: "727px",
                                            height: "560px",
                                            zIndex: 0,
                                        }}
                                    />
                                    <Box
                                        sx={{
                                            backgroundColor: "rgba(255, 255, 255, 0.12)",
                                            padding: "4px",
                                            borderRadius: "4px",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: "12px",
                                                fontWeight: "500",
                                                color: "#ffffff",
                                            }}
                                        >
                                            Vehicle
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box sx={{ padding: "16px" }}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <Box display="flex" alignItems="center" gap={1}>
                                                <CustomBox>
                                                    <TwoWheelerIcon
                                                        sx={{ width: "20px", height: "20px" }}
                                                    />
                                                </CustomBox>
                                                <Box display="flex" flexDirection="column" >
                                                    <Typography
                                                        sx={{
                                                            fontSize: "12px",
                                                            fontWeight: "600",
                                                            color: "#222222",
                                                        }}
                                                    >
                                                        Vehicle Type
                                                    </Typography>
                                                    <Typography
                                                        sx={{
                                                            fontSize: "12px",
                                                            fontWeight: "400",
                                                            color: "#4D4D4D",
                                                        }}
                                                    >
                                                        {person["Vehicle Type"]
                                                            ? person["Vehicle Type"]
                                                            : "N/A"}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Grid>

                                        <Grid item xs={6}>
                                            <Box display="flex" flexDirection="column" >
                                                <Typography
                                                    sx={{
                                                        fontSize: "12px",
                                                        fontWeight: "600",
                                                        color: "#222222",
                                                    }}
                                                >
                                                    Vehicle No.
                                                </Typography>
                                                <Typography
                                                    sx={{
                                                        fontSize: "12px",
                                                        fontWeight: "400",
                                                        color: "#4D4D4D",
                                                    }}
                                                >
                                                    {person["Vehicle No."]
                                                        ? person["Vehicle No."]
                                                        : "N/A"}
                                                </Typography>
                                            </Box>
                                        </Grid>

                                        <Grid item xs={6}>
                                            <Box display="flex" alignItems="center" gap={1}>
                                                <CustomBox>
                                                    <PersonPinIcon
                                                        sx={{ width: "20px", height: "20px" }}
                                                    />
                                                </CustomBox>
                                                <Box display="flex" flexDirection="column" >
                                                    <Typography
                                                        sx={{
                                                            fontSize: "12px",
                                                            fontWeight: "600",
                                                            color: "#222222",
                                                        }}
                                                    >
                                                        Vehicle Owner
                                                    </Typography>
                                                    <Typography
                                                        sx={{
                                                            fontSize: "12px",
                                                            fontWeight: "400",
                                                            color: "#4D4D4D",
                                                        }}
                                                    >
                                                        {person["Vehicle Owner"]
                                                            ? person["Vehicle Owner"]
                                                            : "N/A"}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Grid>

                                        <Grid item xs={6}>
                                            <Box display="flex" flexDirection="column" >
                                                <Typography
                                                    sx={{
                                                        fontSize: "12px",
                                                        fontWeight: "600",
                                                        color: "#222222",
                                                    }}
                                                >
                                                    Insurance Status
                                                </Typography>
                                                <Typography
                                                    sx={{
                                                        fontSize: "12px",
                                                        fontWeight: "400",
                                                        color: "#4D4D4D",
                                                    }}
                                                >
                                                    {person["Insurance Status"]
                                                        ? person["Insurance Status"]
                                                        : "N/A"}
                                                </Typography>
                                            </Box>
                                        </Grid>


                                        <Grid item xs={12}>
                                            <Box display="flex" alignItems="center" gap={1}>
                                                <CustomBox>
                                                    <EditNoteIcon
                                                        sx={{ width: "20px", height: "20px" }}
                                                    />
                                                </CustomBox>
                                                <Box display="flex" flexDirection="column" >
                                                    <Typography
                                                        sx={{
                                                            fontSize: "12px",
                                                            fontWeight: "600",
                                                            color: "#222222",
                                                        }}
                                                    >
                                                        Observation
                                                    </Typography>
                                                    <Typography
                                                        sx={{
                                                            fontSize: "12px",
                                                            fontWeight: "400",
                                                            color: "#4D4D4D",
                                                        }}
                                                    >
                                                        <Tooltip title={person["Observation"] || "N/A"}>
                                                            <span>
                                                                {person["Observation"]
                                                                    ? person["Observation"].length > 22
                                                                        ? `${person["Observation"].substring(
                                                                            0,
                                                                            22
                                                                        )}...`
                                                                        : person["Observation"]
                                                                    : "N/A"}
                                                            </span>
                                                        </Tooltip>
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Grid>

                                    </Grid>
                                </Box>
                            </Box>
                        ) : null}
                    </React.Fragment>

                </>

            ) : title === "MitigationMeasures" ? (
                <>
                    <Box
                        key={index} // Use index as key or person.id if available
                        sx={{
                            borderRadius: "8px",
                            background: "#FFFFFF",
                            border: "1px solid rgba(0, 0, 0, 0.1)",
                            boxShadow: "0px 0px 8px 0px rgba(0, 0, 0, 0.20)",
                            maxWidth: "370px",
                            minHeight: "150px"

                        }}
                    >
                        <Box
                            position="relative"
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            sx={{
                                backgroundColor: "#09A2E3",
                                minHeight: "56px",
                                borderRadius: "8px 8px 0 0",
                                padding: "8px",
                                overflow: "hidden",
                            }}
                        >
                            <Box display="flex" alignItems="center">
                                {/* Profile image */}
                                <Box
                                    sx={{
                                        width: "40px",
                                        height: "40px",
                                        borderRadius: "50%",
                                        backgroundColor: "none",
                                        marginRight: "8px",
                                        overflow: "hidden",
                                    }}
                                >
                                    {person.image ? (
                                        <Box
                                            component="img"
                                            src={person.image}
                                            alt="Profile"
                                            sx={{
                                                width: "100%",
                                                height: "100%",
                                                objectFit: "cover",
                                            }}
                                        />
                                    ) : (
                                        <GppMaybeOutlinedIcon
                                            sx={{ width: "100%", height: "100%", color: "#ffffff" }}
                                        />
                                    )}
                                </Box>
                                <Box display="flex" justifyContent="space-between" alignItems="center">
                                    <Box>
                                        <Box display="flex" alignItems="center" gap={1}>
                                            <Typography
                                                sx={{
                                                    fontSize: "20px",
                                                    fontWeight: "600",
                                                    color: "#ffffff",
                                                }}
                                            >
                                                {title}
                                            </Typography>

                                        </Box>
                                    </Box>

                                </Box>


                            </Box>

                            <img
                                src={cardHeadBG}
                                alt="bg"
                                style={{
                                    position: "absolute",
                                    bottom: "-410px",
                                    left: "-360px",
                                    width: "727px",
                                    height: "560px",
                                    zIndex: 0,
                                }}
                            />
                        </Box>

                        <Box sx={{ padding: "16px", minHeight: "150px" }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Box display="flex" alignItems="center" gap={1}>

                                        <Box display="flex" flexDirection="row" gap={0.5}>
                                            <Typography
                                                sx={{
                                                    fontSize: "15px",
                                                    fontWeight: "400",
                                                    color: "#222222",
                                                }}
                                            >
                                                {person.id}{"."}
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontSize: "12px",
                                                    fontWeight: "600",
                                                    color: "#4D4D4D",
                                                }}
                                            >
                                                {person["Mitigation Measures"]
                                                    ? person["Mitigation Measures"]
                                                    : "N/A"}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>


                            </Grid>
                        </Box>
                    </Box>
                </>
            ) : title === "LessonsLearned" ? (
                <>
                    <Box
                        key={index} // Use index as key or person.id if available
                        sx={{
                            borderRadius: "8px",
                            background: "#FFFFFF",
                            border: "1px solid rgba(0, 0, 0, 0.1)",
                            boxShadow: "0px 0px 8px 0px rgba(0, 0, 0, 0.20)",
                            maxWidth: "370px",
                            minHeight: "150px"
                        }}
                    >
                        <Box
                            position="relative"
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            sx={{
                                backgroundColor: "#09A2E3",
                                minHeight: "56px",
                                borderRadius: "8px 8px 0 0",
                                padding: "8px",
                                overflow: "hidden",
                            }}
                        >
                            <Box display="flex" alignItems="center">
                                {/* Profile image */}
                                <Box
                                    sx={{
                                        width: "40px",
                                        height: "40px",
                                        borderRadius: "50%",
                                        backgroundColor: "none",
                                        marginRight: "8px",
                                        overflow: "hidden",
                                    }}
                                >
                                    {person.image ? (
                                        <Box
                                            component="img"
                                            src={person.image}
                                            alt="Profile"
                                            sx={{
                                                width: "100%",
                                                height: "100%",
                                                objectFit: "cover",
                                            }}
                                        />
                                    ) : (
                                        <MenuBookOutlinedIcon
                                            sx={{ width: "100%", height: "100%", color: "#ffffff" }}
                                        />
                                    )}
                                </Box>
                                <Box display="flex" justifyContent="space-between" alignItems="center">
                                    <Box>
                                        <Box display="flex" alignItems="center" gap={1}>
                                            <Typography
                                                sx={{
                                                    fontSize: "20px",
                                                    fontWeight: "600",
                                                    color: "#ffffff",
                                                }}
                                            >
                                                {title}
                                            </Typography>

                                        </Box>
                                    </Box>

                                </Box>


                            </Box>

                            <img
                                src={cardHeadBG}
                                alt="bg"
                                style={{
                                    position: "absolute",
                                    bottom: "-410px",
                                    left: "-360px",
                                    width: "727px",
                                    height: "560px",
                                    zIndex: 0,
                                }}
                            />
                        </Box>

                        <Box sx={{ padding: "16px", minHeight: "150px" }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Box display="flex" alignItems="center" gap={1}>

                                        <Box display="flex" flexDirection="row" gap={0.5}>
                                            <Typography
                                                sx={{
                                                    fontSize: "15px",
                                                    fontWeight: "400",
                                                    color: "#222222",
                                                }}
                                            >
                                                {person.id}{"."}
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontSize: "12px",
                                                    fontWeight: "600",
                                                    color: "#4D4D4D",
                                                }}
                                            >
                                                {person["Lessons Learned"]
                                                    ? person["Lessons Learned"]
                                                    : "N/A"}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>


                            </Grid>
                        </Box>
                    </Box>
                </>
            ) : title === "IncidentResponsibility" ? (
                <Box
                    sx={{
                        borderRadius: "8px",
                        background: "#FFFFFF",
                        border: "1px solid rgba(0, 0, 0, 0.1)",
                        boxShadow: "0px 0px 8px 0px rgba(0, 0, 0, 0.20)",
                        minWidth: "370px",
                        minHeight: "230px"
                    }}
                >
                    <Box
                        position="relative"
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        sx={{
                            backgroundColor: "#09A2E3",
                            minHeight: "56px",
                            borderRadius: "8px 8px 0 0",
                            padding: "8px",
                            overflow: "hidden",
                        }}
                    >
                        <Box display="flex" alignItems="center">
                            <Box
                                sx={{
                                    width: "40px",
                                    height: "40px",
                                    borderRadius: "50%",
                                    backgroundColor: "none",
                                    marginRight: "8px",
                                    overflow: "hidden",
                                }}
                            >
                                {person.image ? (
                                    <Box
                                        component="img"
                                        src={person.image}
                                        alt="Profile"
                                        sx={{
                                            width: "100%",
                                            height: "100%",
                                            objectFit: "cover",
                                        }}
                                    />
                                ) : (
                                    <AccountCircleIcon
                                        sx={{
                                            width: "100%",
                                            height: "100%",
                                            color: "#ffffff",
                                        }}
                                    />
                                )}
                            </Box>

                            <Box>
                                <Typography
                                    sx={{
                                        fontSize: "20px",
                                        fontWeight: "600",
                                        color: "#ffffff",
                                    }}
                                >
                                    {person["Person Responsible"]}
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: "12px",
                                        fontWeight: "400",
                                        color: "#ffffff",
                                    }}
                                >
                                    {person?.["Person Responsible"]} ( {person?.["Designation Code"]})
                                </Typography>
                            </Box>
                        </Box>

                        <img
                            src={cardHeadBG}
                            alt="bg"
                            style={{
                                position: "absolute",
                                bottom: "-410px",
                                left: "-360px",
                                width: "727px",
                                height: "560px",
                                zIndex: 0,
                            }}
                        />

                    </Box>
                    <Box sx={{ padding: "16px" }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Box display="flex" alignItems="center" gap={1}>
                                    <CustomBox>
                                        <ContactPageIcon
                                            sx={{ width: "20px", height: "20px" }}
                                        />
                                    </CustomBox>
                                    <Box display="flex" flexDirection="column" gap={0.5}>
                                        <Typography
                                            sx={{
                                                fontSize: "12px",
                                                fontWeight: "600",
                                                color: "#222222",
                                            }}
                                        >
                                            Rules Contravened
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontSize: "12px",
                                                fontWeight: "400",
                                                color: "#4D4D4D",
                                            }}
                                        >
                                            {person["Rules Contravened"]
                                                ? person["Rules Contravened"]
                                                : "N/A"}
                                        </Typography>
                                    </Box>
                                </Box>

                            </Grid>

                            <Grid item xs={12}>
                                <Box display="flex" alignItems="center" gap={1}>
                                    <CustomBox>
                                        <DateRangeIcon
                                            sx={{ width: "20px", height: "20px" }}
                                        />
                                    </CustomBox>
                                    <Box display="flex" flexDirection="column" gap={0.5}>
                                        <Typography
                                            sx={{
                                                fontSize: "12px",
                                                fontWeight: "600",
                                                color: "#222222",
                                            }}
                                        >
                                            Nature of Contravention
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontSize: "12px",
                                                fontWeight: "400",
                                                color: "#4D4D4D",
                                            }}
                                        >
                                            {person["Nature of Contravention"]
                                                ? person["Nature of Contravention"]
                                                : "N/A"}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>

                            <Grid item xs={12}>
                                <Box display="flex" alignItems="center" gap={1}>
                                    <CustomBox>
                                        <EmojiPeopleIcon
                                            sx={{ width: "20px", height: "20px" }}
                                        />
                                    </CustomBox>
                                    <Box display="flex" flexDirection="column" gap={0.5}>
                                        <Typography
                                            sx={{
                                                fontSize: "12px",
                                                fontWeight: "600",
                                                color: "#222222",
                                            }}
                                        >
                                            Action Taken
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontSize: "12px",
                                                fontWeight: "400",
                                                color: "#4D4D4D",
                                            }}
                                        >
                                            <Tooltip title={person["Action Taken"] || "N/A"}>
                                                <span>
                                                    {person["Action Taken"]
                                                        ? person["Action Taken"].length > 22
                                                            ? `${person["Action Taken"].substring(
                                                                0,
                                                                22
                                                            )}...`
                                                            : person["Action Taken"]
                                                        : "N/A"}
                                                </span>
                                            </Tooltip>
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>

                        </Grid>
                    </Box>
                </Box>
            ) : title === "InvestigationEvidence" ? (
                <Box
                    sx={{
                        borderRadius: "8px",
                        background: "#FFFFFF",
                        border: "1px solid rgba(0, 0, 0, 0.1)",
                        boxShadow: "0px 0px 8px 0px rgba(0, 0, 0, 0.20)",
                        maxWidth: "370px",
                        minHeight: "270px"
                    }}
                >
                    <Box
                        position="relative"
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        sx={{
                            backgroundColor: "#09A2E3",
                            minHeight: "56px",
                            borderRadius: "8px 8px 0 0",
                            padding: "8px",
                            overflow: "hidden",
                        }}
                    >
                        <Box display="flex" alignItems="center">
                            <Box
                                sx={{
                                    width: "40px",
                                    height: "40px",
                                    borderRadius: "50%",
                                    backgroundColor: "none",
                                    marginRight: "8px",
                                    overflow: "hidden",
                                }}
                            >
                                {person.Category === "Photo" ? <PhotoCameraBackIcon sx={{
                                    width: "100%",
                                    height: "100%",
                                    color: "#ffffff",
                                }} /> : person.Category === "Video" ? <OndemandVideoIcon sx={{
                                    width: "100%",
                                    height: "100%",
                                    color: "#ffffff",
                                }} /> : <ArticleIcon sx={{
                                    width: "100%",
                                    height: "100%",
                                    color: "#ffffff",
                                }} />
                                }

                            </Box>

                            <Box>
                                <Typography
                                    sx={{
                                        fontSize: "20px",
                                        fontWeight: "600",
                                        color: "#ffffff",
                                    }}
                                >
                                    Evidence
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: "12px",
                                        fontWeight: "400",
                                        color: "#ffffff",
                                    }}
                                >
                                    {person?.["Category"]}
                                </Typography>
                            </Box>
                        </Box>

                        <img
                            src={cardHeadBG}
                            alt="bg"
                            style={{
                                position: "absolute",
                                bottom: "-410px",
                                left: "-360px",
                                width: "727px",
                                height: "560px",
                                zIndex: 0,
                            }}
                        />

                    </Box>
                    <Box sx={{ padding: "16px" }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Box alignItems="center" width="100%" gap={1}>
                                    <StyledBox2>
                                        {person?.files?.length > 1 ? (
                                            <Slider {...settings} style={{ width: '100%' }}>
                                                {person?.files?.map((link, index) => (
                                                    <Box key={index} sx={{ display: 'flex', justifyContent: 'center' }}>
                                                        {renderFile({ link, category: person.Category })}
                                                    </Box>
                                                ))}
                                            </Slider>
                                        ) : (
                                            person?.files?.map((link, index) => (
                                                <Box key={index} sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                                                    {renderFile({ link, category: person.Category })}
                                                </Box>
                                            ))
                                        )}
                                    </StyledBox2>
                                </Box>
                            </Grid>


                            <Grid item xs={12}>
                                <Box display="flex" alignItems="center" gap={1}>
                                    <CustomBox>
                                        <EditNoteIcon
                                            sx={{ width: "20px", height: "20px" }}
                                        />
                                    </CustomBox>
                                    <Box display="flex" flexDirection="column" gap={0.5}>
                                        <Typography
                                            sx={{
                                                fontSize: "12px",
                                                fontWeight: "600",
                                                color: "#222222",
                                            }}
                                        >
                                            Description
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontSize: "12px",
                                                fontWeight: "600",
                                                color: "#4D4D4D",
                                            }}
                                        >
                                            <Tooltip title={person["Description"] || "N/A"}>
                                                <span>
                                                    {person["Description"]
                                                        ? person["Description"].length > 22
                                                            ? `${person["Description"].substring(
                                                                0,
                                                                22
                                                            )}...`
                                                            : person["Description"]
                                                        : "N/A"}
                                                </span>
                                            </Tooltip>
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            ) : title === "WitnessStatement" ? (
                <Box
                    sx={{
                        borderRadius: "8px",
                        background: "#FFFFFF",
                        border: "1px solid rgba(0, 0, 0, 0.1)",
                        boxShadow: "0px 0px 8px 0px rgba(0, 0, 0, 0.20)",
                        maxWidth: "370px",
                        minHeight: "270px"
                    }}
                >
                    <Box
                        position="relative"
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        sx={{
                            backgroundColor: "#09A2E3",
                            minHeight: "56px",
                            borderRadius: "8px 8px 0 0",
                            padding: "8px",
                            overflow: "hidden",
                        }}
                    >
                        <Box display="flex" alignItems="center">
                            <Box
                                sx={{
                                    width: "40px",
                                    height: "40px",
                                    borderRadius: "50%",
                                    backgroundColor: "none",
                                    marginRight: "8px",
                                    overflow: "hidden",
                                }}
                            >
                                {person?.Category === "Photo" ? <PhotoCameraBackIcon sx={{
                                    width: "100%",
                                    height: "100%",
                                    color: "#ffffff",
                                }} /> : person.Category === "Video" ? <OndemandVideoIcon sx={{
                                    width: "100%",
                                    height: "100%",
                                    color: "#ffffff",
                                }} /> : <SupervisedUserCircleIcon sx={{
                                    width: "100%",
                                    height: "100%",
                                    color: "#ffffff",
                                }} />
                                }

                            </Box>

                            <Box>
                                <Typography
                                    sx={{
                                        fontSize: "20px",
                                        fontWeight: "600",
                                        color: "#ffffff",
                                    }}
                                >
                                    {person?.["Witness Name"]}
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: "12px",
                                        fontWeight: "400",
                                        color: "#ffffff",
                                    }}
                                >
                                    {person?.["Staff Type"]}
                                </Typography>
                            </Box>
                        </Box>

                        <img
                            src={cardHeadBG}
                            alt="bg"
                            style={{
                                position: "absolute",
                                bottom: "-410px",
                                left: "-360px",
                                width: "727px",
                                height: "560px",
                                zIndex: 0,
                            }}
                        />

                    </Box>
                    <Box sx={{ padding: "16px" }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Box alignItems="center" width="100%" gap={1}>
                                    <StyledBox2>
                                        {person?.files?.length > 1 ? (
                                            <Slider {...settings} style={{ width: '100%' }}>
                                                {person?.files?.map((link, index) => (
                                                    <Box key={index} sx={{ display: 'flex', justifyContent: 'center' }}>
                                                        {renderFile({ link, category: person.Category })}
                                                    </Box>
                                                ))}
                                            </Slider>
                                        ) : (
                                            person?.files?.map((link, index) => (
                                                <Box key={index} sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                                                    {renderFile({ link, category: person.Category })}
                                                </Box>
                                            ))
                                        )}
                                    </StyledBox2>
                                </Box>
                            </Grid>

                            <Grid item xs={12}>
                                <Box display="flex" alignItems="center" gap={1}>
                                    <CustomBox>
                                        <DateRangeIcon
                                            sx={{ width: "20px", height: "20px" }}
                                        />
                                    </CustomBox>
                                    <Box display="flex" flexDirection="column" gap={0.5}>
                                        <Typography
                                            sx={{
                                                fontSize: "12px",
                                                fontWeight: "600",
                                                color: "#222222",
                                            }}
                                        >
                                            Date of Statement
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontSize: "12px",
                                                fontWeight: "600",
                                                color: "#4D4D4D",
                                            }}
                                        >
                                            <Tooltip title={person["Date of Statement"] || "N/A"}>
                                                <span>
                                                    {person?.["Date of Statement"]
                                                        ? convertMillisecondsToDate(person?.["Date of Statement"])
                                                        : "N/A"}
                                                </span>
                                            </Tooltip>
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box display="flex" alignItems="center" gap={1}>
                                    <CustomBox>
                                        <ContactPageOutlinedIcon
                                            sx={{ width: "20px", height: "20px" }}
                                        />
                                    </CustomBox>
                                    <Box display="flex" flexDirection="column" gap={0.5}>
                                        <Typography
                                            sx={{
                                                fontSize: "12px",
                                                fontWeight: "600",
                                                color: "#222222",
                                            }}
                                        >
                                            Description
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontSize: "12px",
                                                fontWeight: "600",
                                                color: "#4D4D4D",
                                            }}
                                        >
                                            <Tooltip title={person["Description"] || "N/A"}>
                                                <span>
                                                    {person?.["Description"]
                                                        ? person["Description"].length > 22
                                                            ? `${person["Description"].substring(
                                                                0,
                                                                22
                                                            )}...`
                                                            : person?.["Description"]
                                                        : "N/A"}
                                                </span>
                                            </Tooltip>
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            ) : title === "ContributingFactor" ? (
                <Box
                    sx={{
                        borderRadius: "8px",
                        background: "#FFFFFF",
                        border: "1px solid rgba(0, 0, 0, 0.1)",
                        boxShadow: "0px 0px 8px 0px rgba(0, 0, 0, 0.20)",
                        maxWidth: "370px",
                        minHeight: "270px"
                    }}
                >
                    <Box
                        position="relative"
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        sx={{
                            backgroundColor: "#09A2E3",
                            minHeight: "56px",
                            borderRadius: "8px 8px 0 0",
                            padding: "8px",
                            overflow: "hidden",
                        }}
                    >
                        <Box display="flex" alignItems="center">
                            <Box
                                sx={{
                                    width: "40px",
                                    height: "40px",
                                    borderRadius: "50%",
                                    backgroundColor: "none",
                                    marginRight: "8px",
                                    overflow: "hidden",
                                }}
                            >
                                {person.image ? (
                                    <Box
                                        component="img"
                                        src={person.image}
                                        alt="Profile"
                                        sx={{
                                            width: "100%",
                                            height: "100%",
                                            objectFit: "cover",
                                        }}
                                    />
                                ) : (
                                    <YardIcon
                                        sx={{
                                            width: "100%",
                                            height: "100%",
                                            color: "#ffffff",
                                        }}
                                    />
                                )}
                            </Box>

                            <Box>
                                <Typography
                                    sx={{
                                        fontSize: "20px",
                                        fontWeight: "600",
                                        color: "#ffffff",
                                    }}
                                >
                                    {person?.["contributingFactor"]}
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: "12px",
                                        fontWeight: "400",
                                        color: "#ffffff",
                                    }}
                                >
                                    {person?.["reason"]}
                                </Typography>
                            </Box>
                        </Box>

                        <img
                            src={cardHeadBG}
                            alt="bg"
                            style={{
                                position: "absolute",
                                bottom: "-410px",
                                left: "-360px",
                                width: "500px",
                                height: "560px",
                                zIndex: 0,
                            }}
                        />

                    </Box>
                    <Box sx={{ padding: "16px" }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Box display="flex" alignItems="center" gap={0.5}>
                                    {person.why.map((data, index) => (
                                        <Chip
                                            size="small"
                                            key={index}
                                            label={`Why ${index + 1}`} // Label showing "Why 1", "Why 2", etc.
                                            onClick={() => handleClick(index)} // Pass the ID on click
                                            color={selectedWhyId === index ? 'primary' : 'default'} // Highlight selected chip
                                        />
                                    ))}
                                </Box>


                                <Box>
                                    {person.why[selectedWhyId] && (
                                        <>
                                            <Grid item xs={12}>
                                                <Box display="flex" alignItems="center" sx={{ py: 1 }}>
                                                    <Typography
                                                        sx={{
                                                            fontSize: "12px",
                                                            fontWeight: "400",
                                                            color: "#222222",
                                                        }}
                                                    >
                                                        {person.why[selectedWhyId]?.reason}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            {person.why[selectedWhyId].rootCause.length > 0 &&
                                                <Box gap={1} sx={{ py: 1 }}>
                                                    <Typography
                                                        sx={{
                                                            fontSize: "12px",
                                                            fontWeight: "600",
                                                            color: "#222222",
                                                        }}
                                                    >

                                                        Root Cause
                                                    </Typography>
                                                    {person.why[selectedWhyId]?.rootCause?.map((cause, idx) => (
                                                        <Chip
                                                            size="small"
                                                            key={idx}
                                                            label={cause}
                                                            color="grey"
                                                            sx={{ margin: "2px" }}
                                                        />
                                                    ))}
                                                </Box>
                                            }



                                            <Grid item xs={12}>
                                                <Box display="flex" alignItems="center" sx={{ py: 1 }}>
                                                    <Box display="flex" flexDirection="column">
                                                        <Typography
                                                            sx={{
                                                                fontSize: "12px",
                                                                fontWeight: "600",
                                                                color: "#222222",
                                                            }}
                                                        >
                                                            Comment
                                                        </Typography>
                                                        <Typography
                                                            sx={{
                                                                fontSize: "12px",
                                                                fontWeight: "400",
                                                                color: "#4D4D4D",
                                                            }}
                                                        >
                                                            <Tooltip title={person.why[selectedWhyId]?.comment || "N/A"}>
                                                                <span>
                                                                    {person.why[selectedWhyId]?.comment
                                                                        ? person.why[selectedWhyId]?.comment.length > 22
                                                                            ? `${person.why[selectedWhyId]?.comment.substring(0, 22)}...`
                                                                            : person.why[selectedWhyId]?.comment
                                                                        : "N/A"}
                                                                </span>
                                                            </Tooltip>
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </Grid>
                                        </>
                                    )}
                                </Box>
                            </Grid>


                        </Grid>
                    </Box>
                </Box>
            ) : title === "CompensationInfo" ?
                (
                    <Box
                        sx={{
                            borderRadius: "8px",
                            background: "#FFFFFF",
                            border: "1px solid rgba(0, 0, 0, 0.1)",
                            boxShadow: "0px 0px 8px 0px rgba(0, 0, 0, 0.20)",
                            width: "100%",
                            minHeight: "270px"
                        }}
                    >
                        {/* Profile Header */}
                        <Box
                            position="relative"
                            display="flex"
                            alignItems="center"
                            justifyContent="flex-start"
                            sx={{
                                backgroundColor: "#09A2E3",
                                minHeight: "56px",
                                borderRadius: "8px 8px 0 0",
                                padding: "8px",
                                overflow: "hidden",
                            }}
                        >
                            <Box display="flex" alignItems="center">
                                {/* Profile Image */}
                                <Box
                                    sx={{
                                        width: "40px",
                                        height: "40px",
                                        borderRadius: "50%",
                                        backgroundColor: "none",
                                        marginRight: "8px",
                                        overflow: "hidden",
                                    }}
                                >

                                    <AccountCircleIcon
                                        sx={{
                                            width: "100%",
                                            height: "100%",
                                            color: "#ffffff",
                                        }}
                                    />

                                </Box>
                                <Box display="flex" flexDirection="column" sx={{ marginRight: "20px" }}>
                                    <Typography
                                        sx={{
                                            fontSize: "16px",
                                            fontWeight: "500",
                                            color: "#ffffff",
                                        }}
                                    >
                                        {person?.Name}
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: "14px",
                                            fontWeight: "400",
                                            color: "#ffffff",
                                        }}
                                    >
                                        {person?.Designation}
                                    </Typography>
                                </Box>
                            </Box>

                            {/* Additional Info */}
                            <Box display="flex" alignItems="center">
                                <CustomBox>
                                    <ContactPageOutlinedIcon
                                        sx={{ color: "#09A2E3", width: "20px", height: "20px" }}
                                    />
                                </CustomBox>
                                <Box display="flex" flexDirection="column" sx={{ marginRight: "20px" }}>
                                    <Typography
                                        sx={{
                                            fontSize: "12px",
                                            fontWeight: "400",
                                            color: "#ffffff",
                                        }}
                                    >
                                        Gender - {person?.Gender}
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: "12px",
                                            fontWeight: "400",
                                            color: "#ffffff",
                                        }}
                                    >
                                        Age - {person?.Age}
                                    </Typography>
                                </Box>


                                <Box display="flex" alignItems="center">
                                    <CustomBox>
                                        <DateRangeOutlinedIcon
                                            sx={{ color: "#09A2E3", width: "20px", height: "20px" }}
                                        />
                                    </CustomBox>
                                    <Box display="flex" flexDirection="column" sx={{ marginRight: "20px" }}>

                                        <Typography
                                            sx={{
                                                fontSize: "12px",
                                                fontWeight: "400",
                                                color: "#ffffff",
                                            }}
                                        >
                                            IME Date - {person?.["IME Date"]}
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontSize: "12px",
                                                fontWeight: "400",
                                                color: "#ffffff",
                                            }}
                                        >
                                            PME Date - {person?.["PME Date"]}
                                        </Typography>
                                    </Box>
                                </Box>

                                <Box>
                                    <Typography
                                        sx={{
                                            fontSize: "12px",
                                            fontWeight: "400",
                                            color: "#ffffff",
                                        }}
                                    >
                                        Outcome - {person?.Outcome}
                                    </Typography>
                                </Box>
                            </Box>
                            <img
                                src={cardHeadBG}
                                alt="bg"
                                style={{
                                    position: "absolute",
                                    bottom: "-410px",
                                    left: "-360px",
                                    width: "727px",
                                    height: "560px",
                                    zIndex: 0,
                                }}
                            />
                        </Box>

                        <Box sx={{ padding: "12px" }}>
                            <Grid container spacing={1}>
                                {/* Main Box with Paper containing the first set of data */}
                                <Grid item xs={6}>
                                    <Paper elevation={2} sx={{ padding: 1 }}>
                                        <Grid container>
                                            {/* First set of content */}
                                            <Grid item xs={6}>
                                                <Box display="flex" alignItems="center" gap={1}>
                                                    <Box display="flex" flexDirection="column">
                                                        <Typography variant="body2">Compensation Paid</Typography>
                                                        <Typography variant="caption">
                                                            {person["Compensation Paid"] ? person["Compensation Paid"] : "N/A"}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </Grid>

                                            <Grid item xs={3}>
                                                <Box display="flex" alignItems="center" gap={1}>
                                                    <Box display="flex" flexDirection="column">
                                                        <Typography variant="body2">Name of Injured</Typography>
                                                        <Typography variant="caption">
                                                            {person["Name"] ? person["Name"] : "N/A"}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </Grid>

                                            <Grid item xs={3}>
                                                <Box display="flex" alignItems="center" gap={1}>
                                                    <Box display="flex" flexDirection="column">
                                                        <Typography variant="body2">VTC Date</Typography>
                                                        <Typography variant="caption">
                                                            {person["Vocationally Trained"] ? person["Vocationally Trained"] : "N/A"}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </Grid>

                                            <Grid item xs={6}>
                                                <Box display="flex" alignItems="center" gap={1}>
                                                    <Box display="flex" flexDirection="column">
                                                        <Typography variant="body2">Return For Regular Job</Typography>
                                                        <Typography variant="caption">
                                                            {person["Return For Regular Job"]
                                                                ? person["Return For Regular Job"]
                                                                : "N/A"}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </Grid>

                                            <Grid item xs={6}>
                                                <Box display="flex" alignItems="center" gap={1}>
                                                    <Box display="flex" flexDirection="column">
                                                        <Typography variant="body2">Date of Return to Duty</Typography>
                                                        <Typography variant="caption">
                                                            {person["Date Of Return To Duty"]
                                                                ? convertMillisecondsToDate(person?.["Date Of Return To Duty"])
                                                                : "N/A"}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </Grid>

                                            <Grid item xs={8}>
                                                <Box display="flex" alignItems="center" gap={1}>
                                                    <Box display="flex" flexDirection="column">
                                                        <Typography variant="body2">Statutory Compensation Paid</Typography>
                                                        <Typography variant="caption">
                                                            {person["Statutory Compensation Paid (Rs) "]
                                                                ? person["Statutory Compensation Paid (Rs) "]
                                                                : "N/A"}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </Grid>

                                            <Grid item xs={4}>
                                                <Box display="flex" alignItems="center" gap={1}>
                                                    <Box display="flex" flexDirection="column">
                                                        <Typography variant="body2">Ex-Gratia Paid</Typography>
                                                        <Typography variant="caption">
                                                            {person["Ex Gratia Paid"] ? person["Ex Gratia Paid"] : "N/A"}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Box display="flex" alignItems="center" gap={1}>
                                                    <Box display="flex" flexDirection="column">
                                                        <Typography variant="body2">
                                                            Other benefits or Benefits to dependents
                                                        </Typography>
                                                        <Typography variant="caption">
                                                            {person["Other Benefits"]
                                                                ? person["Other Benefits"]
                                                                : "N/A"}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </Grid>

                                {/* Second Box with Paper containing another set of data */}
                                <Grid item xs={6}>
                                    <Paper elevation={2} sx={{ padding: 1 }}>
                                        <Grid container >
                                            {/* Second set of content */}
                                            <Grid item xs={8}>
                                                <Box display="flex" flexDirection="column">
                                                    <Typography variant="body2">Job done at the time of accident</Typography>
                                                    <Typography variant="caption">
                                                        {person["Job done at the time of accident"]
                                                            ? person["Job done at the time of accident"]
                                                            : "N/A"}
                                                    </Typography>
                                                </Box>
                                            </Grid>

                                            <Grid item xs={4}>
                                                <Box display="flex" flexDirection="column">
                                                    <Typography variant="body2">Total Experience</Typography>
                                                    <Typography variant="caption">
                                                        {person["Total Experience"] ? person["Total Experience"] : "N/A"}
                                                    </Typography>
                                                </Box>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Box display="flex" flexDirection="column">
                                                    <Typography variant="body2">Experience at the time of accident</Typography>
                                                    <Typography variant="caption">
                                                        {person["Experience at the time of accident"]
                                                            ? person["Experience at the time of accident"]
                                                            : "N/A"}
                                                    </Typography>
                                                </Box>
                                            </Grid>

                                            <Grid item xs={8}>
                                                <Box display="flex" flexDirection="column">
                                                    <Typography variant="body2">Body parts gone out of use</Typography>
                                                    <Typography variant="caption">
                                                        {person["Body parts gone out of use"]
                                                            ? person["Body parts gone out of use"]
                                                            : "N/A"}
                                                    </Typography>
                                                </Box>
                                            </Grid>

                                            <Grid item xs={4}>
                                                <Box display="flex" flexDirection="column">
                                                    <Typography variant="body2">Body parts (Loss)</Typography>
                                                    <Typography variant="caption">
                                                        {person["Body parts (Loss)"] ? person["Body parts (Loss)"] : "N/A"}
                                                    </Typography>
                                                </Box>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Box display="flex" flexDirection="column">
                                                    <Typography variant="body2">
                                                        Experience of victim job being on time of accident
                                                    </Typography>
                                                    <Typography variant="caption">
                                                        {person["Experience of victim job being on time of accident"]
                                                            ? person["Experience of victim job being on time of accident"]
                                                            : "N/A"}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>

                )

                : null}
        </>
    );


    return (
        <>
            <Box sx={{ position: "relative", borderRadius: "8px" }}>
                {cardData.length > 0 && (
                    <Box sx={{ float: "right", marginRight: "12px", mt: "-16px" }} >
                        <IconButton
                            onClick={prevSlide}
                            sx={{
                                marginRight: "8px",
                                backgroundColor: '#09A2E3', // blue background
                                color: '#fff', // white arrow
                                borderRadius: '20%',
                                '&:hover': {
                                    backgroundColor: '#09A2E3', // slightly darker blue on hover
                                },
                            }}
                        >
                            <ArrowBackIosIcon sx={{ fontSize: "12px" }} />
                        </IconButton>

                        {/* Next Button */}
                        <IconButton
                            onClick={nextSlide}
                            sx={{

                                backgroundColor: '#09A2E3', // blue background
                                color: '#fff', // white arrow
                                borderRadius: '20%',
                                '&:hover': {
                                    backgroundColor: '#09A2E3', // slightly darker blue on hover
                                },
                            }}
                        >
                            <ArrowForwardIosIcon sx={{ fontSize: "12px" }} />
                        </IconButton>
                    </Box>
                )}
                <Box sx={{ display: "flex", overflow: "hidden", width: "100%"}}>
                    {cardData.length > 0 && (
                        <Box
                            sx={{
                                mt:"-24px",
                                width: '50%',
                                padding: '0px',
                                margin: '0 auto',
                              
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            {renderCard(cardData[currentIndex], currentIndex, title)} {/* Render Current Slide */}
                        </Box>
                    )}
                </Box>
            </Box>


        </>
    );
}

export default CardDrawer1;

