import React from "react";
import { useState, useEffect } from "react";
import { Grid, Typography, Box, Chip, Modal, Divider, Button, Avatar, Tabs, Tab } from "@mui/material";
import CustomToggleButtonGroup from "../Components/CustomToggleButtonGroup";
import CustomStepperBox from "../Components/CustomStepperBox";
import IncidentViewTabSection from "../Components/IncidentViewTab";
import CustomWorkflowAccordion from "../Components/CustomWorkflowAccordion";
import RightDrawer from "../Components/RightDrawerWorkFlow";
import CustomCheckCard from "../Components/CustomCheckCard";
import FloatingButton from '../Components/FloatButton';
import { useDispatch, useSelector } from 'react-redux';
import { handleChangeWorkfLowDrawerFormData, setCAPADetails, updateSwitchState, setReviewData } from "../redux/incidentWorkflow/actions";
import { firstReviwerNextDrawerFields, firstReviwerReturnDrawerFields, secondReviwerInvestionReviewNextDrawerFields, firstReviwerReturnInvestionReviewDrawerFields, firstReviwerInvestionReviewNextDrawerFields, assignCAPAFormFields, assignInvestigationFormFields, incidentClassificationFormFields } from "../utils/workFlowFormFields";
import SeverityTag from "../Components/SeverityTag";
import InvestigationCard from '../Components/InvestigationCards';
import { useLocation, useNavigate } from 'react-router-dom';
import { getIncidentViewData, getOverallIncidentStatusTimeline } from '../Apis/apiCall';
import { convertMillisecondsToDate, formatDateToIndianLocale2 } from '../utils/helper';
import { setIncidentFormData } from "../redux/incidentReport/actions";
import IncidentClassificationDrawer from "../Components/IncidentClassificationDrawer";
import { setWorkflowInputData } from "../redux/incidentInvestflow/actions";
import EditIcon from "@mui/icons-material/Edit";




export default function IncidentView() {
  const navigate = useNavigate();
  const location = useLocation();
  const { id, customReportId, status, mode, role } = location?.state?.data || {};
  const dispatch = useDispatch();
  const [levelReviewer, setLevelReviewer] = useState(''); //Can be 1st Viewer Also
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [classificationDrawer, setClassificationDrawer] = useState(false);
  const [drawerTitle, setDrawerTitle] = useState("");
  const [boxesOpen, setBoxesOpen] = useState(false);
  const [newCapa, setNewCapa] = useState(0);
  const [incidentReportData, setIncidentReportData] = useState([]);
  const [workFlowInputsData, setWorkFlowInputsData] = useState([]);
  console.log("incidentReportData", incidentReportData);

  const reportDate = convertMillisecondsToDate(incidentReportData?.["Report Time"]);
  const incidentDate = convertMillisecondsToDate(incidentReportData?.["Incident Time"]);
  localStorage.setItem("IncidentDate", incidentDate);
  localStorage.setItem("ReportDate", reportDate);

  const formData = useSelector(state => state.incidentWorkFlowReducer);
  const {
    Return,
    Review,
    CAPA,
    CAPADetail,
    switchStates,
    Assign_Investigation,
  } = formData;
  console.log("getprops", id, status[0], mode, role, newCapa);

  const afterFRInvestigationCompleted = (status[0] === "Investigation Under Approval" || status[0] === "Investigation Approval Resubmission") ? true : false;

  useEffect(() => {
    if ((status[0] === "New Incident Clarification") && role === "Reporting Officer") {
      setLevelReviewer("0");
    }
    if ((status[0] === "New Incident" || status[0] === "Incident Resubmission" || status[0] === 'Incident Revalidation' || status[0] === "Investigation Completed" || status[0] === "Investigation Resubmission" || status[0] === "Investigation Clarification") && role === "First Reviewer") {
      setLevelReviewer("1");

    }

    if ((status[0] === "Incident Review" || status[0] === "Resubmitted for Review" || status[0] === 'Investigation Validation' || status[0] === "Investigation Completed" || status[0] === "Investigation Approval Resubmission" || status[0] === "Investigation Under Approval") && role === "Second Reviewer") {
      setLevelReviewer("2");

    }
    if ((status[0] === 'Investigation Initiated' || status[0] === "Investigation Reinitiated" || status[0] === "Investigation Query") && role === "Investigator") {
      setLevelReviewer("3");
    }

    if (status[0] === "CAPA In Progress" && role === "First Reviewer") {
      setLevelReviewer("4");
    }

    if ((status[0] === "CAPA In Progress" || status[0] === "Incident Closed") && role === "Second Reviewer") {
      setLevelReviewer("5");
    }


  }, []);




  useEffect(() => {
    if (id) {
      fetchIncidentAllData(id);
      getWokflowInputData(id);
    }
  }, []);

  useEffect(() => {
    // Convert and set dates in local storage when the component loads
    const reportDate = convertMillisecondsToDate(incidentReportData?.["Report Time"]);
    const incidentDate = convertMillisecondsToDate(incidentReportData?.["Incident Time"]);

    localStorage.setItem("IncidentDate", incidentDate);
    localStorage.setItem("ReportDate", reportDate);

    console.log("Dates set in localStorage:", { incidentDate, reportDate });

    // Cleanup: Remove dates from local storage when the component unmounts
    return () => {
      localStorage.removeItem("IncidentDate");
      localStorage.removeItem("ReportDate");
      console.log("Dates removed from localStorage");
    };
  }, [incidentReportData]);

  const getWokflowInputData = async (incidentId) => {
    try {
      const result = await getOverallIncidentStatusTimeline(incidentId);
      setWorkFlowInputsData(result.data?.data);

    } catch (error) {
      console.log(error);
    }
  }

  const fetchIncidentAllData = async (id) => {
    try {
      const result = await getIncidentViewData(id)
      const CAPADetails = result.data?.data?.CAPADetail?.map(item => ({
        ...item,
        "Due Date": convertMillisecondsToDate(item?.["Due Date"]),
      })) ?? [];

      const capas = result.data?.data?.capas?.map(item => ({
        ...item,
        "Due Date": convertMillisecondsToDate(item?.["Due Date"]),
      })) ?? [];

      const investigationCAPA = result.data?.data?.investigationCAPA?.map(item => ({
        ...item,
        "Due Date": convertMillisecondsToDate(item?.["Due Date"]),
      })) ?? [];

      // Combine all arrays into a single array
      const allCAPA = [...CAPADetails, ...capas, ...investigationCAPA];
      setIncidentReportData({
        ...result.data.data,  // Set all the data properties
        CAPADetail: [
          ...allCAPA,  // Add CAPADetail array from the data if available

        ]
      });

      if (role === "Second Reviewer") {
        dispatch(setCAPADetails(allCAPA.length > 0 ?
          [
            ...allCAPA
          ] : []));

        if (status[0] === "Investigation Validation" && role === "Second Reviewer") {
          if (!result.data.data?.capas || result.data.data.capas.length === 0) {
            dispatch(updateSwitchState("CAPA", "No"));
          }
          if (!result.data.data?.investigationLeader) {
            dispatch(updateSwitchState("Investigation", "No"));
          } else {
            dispatch(handleChangeWorkfLowDrawerFormData({ fieldName: "Investigation Leader", value: result.data.data?.investigationLeader, title: "Assign_Investigation" }));
            dispatch(handleChangeWorkfLowDrawerFormData({ fieldName: "Investigation Members", value: result.data.data?.investigationMembers, title: "Assign_Investigation" }));
            dispatch(handleChangeWorkfLowDrawerFormData({ fieldName: "Target completion date", value: convertMillisecondsToDate(result.data.data?.targetCompletionDate), title: "Assign_Investigation" }));
          }
        }
      }
      setNewCapa(CAPADetail?.length)
      // when second rever return to first review reviwer form old data 
      if (status[0] === 'Incident Revalidation' && role === "First Reviewer") {
        const reviewedData = {
          Severity: String(result.data.data?.firstReviewerRecommendedSeverity),
          "Incident Type": result.data.data?.firstReviewerRecommendedIncidentType,
          "Investigation Recommended": result.data.data?.investigationRecommended,
          DGMS: result.data.data?.frReviewData?.DGMS,
          "Factories Act": result.data.data?.frReviewData?.["Factories Act"],
          OISD: result.data.data?.frReviewData?.OISD,
          PNGRB: result.data.data?.frReviewData?.PNGRB,
          "OISD Fire": result.data.data?.frReviewData?.["OISD Fire"],
          "PNGRB Fire": result.data.data?.frReviewData?.["PNGRB Fire"],
          "OISD Major Fire": result.data.data?.frReviewData?.["OISD Major Fire"],
          "PNGRB Major Fire": result.data.data?.frReviewData?.["PNGRB Major Fire"],
          "High Potential Near Miss": result.data.data?.highPotentialNearMiss
        }
        dispatch(setReviewData(reviewedData));
      }

      // when first reviwer accept send to second reviwer ,

      if ((status[0] === 'Incident Review' || status[0] === "Resubmitted for Review") && role === "Second Reviewer") {
        const reviewedData = {
          Severity: String(result.data.data?.firstReviewerRecommendedSeverity),
          "Incident Type": result.data.data?.firstReviewerRecommendedIncidentType,
          "Investigation Recommended": result.data.data?.investigationRecommended,
          DGMS: result.data.data?.frReviewData?.DGMS,
          "Factories Act": result.data.data?.frReviewData?.["Factories Act"],
          OISD: result.data.data?.frReviewData?.OISD,
          PNGRB: result.data.data?.frReviewData?.PNGRB,
          "OISD Fire": result.data.data?.frReviewData?.["OISD Fire"],
          "PNGRB Fire": result.data.data?.frReviewData?.["PNGRB Fire"],
          "OISD Major Fire": result.data.data?.frReviewData?.["OISD Major Fire"],
          "PNGRB Major Fire": result.data.data?.frReviewData?.["PNGRB Major Fire"],
          "High Potential Near Miss": result.data.data?.highPotentialNearMiss
        }
        dispatch(setReviewData(reviewedData));
      }

      // second reviwer send to investoigator or resubmit again to investigator

      if ((status[0] === "Investigation Initiated" || status[0] === "Investigation Reinitiated") && role === "Investigator") {
        const WorkflowInputData = {
          DGMS: result.data.data?.srReviewData?.DGMS,
          OISD: result.data.data?.srReviewData?.OISD,
          PNGRB: result.data.data?.srReviewData?.PNGRB,
          "OISD Fire": result.data.data?.srReviewData?.["OISD Fire"],
          "PNGRB Fire": result.data.data?.srReviewData?.["PNGRB Fire"],
          "Factories Act": result.data.data?.srReviewData?.["Factories Act"],
          "OISD Major Fire": result.data.data?.srReviewData?.["OISD Major Fire"],
          "PNGRB Major Fire": result.data.data?.srReviewData?.["PNGRB Major Fire"]
        };
        dispatch(setWorkflowInputData(WorkflowInputData))
      }

      if (status === "Investigation Validation" && role === "Second Reviewer") {
        const WorkflowInputData = {
          DGMS: result.data.data?.srReviewData?.DGMS,
          OISD: result.data.data?.srReviewData?.OISD,
          PNGRB: result.data.data?.srReviewData?.PNGRB,
          "OISD Fire": result.data.data?.srReviewData?.["OISD Fire"],
          "PNGRB Fire": result.data.data?.srReviewData?.["PNGRB Fire"],
          "Factories Act": result.data.data?.srReviewData?.["Factories Act"],
          "OISD Major Fire": result.data.data?.srReviewData?.["OISD Major Fire"],
          "PNGRB Major Fire": result.data.data?.srReviewData?.["PNGRB Major Fire"]
        };
        dispatch(setReviewData(WorkflowInputData))
      }

      // when investigation send to first reviwer or resubmited aagain ,first reviwer form old data 
      if ((status[0] === 'Investigation Completed' || status[0] === 'Investigation Resubmission') && role === "First Reviewer") {
        const reviewedData = {
          Severity: String(result.data.data?.investigatorRecommendedSeverity),
          "Incident Type": result.data.data?.investigatorRecommendedType,
          "Investigation Recommended": result.data.data?.investigationRecommended,
          DGMS: result.data.data?.invData?.DGMS,
          "Factories Act": result.data.data?.invData?.["Factories Act"],
          OISD: result.data.data?.invData?.OISD,
          PNGRB: result.data.data?.invData?.PNGRB,
          "OISD Fire": result.data.data?.invData?.["OISD Fire"],
          "PNGRB Fire": result.data.data?.invData?.["PNGRB Fire"],
          "OISD Major Fire": result.data.data?.invData?.["OISD Major Fire"],
          "PNGRB Major Fire": result.data.data?.invData?.["PNGRB Major Fire"],
          "High Potential Near Miss": result.data.data?.highPotentialNearMiss
        }
        dispatch(setReviewData(reviewedData));
      }

      // when first evewier return to investigator after investigation done 

      if (status[0] === 'Investigation Query' && role === "Investigator") {
        const reviewedData = {
          Severity: String(result.data.data?.investigatorRecommendedSeverity),
          "Incident Type": result.data.data?.investigatorRecommendedType,
          "Investigation Recommended": result.data.data?.investigationRecommended,
          DGMS: result.data.data?.invData?.DGMS,
          "Factories Act": result.data.data?.invData?.["Factories Act"],
          OISD: result.data.data?.invData?.OISD,
          PNGRB: result.data.data?.invData?.PNGRB,
          "OISD Fire": result.data.data?.invData?.["OISD Fire"],
          "PNGRB Fire": result.data.data?.invData?.["PNGRB Fire"],
          "OISD Major Fire": result.data.data?.invData?.["OISD Major Fire"],
          "PNGRB Major Fire": result.data.data?.invData?.["PNGRB Major Fire"],
          "High Potential Near Miss": result.data.data?.highPotentialNearMiss
        }
        dispatch(setWorkflowInputData(reviewedData));

      }

      // when first reviwer send to second or resubmit  aftre invest done 
      if ((status[0] === 'Investigation Under Approval' || status[0] === 'Investigation Approval Resubmission') && role === "Second Reviewer") {
        const reviewedData = {
          Severity: String(result.data.data?.investigatorRecommendedSeverity),
          "Incident Type": result.data.data?.investigatorRecommendedType,
          "Investigation Recommended": result.data.data?.investigationRecommended,
          DGMS: result.data.data?.invData?.DGMS,
          "Factories Act": result.data.data?.invData?.["Factories Act"],
          OISD: result.data.data?.invData?.OISD,
          PNGRB: result.data.data?.invData?.PNGRB,
          "OISD Fire": result.data.data?.invData?.["OISD Fire"],
          "PNGRB Fire": result.data.data?.invData?.["PNGRB Fire"],
          "OISD Major Fire": result.data.data?.invData?.["OISD Major Fire"],
          "PNGRB Major Fire": result.data.data?.invData?.["PNGRB Major Fire"],
          "High Potential Near Miss": result.data.data?.highPotentialNearMiss
        }
        dispatch(setReviewData(reviewedData));
      }
      // when second reviwer return to first reviwer aftre invest done  

      if (status[0] === 'Investigation Clarification' && role === "First Reviewer") {
        const reviewedData = {
          Severity: String(result.data.data?.investigatorRecommendedSeverity),
          "Incident Type": result.data.data?.investigatorRecommendedType,
          "Investigation Recommended": result.data.data?.investigationRecommended,
          DGMS: result.data.data?.invData?.DGMS,
          "Factories Act": result.data.data?.invData?.["Factories Act"],
          OISD: result.data.data?.invData?.OISD,
          PNGRB: result.data.data?.invData?.PNGRB,
          "OISD Fire": result.data.data?.invData?.["OISD Fire"],
          "PNGRB Fire": result.data.data?.invData?.["PNGRB Fire"],
          "OISD Major Fire": result.data.data?.invData?.["OISD Major Fire"],
          "PNGRB Major Fire": result.data.data?.invData?.["PNGRB Major Fire"],
          "High Potential Near Miss": result.data.data?.highPotentialNearMiss
        }
        dispatch(setReviewData(reviewedData));
      }

    } catch (error) {
      console.log(error);
    }
  }

  const handleClassification = () => {
    setClassificationDrawer(true);
  }

  const handleDrawerOpen = async (action) => {
    console.log("butttttt", action, levelReviewer);
    if (action === "editReport") {
      const data = await getIncidentformData();
      if (data) {
        const data2 = { 
          ...data, 
          Severity: data?.Severity !== undefined ? String(data?.Severity) : '',
          "Date of Accident": data?.["Date of Accident"] ? convertMillisecondsToDate(data?.["Date of Accident"]) : '',
        };
        dispatch(setIncidentFormData(data2));
      }
      navigate("/module/incident-management/report-incident");
    }
    if (action === "previous" || action === "comment") {
      setDrawerTitle("Return");
      setBoxesOpen(false);
      setDrawerOpen(true);
    }
    if (action === "next" && levelReviewer === "1") {
      setDrawerTitle("Review");
      setDrawerOpen(true);
    }

    if (action === "next" && levelReviewer === "2") {
      if (status[0] === "Investigation Completed") {
        setDrawerTitle("Review");
        setDrawerOpen(true);
      } else {
        setBoxesOpen(true);
        const reviewedData = {
          Severity: String(incidentReportData?.firstReviewerRecommendedSeverity),
          "Incident Type": incidentReportData?.firstReviewerRecommendedIncidentType,
          "Investigation Recommended": incidentReportData?.investigationRecommended,
        }
        dispatch(setReviewData(reviewedData));
      }
    }

    if (action === "next" && levelReviewer === "3") {
      setDrawerTitle("Review");
      const reviewedData = {
        Severity: String(incidentReportData?.secondReviewerRecommendedSeverity),
        "Incident Type": incidentReportData?.secondReviewerRecommendedIncidentType,
      }
      dispatch(setReviewData(reviewedData));
    }


  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
    setClassificationDrawer(false);
  };


  const processObject = (obj) => {
    if (!obj || typeof obj !== 'object') return obj;

    // Recursively process each key in the object
    for (const key in obj) {
      if (obj[key] instanceof Array) {
        obj[key] = obj[key].map(item => processObject(item));
      } else if (typeof obj[key] === 'object') {
        obj[key] = processObject(obj[key]);
      } else if (key.toLowerCase().includes('Date') || key.toLowerCase().includes('time')) {
        obj[key] = convertMillisecondsToDate(obj[key]);
      }
    }

    return obj;
  };
  const getIncidentformData = async () => {
    try {
      const result = await getIncidentViewData(id);
      const data = result?.data?.data;

      // If data is not an object, return an empty object
      if (!data || typeof data !== 'object') return {};
      const switchStates = {
        Services: 
          (data?.PersonnelDetail?.length > 0 || data?.EvidenceDetail?.length > 0 || data?.EquipmentDetail?.length > 0)
          ? "Yes" 
          : "No",
        Personnel: data?.PersonnelDetail && data?.PersonnelDetail.length > 0 ? "Yes" : "No", 
        Equipment: data?.EquipmentDetail && data?.EquipmentDetail.length > 0 ? "Yes" : "No",
        Vehicle: data?.VehicleDetail && data?.VehicleDetail.length > 0 ? "Yes" : "No",
        CAPA: data?.CAPADetail && data?.CAPADetail.length > 0 ? "Yes" : "No",
        Evidence: data?.EvidenceDetail && data?.EvidenceDetail.length > 0 ? "Yes" : "No",
        Regulatory: data?.Regulatory && Object.keys(data?.Regulatory).length > 0 ? "Yes" : "No",
        LTI: data?.LTIDetail && data?.LTIDetail.length > 0 ? "Yes":"No", // Keeping it as "No" as per your original data
      };

      // Process the data object with default values and date conversion
      return processObject({
        incidentReportID: data?.id,
        Area: data?.Area || '',
        Shift: data?.Shift || '',
        Activity: data?.Activity || '',
        Severity: data?.Severity || '',
        "Sub Area": data?.["Sub Area"] || '',
        LTIDetail: Array.isArray(data?.LTIDetail) ? data.LTIDetail : [],
        Personnel: data?.Personnel || {},
        CAPADetail: Array.isArray(data?.CAPADetail) ? data.CAPADetail : [],
        Department: data?.Department || '',
        Regulatory: data?.Regulatory || {},
        switchStates: switchStates || {},
        "Well No.":data?.["Well No."]|| '',
        Others:data?.Others || '',
        "Staff Type": data?.["Staff Type"] || '',
        GeneralInfo: Array.isArray(data?.GeneralInfo) ? data.GeneralInfo : [],
        "Losses Type": data?.["Losses Type"] || '',
        "Report Time": data?.["Report Time"] || null,
        "Sub Activity": data?.["Sub Activity"] || '',
        "Employee Type": data?.["Employee Type"] || '',
        "Incident Time": data?.["Incident Time"] || null,
        "Incident Type": data?.["Incident Type"] || '',
        VehicleDetail: Array.isArray(data?.VehicleDetail) ? data.VehicleDetail : [],
        EvidenceDetail: Array.isArray(data?.EvidenceDetail) ? data.EvidenceDetail : [],
        EquipmentDetail: Array.isArray(data?.EquipmentDetail) ? data.EquipmentDetail : [],
        PersonnelDetail: Array.isArray(data?.PersonnelDetail) ? data.PersonnelDetail : [],
        "Date of Accident": data?.["Date of Accident"] || null,
        "Incident Summary": data?.["Incident Summary"] || '',
        "Operation Status": data?.["Operation Status"] || '',
        "Cause of Accident": data?.["Cause of Accident"] || '',
        "Incident Location": data?.["Incident Location"] || '',
        "Immediate Measures": data?.["Immediate Measures"] || '',
        "Incident Short Summary": data?.["Incident Short Summary"] || '',
        "Immediate Last Accident": data?.["Immediate Last Accident"] || '',
        "Reported By - Staff Name": data?.["Reported By - Staff Name"] || '',
        "Reported By - Entity Name": data?.["Reported By - Entity Name"] || '',
        locationName: data?.locationName || '',
        locationCode: data?.locationCode || '',
        reportedByStaffName: data?.reportedByStaffName || '',
        reportedByEntityName: data?.reportedByEntityName || ''
      });
    } catch (error) {
      console.log(error);
      return {};
    }
  };



  const [fRCAPAMessage, setFRCAPAMessage] = useState("");

  const hasHighPotentialNearMiss = (data) => {
    // Ensure data is defined and not null before checking
    if (!data) return false;

    return Object.values(data).some(value => value === true);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <Box display="flex" flexDirection="column" gap={1}>
            <Box display="flex" gap={1}>
              <Typography variant="h2">{incidentReportData?.["Incident Type"]}</Typography>
              <SeverityTag severity={incidentReportData?.Severity == 0 ? "N/A" : incidentReportData?.Severity} />
              {incidentReportData?.CAPADetail?.length > 0 && (
                <Chip
                  label={"CAPA Assigned"}
                  sx={{
                    color: "#0FAB8E",
                    backgroundColor: "rgba(19, 222, 185, 0.20)",
                    fontSize: "12px",
                    fontWeight: "600",
                    minWidth: "104px",
                    maxHeight: "24px",
                    borderRadius: "4px",
                  }}
                />
              )}
              {incidentReportData?.highPotentialNearMiss && (
                <Chip
                  label="High Potential Near Miss"
                  sx={{
                    color: "#FF231F",
                    backgroundColor: "rgba(255, 87, 83, 0.15)",
                    fontSize: "12px",
                    fontWeight: "600",
                    minWidth: "104px",
                    maxHeight: "24px",
                    borderRadius: "4px",
                  }}
                />
              )}
              <Chip
                label={
                  <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
                    <span>Classification</span>
                    <EditIcon sx={{ color: "#0AA2E3", fontSize: "14px" }} />
                  </Box>
                }
                sx={{
                  color: "#0AA2E3",
                  backgroundColor: "#ffffff",
                  fontSize: "12px",
                  fontWeight: "600",
                  minWidth: "104px",
                  maxHeight: "24px",
                  borderRadius: "4px",

                }}
                onClick={handleClassification}
              />

            </Box>
            <Box display="flex" flexDirection="column" gap={0.5} position="relative">
              <Box display="flex" gap={1}>
                <Typography variant="body1" sx={{ color: "#222222" }}>
                  ID:
                </Typography>
                <Typography variant="body1" sx={{ color: "#525966" }}>
                  {customReportId}
                </Typography>
              </Box>
              <Box display="flex" gap={1}>
                <Typography variant="body1" sx={{ color: "#222222" }}>
                  Reporting Officer:
                </Typography>
                <Typography variant="body1" sx={{ color: "#525966" }}>
                  {incidentReportData?.reportedByStaffName}
                </Typography>
              </Box>
              <Box display="flex" gap={1}>
                <Typography variant="body1" sx={{ color: "#222222" }}>
                  Reporting Time:
                </Typography>
                <Typography variant="body1" sx={{ color: "#525966" }}>
                  {formatDateToIndianLocale2(incidentReportData?.["Report Time"])}
                </Typography>
              </Box>

            </Box>
          </Box>

        </Grid>
        {mode === "action" &&
          <Grid item xs={12} md={4}>
            {levelReviewer === "1" && <CustomToggleButtonGroup
              title="First Reviewer Action"
              defaultValue=""
              options={
                (status[0] === 'Incident Revalidation' || status[0] === "Investigation Clarification") ?
                  [{ value: "next", label: "Proceed to next level" }] :
                  [
                    { value: "previous", label: "Return to previous level" },
                    { value: "next", label: "Proceed to next level" },
                  ]}
              onChange={handleDrawerOpen}
            />}
            {levelReviewer === "2" && <CustomToggleButtonGroup
              title="Second Reviewer Action"
              defaultValue=""
              options={status[0] === 'Investigation Validation' ? [
                { value: "next", label: "Proceed to next level" },
              ] : [
                { value: "previous", label: "Return to previous level" },
                { value: "next", label: "Proceed to next level" },
              ]}
              onChange={handleDrawerOpen}
            />}
            {levelReviewer === "3" && <CustomToggleButtonGroup
              title="Investigator Action"
              defaultValue=""
              options={
                status[0] === "Investigation Query" ?
                  [
                    { value: "next", label: "Proceed to next level" },
                  ] :
                  [
                    { value: "previous", label: "Return to previous level" },
                    { value: "next", label: "Proceed to next level" },
                  ]}
              onChange={handleDrawerOpen}
            />}
            {(levelReviewer === "4" && mode === "action") && <CustomToggleButtonGroup
              title="First Reviwer Action"
              defaultValue=""
              options={[
                { value: "proceed", label: "Proceed" }
              ]}
              onChange={() => setFRCAPAMessage("Please select a CAPA Detail in the table to proceed.")}
            />}
            {(levelReviewer === "5" && mode === "action") && <CustomToggleButtonGroup
              title="Second Reviwer Action"
              defaultValue=""
              options={[
                { value: "proceed", label: "Proceed" }
              ]}
              onChange={() => setFRCAPAMessage("Please select a CAPA Detail in the table to proceed.")}
            />}

            {levelReviewer === "0" && <CustomToggleButtonGroup
              title="Repoting Officer Action"
              defaultValue=""
              options={[
                { value: "editReport", label: "Edit Report" },
              ]}
              onChange={handleDrawerOpen}
            />}
          </Grid>
        }

        <Grid item xs={12} md={8}>
          <Grid container spacing={2}>
            <Grid item xs={12} mt={1}>
              <CustomStepperBox stepData={workFlowInputsData} />
            </Grid>

            <Grid item xs={12}>
              <IncidentViewTabSection data={incidentReportData} title="Incident Info" level={levelReviewer} mode={mode} />
            </Grid>
          </Grid>
        </Grid>

        <>
          <Grid item xs={12} md={4} mt={1}>
            <Grid container spacing={2}>
              {(levelReviewer === "2" && boxesOpen) && (
                <Grid item xs={12}>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{
                      width: "100%",
                      paddingX: "24px",
                      paddingY: "12px",
                      backgroundColor: "#ffffff",
                      borderRadius: "8px",
                      position: "relative",
                      pt: 3,
                      height: "110px",
                    }}
                  >
                    <CustomCheckCard
                      title={switchStates?.CAPA === "No" || newCapa < CAPADetail?.length ? "Added CAPA" : "Add CAPA"}
                      subtitle="CAPA"
                      assigned={CAPADetail?.length}
                      showIcon={switchStates?.CAPA === "No" || newCapa < CAPADetail?.length}
                      iconBgColor="#1ABC9C"
                      onClick={() => { setDrawerOpen(true); setDrawerTitle("CAPA"); }}
                    />
                    {!afterFRInvestigationCompleted &&
                      <CustomCheckCard
                        title={Object.keys(Assign_Investigation)?.length > 0 ? "Assigned Investigation" : switchStates?.Investigation === "No" ? "No Investigation" : "Assign Investigation"}
                        subtitle=""
                        assigned=''
                        showIcon={Object.keys(Assign_Investigation)?.length > 0 || switchStates?.Investigation === "No"}
                        iconBgColor="#1ABC9C"
                        onClick={() => { setDrawerOpen(true); setDrawerTitle("Assign_Investigation"); }}
                      />
                    }
                    <CustomCheckCard
                      title={Object.keys(Review)?.length > 0 ? "Review Completed" : "Complete Review"}
                      subtitle=""
                      assigned=''
                      showIcon={Review?.["Review completed"] === true || Review?.["Approved Investigation"] === true}
                      iconBgColor="#1ABC9C"
                      onClick={() => { setDrawerOpen(true); setDrawerTitle("Review"); }}
                    />
                  </Box>
                </Grid>
              )}
              <Grid item xs={12}>
                <Box
                  sx={{
                    width: "100%",
                    height: "100%",
                    // paddingX: "24px",
                    paddingY: "16px",
                    backgroundColor: "#ffffff",
                    borderRadius: "8px",
                  }}
                >
                  <Typography variant="body1" sx={{ mb: "16px", ml: "24px" }}>
                    {levelReviewer === "3" && drawerTitle === "Review" ? "Investigation" : "Workflow Inputs"}
                  </Typography>
                  <Divider
                    sx={{
                      width: "100%",
                      border: "0.5px solid #B7C8E5",
                      mb: "16px",
                    }}
                  />
                  <Box
                    display="flex"
                    flexDirection="column"
                    gap={1.5}
                    sx={{
                      paddingX: "24px",
                      height: boxesOpen ? "304px" : "425px",
                      overflowY: "scroll",
                      scrollbarWidth: "none",
                      "&::-webkit-scrollbar": { display: "none" },
                    }}
                  >
                    {levelReviewer === "3" && drawerTitle === "Review" && mode === "action" ?
                      <InvestigationCard levelReviewer={levelReviewer} status={status[0]} IncidentReportData={incidentReportData} />
                      : <CustomWorkflowAccordion data={workFlowInputsData} />
                    }
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>

          <Box
            sx={{
              position: 'fixed',
              bottom: 30, // Distance from the bottom
              right: 24, // Distance from the right
              zIndex: 1000, // Ensure it's above other components
            }}
          >
            {mode === "action" &&
              <FloatingButton title={"ActionPage"} subtitle={drawerTitle} level={levelReviewer} incidentId={id} status={status[0]} />
            }
          </Box>
        </>

      </Grid>
      <RightDrawer
        open={drawerOpen}
        onClose={handleDrawerClose}
        title={drawerTitle}
        status={status[0]}
        fields={
          drawerTitle === "Review" && levelReviewer === "1" && status[0] === "Investigation Review" ? firstReviwerInvestionReviewNextDrawerFields : drawerTitle === "Review" && levelReviewer === "1" && status[0] === "New Incident" ? firstReviwerNextDrawerFields : drawerTitle === "Review" && levelReviewer === "1" && status[0] === "Incident Revalidation" ? firstReviwerNextDrawerFields : drawerTitle === "Review" && levelReviewer === "1" && status[0] === "Incident Resubmission" ? firstReviwerNextDrawerFields : drawerTitle === "Review" && levelReviewer === "1" && status[0] === "Investigation Completed" ? firstReviwerInvestionReviewNextDrawerFields : drawerTitle === "Review" && levelReviewer === "1" && status[0] === "Investigation Resubmission" ? firstReviwerInvestionReviewNextDrawerFields : drawerTitle === "Review" && levelReviewer === "1" && status[0] === "Investigation Resubmission" ? firstReviwerInvestionReviewNextDrawerFields : drawerTitle === "Review" && levelReviewer === "1" && status[0] === "Investigation Clarification" ? firstReviwerInvestionReviewNextDrawerFields
            : drawerTitle === "Review" && levelReviewer === "2" && (status[0] === "Investigation Review" || status[0] === "Investigation Under Approval" || status[0] === "Investigation Approval Resubmission") ? secondReviwerInvestionReviewNextDrawerFields : drawerTitle === "Review" && levelReviewer === "2" ? firstReviwerNextDrawerFields : drawerTitle === "Assign_Investigation" && levelReviewer == "2" ? assignInvestigationFormFields : drawerTitle === "CAPA" && levelReviewer == "2" ? assignCAPAFormFields : drawerTitle === "Return" && status[0] === "Investigation_Review" ? firstReviwerReturnInvestionReviewDrawerFields : firstReviwerReturnDrawerFields
        }
        cardData={drawerTitle === "CAPA" ? CAPADetail : []}
        data={drawerTitle === "Review" || drawerTitle === "Return" ? incidentReportData : incidentReportData} // Provide the card data if needed

      />
      <IncidentClassificationDrawer
        open={classificationDrawer}
        onClose={handleDrawerClose}
        title={levelReviewer === "3" ? "WorkflowInput" : "Review"}
        fields={incidentClassificationFormFields}
        status={status[0]}
        level={levelReviewer}
        mode={mode}
      />

      <Modal
        open={fRCAPAMessage.length > 0}
        onClose={() => setFRCAPAMessage("")}
        aria-labelledby="message-modal-title"
        aria-describedby="message-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 300,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <Typography align="center" id="message-modal-description" sx={{ mt: 2, fontSize: '16px', fontWeight: '600' }}>
            {fRCAPAMessage}
          </Typography>
          <Box textAlign="center" sx={{ mt: 3 }}>
            <Button variant="contained" sx={{ color: 'white' }} onClick={() => setFRCAPAMessage("")}>
              Okay
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
}
