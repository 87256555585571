// actions.js
import { UPDATE_ACTIVE_STEP, COMPLETE_FORM1,COMPLETE_FORM2 } from './constant';

export const updateActiveStep = (step) => ({
  type: UPDATE_ACTIVE_STEP,
  payload: step,
});


export const completeForm1 = (data) => ({
  type: COMPLETE_FORM1,
  payload:data
});
export const completeForm2 = (data) => ({
  type: COMPLETE_FORM2,
  payload:data
});
