import React, { useEffect, useState } from 'react';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { Box, Paper, Typography, Grid, Divider, Button, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import DoughnutChart from "../Components/ChartAndGraphs/DoughnutChart";
import BarGraph from '../Components/ChartAndGraphs/BarGraph';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { overDueImage } from "../constant";
import { useNavigate } from 'react-router-dom';
import SafetyTipSlider from '../Components/AlertSliderShow';
import { DashBoardData, DashBoardDataWithFilter } from '../Apis/apiCall';
import { useSelector, useDispatch } from 'react-redux';
import WelcomeUserCard from "../Components/welcomeUserCard"
import ChartModal from '../Components/ChartModal';
import { getAssignedLocationsTree, dashboardBroadCast, getAllLocationName } from '../Apis/apiCall';
import BroadcastSlideshow from "../Components/BroadCastInfoSlide";
import CloseIcon from '@mui/icons-material/Close';
import OrgTreeView from "../Components/OrgTree";
import { showTreeModel } from '../redux/Loader/actions';
import Loader from "../Components/Loader/Loader";
import { START_LOADING, STOP_LOADING } from "../redux/Loader/constant"




const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#fff',
    padding: theme.spacing(1),
    borderRadius: "12px",
    cursor: 'pointer',
    ...theme.typography.body2,
    color: theme.palette.text.secondary,
    ...theme.applyStyles('dark', {
        backgroundColor: '#1A2027',
    }),
}));

const colors = {
    backgroundColor: [
        '#D9DEC1',
        '#FFCA58',
        '#BCE1A5',
        '#A1CFDD',
        '#A1A0EC',
        '#FF9567',
    ],
    borderColor: [
        '#D9DEC1',
        '#FFCA58',
        '#BCE1A5',
        '#A1CFDD',
        '#A1A0EC',
        '#FF9567',
    ],
};
const createChartData = (labels, data) => ({
    labels,
    datasets: [{
        data,
        backgroundColor: colors.backgroundColor,
        borderColor: colors.borderColor,
        borderWidth: 1,
    }],
});


const chartData = {
    labels: ['Location 1', 'Location 2', 'Location 3', 'Location 4', 'Location 5'],
    datasets: [
        {

            data: [0, 0, 0, 0, 0, 0],
            backgroundColor: [
                '#D9DEC1',
                '#FFCA58',
                '#BCE1A5',
                '#A1CFDD',
                "#A1A0EC",
                '#FF9567'
            ],
            borderColor: [
                '#D9DEC1',
                '#FFCA58',
                '#BCE1A5',
                '#A1CFDD',
                "#A1A0EC",
                '#FF9567'
            ],
            borderWidth: 1,
        },
    ],
};






const Dashboard = () => {
    const { dashboardFilter } = useSelector(state => state.lossControlReducer.tours);
    const { isShowTreeModel } = useSelector(state => state.loadingReducer);
    const dispatch = useDispatch();
    const loading = useSelector((state) => state.loadingReducer.loading);
    const [step, setStep] = useState(0);
    const [role, setRole] = useState(null);
    const [userName, setUserName] = useState(null);
    const [viewCount, setViewCount] = useState(null);
    const [overDueCount, setOverDueCount] = useState(null);
    const navigate = useNavigate();
    const [chartState, setChartState] = useState(chartData);
    const [tourBarGraphData, setTourBarGraphData] = useState({
        labels: ['Discipline1', 'Discipline2', 'Discipline3', 'Discipline4', 'Discipline5'],
        datasets: [
            {
                label: 'Dataset 1',
                data: [5, 8, 3, 7, 6],
                backgroundColor: ['#3DE8BC'],
            },

        ],

    });
    const [delayBarGraphData, setDelayBarGraphData] = useState({
        labels: ['Discipline1', 'Discipline2', 'Discipline3', 'Discipline4', 'Discipline5'],
        datasets: [
            {
                label: 'Dataset 1',
                data: [5, 8, 3, 7, 6],
                backgroundColor: ['#3DE8BC'],
            },

        ],

    })
    const [BarGraphData, setBarGraphData] = useState({
        labels: ['Discipline1', 'Discipline2', 'Discipline3', 'Discipline4', 'Discipline5'],
        datasets: [
            {
                label: 'Dataset 1',
                data: [5, 8, 3, 7, 6],
                backgroundColor: ['#3DE8BC'],
            },
            {
                label: 'Dataset 2',
                data: [6, 9, 2, 6, 7],
                backgroundColor: ['#FF8C95'],
            },
        ],

    });
    const [boardCasteData, setBoardCasteData] = useState({
        broadcasts: [],
        safetyTips: [],
        greetings: []
    });
    const [openChartModal, setOpenChartModal] = useState(false);
    const [chartModalData, setChartModalData] = useState(null);
    const [chartModalTitle, setChartModalTitle] = useState(null);
    const [treeData, setTreeData] = useState(null);
    const [allLoactionName, setAllLoactionName] = useState([]);
    const loginDataString = localStorage.getItem("loginData");
    const loginData = loginDataString ? JSON.parse(loginDataString) : null;
    const locationID = loginData?.currentLocationID;
    

    const [allLocationsReportCountIDs, setAllLocationsReportCountIDs] = useState([]);
    const [top5LocationsIDs, setTop5LocationsIDs] = useState([]);
    const [actionItemComplianceLocations, setActionItemComplianceLocations] = useState([]);
    const [completionTimeComplianceLocations, setCompletionTimeComplianceLocations] = useState([]);
    const [disciplineDistributionLocations, setDisciplineDistributionLocations] = useState([]);
    const [disciplinesByInstructionLocations, setDisciplinesByInstructionLocations] = useState([]);
    const [top5DisciplinesLocations, setTop5DisciplinesLocations] = useState([]);
    const [top5ExecutivesLocations, setTop5ExecutivesLocations] = useState([]);
    const [top10EmployeesLocations, setTop10EmployeesLocations] = useState([]);
    const [tourCompletionLocations, setTourCompletionLocations] = useState([]);
    const [imDelays, setIMDelays] = useState([]);


    useEffect(() => {
        (isShowTreeModel) && dispatch(showTreeModel());
        getallLocationName();

    }, []);

    const getallLocationName = async () => {
        try {
            const result = await getAllLocationName();
            setAllLoactionName(result.data.data);
        } catch (error) {
            console.log(error);

        }
    }
    const handleCloseOverlay = () => {
        dispatch(showTreeModel());
    };


    const handleOpenChartModal = (modalData, modalTitle) => {
        setChartModalData(modalData);
        setChartModalTitle(modalTitle);
        setOpenChartModal(true);
        const fetchTreeData = async () => {
            dispatch({ type: START_LOADING }); 
            try {
                const response = await getAssignedLocationsTree();
                setTreeData(response.data.data);
            } catch (error) {
                console.error("Error fetching tree data:", error);
            }
            finally{
                dispatch({ type: STOP_LOADING }); 
              }
        };

        fetchTreeData();
    };
    const handleCloseChartModal = () => {
        setOpenChartModal(false);
    };






    useEffect(() => {
        fetchBroadCasteData();
        const roles = localStorage.getItem("lossControlRoles");
        setRole(roles)
        const loginDataString = localStorage.getItem("loginData");
        if (loginDataString) {
            const data = JSON.parse(loginDataString);
            const { firstName } = data;
            setUserName(firstName);

        }
    }, []);



    useEffect(() => {
        // Check if locations are already in the filter
        if (dashboardFilter?.location.length > 0) {
            console.log(dashboardFilter.location)
            fetchChartData(dashboardFilter?.period, dashboardFilter?.location);
        } else {
            const childIDs = loginData.childData
            console.log(childIDs)
            const locations = [...childIDs,locationID]
            fetchChartData(dashboardFilter?.period, locations);
        }
    }, [dashboardFilter]);


    const fetchBroadCasteData = async () => {
        dispatch({type: START_LOADING})
        try {
            const result = await dashboardBroadCast();
            setBoardCasteData(result.data.data);
        } catch (error) {
            console.log(error);
        }
        finally{
            dispatch({ type: STOP_LOADING }); 
          }
    }


    const fetchChartData = async (month, locationID) => {
        dispatch({type: START_LOADING})
        try {
            let result;
            if (month || locationID) {
                result = await DashBoardDataWithFilter(month, locationID);
            } else {
                result = await DashBoardData();
            }

            const data = result.data.data;

            // Setting Dashboard Data from here

            setViewCount(result.data.data.view);
            setOverDueCount(result.data.data.overdue);
            setAllLocationsReportCountIDs(data.allLocationsReportCount.map(location => location.id));
            setTop5LocationsIDs(data.top5Locations.map(location => location.id));

            setActionItemComplianceLocations(
                data.actionItemCompliance.flatMap(item => item.locations)
            );

            setCompletionTimeComplianceLocations(
                data.completionTimeCompliance.flatMap(item => item.locations)
            );

            setDisciplineDistributionLocations(
                data.disciplineDistribution.flatMap(discipline => discipline.locations)
            );

            setDisciplinesByInstructionLocations(
                data.disciplinesByInstruction.flatMap(discipline => discipline.locations)
            );

            setTop5DisciplinesLocations(
                data.top5Disciplines.flatMap(discipline => discipline.locations)
            );

            setTop5ExecutivesLocations(
                data.top5Executives.flatMap(executive => executive.locations)
            );

            setTop10EmployeesLocations(
                data.top10Employees.flatMap(employee => employee.locations)
            );

            setTourCompletionLocations(
                data.tourCompletion.flatMap(tour => tour.locations)
            );

            setIMDelays(
                data.imByDelays.flatMap(tour => tour.locations)
            );

            const top5LocationsChartData = createChartData(
                data.top5Locations.map(location => location.displayName),
                data.top5Locations.map(location => location.count)
            );

            const allLocationsReportCountChartData = createChartData(
                data.allLocationsReportCount.map(location => location.displayName),
                data.allLocationsReportCount.map(location => location.count)
            );

            const actionItemComplianceChartData = createChartData(
                data.actionItemCompliance.map(location => location.displayName),
                data.actionItemCompliance.map(location => location.count)
            );

            const disciplinesChartData = createChartData(
                data.disciplinesByInstruction.map(location => location.displayName),
                data.disciplinesByInstruction.map(location => location.count)

            );

            const completionTimeCountsChartData = createChartData(
                data.completionTimeCompliance.map(location => location.displayName),
                data.completionTimeCompliance.map(location => location.count)

            );

            const imCompletionsChartData = createChartData(
                data.top10Employees?.map(employee => employee.displayName),
                data.top10Employees?.map(employee => employee.count)
            );

            const imDelayChartData = createChartData(
                data?.imByDelays?.map(employee => employee.name),
                data?.imByDelays?.map(employee => employee.count)
            );
            const top5ExecutivesChartData = createChartData(
                data?.top5Executives?.map(employee => employee.name),
                data?.top5Executives?.map(employee => employee.count)
            );
            const top5DisciplinesChartData = createChartData(
                data?.top5Disciplines?.map(employee => employee.displayName),
                data?.top5Disciplines?.map(employee => employee.count)
            );
            const labels = data.disciplineDistribution.map(discipline => discipline.displayName);
            const dataset1Data = data.disciplineDistribution.map(discipline => discipline.satisfactoryCount);
            const dataset2Data = data.disciplineDistribution.map(discipline => discipline.unsatisfactoryCount);
            const tourCompletionLabels = data.tourCompletion.map(ele => ele.name);
            const tourCompletionData = data.tourCompletion.map(ele => ele.count);
            // Ensure the dataset has at least 5 elements by padding with zeros if necessary
            while (tourCompletionData.length < 10) {
                tourCompletionData.push(0);
            }
            while (tourCompletionLabels.length < 10) {
                tourCompletionLabels.push("data");
            }
            console.log(tourCompletionData);
            setTourBarGraphData({
                labels: tourCompletionLabels,
                datasets: [
                    {
                        data: tourCompletionData,
                        backgroundColor: BarGraphData.datasets[0].backgroundColor, // Retain original colors
                    },

                ],
            });

            const recommendationDelaysLabels = data.tourCompletion.map(ele => ele.name);
            const recommendationDelaysCompletionData = data.recommendationDelays.map(ele => ele.count);

            // Ensure the dataset has at least 5 elements by padding with zeros if necessary
            while (recommendationDelaysCompletionData.length < 10) {
                recommendationDelaysCompletionData.push(0);
            }
            while (recommendationDelaysLabels.length < 10) {
                recommendationDelaysLabels.push("data");
            }

            setDelayBarGraphData({
                labels: recommendationDelaysLabels,
                datasets: [
                    {
                        data: recommendationDelaysCompletionData,
                        backgroundColor: BarGraphData.datasets[0].backgroundColor, // Retain original colors
                    },
                ],
            });



            setBarGraphData({
                labels: labels,
                datasets: [
                    {
                        label: 'Satisfactory',
                        data: dataset1Data,
                        backgroundColor: BarGraphData.datasets[0].backgroundColor, // Retain original colors
                    },
                    {
                        label: 'Unsatisfactory',
                        data: dataset2Data,
                        backgroundColor: BarGraphData.datasets[1].backgroundColor, // Retain original colors
                    },
                ],
            });


            // Combine all chart data into a single object
            const chartData = {
                top5Locations: top5LocationsChartData,
                allLocationsReportCount: allLocationsReportCountChartData,
                actionItemCompliance: actionItemComplianceChartData,
                disciplinesByInstruction: disciplinesChartData,
                completionTimeCounts: completionTimeCountsChartData,
                top10Employees: imCompletionsChartData,
                imDelayChartData: imDelayChartData,
                top5DisciplinesChartData: top5DisciplinesChartData,
                top5ExecutivesChartData: top5ExecutivesChartData,
            };
            console.log(chartData);
            setChartState(chartData);

        } catch (error) {
            console.log(error);
        }
        finally{
            dispatch({ type: STOP_LOADING }); 
          }

    }

    const handleButtonClick = () => {
        navigate('/module/loss-control/create-tour');
    };

    const handleActionPage = ({ overDue }) => {
        const title = "Loss Control";
        if (overDue) {
            if (role?.includes("Executive Officer", "Mine Manager", "Installtion Manager")) {
                navigate('/my-actions', {
                    state: {
                        moduleID: title === "Incident Management" ? 1 : 2,
                        moduleName: title === "Incident Management" ? "Incident Management" : "Loss Control",
                        homepage: true,
                        overDue: 1,
                    }
                }
                );
            } else {
                navigate('/module/loss-control/view-tours', {
                    state: {
                        moduleID: 2,
                        moduleName: "Loss Control",
                        homepage: true,
                        overDue: 1,
                    }
                }
                );
            }
        }
        else {
            navigate('/module/loss-control/view-tours', {
                state: {
                    moduleID: 2,
                    moduleName: "Loss Control",
                    homepage: true,
                    overDue: 0,
                }
            });
        }
    }






    const handleStepchange = (value) => {
        setStep(value);
    };

    return (
        <>
        {loading && ( 
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            zIndex: 1000,
          }} 
        >
          <Loader />
        </Box>
      )}
            <Box display="flex" justifyContent="center" gap={2} alignItems="center" marginBottom={1.6} >
                <FiberManualRecordIcon onClick={() => handleStepchange(0)} sx={{ color: step === 0 ? "#0AA2E3" : "#AEAEB1", fontSize: '12px' }} />
                <FiberManualRecordIcon onClick={() => handleStepchange(1)} sx={{ color: step === 1 ? "#0AA2E3" : "#AEAEB1", fontSize: '12px' }} />
                {/* <FiberManualRecordIcon onClick={() => handleStepchange(2)} sx={{ color: step === 2 ? "#0AA2E3" : "#AEAEB1", fontSize: '12px' }} /> */}
            </Box>

            {step === 0 &&
                (
                    <>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={12} lg={9}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6} lg={4} onClick={() => handleOpenChartModal(chartState.top5Locations, "Top 5 Tour Locations")}>
                                        <Item>
                                            <Typography variant='body2' sx={{ textAlign: 'left', fontWeight: 600, marginBottom: 1 }}>
                                                Top 5 Tour Locations
                                            </Typography>
                                            <DoughnutChart data={chartState.top5Locations} title="Top 5 Tour Locations" />
                                        </Item>
                                    </Grid>

                                    <Grid item xs={12} md={6} lg={4} onClick={() => handleOpenChartModal(chartState.allLocationsReportCount, " All Tour Locations")}>
                                        <Item>
                                            <Typography variant='body2' sx={{ textAlign: 'left', fontWeight: 600, marginBottom: 1 }}>
                                                All Tour Locations
                                            </Typography>
                                            <DoughnutChart data={chartState.allLocationsReportCount} title=" All Tour Locations" />
                                        </Item>
                                    </Grid>

                                    <Grid item xs={12} md={6} lg={4} onClick={() => handleOpenChartModal(chartState.actionItemCompliance, "Recommendations Action Status")}>
                                        <Item>
                                            <Typography variant='body2' sx={{ textAlign: 'left', fontWeight: 600, marginBottom: 1 }}>
                                                Recommendations Action Status
                                            </Typography>
                                            <DoughnutChart data={chartState.actionItemCompliance} title="Recommendations Action Status" />
                                        </Item>
                                    </Grid>

                                    <Grid item xs={12} md={6} lg={4} onClick={() => handleOpenChartModal(chartState.completionTimeCounts, "Ontime Compliance")}>
                                        <Item>
                                            <Typography variant='body2' sx={{
                                                textAlign: 'left',
                                                fontWeight: 600,
                                                marginBottom: 1,
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                            }}>
                                                Ontime Compliance
                                            </Typography>
                                            <DoughnutChart data={chartState.completionTimeCounts} title="Ontime Compliance" />
                                        </Item>
                                    </Grid>

                                    <Grid item xs={12} md={6} lg={4} onClick={() => handleOpenChartModal(chartState.top10Employees, "Executive By Tours")}>
                                        <Item>
                                            <Typography variant='body2' sx={{ textAlign: 'left', fontWeight: 600, marginBottom: 1 }}>
                                                Executive By Tours
                                            </Typography>
                                            <DoughnutChart data={chartState.top10Employees} title="Executive By Tours" />
                                        </Item>
                                    </Grid>

                                    <Grid item xs={12} md={6} lg={4} onClick={() => handleOpenChartModal(chartState.disciplinesByInstruction, "Disciplines By Instructions")}>
                                        <Item>
                                            <Typography variant='body2' sx={{ textAlign: 'left', fontWeight: 600, marginBottom: 1 }}>
                                                Recommendations By Disciplines
                                            </Typography>
                                            <DoughnutChart data={chartState.disciplinesByInstruction} title="Disciplines by Instructions" />
                                        </Item>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={4} onClick={() => handleOpenChartModal(chartState.top5ExecutivesChartData, "IM By Completions")}>
                                        <Item>
                                            <Typography variant='body2' sx={{ textAlign: 'left', fontWeight: 600, marginBottom: 1 }}>
                                                IM By Completions
                                            </Typography>
                                            <DoughnutChart data={chartState.top5ExecutivesChartData} title="IM By Completions" />
                                        </Item>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={4} onClick={() => handleOpenChartModal(chartState.imDelayChartData, "IM By Delays")}>
                                        <Item>
                                            <Typography variant='body2' sx={{ textAlign: 'left', fontWeight: 600, marginBottom: 1 }}>
                                                IM By Delays
                                            </Typography>
                                            <DoughnutChart data={chartState.imDelayChartData} title="IM By Delays" />
                                        </Item>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={4} onClick={() => handleOpenChartModal(chartState.top5DisciplinesChartData, "Top 5 Disciplines")}>
                                        <Item>
                                            <Typography variant='body2' sx={{ textAlign: 'left', fontWeight: 600, marginBottom: 1 }}>
                                                Top 5 Disciplines
                                            </Typography>
                                            <DoughnutChart data={chartState.top5DisciplinesChartData} title="Top 5 Disciplines" />
                                        </Item>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {/* Main Grid of 4 columns */}
                            <Grid item xs={12} md={12} lg={3}>
                                <Grid container spacing={1}>
                                    {isShowTreeModel ? (
                                        <Grid item xs={12}>
                                            <Item sx={{
                                                minHeight: "600px",
                                                maxHeight: "600px",
                                                overflowY: "auto", // Enables vertical scrolling
                                                padding: "8px", // Optional: Adjust for inner content spacing
                                                scrollbarWidth: "none", // Hides scrollbar in Firefox
                                                "-ms-overflow-style": "none", // Hides scrollbar in Internet Explorer and Edge
                                                "&::-webkit-scrollbar": {
                                                    display: "none", // Hides scrollbar in Chrome, Safari, and Edge
                                                },
                                            }}>
                                                <Typography variant='body2' sx={{ fontWeight: 600, float: "left", mt: 1, ml: "6px" }}>
                                                    All Locations
                                                </Typography>

                                                <IconButton
                                                    onClick={handleCloseOverlay}
                                                    sx={{ float: "right" }}

                                                >
                                                    <CloseIcon />
                                                </IconButton>
                                                <OrgTreeView data={allLoactionName} />
                                            </Item>
                                        </Grid>
                                    ) : (
                                        <>
                                            <Grid item xs={12}>
                                                <Item >
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            justifyContent: 'space-between', // Ensures content is spread with text on left, image on right
                                                            width: '100%', // Full width to allow proper alignment
                                                           lineHeight:0,
                                                        }}
                                                    >
                                                        {/* Left side: Typography content */}
                                                        <Box>
                                                            <Typography variant='body2' sx={{ fontWeight: 600 }}>
                                                                {boardCasteData?.greetings[0]?.message ? boardCasteData?.greetings[0]?.message : "Welcome"}
                                                            </Typography>
                                                            <Typography variant='caption'>
                                                                {userName ? userName.charAt(0).toUpperCase() + userName.slice(1) : "User"}
                                                            </Typography>
                                                        </Box>

                                                        {/* Right side: Image */}
                                                        <WelcomeUserCard />
                                                    </Box>
                                                </Item>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Item>
                                                    <BroadcastSlideshow broadcasts={boardCasteData?.broadcasts} />
                                                </Item>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Item>
                                                    <SafetyTipSlider data={boardCasteData?.safetyTips} />
                                                </Item>
                                            </Grid>
                                            <Grid item xs={12}>

                                                <Box
                                                    sx={{
                                                        backgroundColor: '#fff',
                                                        padding: "5px",
                                                        borderRadius: "12px",
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'space-between', // Spread content between left and right
                                                        width: '100%', // Ensure the box spans full width
                                                    }}
                                                    onClick={() => handleActionPage({ overDue: false })}
                                                >
                                                    {/* Left content: image and text */}
                                                    <Box sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                                                        <VisibilityOutlinedIcon />

                                                        <Typography
                                                            variant='body2'
                                                            sx={{
                                                                textAlign: 'left',
                                                                fontWeight: 600,
                                                                ml: 1,
                                                                // Smooth transition for color and font-size
                                                                '&:hover': {
                                                                    color: '#0AA2E3',

                                                                },
                                                            }}
                                                        >
                                                            View Tours
                                                        </Typography>
                                                    </Box>

                                                    {/* Right content: Divider and number */}
                                                    <Box sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={handleActionPage}>
                                                        <Divider
                                                            orientation="vertical"
                                                            flexItem
                                                            sx={{ mx: 1, color: "#000" }}
                                                        />
                                                        <Typography
                                                            variant='body2'
                                                            sx={{ textAlign: 'right', fontWeight: 600 }}
                                                        >
                                                            {viewCount ? viewCount : 0}
                                                        </Typography>
                                                    </Box>

                                                </Box>

                                            </Grid>
                                            <Grid item xs={12}>

                                                <Box
                                                    sx={{
                                                        backgroundColor: '#fff',
                                                        padding: "5px",
                                                        borderRadius: "12px",
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'space-between', // Spread content between left and right
                                                        width: '100%', // Ensure the box spans full width
                                                    }}
                                                    onClick={() => handleActionPage({ overDue: true })}
                                                >
                                                    {/* Left content: image and text */}
                                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                        <img src={overDueImage} alt="iconImageOverdue" />
                                                        <Typography
                                                            variant='body2'
                                                            sx={{
                                                                textAlign: 'left',
                                                                fontWeight: 600,
                                                                ml: 1,
                                                                // Smooth transition for color and font-size
                                                                '&:hover': {
                                                                    color: '#0AA2E3',

                                                                },
                                                            }}
                                                        >
                                                            Overdue Tour Actions
                                                        </Typography>
                                                    </Box>

                                                    {/* Right content: Divider and number */}
                                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                        <Divider
                                                            orientation="vertical"
                                                            flexItem
                                                            sx={{ mx: 1, color: "#000" }}  // Add space around the divider
                                                        />
                                                        <Typography
                                                            variant='body2'
                                                            sx={{ textAlign: 'right', color: "red", fontWeight: 600 }}
                                                        >
                                                            {overDueCount ? overDueCount : 0}
                                                        </Typography>
                                                    </Box>
                                                </Box>

                                            </Grid>
                                            {role?.includes("Executive Officer") && (
                                                <Grid item xs={12}>
                                                    <Button
                                                        variant="medium"
                                                        sx={{
                                                            color: "#fff",
                                                            bgcolor: "#0AA2E3",
                                                            textTransform: "none",
                                                            width: "100%",
                                                            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.3)',
                                                        }}
                                                        onClick={handleButtonClick}
                                                    >
                                                        Start New Tour
                                                    </Button>
                                                </Grid>
                                            )}

                                        </>
                                    )


                                    }


                                </Grid>
                            </Grid>



                        </Grid>

                    </>

                )}
            {step === 1 &&
                (
                    <>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={12} lg={9}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Item>
                                            <Typography variant='body2' sx={{ textAlign: 'left', fontWeight: 600, marginBottom: 1 }}>
                                                Tour By Disciplines
                                            </Typography>
                                            <BarGraph data={BarGraphData} />
                                        </Item>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Item>
                                            <Typography variant='body2' sx={{ textAlign: 'left', fontWeight: 600, marginBottom: 1 }}>
                                                Tour Completion
                                            </Typography>
                                            <BarGraph data={tourBarGraphData} />
                                        </Item>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Item>
                                            <Typography variant='body2' sx={{ textAlign: 'left', fontWeight: 600, marginBottom: 1 }}>
                                                Recommendation Delays
                                            </Typography>
                                            <BarGraph data={delayBarGraphData} />
                                        </Item>
                                    </Grid>
                                </Grid>

                            </Grid>

                            {/* Main Grid of 4 columns */}
                            <Grid item xs={12} md={12} lg={3}>
                                <Grid container spacing={1}>

                                    {isShowTreeModel ? (
                                        <Grid item xs={12}>
                                            <Item sx={{
                                                minHeight: "600px",
                                                maxHeight: "600px",
                                                overflowY: "auto", // Enables vertical scrolling
                                                padding: "8px", // Optional: Adjust for inner content spacing
                                                scrollbarWidth: "none", // Hides scrollbar in Firefox
                                                "-ms-overflow-style": "none", // Hides scrollbar in Internet Explorer and Edge
                                                "&::-webkit-scrollbar": {
                                                    display: "none", // Hides scrollbar in Chrome, Safari, and Edge
                                                },
                                            }}>
                                                <Typography variant='body2' sx={{ fontWeight: 600, float: "left", mt: 1, ml: "6px" }}>
                                                    All Locations
                                                </Typography>

                                                <IconButton
                                                    onClick={handleCloseOverlay}
                                                    sx={{ float: "right" }}

                                                >
                                                    <CloseIcon />
                                                </IconButton>
                                                <OrgTreeView data={allLoactionName} />
                                            </Item>
                                        </Grid>
                                    ) : (
                                        <>
                                            <Grid item xs={12}>
                                                <Item >
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            justifyContent: 'space-between', // Ensures content is spread with text on left, image on right
                                                            width: '100%', // Full width to allow proper alignment
                                                            //sx={{ bgcolor: "rgba(9, 162, 227, 0.1)" }}
                                                        }}
                                                    >
                                                        {/* Left side: Typography content */}
                                                        <Box>
                                                            <Typography variant='body2' sx={{ fontWeight: 600 }}>
                                                                {boardCasteData.greetings[0]?.message ? boardCasteData.greetings[0]?.message : "Welcome"}
                                                            </Typography>
                                                            <Typography variant='caption'>
                                                                {userName ? userName.charAt(0).toUpperCase() + userName.slice(1) : "User"}
                                                            </Typography>
                                                        </Box>

                                                        {/* Right side: Image */}
                                                        <WelcomeUserCard />
                                                    </Box>
                                                </Item>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Item>
                                                    <BroadcastSlideshow broadcasts={boardCasteData?.broadcasts} />
                                                </Item>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Item>
                                                    <SafetyTipSlider data={boardCasteData?.safetyTips} />
                                                </Item>
                                            </Grid>
                                            <Grid item xs={12}>

                                                <Box
                                                    sx={{
                                                        backgroundColor: '#fff',
                                                        padding: "5px",
                                                        borderRadius: "12px",
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'space-between', // Spread content between left and right
                                                        width: '100%', // Ensure the box spans full width
                                                    }}
                                                    onClick={handleActionPage}
                                                >
                                                    {/* Left content: image and text */}
                                                    <Box sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                                                        <VisibilityOutlinedIcon />

                                                        <Typography
                                                            variant='body2'
                                                            sx={{
                                                                textAlign: 'left',
                                                                fontWeight: 600,
                                                                ml: 1,
                                                                // Smooth transition for color and font-size
                                                                '&:hover': {
                                                                    color: '#0AA2E3',

                                                                },
                                                            }}
                                                        >
                                                            View All Tours
                                                        </Typography>
                                                    </Box>

                                                    {/* Right content: Divider and number */}
                                                    <Box sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={handleActionPage}>
                                                        <Divider
                                                            orientation="vertical"
                                                            flexItem
                                                            sx={{ mx: 1, color: "#000" }}
                                                        />
                                                        <Typography
                                                            variant='body2'
                                                            sx={{ textAlign: 'right', fontWeight: 600 }}
                                                        >
                                                            {viewCount ? viewCount : 0}
                                                        </Typography>
                                                    </Box>

                                                </Box>

                                            </Grid>
                                            <Grid item xs={12}>

                                                <Box
                                                    sx={{
                                                        backgroundColor: '#fff',
                                                        padding: "5px",
                                                        borderRadius: "12px",
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'space-between', // Spread content between left and right
                                                        width: '100%', // Ensure the box spans full width
                                                    }}
                                                    onClick={handleActionPage}
                                                >
                                                    {/* Left content: image and text */}
                                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                        <img src={overDueImage} alt="iconImageOverdue" />
                                                        <Typography
                                                            variant='body2'
                                                            sx={{
                                                                textAlign: 'left',
                                                                fontWeight: 600,
                                                                ml: 1,
                                                                // Smooth transition for color and font-size
                                                                '&:hover': {
                                                                    color: '#0AA2E3',

                                                                },
                                                            }}
                                                        >
                                                            Overdue Tour Actions
                                                        </Typography>
                                                    </Box>

                                                    {/* Right content: Divider and number */}
                                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                        <Divider
                                                            orientation="vertical"
                                                            flexItem
                                                            sx={{ mx: 1, color: "#000" }}  // Add space around the divider
                                                        />
                                                        <Typography
                                                            variant='body2'
                                                            sx={{ textAlign: 'right', color: "red", fontWeight: 600 }}
                                                        >
                                                            {overDueCount ? overDueCount : 0}
                                                        </Typography>
                                                    </Box>
                                                </Box>

                                            </Grid>
                                            {role?.includes("Executive Officer") && (
                                                <Grid item xs={12}>
                                                    <Button
                                                        variant="medium"
                                                        sx={{
                                                            color: "#fff",
                                                            bgcolor: "#0AA2E3",
                                                            textTransform: "none",
                                                            width: "100%",
                                                            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.3)',
                                                        }}
                                                        onClick={handleButtonClick}
                                                    >
                                                        Start New Tour
                                                    </Button>
                                                </Grid>
                                            )}

                                        </>
                                    )


                                    }


                                </Grid>
                            </Grid>



                        </Grid>

                    </>
                )
            }
            {step === 2 &&
                (
                    <>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6} lg={3} onClick={() => handleOpenChartModal(chartState.top5Locations, "Top Five Locations")}>
                                        <Item>
                                            <Typography variant='body2' sx={{ textAlign: 'left', fontWeight: 600, marginBottom: 1 }}>
                                                Top Five Locations
                                            </Typography>
                                            <DoughnutChart
                                                data={chartState.top5Locations}
                                                title="Top Five Locations"
                                            />
                                        </Item>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={3} onClick={() => handleOpenChartModal(chartState.top5Locations, "Top Five Locations")}>
                                        <Item>
                                            <Typography variant='body2' sx={{ textAlign: 'left', fontWeight: 600, marginBottom: 1 }}>
                                                All Locations
                                            </Typography>
                                            <DoughnutChart
                                                data={chartState.top5Locations}
                                                title="Top Five Locations"
                                            />
                                        </Item>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={3} onClick={() => handleOpenChartModal(chartState.top5Locations, "Top Five Locations")}>
                                        <Item>
                                            <Typography variant='body2' sx={{ textAlign: 'left', fontWeight: 600, marginBottom: 1 }}>
                                                Action Item Complaince
                                            </Typography>
                                            <DoughnutChart
                                                data={chartState.top5Locations}
                                                title="Top Five Locations"
                                            />
                                        </Item>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={3} onClick={() => handleOpenChartModal(chartState.top5Locations, "Top Five Locations")}>
                                        <Item>
                                            <Typography variant='body2'
                                                sx={{
                                                    textAlign: 'left',
                                                    fontWeight: 600,
                                                    marginBottom: 1,
                                                    whiteSpace: 'nowrap',      // Prevents text from wrapping to the next line
                                                    overflow: 'hidden',        // Hides the overflowing text
                                                    textOverflow: 'ellipsis',  // Adds the "..." after truncating the text
                                                }}>
                                                Timelines of Compliance Before Submission
                                            </Typography>
                                            <DoughnutChart
                                                data={chartState.top5Locations}
                                                title="Top Five Locations"
                                            />
                                        </Item>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={3} onClick={() => handleOpenChartModal(chartState.top5Locations, "Top Five Locations")}>
                                        <Item>
                                            <Typography variant='body2' sx={{ textAlign: 'left', fontWeight: 600, marginBottom: 1 }}>
                                                Top Employees
                                            </Typography>
                                            <DoughnutChart
                                                data={chartState.top5Locations}
                                                title="Top Five Locations"
                                            />
                                        </Item>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={3} onClick={() => handleOpenChartModal(chartState.top5Locations, "Top Five Locations")}>
                                        <Item>
                                            <Typography variant='body2' sx={{ textAlign: 'left', fontWeight: 600, marginBottom: 1 }}>
                                                Disciplines By Instructions
                                            </Typography>
                                            <DoughnutChart
                                                data={chartState.top5Locations}
                                                title="Top Five Locations"
                                            />
                                        </Item>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={3} onClick={() => handleOpenChartModal(chartState.top5Locations, "Top Five Locations")}>
                                        <Item>
                                            <Typography variant='body2' sx={{ textAlign: 'left', fontWeight: 600, marginBottom: 1 }}>
                                                Disciplines By Instructions
                                            </Typography>
                                            <DoughnutChart
                                                data={chartState.top5Locations}
                                                title="Top Five Locations"
                                            />
                                        </Item>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={3} onClick={() => handleOpenChartModal(chartState.top5Locations, "Top Five Locations")}>
                                        <Item>
                                            <Typography variant='body2' sx={{ textAlign: 'left', fontWeight: 600, marginBottom: 1 }}>
                                                Disciplines By Instructions
                                            </Typography>
                                            <DoughnutChart
                                                data={chartState.top5Locations}
                                                title="Top Five Locations"
                                            />
                                        </Item>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Item>
                                            <Typography variant='body2' sx={{ textAlign: 'left', fontWeight: 600, marginBottom: 1 }}>
                                                Discipline Distribution
                                            </Typography>
                                            <BarGraph data={BarGraphData} />
                                        </Item>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </>
                )
            }
            <ChartModal
                open={openChartModal}
                onClose={handleCloseChartModal}
                data={chartModalData}
                treeData={treeData}
                allLocations={allLoactionName}
                title={chartModalTitle}
                allLocationsReportCountIDs={allLocationsReportCountIDs}
                top5LocationsIDs={top5LocationsIDs}
                actionItemComplianceLocations={actionItemComplianceLocations}
                completionTimeComplianceLocations={completionTimeComplianceLocations}
                disciplineDistributionLocations={disciplineDistributionLocations}
                disciplinesByInstructionLocations={disciplinesByInstructionLocations}
                top5DisciplinesLocations={top5DisciplinesLocations}
                top5ExecutivesLocations={top5ExecutivesLocations}
                top10EmployeesLocations={top10EmployeesLocations}
                tourCompletionLocations={tourCompletionLocations}
                imDelays={imDelays}
            />




        </>
    );
};

export default Dashboard;
