import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Typography,
  IconButton,
} from "@mui/material";
import UploadIcon from "@mui/icons-material/CloudUpload";
import CloseIcon from "@mui/icons-material/Close";
import Compressor from "compressorjs";
import { useDispatch,useSelector } from "react-redux";
import { addVerificationImg } from "../../redux/LossControl/actions";

const VisitVerificationDialog = ({ open, onClose, handleProceed }) => {
  const dispatch = useDispatch();
  const [verificationMedia, setVerificationMedia] = useState([]);

  const handleMediaUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const base64 = await convertToBase64(file);
    const newMedia = {
      base64: base64.split(",")[1],
      name: file.name,
      type: file.type,
    };

    // Allow only one media at a time
    setVerificationMedia([newMedia]);
  };

  const handleFileDelete = () => {
    setVerificationMedia([]);
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      new Compressor(file, {
        quality: 0.2,
        success(compressedFile) {
          const reader = new FileReader();
          reader.readAsDataURL(compressedFile);
          reader.onload = () => resolve(reader.result);
          reader.onerror = reject;
        },
        error(err) {
          reject(err);
        },
      });
    });
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle sx={{fontWeight: "600"}}>
      Visit Verification
        <IconButton
            aria-label="close"
            onClick={()=>onClose()}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column" alignItems="flex-start">
          {/* <Typography gutterBottom sx={{ fontSize: "14px", fontWeight: "600" }}>
            Upload Picture/Video
          </Typography> */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              border: "1px dashed #09A2E3",
              padding: 2,
              borderRadius: 1,
              textAlign: "center",
              width: "100%",
            }}
          >
            <IconButton component="label">
              <UploadIcon fontSize="large" sx={{ color: "#000000" }} />
              <input
                hidden
                accept="image/*,video/*"
                type="file"
                onChange={handleMediaUpload}
              />
            </IconButton>
            <Typography
              variant="caption"
              sx={{
                mt: 1,
                color: "#348BD2",
                fontWeight: "600",
                textDecoration: "underline",
              }}
            >
              Click to upload
            </Typography>
            <Typography variant="caption" sx={{ fontWeight: "600" }}>
              PNG, JPG, MP4 (max. 10 MB)
            </Typography>
          </Box>

          {verificationMedia.length > 0 && (
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                mt: 2,
                gap: 1,
              }}
            >
              {verificationMedia?.map((file, index) => (
                <Box key={index} sx={{ position: "relative", width: 80, height: 80 }}>
                  <IconButton
                    onClick={handleFileDelete}
                    sx={{
                      position: "absolute",
                      top: -8,
                      right: -8,
                      zIndex: 2,
                      backgroundColor: "white",
                      padding: "4px",
                      "&:hover": {
                        backgroundColor: "white",
                      },
                      boxShadow: "0px 2px 4px rgba(0,0,0,0.1)",
                    }}
                    size="small"
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                  <Box
                    sx={{
                      width: "100%",
                      height: "100%",
                      borderRadius: 2,
                      overflow: "hidden",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      border: "1px solid #ccc",
                    }}
                  >
                    {file.type.startsWith("image/") ? (
                      <img
                        src={`data:${file.type};base64,${file.base64}`}
                        alt={file.name}
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                      />
                    ) : (
                      <Typography variant="caption">Video Uploaded</Typography>
                    )}
                  </Box>
                </Box>
              ))}
            </Box>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        {/* <Button onClick={onClose} color="primary">
          Cancel
        </Button> */}
        <Button
          onClick={() => {
            // console.log("Uploaded Media:", verificationMedia);
            dispatch(addVerificationImg(verificationMedia));
            handleProceed();
          }}
          color="primary"
          variant="contained"
          sx={{color:'white'}}
        >
          {verificationMedia?.length > 0 ? "Submit & Proceed" : "Skip"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default VisitVerificationDialog;
