import React, { useEffect, useState } from "react";
import { Box, Stepper, Step, StepLabel, Typography } from "@mui/material";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import KeyboardBackspaceOutlinedIcon from "@mui/icons-material/KeyboardBackspaceOutlined";
import CircleIcon from "@mui/icons-material/Circle";
import { getOverallStatusTimeline } from "../../Apis/apiCall";
import { formatDateToIndianLocale2, removeUnderScoreInString } from "../../utils/helper";

//boxShadow:"0px 0px 6px 2px rgba(30, 144, 255, 0.6)",



export default function ViewStepperBox({ stepData, reportID, tourDate }) {
  const activeStepIndex = stepData.findIndex(
    (step) => step.status === "active"
  );
  const [activeSteps, setActiveSteps] = useState(activeStepIndex);
  const [selectedStep, setSelectedStep] = useState(activeStepIndex);
  const [timelineData, setTimelineData] = useState([]);
  const [selectedDetails, setSelectedDetails] = useState(null);

  // For First time rendering
  const [currentState, setCurrentState] = useState("");
  const [currentRole, setCurrentRole] = useState("");
  const [currentFullName, setCurrentFullName] = useState("");
  const [surveyDate, setSurveyDate] = useState("");
  const [allCompletedByIM,setAllCompletedByIM] = useState(null);
  const [approvedByMm,setApprovedByMm] = useState(null);

  useEffect(() => {
    fetchTimelineData();
  }, []);

  const fetchTimelineData = async () => {
    try {
      const res = await getOverallStatusTimeline(reportID);
      setTimelineData(res.data.data);
    } catch (error) {
      console.error("error while fetching timeline data");
    }
  };

  useEffect(() => {
    if (timelineData.length > 0) {
      const lastEntry = timelineData[timelineData.length - 1];
      setCurrentState(lastEntry.status);
      setCurrentRole(lastEntry.roleName);
      setCurrentFullName(`${lastEntry.firstName || ""} ${lastEntry.middleName || ""} ${
          lastEntry.lastName || ""
        }`); 
      setSurveyDate(convertIST(lastEntry.createdAt));
      setSelectedDetails(lastEntry);
      setAllCompletedByIM(lastEntry.allCompletedByIM);
      setApprovedByMm(lastEntry.approvedByMM)
      determineStatuses();
    }
  }, [timelineData]);

  

  const getStepIcon = (status,index) => {
    const isSelected = selectedStep === index;
    const boxShadow = isSelected ? "0px 0px 6px 2px rgba(30, 144, 255, 0.6)" : "none";
  
    if (status === "completed") {
      return (
        <CheckOutlinedIcon
          sx={{
            backgroundColor: "#FFFFFF",
            borderRadius: "50%",
            color: "#4591F5",
            fill: "#4591F5",
            border: "2px solid #4591F5",
            boxShadow,
            cursor: "pointer"
          }}
        />
      );
    } else if (status === "pending") {
      return (
        <CircleIcon
          sx={{
            backgroundColor: "#FFFFFF",
            borderRadius: "50%",
            color: "#FFAC07",
            fill: "#FFAC07",
            border: "2px solid #FFAC07",
            boxShadow,
            cursor: "pointer"
          }}
        />
      );
    } else if (status === "returned") {
      return (
        <KeyboardBackspaceOutlinedIcon
          sx={{
            backgroundColor: "red",
            borderRadius: "50%",
            color: "white",
            fill: "white",
            border: "2px solid red",
            boxShadow,
            cursor: "pointer"
          }}
        />
      );
    } else if (status === "inactive") {
      return (
        <CircleIcon
          sx={{
            backgroundColor: "#FFFFFF",
            borderRadius: "50%",
            color: "#gray",
            fill: "#FFFFFF",
            border: "2px solid gray",
            cursor: "not-allowed",
            opacity: 0.5
          }}
        />
      );
    } else if (status === "disable") {
      return (
        <CircleIcon
          sx={{
            backgroundColor: "#FFFFFF",
            borderRadius: "50%",
            color: "#gray",
            fill: "#FFFFFF",
            border: "2px solid gray",
            cursor: "not-allowed",
            opacity: 0.5
          }}
        />
      );
    }
    return (
      <CircleIcon
        sx={{
          backgroundColor: "#FFFFFF",
          borderRadius: "50%",
          color: "#FFAC07",
          fill: "#FFFFFF",
          border: "2px solid #B7C8E5",
          boxShadow,
        }}
      />
    );
  };

  const getDetailsForStep = (stepLabel, index) => {
    if (!timelineData.length || activeSteps[index] === "inactive") return null;

    switch (stepLabel.toLowerCase()) {
      case "tour submitted":
        const toFind = findRecentExecutiveOfficerEntry(timelineData).status
        return timelineData.find(item => item.status === toFind);
      
      case "im review":
        return [...timelineData]
          .reverse()
          .find(item => item.roleName === "Installation Manager");
      
      case "mines manager":
        return [...timelineData]
          .reverse()
          .find(item => item.roleName === "Mine Manager");

      case "mine agent":
        return timelineData[timelineData.length - 1] 
      
      case "intimation":
        return timelineData[timelineData.length - 1] 
      
      case "tour closed":
        return timelineData[timelineData.length - 1] 

      default:
        return null;
    }
  };

  const handleStepClick = (step, index) => {
    if (activeSteps[index] === "inactive") return;

    setSelectedStep(index)

    const details = getDetailsForStep(step.label, index);
    if (details) {
      setSelectedDetails(details);
      setCurrentState(details.status);
      setCurrentRole(details.roleName);
      setCurrentFullName(`${details.firstName} ${details.middleName} ${details.lastName}`);
      setSurveyDate(convertIST(details.createdAt));
    }
  };

  function checkMineManagerStatus(data) {
    let underReviewMM = null;
    let returnedByMM = null;
    for (let i = data.length - 1; i >= 0; i--) {
        const item = data[i];

        if (!underReviewMM && item.roleName === "Mine Manager" && item.status === "Under_Review") {
            underReviewMM = item;
        }

        if (!returnedByMM && item.roleName === "Mine Manager" && item.status === "Returned_By_MM") {
            returnedByMM = item;
        }
        if (underReviewMM && returnedByMM) {
            break;
        }
    }

    if (!underReviewMM && !returnedByMM) {
        return null;
    }
    if (underReviewMM && !returnedByMM) return "URMM";
    if (!underReviewMM && returnedByMM) return "RBMM";
    return underReviewMM.createdAt > returnedByMM.createdAt ? "URMM" : "RBMM";
}

  const findRecentExecutiveOfficerEntry = (timelineData) => {
    if (!timelineData || timelineData.length === 0) return null;

    // Traverse from the end of the array
    for (let i = timelineData.length - 1; i >= 0; i--) {
      if (timelineData[i].roleName === "Executive Officer") {
        return timelineData[i];
      }
    }

    // Return null if no Executive Officer entry is found
    return null;
  };


  const determineStatuses = () => {
    if (timelineData.length > 0) {
      const currentState = timelineData[timelineData.length - 1].status;
      const currRole = timelineData[timelineData.length - 1].roleName;
      let statuses = [];
      const MMCheck = checkMineManagerStatus(timelineData);

      switch (currentState) {
        case "New_Survey":
          statuses = ["completed", "pending", "inactive", "inactive", "inactive", "inactive"];
          setSelectedStep(1);
          break;
        case "Revalidation":
          statuses = ["pending", "returned", "inactive", "inactive", "inactive", "inactive"];
          setSelectedStep(0);
          break;
        case "Resubmission":
          statuses = ["completed", "pending", "inactive", "inactive", "inactive", "inactive"];
          setSelectedStep(1);
          break;
        case "In_Progress":
          if (currRole === "Installation Manager") {
            statuses = ["completed", "pending", "inactive", "inactive", "inactive", "inactive"];
            setSelectedStep(1);
          } else {
            statuses = ["completed", "pending", "inactive", "inactive", "inactive", "inactive"];
            setSelectedStep(1);
          }
          break;
        case "Under_Review":
          if(MMCheck!== null){
            if(MMCheck === "URMM"){
              statuses = ["completed", "pending", "pending", "inactive", "inactive", "inactive"];
              setSelectedStep(2);
            }
            if(MMCheck === "RBMM"){
              statuses = ["completed", "pending", "returned", "inactive", "inactive", "inactive"];
              setSelectedStep(1);
            }
            break;
          }
          if (currRole === "Installation Manager") {
            statuses = ["completed", "pending", "inactive", "inactive", "inactive", "inactive"];
            setSelectedStep(1);
          } else if (currRole === "Mine Manager") {
            statuses = ["completed", "pending", "pending", "inactive", "inactive", "inactive"];
            setSelectedStep(2);
          }
          break;
        case "Returned_By_MM":
          statuses = ["completed", "pending", "returned", "inactive", "inactive", "inactive"];
          setSelectedStep(1);
          break;
        case "Recommendation_Resubmission":
          if(MMCheck!== null){
            if(MMCheck === "URMM"){
              statuses = ["completed", "pending", "pending", "inactive", "inactive", "inactive"];
              setSelectedStep(2);
            }
            if(MMCheck === "RBMM"){
              statuses = ["completed", "pending", "returned", "inactive", "inactive", "inactive"];
              setSelectedStep(1);
            }
            break;
          }
          statuses = ["completed", "pending", "pending", "inactive", "inactive", "inactive"];
          setSelectedStep(1);
          break;
        case "Completed":
          statuses = ["completed", "completed", "completed", "completed", "completed", "completed"];
          setSelectedStep(5);
          break;
        default:
          statuses = ["inactive", "inactive", "inactive", "inactive", "inactive", "inactive"];
          setSelectedStep(0);
      }
      setActiveSteps(statuses);
    }
  };

  const convertIST = (timestamp) => {
    return new Date(timestamp).toLocaleString("en-IN", {
      timeZone: "Asia/Kolkata",
      dateStyle: "medium",
      timeStyle: "short",
    });
  };

  const getStatusText = (currentRole, currentState, selectedStep) => {
    if (currentState === "Completed" && (currentRole === "Mine Manager" || currentRole === "Executive Officer")) {
      switch (selectedStep) {
        case 0:
          return "New Tour / Executive Office";
        case 1:
          return "All Recommendations Completed";
        case 2:
          return "All Recommendations Approved";
        case 3:
          return "Email Update Sent";
        case 4:
          return "Executive / Email Update Sent";
        case 5:
          return "Email Update Sent";
        default:
          return `Completed / ${currentRole}`;
      }
    }
  
    if (currentState === "New_Survey") {
      return `New Tour / ${currentRole}`;
    }
  
    if (currentState === "Returned_By_MM") {
      return `Returned By MM / ${currentRole}`;
    }
  
    if (currentState === "Under_Review" && currentRole === "Installation Manager") {
      if(timelineData[timelineData.length - 1].status === "Completed"){
        return `All Recommendations Completed`
      }
      else{
      return allCompletedByIM === 1
        ? `All Recommendations Completed / ${currentRole}`
        : `Recommendation Completed / ${currentRole}`;
      }
    }
  
    if (currentState === "Recommendation_Resubmission" && currentRole === "Installation Manager") {
      if(timelineData[timelineData.length - 1].status === "Completed"){
        return `All Recommendations Completed`
      }
      else {
        return allCompletedByIM === 1
        ? `All Recommendations Completed / ${currentRole}`
        : `Recommendation Completed / ${currentRole}`;
      }
    }

    if( currentRole === "Mine Manager" && currentState === "Under_Review" && approvedByMm === 1){
      return `Recommendation Approved / ${currentRole}`
    }
  
    return `${removeUnderScoreInString(currentState)} / ${currentRole}`;
  };
  

  return (
    <Box
      sx={{
        width: "100%",
        paddingX: "24px",
        paddingY: "12px",
        backgroundColor: "#ffffff",
        borderRadius: "8px",
        position: "relative",
        pt: 3,
        height: "136px",
      }}
    >
      <Stepper
        activeStep={-1}
        alternativeLabel
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          transform: "translateY(-26%)",
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          "& .MuiStepConnector-root": {
            flex: "1 1 0",
            height: "2px",
            backgroundColor: "rgba(0, 0, 0, 0.12)",
          },
        }}
      >
        {stepData?.map((obj, index) => (
          <Step key={index} onClick={() => handleStepClick(obj, index)} 
          sx={{ 
            cursor: activeSteps[index] === "inactive" ? "not-allowed" : "pointer",
            opacity: activeSteps[index] === "inactive" ? 0.5 : 1
          }}
          >
            <StepLabel
              StepIconComponent={() => (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "relative",
                  }}
                >
                  {getStepIcon(activeSteps[index],index)}
                </Box>
              )}
              optional={
                <Box
                  sx={{
                    maxWidth: "100%",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  }}
                >
                  <Typography
                    variant="caption"
                    sx={{
                      color: activeSteps[index] === "inactive" ? "#52596690" : "#525966",
                      fontSize: {
                        xs: "0.6rem",
                        sm: "0.65rem",
                        md: "0.7rem",
                        lg: "0.8rem",
                        xl: "0.85rem",
                      },
                      lineHeight: 1.2,
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      maxWidth: "100%",
                    }}
                  >
                      {activeSteps[index] === "completed" ? obj.label : obj.label}
                      
                  </Typography>
                </Box>
              }
            />
          </Step>
        ))}
      </Stepper>

      <Box px={1} display="flex" flexDirection="column" gap={0.5} mt={3}>
      {(currentState && currentRole) && (
          <>
           <Typography
              sx={{ color: "#525966", fontSize: "16px", fontWeight: "600" }}
            >
              {getStatusText(currentRole,currentState,selectedStep)}
            </Typography>

            <Box>
              <Box display="flex" gap={0.5}>
                <Typography variant="body2" sx={{ color: "#525966" }}>
                  {(currentState === "Completed" && selectedStep >= stepData.length - 3) ? "" : currentFullName}
                </Typography>
              </Box>
              <Box display="flex" gap={0.5}>
                <Typography variant="body2" sx={{ color: "#525966" }}>
                  {selectedStep === 0 ? formatDateToIndianLocale2(tourDate) : surveyDate}
                </Typography>
              </Box>
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
}