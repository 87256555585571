import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Grid,
  Button,
  Avatar,
  Stack,
  Modal,
} from "@mui/material";
import { viewTicket, updateTicketStatusByUser, updateTicketStatusByAdmin } from "../../Apis/apiCall";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { formatDateToIndianLocale2 } from "../../utils/helper";
import ChatBox from "./ChatBox";
import AssignTicketDialog from "./AssignTicketDialog";
import ConfirmationModal from "../../Components/LossControl/ConfirmationModal";
import DraggableMediaDialog from "../../Components/DraggableImageDialog";

export default function ViewTicket () {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  
  const [ticketDetails, setTicketDetails] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [escalateConfirm, setEscalateConfirm] = useState(false);
  const [acceptConfirm, setAcceptConfirm] = useState(false);
  const [isChatBoxOpen, setIsChatBoxOpen] = useState(true);
  const [flow, setFlow] = useState(null)
  const [assignModalOpen, setAssignModalOpen] = useState(false);

  const [draggable, setDraggable] = useState("");
  
  const logindata = JSON.parse(localStorage.getItem("loginData"));
  const role = logindata.config.permissionDetails.applicationRoles[0].name;

  useEffect(() => {
    if (role === "Support Agent") {
      setFlow(2);
    }
    else if (role === "Support Admin") {
      setFlow(3);
    }
    else{
      setFlow(1);
    }
  }, []);


  useEffect(()=>{
    if( (flow === 3) || ((flow === 1 || flow===2) && (ticketDetails?.status === "open" || ticketDetails?.status === "escalated" || ticketDetails?.status === "reopen")) ){
      setIsChatBoxOpen(false);
    }
  },[flow,ticketDetails])

  useEffect(() => {
    const fetchTicketDetails = async () => {
      try {
        const res = await viewTicket(id);
        setTicketDetails(res?.data?.data || {});
      } catch (error) {
        console.error("Error while fetching ticket details", error);
      }
    };

    if (id) {
      fetchTicketDetails();
    }
  }, [id]);

  if (!ticketDetails) {
    return <Typography>Loading...</Typography>;
  }

  const handleBackButton = () => {
    navigate(`/support`);
  };

  const handleAdminAssignButton = () => {
    setAssignModalOpen(true);
  }

  const handleWithdrawButton = async () => {
    try {
      await updateTicketStatusByUser({ticketID: ticketDetails.id , status:'withdraw'});
      navigate(`/support`);
    } catch (error) {
      console.error("error while withdrawing ticket", error);
    }
  };
  
  const handleEscalateButton = async () => {
    try {
      await updateTicketStatusByUser({ticketID: ticketDetails.id , status:'escalated'});
      navigate(`/support`);
    } catch (error) {
      console.error("error while escalating ticket", error);
    }
  };
  
  const handleAcceptButton = async () => {
    try {
      await updateTicketStatusByUser({ticketID: ticketDetails.id , status:'assigned'});
      navigate(`/support`);
    } catch (error) {
      console.error("error while accepting ticket", error);
    }
  };

  const handleEditButton = () => {
    navigate(`/support/edit-ticket/${id}`, { state: { ticketDetails } });
  };

  return (
    <>
      <Box>
        <Typography
          sx={{ color: "#2E2C34", fontSize: "20px", fontWeight: 600, mb: 2 }}
        >
          Ticket
        </Typography>
        <Box
          sx={{
            backgroundColor: "#fff",
            padding: 2,
            borderRadius: 2,
            boxShadow: 2,
            maxWidth: "100%",
            margin: "0 auto",
            position: 'relative'
          }}
        >
          {/* Header */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              marginBottom: 3,
              gap: 1,
            }}
          >
            <Box
              sx={{
                width: 12,
                height: 12,
                backgroundColor: ticketDetails.status === "withdraw" ? "red" : "#FFD700",
                borderRadius: "50%",
              }}
            />
            <Typography variant="h6" fontWeight="bold">
              {`Ticket #${ticketDetails.customID || ticketDetails.id}`}
            </Typography>
          </Box>

          {/* Ticket Details */}
          <Grid container spacing={2} sx={{ marginBottom: 2 }}>
            {[
              {
                label: "Ticket No.",
                value: ticketDetails.customID || ticketDetails.id,
              },
              {
                label: "Date & Time",
                value: formatDateToIndianLocale2(ticketDetails.createdAt),
              },
              { label: "Reported by", value: ticketDetails.name },
              {
                label: "Module",
                value:
                  ticketDetails.moduleID === 2
                    ? "Loss Control"
                    : "Incident Management",
              },
              { label: "Ticket Status", value: ticketDetails.status.charAt(0).toUpperCase() + ticketDetails.status.slice(1) },
              { label: "Started By", value: ticketDetails.createdBy },
              { label: "Sub-Section", value: ticketDetails.section },
              { label: "Closed By", value: "" },
              { label: "Role", value: "System Admin" },
              { label: "Site Name", value: "" },
              { label: "Priority", value: ticketDetails.priority.charAt(0).toUpperCase() + ticketDetails.priority.slice(1) },
              {
                label: "Started At",
                value: formatDateToIndianLocale2(ticketDetails.createdAt),
              }
            ].map((item, index) => (
              <>
              <Grid item xs={6} sm={3} key={index}>
                <Typography variant="body2" sx={{color:'#2E2A40',fontWeight:600}}>
                  {item.label}
                </Typography>
                <Typography variant="body2" sx={{color:'#757575'}}>{item.value || "--"}</Typography>
              </Grid>
              {isChatBoxOpen && index%3===2 && (
              <Grid item xs={6} sm={3}>
              </Grid>)}
              </>
            ))}
          </Grid>

          {/* Issue Summary */}
          <Box sx={{width: isChatBoxOpen ? '60%' : "100%"}}>
            <Typography variant="body1" fontWeight="bold" marginBottom={1}>
              Issue Summary
            </Typography>
            <Typography variant="body2" marginBottom={2}>
              {ticketDetails.issueSummary || "--"}
            </Typography>
          </Box>

          {/* Issue Description */}
          <Box sx={{width: isChatBoxOpen ? '60%' : "100%"}}>
            <Typography variant="body1" fontWeight="bold" marginBottom={1}>
              Issue Description
            </Typography>
            <Typography variant="body2" marginBottom={3}>
              {ticketDetails.issueDescription || "--"}
            </Typography>
          </Box>

          {/* Attachments */}
          <Typography variant="body1" fontWeight="bold" marginBottom={1}>
            Attachments
          </Typography>
          <Stack direction="row" spacing={1} marginBottom={3}>
            {ticketDetails.attachments?.length > 0 ? (
              ticketDetails?.attachments?.map((attach, index) => (
                <Avatar
                  key={index}
                  variant="square"
                  src={attach}
                  alt="Attachment"
                  sx={{ width: 64, height: 64 }}
                  onClick={()=>setDraggable(attach)}
                />
              ))
            ) : (
              <Typography>No Attachments</Typography>
            )}
          </Stack>

          {/* Buttons */}
          <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleBackButton}
            >
              Back
            </Button>

            {/* Button 1-User, 2-Agent, 3-Admin */}
            {flow === 1 ? (
              <>
            {(ticketDetails?.status === "open" || ticketDetails?.status === "escalated" || ticketDetails?.status === "reopen") && (
              <Box sx={{display: 'flex', alignItems: 'center', gap: 2}}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => setOpenModal(true)} // Open the confirmation modal
                >
                  Withdraw
                </Button>
                {ticketDetails?.status !== "escalated" &&
                (<Button
                  variant="outlined"
                  color="primary"
                  onClick={() => setEscalateConfirm(true)}
                >
                  Escalate
                </Button>)}
                <Button
                  variant="contained"
                  sx={{ color: "white", backgroundColor: '#09A2E3' }}
                  onClick={() => handleEditButton()}
                >
                  Edit
                </Button>
              </Box>
            )}
            </>
            ) : flow === 2 ? (
              <>
                <Button
                  variant="contained"
                  sx={{ color: "white", backgroundColor: '#09A2E3' }}
                  onClick={() => setAcceptConfirm(true)}
                >
                  Accept
                </Button>
              </>
            ) : flow === 3 ? (
              <>
              {(ticketDetails?.status === "open" || ticketDetails?.status === "escalated" || ticketDetails?.status === "reopen") ? (
                <Button
                  variant="contained"
                  sx={{ color: "white", backgroundColor: '#09A2E3' }}
                  onClick={() => handleAdminAssignButton()}
                >
                  Assign
                </Button>
                ) : (
                <Button
                  variant="contained"
                  sx={{ color: "white", backgroundColor: '#09A2E3' }}
                  onClick={() => setIsChatBoxOpen(true)}
                >
                  View
                </Button>
                )}
              {(ticketDetails?.status === "closed") && (
                <Button
                  variant="contained"
                  sx={{ color: "white", backgroundColor: '#09A2E3' }}
                  onClick={() => handleAdminAssignButton()}
                >
                  Reopen
                </Button>
                )}
              </>
            ) : (
              <></>
            )}
            
          </Box>
          {isChatBoxOpen && (<ChatBox open={isChatBoxOpen} isOpen = {setIsChatBoxOpen} ticketDetails={ticketDetails}/>)}
        </Box>
      </Box>

      {/* Withdraw Confirmation */}
      <ConfirmationModal open={openModal} title={"Are you sure?"} message="After withdrawing, the ticket will be deleted permanently." onCancel={() => setOpenModal(false)} onConfirm={() => {setOpenModal(false); handleWithdrawButton();}} />
      {/* Escalate Confirmation */}
      <ConfirmationModal open={escalateConfirm} title={"Are you sure?"} message="Do you really want to escalate this ticket?" onCancel={() => setEscalateConfirm(false)} onConfirm={() => {setEscalateConfirm(false); handleEscalateButton();}} />
      {/* Accept Confirmation */}
      <ConfirmationModal open={acceptConfirm} title={"Are you sure?"} message="Do you really want to accept this ticket?" onCancel={() => setAcceptConfirm(false)} onConfirm={() => {setAcceptConfirm(false); handleAcceptButton();}} />
      
      <AssignTicketDialog open={assignModalOpen} onClose={() => setAssignModalOpen(false)} ticketID={ticketDetails.id} />
      <DraggableMediaDialog
        imageSrc={draggable}
        open={draggable?.length !== 0}
        onClose={()=>setDraggable("")}
      />
    </>
  );
};
