import React, { useState, useEffect } from "react";
import {
    Drawer,
    IconButton,
    Grid,
    Typography,
    Button,
    Fab,
    Box,
    Divider,
    Switch,
    Checkbox,
    FormControlLabel,
    Paper,
    Chip
} from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CloseIcon from "@mui/icons-material/Close";
import CustomInput from "./CustomInputField";
import CustomSelect from "./CustomSelectField";
import CustomButtonGroup from "./CustomButtonGroup";
import CustomMultipleSelect from "./MutlipleSelect"
import BasicDateTimePicker from "./CustomDateTime";
import CheckboxSelect from "./CustomeCheckInput";
import CustomeDatePicker from "./CustomeDate"
import CardEditDelete from "./CardEditDelete";
import { useDispatch, useSelector } from 'react-redux';
import { addMitigationMeasures, addWitnessStatementDetail, setCompensationFormData, addCompensationInfoDetail, addLessonsLearned, updateSwitchState, handleChangeDrawerFormData, AddIncidentResponsibility, addPersonnelDetail, addCAPADetail, addEvidenceDetail, removeInvestigationMember, addToCauseAnalysis, clearCauses } from '../redux/incidentInvestflow/actions';
import dayjs from 'dayjs';
import CustomFileUpload from "./CustomFileUpload";
import { getSubArea, getStaffAndEntity, getEntity, getCAPAOwner } from '../Apis/apiCall'
import CustomMultiSelect from '../Components/CustomeMutliSelect';
import CauseAnalysis from "./CauseAnalysis";
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import PreviewIcon from '@mui/icons-material/Preview';
import ContributingFactorPage from './AddContributingFactor';
import PersonnnelCard from "./GeneralCard";

const togleStyle =
{
    '& .MuiSwitch-switchBase.Mui-checked': {
        color: '#0AA2E3', // Color when checked (knob)
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: '#0AA2E3', // Color when checked (track)
    },
}

const AddButtonStyle = {
    fontSize: "14px",
    fontWeight: "500",
    paddingY: "8px",
    paddingX: "16px",
    color: "#fff",
    borderRadius: "60px",
    backgroundColor: "#0AA2E3",
    width: "70px",
    textTransform: "none"
};
const AddButtonStyle2 = {
    marginTop: "48px",
    fontSize: "14px",
    fontWeight: "500",
    paddingY: "8px",
    paddingX: "16px",
    color: "#fff",
    borderRadius: "60px",
    backgroundColor: "#0AA2E3",
    width: "100%",
    boxShadow: '0px 0px 10px 1px #09A2E3',
    textTransform: "none"
};
const AddButtonStyle3 = {
    fontSize: "14px",
    fontWeight: "500",
    color: "#0AA2E3",
    borderRadius: "60px",
    backgroundColor: "#88d6f8",
    textTransform: "none"
};

const genderList = ["Male", "Female", "Other"];
const deginationCodeOptions = ["D1", "D2", "D3"];


const RightDrawerInvestigation = ({ open, onClose, titles, fields = [], cardData = [], data
}) => {
    console.log("cd", cardData);
    const title = titles.replace(/\s+/g, '');
    const dispatch = useDispatch();
    const [tabValue, setTabValue] = React.useState('1');
    // const [step, setStep] = React.useState("newAdd");
    const [staffNameList, setStaffNameList] = useState([]);
    const [entityList, setEntityList] = useState([]);
    const [subAreaList, setSubAreaList] = useState([]);
    const [CAPAOwnerList, setCAPAOwnerList] = useState([]);
    const formData = useSelector(state => state.incidentInvestigationFlowReducer);
    const {
        Return,
        Review,
        InvestigationEvidence,
        InvestigationEvidenceDetail,
        ContributingFactorDetail,
        CAPA,
        RegulatoryInfo,
        CAPADetail,
        MitigationMeasures,
        MitigationMeasuresDetail,
        LessonsLearnedDetail,
        LessonsLearned,
        switchStates,
        RootCauseAnalysis,
        IncidentResponsibility,
        IncidentResponsibilityDetail,
        WitnessStatement,
        WitnessStatementDetail,
        CompensationInfo,
        CompensationInfoDetail,
        WorkflowInput,
        formEdit
    } = formData;


    const isFormValid = () => {
        return (
            RootCauseAnalysis.rootCause?.length > 0 &&
            RootCauseAnalysis.immediateCause?.length > 0 &&
            RootCauseAnalysis.indirectCause?.length > 0
        );
    };



    const filteredFields = fields.filter((field) => {
        const outcome = CompensationInfo?.Outcome;

        // Exclude "Date Of Return To Duty" and "Return For Regular Job" if outcome is "Fatality"
        if (
            outcome === "Fatality" &&
            (field.formFieldName === "Date Of Return To Duty" || field.formFieldName === "Return For Regular Job" || field.formFieldName === "Permanent disablement")
        ) {
            return false; // Exclude these fields
        }

        // Exclude "Cause Of Death" if outcome is "Minor Injury" or "Major Injury"
        if (
            (outcome === "Minor Injury" || outcome === "Major Injury") &&
            field.formFieldName === "Cause Of Death"
        ) {
            return false; // Exclude this field
        }

        // Include all other fields by default
        return true;
    });


    console.log("filed fulterd", CompensationInfo?.Outcome, filteredFields);

    useEffect(() => {
        if (CAPA && CAPA["Area"]) {
            fetchSubArea(CAPA["Area"]);
        }
        fetchCAPAOwner();
    }, [CAPA]);


    const fetchSubArea = async (data) => {
        try {
            const result = await getSubArea(data);
            console.log(result.data.data);
            setSubAreaList(result.data.data); // Set the formatted staff list

        } catch (error) {
            console.log(error);
        }
    }
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const fetchCAPAOwner = async () => {
        try {
            const result = await getCAPAOwner();
            console.log(result.data.data);
            const formattedStaffList = result.data.data.map(staffMember => ({
                value: staffMember.id,
                label: `${staffMember.firstName} ${staffMember.lastName}`
            }));
            setCAPAOwnerList(formattedStaffList);

        } catch (error) {
            console.log(error);
        }
    }


    const handleInputChange = (e, fieldName, title) => {
        const { value } = e.target;
        if (fieldName === 'Age' || fieldName === 'LTI Hours' || fieldName === "Hours at Work") {
            if (value.length <= 2) {
                dispatch(handleChangeDrawerFormData({ fieldName, value, title }));
            }
        } else {
            dispatch(handleChangeDrawerFormData({ fieldName, value, title }));

        }
    };

    const handleSelectChange = (label, value, title) => {
        if (label === 'Age' || label === 'LTI Hours' || label === "Hours at Work") {
            if (value.length <= 2) {
                dispatch(handleChangeDrawerFormData({ fieldName: label, value, title }));
            }
        } else {
            dispatch(handleChangeDrawerFormData({ fieldName: label, value, title }));

        }


    };

    const handleButtonChange = (fieldName, value, title) => {
        dispatch(handleChangeDrawerFormData({ fieldName, value, title }));
    };

    const handleDateChange = (newValue, label, title) => {
        const formattedDateTime = dayjs(newValue).format('DD-MM-YYYY');
        dispatch(handleChangeDrawerFormData({ fieldName: label, value: formattedDateTime, title }));
    };

    const handleSelect = (value, fieldName) => {
        dispatch(handleChangeDrawerFormData({ fieldName, value, title }));
    };
   
    const handleToggle = (name, sectionName) => {
        const hasSectionDetails =
          RootCauseAnalysis &&
          (RootCauseAnalysis.rootCause.length > 0 ||
            RootCauseAnalysis.immediateCause.length > 0 ||
            RootCauseAnalysis.indirectCause.length > 0);
      
        const sectionDetailsMap = {
          MitigationMeasures: MitigationMeasuresDetail,
          LessonsLearned: LessonsLearnedDetail,
          CAPA: CAPADetail,
          InvestigationEvidence: InvestigationEvidenceDetail,
          WitnessStatement: WitnessStatementDetail,
          IncidentResponsibility: IncidentResponsibilityDetail,
          CompensationInfo: CompensationInfoDetail,
          ContributingFactor: ContributingFactorDetail,
          RegulatoryInfo:
            RegulatoryInfo && Object.keys(RegulatoryInfo).length > 0
              ? RegulatoryInfo
              : [],
        };
      
        const hasDetailsForSection =
          sectionDetailsMap[sectionName] &&
          Object.keys(sectionDetailsMap[sectionName]).length > 0;
      
        console.log("Toggle name:", name, "Section:", sectionName);
      
        if (name === "Yes") {
          // If clicked "Yes", update the switch state to "Yes"
          dispatch(updateSwitchState(sectionName, name));
        } else {
          // If the section has details, keep it as "Yes"
          if (hasDetailsForSection) {
            dispatch(updateSwitchState(sectionName, "Yes"));
          }
          // If RootCauseAnalysis has details and the section is "RootCauseAnalysis", keep it "Yes"
          else if (hasSectionDetails && sectionName === "RootCauseAnalysis") {
            dispatch(updateSwitchState(sectionName, "Yes"));
          } else {
            // If no details, allow toggling to "No"
            dispatch(updateSwitchState(sectionName, "No"));
          }
        }
      };
      


    const SubmitFormData = async (title) => {
        console.log("hhhhh", title)
        const isObjectValid = (obj) => {
            return obj && typeof obj === 'object' && Object.values(obj).every(value => value !== null && value !== undefined && value !== '');
        };

        switch (title) {
            case "MitigationMeasures":
                if (isObjectValid(MitigationMeasures)) {
                    dispatch(addMitigationMeasures(MitigationMeasures));
                }
                break;

            case "LessonsLearned":
                if (isObjectValid(LessonsLearned)) {
                    dispatch(addLessonsLearned(LessonsLearned));
                }
                break;
            case "RootCauseAnalysis":
                //dispatch(addToCauseAnalysis()); // Add current causes to RootCauseAnalysis array
                //dispatch(clearCauses()); // Clear cause fields   
                break;

            case "IncidentResponsibility":
                if (isObjectValid(IncidentResponsibility)) {
                    dispatch(AddIncidentResponsibility(IncidentResponsibility));
                }
                break;

            case "CAPA":
                if (isObjectValid(CAPA)) {
                    dispatch(addCAPADetail(CAPA));
                }
                break;


            case "InvestigationEvidence":
                if (isObjectValid(InvestigationEvidence)) {
                    dispatch(addEvidenceDetail(InvestigationEvidence));
                }
                break;
            case "WitnessStatement":
                if (isObjectValid(WitnessStatement)) {
                    dispatch(addWitnessStatementDetail(WitnessStatement));
                }
                break;
            case "CompensationInfo":
                if (isObjectValid(CompensationInfo)) {
                    dispatch(addCompensationInfoDetail(CompensationInfo));
                }
                break;

            default:
                console.warn("Invalid title provided");
        }
    };




    const renderField = (field, title) => {

        let isSelected;
        const fieldValue = formData?.[title]?.[field.formFieldName] || '';
        if (field.formFieldName === "Employee Type") {
            isSelected = formData?.[title]?.["Type"] ? "Yes" : null;
        }

        const isOISDMajorFire = field.formFieldName === "OISD Major Fire" &&
            (formData?.[title]?.["OISD"] === "Major") &&
            (formData?.[title]?.["OISD Fire"] === true);

        const isOISDMinorFire = field.formFieldName === "OISD Major Fire" &&
            (formData?.[title]?.["OISD"] === "Minor") &&
            (formData?.[title]?.["OISD Fire"] === true);

        const isPNGRBMajorFire = field.formFieldName === "PNGRB Major Fire" &&
            formData?.[title]?.["PNGRB"] === "Major" &&
            (formData?.[title]?.["PNGRB Fire"] === true);

        const isPNGRBMinorFire = field.formFieldName === "PNGRB Major Fire" &&
            (formData?.[title]?.["PNGRB"] === "Minor") &&
            (formData?.[title]?.["PNGRB Fire"] === true);




        switch (field.type) {
            case 'InputBox':
                return (
                    <CustomInput
                        placeholder={field.formFieldName}
                        label={field.formFieldName}
                        name={field.formFieldName}
                        value={fieldValue}
                        type={field?.dataType === "String" ? "text" : "number"}
                        onChange={(e) => handleSelectChange(field.formFieldName, e.target.value, title)}
                    />
                );



            case 'Dropdown':
                return (

                    <>

                        {(field?.formFieldName === "Body Parts Involved" || field?.formFieldName === "Body Parts(Loss)" || field?.formFieldName === "Body Parts Gone Out Of Use") &&
                            <CustomMultipleSelect
                                label={field.formFieldName}
                                value={fieldValue}
                                options={field?.options ? field?.options : ""}
                                onChange={(e) => handleInputChange(e, field.formFieldName, title)}
                            />
                        }
                        {field.formFieldName === "Investigation Members" &&
                            <CustomMultiSelect
                                label={field.formFieldName}
                                value={fieldValue}
                                options={field?.options ? field?.options : ""}
                                onChange={(e) => handleInputChange(e, field.formFieldName, title)}
                            />
                        }                           {isOISDMajorFire &&
                            <CustomSelect
                                label={"Major Fire"}
                                placeholder={"Major Fire Category"}
                                value={fieldValue}
                                options={field?.options || ""}
                                onChange={(e) => handleInputChange(e, field.formFieldName, title)}
                            />
                        } {
                            isOISDMinorFire &&
                            <CustomSelect
                                label={"Minor Fire"}
                                placeholder={"Minor Fire Category"}
                                value={fieldValue}
                                options={field?.options || ""}
                                onChange={(e) => handleInputChange(e, field.formFieldName, title)}
                            />
                        } {isPNGRBMajorFire &&
                            <CustomSelect
                                label={"Major Fire"}
                                placeholder={"Major Fire Category"}
                                value={fieldValue}
                                options={field?.options || ""}
                                onChange={(e) => handleInputChange(e, field.formFieldName, title)}
                            />
                        }{isPNGRBMinorFire &&
                            <CustomSelect
                                label={"Minor Fire"}
                                placeholder={"Minor Fire Category"}
                                value={fieldValue}
                                options={field?.options || ""}
                                onChange={(e) => handleInputChange(e, field.formFieldName, title)}
                            />
                        }
                        {
                            (field.formFieldName !== "PNGRB Major Fire" && field.formFieldName !== "OISD Major Fire" && field.formFieldName !== "Investigation Members" && field?.formFieldName !== "Body Parts Involved" && field?.formFieldName !== "Body Parts(Loss)" && field?.formFieldName !== "Body Parts Gone Out Of Use")
                            &&
                            (
                                <CustomSelect
                                    label={field.formFieldName}
                                    value={fieldValue}
                                    options={field?.formFieldName == "Name" ? staffNameList : field?.formFieldName == "Entity" ? entityList : field?.formFieldName == "Gender" ? genderList : field?.formFieldName == "Sub Area" ? subAreaList : field?.formFieldName == "Designation Code" ? deginationCodeOptions : field?.formFieldName == "CAPA Owner" ? CAPAOwnerList : (field?.options) ? field.options : ""}
                                    onChange={(e) => handleInputChange(e, field.formFieldName, title)}
                                />
                            )
                        }

                    </>

                );

            case 'TextArea':
                return (
                    <CustomInput
                        placeholder={field.formFieldName}
                        label={field.formFieldName}
                        multiline
                        rows={4}
                        type={field?.dataType === "String" ? "text" : "number"}
                        value={fieldValue}
                        onChange={(e) => handleSelectChange(field.formFieldName, e.target.value, title)}
                    />
                );


            case 'ButtonGroup':
                return (
                    <>
                        {(field.formFieldName === "Severity" || field.formFieldName === "Incident Type") ?
                            <>
                                <Typography variant='body2'>
                                    {field.formFieldName}
                                </Typography>
                                <Paper
                                    elevation={0}
                                    sx={{
                                        border: '1px solid #B7C8E5',
                                        paddingX: '16px',
                                        borderRadius: '8px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'flex-start',
                                        width: '100%',
                                    }}
                                >
                                    <Grid container spacing={2}>
                                        <Grid item xs={4} display='flex' flexDirection='column' gap={0.5}>
                                            <Typography variant="caption" sx={{ color: '#666F7F' }}>
                                                Reported
                                            </Typography>
                                            <Box
                                                sx={{
                                                    backgroundColor: '#F7F7F7',
                                                    padding: '4px 8px',
                                                    borderRadius: '4px',
                                                    width: 'fit-content',
                                                    fontWeight: 500,
                                                    color: '#A4B2CC',
                                                }}
                                            >
                                                {data?.[field.formFieldName]}
                                            </Box>
                                        </Grid>
                                        <Grid item xs={8} display='flex' flexDirection='column' gap={0.5}>
                                            <Box display='flex' flexDirection='row' justifyContent='space-between'>
                                                <Typography variant="caption" sx={{ color: '#666F7F' }}>
                                                    Suggested
                                                </Typography>
                                                {field.formFieldName === "Incident Type" && (
                                                    <Typography
                                                        variant="caption"
                                                        sx={{ fontSize: "9px", color: '#0AA2E3', textDecoration: 'underline' }} // Added margin left for spacing
                                                    >
                                                        {fieldValue === "UA" ? "Unsafe Act" :

                                                            fieldValue === "UC" ? "Unsafe Condition" :

                                                                fieldValue === "NM" ? "Near Miss" :
                                                                    fieldValue === "Minor" ? "Minor" :
                                                                        fieldValue === "MR" ? "Minor Reportable" :

                                                                            fieldValue === "DO" ? "Dangerous Occurrence" :

                                                                                fieldValue === "Serious" ? "Serious Bodily Injury"

                                                                                    : "Fatal"
                                                        }
                                                    </Typography>
                                                )}
                                            </Box>
                                            <CustomButtonGroup
                                                buttonNames={field?.options || []}
                                                selected={fieldValue}
                                                onSelect={(name) => handleButtonChange(field.formFieldName, name, title)}
                                            />
                                        </Grid>
                                    </Grid>
                                </Paper>

                            </>
                            :
                            <>
                                <Typography variant='body2' sx={{ color: '#666F7F' }}>
                                    {field.formFieldName}
                                </Typography>
                                <CustomButtonGroup
                                    buttonNames={field?.options || []}
                                    selected={fieldValue}
                                    onSelect={(name) => handleButtonChange(field.formFieldName, name, title)}
                                />
                            </>

                        }
                    </>
                );


            case 'DateTime':
                return (
                    <BasicDateTimePicker
                        label={field.formFieldName}
                        value={fieldValue ? fieldValue : ""}
                        onChange={(e) => handleDateChange(e, field.formFieldName, title)}
                    />
                );
            case 'Date':
                return (
                    <CustomeDatePicker
                        label={field.formFieldName}
                        value={fieldValue ? fieldValue : ""}
                        onChange={(e) => handleDateChange(e, field.formFieldName, title)}
                    />
                );

            case 'toggleSwitch':
                return (
                    <>
                        <Box display='flex' alignItems='center'>
                            <Typography variant="body2" sx={{ color: '#525966', mr: "1px" }}>{(field.formFieldName === "Investigation Recommended" && formData?.[title]?.["High Potential Near Miss"] === true) ? "Investigation Required" : field.formFieldName}</Typography>
                            <Switch size="small" style={{ ...togleStyle }}
                                checked={fieldValue || false}
                                onChange={(e) => handleSelect(e.target.checked, field.formFieldName)}
                            />
                        </Box>
                    </>
                );
            case 'checkBox':
                return (
                    <>
                        {(field.formFieldName === "OISD Fire" && formData?.[title]?.["OISD"] && formData?.[title]?.["OISD"] != "Not Applicable") && (
                            <CheckboxSelect
                                label="fire"
                                checked={Boolean(fieldValue)} // Ensure checked is always a boolean
                                onChange={(newChecked) => handleSelect(newChecked, field.formFieldName)}
                            />
                        )}
                        {(field.formFieldName === "PNGRB Fire" && formData?.[title]?.["PNGRB"] && formData?.[title]?.["PNGRB"] != "Not Applicable") && (
                            <CheckboxSelect
                                label="fire"
                                checked={Boolean(fieldValue)} // Ensure checked is always a boolean
                                onChange={(newChecked) => handleSelect(newChecked, field.formFieldName)}
                            />
                        )}
                    </>
                );



            default:
                return null;
        }
    };


    const renderFields = (fields, title) => {
        return (
            <Grid container spacing={2}>
                {fields.map((field) => (
                    <Grid
                        item
                        xs={12}
                        md={
                            [
                                "Body Parts(Loss)",
                                "Body Parts Gone Out Of Use",
                                "Severity",
                                "Details not covered above",
                                "Mitigation Measures",
                                "CAPA Description",
                                "Equipment Incident Details",
                                "Incident Type",
                                "Review Date",
                                "Review Input",
                                "Return to previous level",
                                "Investigation recommended",
                                "Review  completed",
                                "Lessons Learned",
                                "Observation",
                                "Rules Contravened",
                                "Person Responsible",
                                "Nature of Contravention",
                                "Action Taken",
                                "Description",
                                "Date of Statement",
                                "Witness Name",
                                "Body Parts Involved",
                                "Investigation Leader",
                                "Investigation Members",
                                "Target completion date",
                                "Description of Other Training",
                                "State whether danger is still present",
                                "Fatalities Count (Employee)",
                                "Injuries Count (Employee)",
                                "Fatalities Count (Contract personnel)",
                                "Injuries Count (Contract personnel)",
                                "No. of people affected by gas",
                                "Date of Immediate last Accident",
                                "Immediate last accident Type",
                                "Incident Cause",
                                "whether Plant shut down/ cause outrage of the facility?",
                                "was the incident avoidable?",
                                "Reason for categorization",
                                "loss of production (in tonnes)",
                                "loss of Manshifts (in hours)",
                                "Action taken to prevent similar accident",
                                "Recommendation by ISO or any other",
                                "How accident could have been avoided?",
                                "Other Benefits Or Benefits To Dependents",
                                "Job Done At The Time Of Accident",
                                "Sector",
                                "Investigation complete",
                                "Whether same incident happen at same location in the past",
                                "Whether internal investigation report to OISD",
                                "Whether internal investigation report is submitted to PNGRB"
                            ].includes(field.formFieldName)
                                ? 12 :
                                ["OISD", "PNGRB", "PNGRB Major Fire", "OISD Major Fire"].includes(field.formFieldName) ? 5 : ["PNGRB Fire", "OISD Fire"].includes(field.formFieldName) ? 2
                                    : 6 // Half-width fields
                        }
                        key={field.formFieldName}
                    >
                        {renderField(field, title)}
                    </Grid>
                ))}
            </Grid>
        );
    };




    const setOpenInputForm = ({ row, title }) => {
        dispatch(setCompensationFormData({ row, title }));
    }



    return (
        <Drawer
            anchor="right" // You can pass 'right', 'left', 'top', 'bottom'
            open={open}
            onClose={onClose}
            PaperProps={{
                sx: { width: "32%", padding: '0px !important' }, // Customize drawer style
            }}
        >
            <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                sx={{
                    paddingY: "16px",
                    paddingX: "24px",
                    borderBottom: "1px solid rgba(171, 171, 171, 0.50)",
                    boxShadow: "0px 2px 4px 0px #ABABAB",
                }}
            >

                <Typography sx={{ fontSize: "24px", fontWeight: "600" }}>
                    {titles ==="INV_CAPA"? "CAPA":titles}
                </Typography>


            </Grid>

            {title === "InvestigationEvidence" ? (

                <>
                    <Box display='flex' justifyContent="flex-end" sx={{ pr: "16px" }}>
                        <Switch
                            checked={switchStates?.InvestigationEvidence === 'Yes'}
                            onChange={(e) => handleToggle(e.target.checked ? 'Yes' : 'No', title)}
                            sx={{ ...togleStyle }}
                        />
                    </Box>

                    {switchStates?.InvestigationEvidence === 'Yes' && (
                        <>
                            <Box sx={{ padding: '24px 24px' }}>
                                <Typography variant='body1' sx={{ mb: '8px' }}>Category</Typography>
                                <Box display='flex' gap={1} sx={{ mb: '16px' }}>
                                    <CustomButtonGroup
                                        buttonNames={["Photo"]}
                                        selected={InvestigationEvidence?.Category || ''} // Select based on Redux state value
                                        onSelect={(name) => handleButtonChange("Category", name, title)}

                                    />
                                    <CustomButtonGroup
                                        buttonNames={["Video"]}
                                        selected={InvestigationEvidence?.Category || ''} // Select based on Redux state value
                                        onSelect={(name) => handleButtonChange("Category", name, title)}
                                    />
                                    <CustomButtonGroup
                                        buttonNames={["Document"]}
                                        selected={InvestigationEvidence?.Category || ''} // Select based on Redux state value
                                        onSelect={(name) => handleButtonChange("Category", name, title)}
                                    />
                                </Box>

                                <CustomFileUpload from={"InvestigationEvidence"} />
                                <Box paddingTop={2}>
                                    <CustomInput
                                        placeholder="Description"
                                        label="Description"
                                        multiline
                                        rows='4'
                                        value={InvestigationEvidence?.Description || ""}   // selectedEvidence={selectedEvidence}
                                        onChange={(e) => handleSelectChange("Description", e.target.value, title)}

                                    />
                                </Box>

                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={4} mt={3}>
                                        {formEdit.InvestigationEvidence ?

                                            <Button
                                                variant="contained"
                                                onClick={() => SubmitFormData(title)}
                                                style={{
                                                    ...AddButtonStyle
                                                }}
                                            >
                                                Save
                                            </Button> :
                                            <AddBoxOutlinedIcon sx={{ fontSize: "32px", color: "#0AA2E3" }} onClick={() => SubmitFormData(title)} />
                                        }
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <Divider sx={{ backgroundColor: '#C1C1C1', height: '2px' }} />
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        {cardData.length > 0 && (<CardEditDelete cardData={cardData} title={title} />)}
                                    </Grid>
                                </Grid>
                            </Box>
                        </>
                    )}
                </>
            ) :
                title === "WitnessStatement" ? (
                    <>
                        <Box display='flex' justifyContent="flex-end" sx={{ pr: "16px" }}>
                            <Switch
                                checked={switchStates?.WitnessStatement === 'Yes'}
                                onChange={(e) => handleToggle(e.target.checked ? 'Yes' : 'No', title)}
                                sx={{ ...togleStyle }}
                            />
                        </Box>
                        {switchStates?.[title] === 'Yes' && (
                            <Box sx={{ padding: '24px 24px' }}>{fields.length > 0 && renderFields(fields, title)}
                                <div style={{ marginTop: '16px' }}>
                                    <CustomFileUpload from={"WitnessStatement"} />

                                </div>

                                <Grid container spacing={3}>


                                    <Grid item xs={12} sm={4} mt={3}>
                                        {formEdit.WitnessStatement ?

                                            <button
                                                variant="contained"
                                                onClick={() => SubmitFormData(title)}
                                                style={{
                                                    ...AddButtonStyle
                                                }}
                                            >
                                                Save
                                            </button> :
                                            <AddBoxOutlinedIcon sx={{ fontSize: "32px", color: "#0AA2E3" }} onClick={() => SubmitFormData(title)} />
                                        }
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <Divider sx={{ backgroundColor: '#C1C1C1', height: '2px' }} />
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        {cardData.length > 0 && (<CardEditDelete cardData={cardData} title={title} />)}
                                    </Grid>
                                </Grid>
                            </Box>)}
                    </>
                )

                    : title === "RootCauseAnalysis" ?
                        (
                            <>
                                <Box display='flex' justifyContent="flex-end" sx={{ pr: "16px" }}>
                                    <Switch
                                        checked={switchStates?.[title] === 'Yes'}
                                        onChange={(e) => handleToggle(e.target.checked ? 'Yes' : 'No', title)}
                                        sx={{ ...togleStyle }}
                                    />
                                </Box>

                                {switchStates?.[title] === 'Yes' && (
                                    <div style={{ padding: "0px 24px 24px" }}>
                                        <CauseAnalysis />
                                        <Grid container spacing={3}>
                                            {/* {isFormValid() && (
                                                <Grid item xs={12} mt={3}>
                                                    {formEdit.RootCauseAnalysis ?

                                                        <button
                                                            variant="contained"
                                                            onClick={() => SubmitFormData(title)}
                                                            style={{
                                                                ...AddButtonStyle
                                                            }}
                                                        >
                                                            Save
                                                        </button> :
                                                        <AddBoxOutlinedIcon sx={{ fontSize: "32px", color: "#0AA2E3" }} onClick={() => SubmitFormData(title)} />
                                                    }
                                                </Grid>
                                            )} */}
                                            {/* <Grid item xs={12} sm={12}>
                                                {cardData.length > 0 && (<CardEditDelete cardData={cardData} title={title} />)}
                                            </Grid> */}
                                            <Grid item xs={12}>
                                                <Grid container justifyContent="flex-end">
                                                    {isFormValid() &&
                                                        <Button
                                                            onClick={onClose}

                                                            style={{
                                                                ...AddButtonStyle,
                                                            }}
                                                        >
                                                            Next
                                                        </Button>
                                                    }
                                                </Grid>
                                            </Grid>

                                        </Grid>
                                    </div>
                                )}

                            </>
                        ) :
                        title === "ContributingFactor" ?
                            (
                                <>
                                    <Box display='flex' justifyContent="flex-end" sx={{ pr: "16px" }}>
                                        <Switch
                                            checked={switchStates?.[title] === 'Yes'}
                                            onChange={(e) => handleToggle(e.target.checked ? 'Yes' : 'No', title)}
                                            sx={{ ...togleStyle }}
                                        />
                                    </Box>

                                    {switchStates?.[title] === 'Yes' && (
                                        <div style={{ padding: "0px 24px 24px" }}>

                                            <Grid container spacing={3}>
                                                <Grid item xs={12}>
                                                    <ContributingFactorPage />
                                                </Grid>

                                                <Grid item xs={12} sm={12}>
                                                    {cardData.length > 0 && (<CardEditDelete cardData={cardData} title={title} />)}
                                                </Grid>
                                                {cardData.length > 0 && <Grid item xs={12}>
                                                    <Grid container justifyContent="flex-end">

                                                    </Grid>
                                                    <Grid container justifyContent="flex-end" spacing={0} sx={{ py: 2 }}>
                                                        
                                                        <Grid item>
                                                            <Button
                                                                onClick={onClose}
                                                                style={{
                                                                    ...AddButtonStyle,
                                                                }}
                                                            >
                                                                Next
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                }

                                            </Grid>
                                        </div>)}


                                </>
                            )
                            : title === "RegulatoryInfo" ?
                                (
                                    <>
                                        <Box display='flex' justifyContent="flex-end" sx={{ pr: "16px" }}>
                                            <Switch
                                                checked={switchStates?.[title] === 'Yes'}
                                                onChange={(e) => handleToggle(e.target.checked ? 'Yes' : 'No', title)}
                                                sx={{ ...togleStyle }}
                                            />
                                        </Box>

                                        {switchStates?.[title] === 'Yes' && (

                                            <div style={{ padding: "0px 24px 24px" }}>
                                                <Box my={2} p={2} border="1px solid #ddd" sx={{ boxShadow: "0px 0px 4px 0px #0003" }} borderRadius={4}>
                                                    {fields.length > 0 && renderFields(fields.slice(0, 9), title)}
                                                </Box>

                                                {/* Second Box: Render fields 10 to all remaining fields */}
                                                <Box my={2} p={2} border="1px solid #ddd" sx={{ boxShadow: "0px 0px 4px 0px #0003" }} borderRadius={4}>
                                                    {fields.length > 10 && renderFields(fields.slice(9), title)}
                                                </Box>


                                                <Grid container spacing={3}>


                                                    <Grid item xs={12}>
                                                        <Grid container justifyContent="flex-end">
                                                            <Button
                                                                onClick={onClose}
                                                                style={{
                                                                    ...AddButtonStyle,
                                                                }}
                                                            >
                                                                Next
                                                            </Button>
                                                        </Grid>
                                                    </Grid>

                                                </Grid>
                                            </div>
                                        )}

                                    </>
                                ) : title === "CompensationInfo" ?
                                    (
                                        <>
                                            <Box display='flex' justifyContent="flex-end" sx={{ pr: "16px" }}>
                                                <Switch
                                                    checked={switchStates?.[title] === 'Yes'}
                                                    onChange={(e) => handleToggle(e.target.checked ? 'Yes' : 'No', title)}
                                                    sx={{ ...togleStyle }}
                                                />
                                            </Box>

                                            {switchStates?.[title] === 'Yes' && (
                                                <div style={{ padding: "0px 24px 24px" }}>

                                                    {!CompensationInfo || Object.keys(CompensationInfo).length === 0 ?
                                                        (<TableContainer component={Paper} sx={{ p: "24px" }}>
                                                            <Table>
                                                                <TableHead>
                                                                    <TableRow sx={{ height: "40px" }}>
                                                                        <TableCell sx={{ height: "40px", padding: "8px" }}>Outcome</TableCell>
                                                                        <TableCell sx={{ height: "40px", padding: "8px" }}>Name</TableCell>
                                                                        <TableCell sx={{ height: "40px", padding: "8px" }} align="center"></TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    {data?.length === 0 ? (
                                                                        <TableRow>
                                                                            <TableCell colSpan={3} align="center">
                                                                                No personnel data
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    ) : (
                                                                        data?.map((row, index) => (
                                                                            <TableRow key={index} sx={{ height: "40px" }} onClick={() => setOpenInputForm({ row, title })}>
                                                                                <TableCell sx={{ height: "40px", padding: "8px" }}>{row?.Outcome}</TableCell>
                                                                                <TableCell sx={{ height: "40px", padding: "8px" }}>{row?.Name}</TableCell>
                                                                                <TableCell sx={{ height: "40px", padding: "8px" }} align="center">
                                                                                    {CompensationInfoDetail[index]?.id === row.id ? (
                                                                                        <PreviewIcon sx={{ color: "#0AA2E3" }} />
                                                                                    ) : (
                                                                                        <Switch checked={row.toggle} />
                                                                                    )}
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        ))
                                                                    )}

                                                                </TableBody>
                                                            </Table>
                                                        </TableContainer>
                                                        ) :

                                                        (<>

                                                            <Grid item xs={12} sm={12}>
                                                                {cardData.length > 0 && (
                                                                    <PersonnnelCard
                                                                        person={cardData.filter((card) =>  CompensationInfo.id == card.id)}
                                                                    />
                                                                )}
                                                            </Grid>

                                                            <div style={{ padding: "16px 12px" }}>
                                                                <Typography variant="body2">Outcome</Typography>
                                                                <Typography variant="body1" sx={{ color: "#A3B2CC", pb: "12px" }}>{CompensationInfo?.Outcome}</Typography>

                                                                {filteredFields.length > 0 && renderFields(filteredFields, title)}
                                                            </div>

                                                            <Grid container spacing={3}>
                                                                <Grid item xs={12}>
                                                                    <Grid container justifyContent="flex-end">
                                                                        <Button
                                                                            onClick={() => SubmitFormData(title)}
                                                                            style={{
                                                                                ...AddButtonStyle,
                                                                            }}
                                                                        >
                                                                            Next
                                                                        </Button>
                                                                    </Grid>
                                                                </Grid>

                                                            </Grid>
                                                        </>)
                                                    }
                                                </div>
                                            )}

                                        </>
                                    )
                                    :
                                    (
                                        <>
                                            {(title != "WorkflowInput" && title != "Return") &&
                                                <Box display='flex' justifyContent="flex-end" sx={{ pr: "16px" }}>
                                                    <Switch
                                                        checked={switchStates?.[title] === 'Yes'}
                                                        onChange={(e) => handleToggle(e.target.checked ? 'Yes' : 'No', title)}
                                                        sx={{ ...togleStyle }}
                                                    />
                                                </Box>
                                            }

                                            {switchStates?.[title] === 'Yes' && (
                                                <div style={{ padding: "28px 24px" }}>{fields.length > 0 && renderFields(fields, title)}
                                                    <Grid container spacing={3}>

                                                        <Grid item xs={12} mt={3}>
                                                            {formEdit?.[title] ?

                                                                <Button
                                                                    variant="contained"
                                                                    onClick={() => SubmitFormData(title)}
                                                                    style={{
                                                                        ...AddButtonStyle
                                                                    }}
                                                                >
                                                                    Save
                                                                </Button> :
                                                                <AddBoxOutlinedIcon sx={{ fontSize: "32px", color: "#0AA2E3" }} onClick={() => SubmitFormData(title)} />
                                                            }
                                                        </Grid>
                                                        <Grid item xs={12} sm={12}>
                                                            {cardData.length > 0 && (<CardEditDelete cardData={cardData} title={title} subtitle={title === "CAPA" ? "InvestCAPA" : ""} />)}
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Grid container justifyContent="flex-end">
                                                                <Button
                                                                    onClick={onClose}
                                                                    style={{
                                                                        ...AddButtonStyle,
                                                                    }}
                                                                >
                                                                    Next
                                                                </Button>
                                                            </Grid>
                                                        </Grid>

                                                    </Grid>
                                                </div>
                                            )
                                            }

                                            {title === "WorkflowInput" && <div style={{ padding: "28px 24px" }}>
                                                <>
                                                    {renderFields(fields, title)}
                                                  
                                                </>
                                                {WorkflowInput?.["Investigation complete"] &&
                                                    <Grid container spacing={3}>

                                                        <Grid item xs={12}>
                                                            <Grid container>
                                                                <Button
                                                                    onClick={onClose}
                                                                    style={{
                                                                        ...AddButtonStyle2,
                                                                    }}
                                                                >
                                                                    Investigation Completed
                                                                </Button>
                                                            </Grid>
                                                        </Grid>

                                                    </Grid>
                                                }
                                            </div>
                                            }

                                        </>
                                    )}
            <Fab
                aria-label="close"
                onClick={onClose}
                sx={{
                    position: "fixed",
                    top: "100px",
                    right: open ? "33.3%" : "0", // Adjust this value based on drawer width
                    transform: "translateX(50%)",
                    zIndex: 1200,
                    height: "36px",
                    width: "36px",
                    backgroundColor: "#0AA2E3",
                    borderRadius: "60px 0 0 60px",
                }}
            >
                <CloseIcon />
            </Fab>
        </Drawer>
    );
};

export default RightDrawerInvestigation;
