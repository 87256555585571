import React, { useEffect } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { TextField, Typography, Box } from '@mui/material';
import dayjs from 'dayjs';
import 'dayjs/locale/en-gb';
import { useDispatch } from 'react-redux';
import { updateIncidentDateTime } from '../../redux/incidentReport/actions'; // Import your action here

export default function DateTimeInput({ label, onChange }) {
  const dispatch = useDispatch();
  const [selectedDate, setSelectedDate] = React.useState(dayjs());

  useEffect(() => {
    const formattedDateTime = dayjs().format('DD-MM-YYYY HH:mm:ss');
    dispatch(updateIncidentDateTime(label, formattedDateTime));
    if (label === "Report Time") {
      localStorage.setItem("ReportDate", formattedDateTime);
    }
    if (label === "Incident Time") {
      localStorage.setItem("IncidentDate", formattedDateTime);
    }
  }, []);

  const handleDateChange = (newValue) => {
    setSelectedDate(newValue); // Set the raw dayjs object for DateTimePicker
    onChange(newValue, label); // Pass the formatted date to onChange callback
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
      {/* Render label above the input field */}
      {label && (
        <Typography variant="body2" sx={{ color: '#7A8699' }}>
          {label}
        </Typography>
      )}
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
        <MobileDateTimePicker
          value={selectedDate} // Keep the raw dayjs object here
          onChange={handleDateChange}
          slotProps={{
            textField: {
              size: 'small',
              fullWidth: true,
            },
          }}
          maxDateTime={dayjs()}
          renderInput={(params) => (
            <TextField
              {...params}
              margin="normal"
              variant="outlined"
              fullWidth
              sx={{
                fontSize: '12px !important',
                '& .MuiOutlinedInput-input': {
                  padding: '8px 12px', // Adjust padding for small size
                  borderColor: '#B7C8E5',
                },
              }}
            />
          )}
        />
      </LocalizationProvider>
    </Box>
  );
}
