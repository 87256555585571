import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import CustomStepper from '../Components/CustomStepper'; // Assuming it's a custom component
import { Box, Paper, FormGroup, Typography, Chip, Card } from '@mui/material';
import { styled } from '@mui/material/styles';
import IncidentReviewTabsSection from '../Components/IncidentReviewTab';
import { capitalizeFirstChar } from '../utils/helper';
import { useSelector } from 'react-redux';
import FloatingButton from '../Components/FloatButton';
import {cardHeadBG} from "../constant";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#fff',
    padding: '14px',
    color: theme.palette.text.secondary,
    borderRadius: '6px',
    border: "none",
    boxShadow: "none",
}));




export default function IncidentReview() {
    const entireData = useSelector(state => state.incidentReportFromReducer);
    const entireData2 = [entireData];
    const activeStep = useSelector((state) => state.stepReducer.activeStep);
    const isFormComplete = useSelector((state) => state.stepReducer.isFormComplete);

    return (
        <Box >
            <Grid container spacing={2}>
                <Grid item xs={12} md={5.5}>
                    <Grid container spacing={2}>

                        <Grid item xs={12}>
                            <Item>
                                <Grid container spacing={1}>
                                    <Grid item xs={4}>
                                        <Typography variant="body2" sx={{ fontWeight: 600, color: "#1E1E1E" }}>Incident Type</Typography>
                                        <Typography variant="caption" sx={{ color: "#09A2E3" }}>{entireData && entireData["Incident Type"] ? capitalizeFirstChar(entireData["Incident Type"]) : "NA"}</Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography variant="body2" sx={{ fontWeight: 600, color: "#1E1E1E" }}>Incident Time</Typography>
                                        <Typography variant="caption" sx={{ color: "#09A2E3" }}>{entireData && entireData["Incident Time"] ? entireData["Incident Time"] : "N/A"}</Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography variant="body2" sx={{ fontWeight: 600, color: "#1E1E1E" }}>Severity</Typography>
                                        <Typography variant="caption" sx={{ color: "#09A2E3" }}>{entireData && entireData.Severity ? entireData.Severity : "NA"}</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="body2" sx={{ fontWeight: 600, color: "#1E1E1E" }}>General Info</Typography>
                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                                            {Array.isArray(entireData?.GeneralInfo) && entireData?.GeneralInfo?.length > 0
                                                ? entireData?.GeneralInfo
                                                    .filter(value =>
                                                        typeof value === 'string' &&
                                                        value.trim() !== '' &&
                                                        value !== 'Other' // Exclude 'Others'
                                                    )
                                                    .map((value, index) => (
                                                        <Chip
                                                            key={index}
                                                            label={value}
                                                            size="small"

                                                            sx={{ color: "#fff", fontSize: '10px', height: '24px', bgcolor: "#09A2E3" }}
                                                        />
                                                    ))
                                                : <Chip label="N/A" size="small" sx={{ fontSize: '10px', height: '24px' }} />
                                            }

                                            {/* Render 'Others' as a chip if it exists */}
                                            {entireData?.Others && (
                                                <Chip
                                                    label={entireData?.Others}
                                                    size="small"
                                                    sx={{ color: "#fff", fontSize: '10px', height: '24px', bgcolor: "#09A2E3"  }}
                                                />
                                            )}
                                        </Box>

                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography variant="body2" sx={{ fontWeight: 600, color: "#1E1E1E" }}>Well No.</Typography>
                                        <Typography variant="caption" sx={{ color: "#09A2E3" }}>{entireData && entireData["Well No."] ? entireData["Well No."] : "N/A"}</Typography>
                                    </Grid>






                                </Grid>

                            </Item>
                        </Grid>

                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={8}>
                                    <Item>
                                        <Grid container spacing={1}>
                                            <Grid item xs={6}>
                                                <Typography variant="body2" sx={{ fontWeight: 600, color: "#1E1E1E" }}>Department</Typography>
                                                <Typography variant="caption" sx={{ color: "#09A2E3" }}>{capitalizeFirstChar(entireData.Department)}</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="body2" sx={{ fontWeight: 600, color: "#1E1E1E" }}>Incident Location</Typography>
                                                <Typography variant="caption" sx={{ color: "#09A2E3" }}>{entireData && entireData["Incident Location"] ? entireData["Incident Location"] : "NA"}</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="body2" sx={{ fontWeight: 600, color: "#1E1E1E" }}>Area</Typography>
                                                <Typography variant="caption" sx={{ color: "#09A2E3" }}>{entireData && entireData["Area"] ? capitalizeFirstChar(entireData["Area"]) : "NA"}</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="body2" sx={{ fontWeight: 600, color: "#1E1E1E" }}>Sub Area</Typography>
                                                <Typography variant="caption" sx={{ color: "#09A2E3" }}>{entireData && entireData["Sub Area"] ? capitalizeFirstChar(entireData["Sub Area"]) : "NA"}</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="body2" sx={{ fontWeight: 600, color: "#1E1E1E" }}>Activity</Typography>
                                                <Typography variant="caption" sx={{ color: "#09A2E3" }}>{entireData && entireData.Activity ? capitalizeFirstChar(entireData.Activity) : "NA"}</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="body2" sx={{ fontWeight: 600, color: "#1E1E1E" }}>Sub Activity</Typography>
                                                <Typography variant="caption" sx={{ color: "#09A2E3" }}>{entireData && entireData["Sub Activity"] ? capitalizeFirstChar(entireData["Sub Activity"]) : "NA"}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Item>
                                </Grid>

                                <Grid item xs={12} sm={4}>
                                    <Item>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12}>
                                                <Typography variant="body2" sx={{ fontWeight: 600, color: "#1E1E1E" }}>Operation Status</Typography>
                                                <Typography variant="caption" sx={{ color: "#09A2E3" }}>{entireData && entireData["Operation Status"] ? capitalizeFirstChar(entireData["Operation Status"]) : "NA"}</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant="body2" sx={{ fontWeight: 600, color: "#1E1E1E" }}>Shift</Typography>
                                                <Typography variant="caption" sx={{ color: "#09A2E3" }}>{entireData && entireData.Shift ? entireData.Shift : "NA"}</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant="body2" sx={{ fontWeight: 600, color: "#1E1E1E" }}>Losses Type</Typography>
                                                <Typography variant="caption" sx={{ color: "#09A2E3" }}>{entireData && entireData?.["Losses Type"] ? capitalizeFirstChar(entireData?.["Losses Type"]) : "NA"}</Typography>
                                            </Grid>

                                        </Grid>
                                    </Item>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Item>
                                        <Grid container spacing={1}>
                                            <Grid item xs={4}>
                                                <Typography variant="body2" sx={{ fontWeight: 600, color: "#1E1E1E" }}>Immediate Last Accident</Typography>
                                                <Typography variant="caption" sx={{ color: "#09A2E3" }}>{capitalizeFirstChar(entireData["Immediate Last Accident"])}</Typography>
                                            </Grid>

                                            <Grid item xs={4}>
                                                <Typography variant="body2" sx={{ fontWeight: 600, color: "#1E1E1E" }}>Date of Accident</Typography>
                                                <Typography variant="caption" sx={{ color: "#09A2E3" }}>{entireData && entireData["Date of Accident"] ? entireData["Date of Accident"] : "NA"}</Typography>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Typography variant="body2" sx={{ fontWeight: 600, color: "#1E1E1E" }}>Cause</Typography>
                                                <Typography variant="caption" sx={{ color: "#09A2E3" }}>{capitalizeFirstChar(entireData["Cause of Accident"])}</Typography>
                                            </Grid>

                                        </Grid>
                                    </Item>
                                </Grid>

                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Item>
                                        <Grid container spacing={1}>

                                            <Grid item xs={4}>
                                                <Typography variant="body2" sx={{ fontWeight: 600, color: "#1E1E1E" }}>Reported By-Staff Name</Typography>
                                                <Typography variant="caption" sx={{ color: "#09A2E3" }}>{entireData && entireData["Reported By - Staff Name"] ? entireData["Reported By - Staff Name"] : "NA"}</Typography>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Typography variant="body2" sx={{ fontWeight: 600, color: "#1E1E1E" }}>Staff Type</Typography>
                                                <Typography variant="caption" sx={{ color: "#09A2E3" }}>{entireData && entireData["Staff Type"] ? capitalizeFirstChar(entireData["Staff Type"]) : "N/A"}</Typography>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Typography variant="body2" sx={{ fontWeight: 600, color: "#1E1E1E" }}>Reported By-Entity Name</Typography>
                                                <Typography variant="caption" sx={{ color: "#09A2E3" }}>{entireData && entireData["Reported By - Entity Name"] ? entireData["Reported By - Entity Name"] : "NA"}</Typography>
                                            </Grid>

                                            <Grid item xs={6}>
                                                <Typography variant="body2" sx={{ fontWeight: 600, color: "#1E1E1E" }}>Report Time</Typography>
                                                <Typography variant="caption" sx={{ color: "#09A2E3" }}>{entireData && entireData["Report Time"] ? entireData["Report Time"] : "N/A"}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Item>
                                </Grid>

                            </Grid>
                        </Grid>

                    </Grid>
                </Grid>


                {/* ---------------------------second section rightside ------------------------------ */}

                <Grid item xs={12} md={6.5}>
                    <Grid container spacing={1} item xs={12}>
                        <Grid container spacing={1} py={1} px={1}>
                            {/* Short Summary: Full-width row */}
                            <Grid item xs={12}>
                                <Typography variant="body2" sx={{ fontWeight: 600, color: "#09A2E3" }}>
                                    Short Summary
                                </Typography>
                                <Typography variant="caption" sx={{ color: "#818181" }}>
                                    {entireData && entireData["Incident Short Summary"] ? entireData["Incident Short Summary"] : "N/A"}
                                </Typography>
                            </Grid>

                            {/* Row with two summary cards, each taking up 6 columns */}
                            <Grid container item xs={12} spacing={2} >
                                {/* Card 1: Solid background color */}
                                <Grid item xs={6}>
                                    <Card sx={{
                                        backgroundColor: 'rgba(9, 162, 227, 0.1)',
                                        padding: 2,
                                        minHeight: "178px",
                                        maxHeight: "178px",
                                        boxShadow: "none",
                                        borderRadius:"8px"
                                    }}>
                                        <Typography variant="body2" sx={{ fontWeight: 600, color: "#1E1E1E" }}>
                                            Incident Summary
                                        </Typography>
                                        <Typography variant="caption" sx={{ color: "#818181" }}>
                                            {entireData && entireData["Incident Summary"] ? entireData["Incident Summary"] : "N/A"}
                                        </Typography>
                                    </Card>
                                </Grid>

                                {/* Card 2: Background image */}
                                <Grid item xs={6}>
                                    <Card sx={{
                                        minHeight: "178px",
                                        maxHeight: "178px",
                                        boxShadow: "none",
                                        borderRadius:"8px",
                                        position: "relative", // Set the card to relative positioning
                                        padding: 2,
                                        backgroundColor: '#09A2E3', // Set a background color for the overlay
                                        zIndex: 1, // Ensure the content appears above the background image
                                    }}>
                                        {/* Background image */}
                                        <img
                                            src={cardHeadBG} // Replace with your image path
                                            alt="bg"
                                            style={{
                                                position: "absolute",
                                                bottom: "-410px",
                                                left: "-360px",
                                                width: "750px",
                                                height: "700px",
                                                zIndex: 0, // Ensure the image stays behind the card content
                                            }}
                                        />

                                        <Typography variant="body2" sx={{ fontWeight: 600, color: "#FFF" }}>
                                            Immediate Measures
                                        </Typography>
                                        <Typography variant="caption" sx={{ color: "#FFF" }}>
                                            {entireData && entireData["Immediate Measures"] ? entireData["Immediate Measures"] : "N/A"}
                                        </Typography>
                                    </Card>

                                </Grid>
                            </Grid>
                        </Grid>



                        <Grid item xs={12}>
                            <Item>
                                <IncidentReviewTabsSection data={entireData2} title={"review"} />
                            </Item>
                        </Grid>
                    </Grid>
                </Grid>
                {(activeStep === 1 && isFormComplete) && (<Box
                    sx={{
                        position: 'fixed',
                        bottom: 30, // Distance from the bottom
                        right: 24, // Distance from the right
                        zIndex: 1000, // Ensure it's above other components
                    }}
                >
                    <FloatingButton />

                </Box>)}
            </Grid >

        </Box >
    )
}
