import React, { useState, useEffect } from "react";
import { Box, Grid, Typography, Button } from "@mui/material";
import IncidentTopCard from "../Components/IncidentTopCard";
import CustomSelect from "../Components/CustomSelectField";
import CustomButtonGroup from "../Components/CustomButtonGroup";
import { filterIcon, listIcon, searchIcon } from "../constant";
import IncidentModuleCard from "../Components/IncidentModuleCard";
import ActionsCard from "../Components/ActionsCard";
import CustomTable from "../Components/CustomTable";
import RightDrawer from "../Components/RightDrawerIncidentReport";
import { useDispatch, useSelector } from "react-redux";
import {
  setAllReportData,
  handleChangeDrawerFormData,
  updateFilter,
  resetFilter
} from "../redux/incidentReport/actions"; // Import your action here
import { getAllReports, getAllReportsByModule, getAllStatiCard, getAllStatiCardByModule, getEntity, getStaffAndEntity, getFilterdropValues, ReportHistoryList, ReportCompletedHistoryList } from '../Apis/apiCall';
import { useLocation } from 'react-router-dom';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';


export default function AllViewTourPage() {
  const dispatch = useDispatch();
  const locations = useLocation();
  const { moduleID, moduleName, homepage, overDue } = locations?.state || {};
  const loginDataString = localStorage.getItem("loginData");
  const data = JSON.parse(loginDataString);
  const roles = data.config.permissionDetails.workflowRoles;

  const [tableView, setTableView] = useState(false);
  const [selectedIncidentsToShow, setSelectedIncidentsToShow] = useState("All Action");
  const [filteredData, setFilteredData] = useState([]);

  const roleIDs = [27, 28, 29];


  const { Filter, allReportsData } = useSelector(
    (state) => state.incidentReportFromReducer
  );

  console.log(Filter, "Filter");
  const [filterApplied, setFilterApplied] = useState(false);


  useEffect(() => {
    if (homepage) {
      dispatch(updateFilter({ fieldName: moduleName, moduleId: moduleID, moduleName: "Filter" }));
    }
    if (overDue > 0) {
      dispatch(handleChangeDrawerFormData({ fieldName: "overDue", value: 1, title: "Filter" }))
    }
    if (moduleID) {
      fetchReportCardDataByModule(moduleID);
    }
  }, [Filter.overDue]);


  const fetchReportCardDataByModule = async (value) => {
    try {
      const result = await getAllReportsByModule(value);
      dispatch(setAllReportData(result.data.data));
    } catch (error) {
      console.log(error);
    }
  }



  const filterMyIncidentOnly = async (fieldName) => {
    // Determine data to set based on the field name clicked
    if (fieldName === "All Action") {
      fetchReportCardDataByModule(Filter.ModuleId);
    }


    if (fieldName === "Completed") {
      const data = await fetchCompletedHistoryList(Filter.ModuleId, roleIDs);
      dispatch(setAllReportData(data))
    }


  };



  const fetchCompletedHistoryList = async (ModuleId, roleIDs) => {
    try {
      const result = await ReportCompletedHistoryList(ModuleId, roleIDs);
      return result.data.data;
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  const handleIncidentsToShow2 = (fieldName) => {
    setSelectedIncidentsToShow((prev) => {
      const newSelected = fieldName;
      filterMyIncidentOnly(newSelected);
      return newSelected;
    });
  };



  useEffect(() => {
    if (Filter.Modules === "Incident Management") {
      const filteredReports = allReportsData.filter((report) => {
        const matchesOverDue =
          !Filter["overDue"]
          || report.overdueTaskCount >= Filter["overDue"];


        return (
          matchesOverDue
        );
      });
      // .filter(module => module.data.length > 0);



      const isModuleSet = Filter.Modules;

      // Check if any other key in Filter (excluding 'Module') has a value
      const hasOtherFilters = Object.keys(Filter).some(
        (key) => key != "Modules" && key != "ModuleId" && Filter[key]
      );

      // Apply filtering only if both conditions are met
      if (isModuleSet && hasOtherFilters) {
        setFilterApplied(true); // Apply filtering
        setFilteredData(filteredReports.length > 0 ? filteredReports : []);
      } else {
        setFilterApplied(false); // Reset filter state
        setFilteredData([]); // Clear the filtered data if filters are not applied
      }
    }
    if (Filter.Modules === "Loss Control") {
      const filteredReports = allReportsData.filter((report) => {


        const matchesOverDue =
          !Filter["overDue"]
          || report.overdueTaskCount >= Filter["overDue"];

        return (
          matchesOverDue
        );
      });
      // .filter(module => module.data.length > 0);



      //const hasStatusOrRoles = Filter.Status || Filter.Roles;

      const isModuleSet = !!Filter.Modules;

      // Check if any other key in Filter (excluding 'Module') has a value
      const hasOtherFilters = Object.keys(Filter).some(
        (key) => (key !== "Modules" && key !== "ModuleId") && Filter[key]
      );

      // Apply filtering only if both conditions are met
      if (isModuleSet && hasOtherFilters) {
        setFilterApplied(true); // Apply filtering
        setFilteredData(filteredReports.length > 0 ? filteredReports : []);
      } else {
        setFilterApplied(false); // Reset filter state
        setFilteredData([]); // Clear the filtered data if filters are not applied
      }
    }

  }, [Filter, selectedIncidentsToShow]);

  const handleResetFilter = () => {
    setSelectedIncidentsToShow(null);
    dispatch(resetFilter());
  }


  return (
    <Box component="main" sx={{ flexGrow: 1, width: "100%" }}>


      <Grid
        container
        spacing={0}
        sx={{
          borderRadius: "4px",
          backgroundColor: "#F9FAFC",
          boxShadow: "0px 0px 12px -4px rgba(0, 0, 0, 0.25)",
          padding: "20px",
          width: "100%",
        }}
      >


        {/* Buttons */}
        <Grid item xs={12}>
          <Grid container spacing={2} mb="16px">
            <Grid item xs={12} container justifyContent="space-between">
              <Grid item>
                {Filter.Modules === "Incident Management" || Filter.Modules === "Loss Control" && (
                  <Grid container spacing={2}>
                    <Grid item>
                      <CustomButtonGroup
                        buttonNames={["All Action"]}
                        selected={selectedIncidentsToShow}
                        onSelect={(name) => handleIncidentsToShow2(name)}
                      />
                    </Grid>

                   {overDue != 1 && <Grid item>
                      <CustomButtonGroup
                        buttonNames={["Completed"]}
                        selected={selectedIncidentsToShow}
                        onSelect={(name) => handleIncidentsToShow2(name)}
                      />
                    </Grid>}


                  </Grid>
                )}
              </Grid>
              <Grid item>
                <Grid container spacing={2}>
                <Grid item>
                    <RefreshOutlinedIcon sx={{ color: "#8FA6CC", fontSize: "32px", borderRadius: "4px", background: "#EEF2FA", p: "4px" }} onClick={handleResetFilter} />
                  </Grid>

                  <Grid item>
                    <AccessTimeFilledIcon sx={{ color: "#8FA6CC", fontSize: "32px", color: "#CC0000", borderRadius: "4px", background: "#EEF2FA", p: "4px" }} onClick={() => dispatch(handleChangeDrawerFormData({ fieldName: "overDue", value: 1, title: "Filter" }))} />
                  </Grid>
                  <Grid item>
                    <img
                      src={searchIcon}
                      alt="search"
                      style={{ cursor: "pointer" }}
                    />
                  </Grid>
                  <Grid item>
                    <img
                      src={listIcon}
                      alt="list"
                      style={{ cursor: "pointer" }}
                      onClick={() => setTableView(!tableView)}
                    />
                  </Grid>
                </Grid>
              </Grid>

            </Grid>
          </Grid>
        </Grid>

        {!tableView && (
          <Grid item xs={12} sx={{ width: "100%" }}>
            {" "}
            {/* Adjust height as needed */}
            <Grid container spacing={2} sx={{ width: "100%" }}>
              {allReportsData?.length > 0 ? (
                <IncidentModuleCard
                  cardData={filterApplied ? filteredData : allReportsData}
                  title={Filter.Modules}
                  mode={"view"}
                  tab = {selectedIncidentsToShow}
                />
              ) : (
                <Typography
                  variant="body1"
                  textAlign="center"
                  sx={{ width: "100%", padding: "20px" }}
                >
                  No data available.
                </Typography>
              )}
            </Grid>
          </Grid>
        )}

        {tableView && (
          <Grid item xs={12} sx={{ width: "100%" }}>

            <CustomTable data={filterApplied ? filteredData : allReportsData} title={Filter.Modules} mode={"view"}  tab = {selectedIncidentsToShow} />

          </Grid>
        )}
      </Grid>


    </Box>
  );
}
