import React from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
  IconButton,
  Divider,
} from '@mui/material';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';
import EastIcon from '@mui/icons-material/East';
import CircleIcon from '@mui/icons-material/Circle';
import {capitalizeFirstChar} from "../utils/helper";

// Function to dynamically get the step icon based on the status
const getStepIcon = (status) => {
  if (
    [
      'Incident Closed',
      'completed',
      'assigned',
      'approved',
      'reviewed'
    ].includes(status)
  ) {
    return (
      <CheckOutlinedIcon
        sx={{
          backgroundColor: '#FFFFFF',
          borderRadius: '50%',
          color: '#4591F5',
          fill: '#4591F5',
          border: '2px solid #4591F5',
        }}
      />
    );
  } else if (['resubmission'].includes(status)) {
    return (
      <EastIcon
        sx={{
          backgroundColor: '#FFFFFF',
          borderRadius: '50%',
          color: '#FFAC07',
          fill: '#FFAC07',
          border: '2px solid #FFAC07',
        }}
      />
    );
  } else if (
    [
      'clarification',
      'revalidation',
      'returned'
    ].includes(status)
  ) {
    return (
      <KeyboardBackspaceOutlinedIcon
        sx={{
          backgroundColor: '#FFFFFF',
          borderRadius: '50%',
          color: 'red',
          fill: 'red',
          border: '2px solid red',
        }}
      />
    );
  }
  return (
    <CircleIcon
      sx={{
        backgroundColor: '#FFFFFF',
        borderRadius: '50%',
        color: '#FFAC07',
        fill: '#FFFFFF',
        border: '2px solid #B7C8E5',
      }}
    />
  );
};

export default function CustomWorkflowAccordion({ data }) {
  return (
    <>
      {data?.map((step, index) => (
        step.status !== 'incomplete' && (
          <Box key={index} sx={{ paddingBottom: '0px' }}>
            <Accordion
              elevation={0}
              sx={{
                padding: '0px !important',
                border: '0.5px solid #B7C8E5',
                borderRadius: '8px !important',
              }}
            >
              {/* Collapsed View */}
              <AccordionSummary
                expandIcon={getStepIcon(step.status)}
                aria-controls={`panel${index}-content`}
                id={`panel${index}-header`}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  backgroundColor: '#F9FAFC',
                  borderRadius: '20px',
                  paddingX: '12px',
                  '& .MuiAccordionSummary-expandIconWrapper': {
                    transform: 'none !important',
                  },
                }}
              >
                <Box>
                  <Typography variant="body2" sx={{ color: '#222222' }}>
                    {capitalizeFirstChar(step?.status) || 'Unknown'} / {step?.role || 'Unknown Role'}
                  </Typography>
                  {/* <Typography variant="caption" sx={{ color: '#525966' }}>
                    {`${step?.firstName || ''} ${step?.lastName || ''}`.trim() || 'N/A'} /{' '}
                    {step?.email || 'N/A'} / {step?.employeeID || 'N/A'}
                  </Typography> */}
                </Box>
              </AccordionSummary>

              {/* Expanded View */}
              <AccordionDetails
                sx={{
                  backgroundColor: '#f9fafc',
                  borderRadius: '8px',
                  paddingX: '12px',
                }}
              >
               

                {/* Remarks Section */}
                <Box display="flex" flexDirection="column">
                  <Typography variant="caption" sx={{ color: '#222222' }}>
                    Remarks
                  </Typography>
                  <Typography variant="caption" sx={{ color: '#525966' }}>
                    {step?.comment || 'N/A'}
                  </Typography>
                </Box>
              </AccordionDetails>
            </Accordion>
          </Box>
        )
      ))}
    </>
  );
}
