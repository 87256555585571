import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  Box,
  Button,
  TextField,
  Typography,
  Container, Link,
  Grid,
} from '@mui/material';
 import {encrypt} from '../utils/encrypt';
 import {getDeviceType,getIPAddress} from '../utils/deviceInfo.js';
import CustomSnackbar from '../Components/CustomSnackbar.js';
import { 
  oilindiaLogo,
  loginBgCircle,
  loginBgRectangle,
  ISO9001,
  ISO27001,
  netzeroLogo,
  iEnergyLogo,
  loginpageSafetyImage
 } from '../constant.js';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import CopyrightIcon from '@mui/icons-material/Copyright';
import {setLocalStorageData} from "../utils/setLocalStorageData.js";
import {uiConfigData} from "../Apis/apiCall.js";

const LoginPage = () => {
  const [formData, setFormData] = useState({
    userName: "",
    password: ""
  });
  const [errors, setErrors] = useState({});
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('accessToken');
    if (token) {
      navigate("/home");
    }
  }, [navigate]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (errors[name]) {
      setErrors({ ...errors, [name]: '' });
    }
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  
  const handleLogin = async (e) => {
    e.preventDefault();
    const device_id = 1000;
    const device_type = getDeviceType();
    const ip = await getIPAddress();
    const encryptedUsername = await encrypt(formData.userName);
    const encryptedPassword = await encrypt(formData.password);
  
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/v1/auth/login`,
        {
          email: encryptedUsername,
          password: encryptedPassword,
        },
        {
          headers: {
            'device-id': device_id,
            'device-type': device_type,
            'ip': ip,
            'type': false,
          },
        }
      );
  
      if (response?.data?.status_code === 200 && response?.data?.data?.token) {
        localStorage.setItem('accessToken', response.data?.data?.token);
  
        if (response?.data?.data?.token) {
          const results = await getconfigData();
          if (results && results.data?.data) {
            const data = JSON.stringify(results.data?.data);
            await setLocalStorageData(results.data?.data);
            localStorage.setItem('loginData', data);
          } else {
            console.error('Error: Invalid configuration data');
          }
        }
  
        navigate('/flashpage');
      } else {
        setErrors({
          userName: formData.userName ? '' : 'Email is required',
          password: formData.password ? '' : 'Password is required',
        });
      }
    } catch (error) {
      console.error('Login Error:', error);
      setSnackbarSeverity('error');
      setSnackbarMessage('Login Error!');
      setSnackbarOpen(true);
    }
  };
  


  const getconfigData = async () => {
    try {
      const result = await uiConfigData();
      console.log('Config Data:', result.data?.data);
      return result;
    } catch (error) {
      console.error('Error fetching config data:', error);
      return null;
    }
  };
  

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  // Determine if the form is filled
  const isFormFilled = formData.userName && formData.password;

  return (
    <Grid container spacing={0} sx={{ backgroundColor: '#F4F5FA', minHeight: '100vh' }}>
      <Grid item xs={12} md={6}>
        <Box sx={{ width:'100%', height:'100%', position:'relative'}}>
          <Typography sx={{position:'absolute', top:'24px', left:'24px', zIndex:'10', fontSize:'24px', fontWeight:'600', color:'#000000'}}>HSSE System</Typography>
          <img src={loginpageSafetyImage} alt='safety image' style={{width:'100%', height:'100vh'}}/>
        </Box>
      </Grid>
      <Grid item xs={12} md={6} sx={{ padding: '24px' }}>
        <Box sx={{
          backgroundImage: `url(${loginBgCircle}), url(${loginBgRectangle})`,
          backgroundSize: 'auto, auto',
          backgroundPosition: 'right -180px top -220px, left -230px bottom -170px',
          backgroundRepeat: 'no-repeat, no-repeat',
          backgroundColor: '#ffffff',
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          padding: '16px',
          position:'relative'
        }}>
          <Box display="flex" justifyContent="center" alignItems="center" height='100%'>
            <Box display="flex" flexDirection='column' alignItems="center">
            <img src={oilindiaLogo} alt='logo' style={{ width: '114px', height: '114px', mb:2 }} />

            <Box
              component="form"
              onSubmit={(e)=>handleLogin(e)}
              noValidate
              display="flex"
              flexDirection="column"
              alignItems="center"
              sx={{ width: '360px', padding: 2 }}
            >
              {/* Email Field */}
              <TextField
                label="Email"
                variant="outlined"
                fullWidth
                sx={{ marginBottom: 2, borderRadius: '50px', backgroundColor: '#FAFAFA' }}
                InputProps={{
                  sx: { borderRadius: '50px' }
                }}
                name="userName"
                value={formData.userName}
                onChange={handleChange}
                error={!!errors.userName}
                helperText={errors.userName}
              />
              
              {/* Password Field */}
              <TextField
                label="Password"
                type="password"
                variant="outlined"
                fullWidth
                sx={{ marginBottom: 1, borderRadius: '50px', backgroundColor: '#FAFAFA' }}
                InputProps={{
                  sx: { borderRadius: '50px' }
                }}
                name="password"
                value={formData.password}
                onChange={handleChange}
                error={!!errors.password}
                helperText={errors.password}
              />
              
              {/* Forgot Password Link */}
              <Box display="flex" justifyContent="flex-end" width="100%">
                <Typography variant="body2" color="text.secondary" sx={{cursor:'pointer', '&:hover': {textDecoration:'underline'} }} onClick={()=>navigate('/forgot-password')}>
                  Forgot Password?
                </Typography>
              </Box>

              {/* Submit Button */}
              <Button
                type="submit"
                variant="contained"
                disabled={!isFormFilled}
                fullWidth
                sx={{
                  marginTop: 2,
                  borderRadius: '50px',
                  backgroundColor: 'linear-gradient(180deg, #A6D7FF 0%, #2196F3 100%)' ,
                  color: 'white',
                  '&:hover': {
                    backgroundColor: 'darkblue'
                  }
                }}
              >
                Sign In
              </Button>
              
              {/* "Or" Text */}
              <Typography variant="body2" color="text.secondary" sx={{ marginY: 2 }}>
                Or
              </Typography>

              {/* Report Incident Button */}
              <Button
                variant="contained"
                fullWidth
                disabled={isFormFilled}
                sx={{
                  borderRadius: '50px',
                  background: isFormFilled ? 'grey.400' : 'linear-gradient(180deg, #A6D7FF 0%, #2196F3 100%)',
                  color: 'white'
                }}
              >
                Report Incident
              </Button>

              {/* Help Link */}
              <Box display="flex" justifyContent="flex-end" width="100%" sx={{ mt: 2 }}>
                  <Box display='flex' flexDirection='row' alignItems='center' gap={0.3} sx={{cursor:'pointer'}} onClick={()=> navigate('/help')}>
                    <Typography variant="body2" color="primary" sx={{'&:hover': {textDecoration:'underline'}}}>Help</Typography>
                    <HelpOutlineIcon color="primary" sx={{ fontSize: '16px' }} />
                  </Box>
              </Box>
            </Box>
            </Box>
          </Box>

          <Box display="flex" justifyContent="center" width="100%">
            <Box display='flex' flexDirection='column' alignItems='center' gap={2}>
              <Box display='flex' flexDirection='row' alignItems='center' gap={4}>
                <img src={ISO9001} alt='ISO9001 logo' />
                <img src={ISO27001} alt='ISO27001 logo' />
              </Box>
              <Box display='flex' flexDirection='row' alignItems='center' gap={1}>
                <Typography sx={{ color: '#545454', fontSize: '12px',mt:"8px" }}>Powered by</Typography>
                <img src={netzeroLogo} alt='netzero logo' />
              </Box>
            </Box>
          </Box>
          
          <Box display="flex" flexDirection='row' alignItems="center" gap={0} sx={{position:'absolute', right:'8px', bottom:'8px'}}>
            <CopyrightIcon sx={{fontSize:'12px', color:'#C3C3C3'}} />
            <img src={iEnergyLogo} alt='iEnergyLogo' style={{width:'60px'}} />
          </Box>

        </Box>
      </Grid>
      <CustomSnackbar
        open={snackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
        handleClose={handleSnackbarClose}
      />
    </Grid>
  );
};

export default LoginPage;