// reducer.js
import { UPDATE_ACTIVE_STEP, COMPLETE_FORM1,COMPLETE_FORM2 } from './constant';

const initialState = {
  activeStep: 0,
  isForm1Complete: false,
  isForm2Complete:false,
};

const stepReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_ACTIVE_STEP:
      return {
        ...state,
        activeStep: action.payload,
      };
    case COMPLETE_FORM1:
      return {
        ...state,
        isForm1Complete: action.payload,
      };
      case COMPLETE_FORM2:
        return {
          ...state,
          isForm2Complete: action.payload,
        };
    default:
      return state;
  }
};

export default stepReducer;
