import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { updateEvidenceFile } from '../redux/incidentReport/actions'; // Import your Redux action
import { updateInvestigationEvidenceFile, updateInvestigationWitnessStatementFile } from '../redux/incidentInvestflow/actions';
import Compressor from 'compressorjs';

// Hidden file input
const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

// Dashed box styling
const DashedBox = styled(Box)({
  border: '2px dashed #000',
  borderRadius: '8px',
  padding: '20px',
  textAlign: 'center',
  cursor: 'pointer',
  display: 'inline-block',
  width: '100%',
});

export default function CustomFileUpload({ from }) {
  console.log(from);
  const dispatch = useDispatch();
  const incidentReportData = useSelector((state) => state.incidentReportFromReducer);
  const incidentWorkflowData = useSelector((state) => state.incidentWorkFlowReducer);
  const InvestformData = useSelector(state => state.incidentInvestigationFlowReducer);

  let formData;
  if (from === "Evidence") {
    formData = incidentReportData;
  } else if (from === "InvestigationEvidence") {
    formData = InvestformData;
  } else if (from === "WitnessStatement") {
    formData = InvestformData;
  } else {
    formData = incidentWorkflowData;
  }

  // Ensure formData is properly checked for the right property
  let Category, files;

  if (formData) {
    // Check if Evidence exists, if not check InvestigationEvidence, then WitnessStatement
    Category = formData?.Evidence?.Category || formData?.InvestigationEvidence?.Category || formData?.WitnessStatement?.Category;
    files = formData?.Evidence?.files || formData?.InvestigationEvidence?.files || formData?.WitnessStatement?.files;
  }

  console.log(formData, Category, files);



  // File validation based on category
  const getAcceptedFileTypes = (category) => {
    switch (category) {
      case 'Photo':
        return '.png, .jpg, .jpeg';
      case 'Video':
        return '.mp4';
      case 'Document':
        return '.docx, .pdf';

      default:
        return '';
    }
  };


  const handleFileChange = async (event) => {
    const files = Array.from(event.target.files); // Convert FileList to an array

    const fileObjects = await Promise.all(
      files.map(async (file) => {
        const base64 = await convertToBase64(file);
        return { name: file.name, type: file.type, base64: base64.split(',')[1], }; // Base64 object
      })
    );

    if (fileObjects.length > 0) {
      const firstFile = fileObjects[0]; // Assuming single file input for now
      const fileURL = URL.createObjectURL(event.target.files[0]); // Create Blob URL

      // Dispatch with two separate parameters
      //const payload = { file: firstFile, fileURL };

      if (from === "Evidence") {
        dispatch(updateEvidenceFile({ file: firstFile, fileURL }));
      } else if (from === "InvestigationEvidence") {
        dispatch(updateInvestigationEvidenceFile({ file: firstFile, fileURL }));
      } else if (from === "WitnessStatement") {
        dispatch(updateInvestigationWitnessStatementFile({ file: firstFile, fileURL }));
      } else {
        dispatch(updateEvidenceFile({ file: firstFile, fileURL }));
      }
    }
  };

  // Convert the file to a base64 string
  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      new Compressor(file, {
        quality: 0.2, // Compression quality
        success(compressedFile) {
          const reader = new FileReader();
          reader.readAsDataURL(compressedFile);
          reader.onload = () => resolve(reader.result);
          reader.onerror = reject;
        },
        error(err) {
          reject(err);
        },
      });
    });
  };




  return (
    <label htmlFor="file-upload">
      <DashedBox>
        <CloudUploadIcon
          sx={{
            backgroundColor: '#E9E9E9',
            color: '#000',
            borderRadius: '50%',
            width: '40px',
            height: '40px',
            boxShadow: 'none',
            minWidth: 0,
            padding: 1,
          }}
        />
        {Category &&
          <VisuallyHiddenInput
            id="file-upload"
            type="file"
            accept={getAcceptedFileTypes(Category)} // Restrict file types based on category
            onChange={handleFileChange}
            multiple
          />
        }

        <Typography
          component="p"
          sx={{ mt: 0, color: '#3f51b5', textDecoration: 'underline', fontWeight: '700', fontSize: '9px' }}
        >
          Click to upload
        </Typography>
        <Typography component="p" sx={{ mt: 1, color: '#000', fontWeight: '700', fontSize: '9px' }}>
          {Category === 'Photo' ? 'PNG, JPG (max. 10 MB)' : ''}
          {Category === 'Video' ? 'MP4 (max. 50 MB)' : ''}
          {Category === 'Document' ? 'DOCX, PDF(max. 10 MB)' : ''}

        </Typography>

        {/* Display the selected file names */}
        {files && files.length > 0 && (
          <Box mt={2}>
            <Typography variant="body2">Selected Files:</Typography>
            <Typography variant="body2" sx={{ fontSize: '10px' }}>
              {files.map((file, index) => (
                <div key={index}>{file.name}</div>
              ))}
            </Typography>
          </Box>
        )}
      </DashedBox>
    </label>
  );
}
