import React, { useState, useRef } from "react";
import {
  Box,
  Typography,
  Tabs,
  Tab,
  Grid,
  Button,
  TextField,
  IconButton,
  Divider,
  Radio,
  RadioGroup,
  FormControlLabel,
  Collapse,
} from "@mui/material";
import { styled } from "@mui/system"; // Import styled from @mui/system
import FlagIcon from "@mui/icons-material/Flag"; // Replace with your desired icon
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { Doughnut } from "react-chartjs-2";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddActionDrawer from "./AddActionDrawer";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import CommentDrawer from "./CommentDrawer";
import AttachmentsDrawer from "./AttachmentsDrawer";

const QuestionnairSection = ({ tabData }) => {
  const tabsRef = useRef(null);

  const [selectedTab, setSelectedTab] = useState(0); // Local state for the selected tab
  const [showBothButtons, setShowBothButtons] = useState(false);
  const [questionnaireSection, SetQuestionnaireSection] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [backview, setBackView] = useState(false);
  const [showButtons, setShowButtons] = useState(false); // Audit question after three button
  const [questionIndex, setQuestionIndex] = useState(0); // Tracks the current step
  const [complianceStatus, setComplianceStatus] = useState("Compliant");
  const [expanded, setExpanded] = useState(false);

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isClicked, setIsClicked] = useState(false);

  const [drawerCommentOpen, setDrawerCommentOpen] = useState(false);
  const [isCommentClicked, setIsCommentClicked] = useState(false);

  const [drawerAttachmentOpen, setDrawerAttachmentOpen] = useState(false);
  const [isAttachmentOpenClicked, setIsAttachmentClicked] = useState(false);

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue); // Update the selected tab index
  };

  const StyledTabs = styled(Tabs)({
    minHeight: "48px",
    "& .MuiTabs-flexContainer": {
      justifyContent: "space-between",
    },
  });

  const StyledTab = styled(Tab)(({ selected }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "8px",
    borderRadius: "40px",
    background: selected ? "#FCFCFC;" : "#FCFCFC;", // Background changes based on selection
    color: "#000",
    fontSize: "16px",
    fontWeight: "600",
    padding: "8px 16px",
    textTransform: "none",
    marginLeft: "10px",
    width: "150px",
    "& .MuiTab-iconWrapper": {
      display: "flex",
      alignItems: "center",
    },
  }));

  const gridItems = Array(24).fill("02");
  gridItems[0] = "01"; // Change the first item's text to "01" for demo

  const gridQusItems = Array(24).fill("02");
  gridQusItems[0] = "01"; // Change the first item's text to "01" for demo

  const handleClick = (index) => {
    setSelectedIndex(index);
  };

  const chartData = {
    // labels: ["Attempted", "Not Attempted"],
    datasets: [
      {
        data: [20, 40], // Example values
        backgroundColor: ["#2ECC71", "#FF6B6B"], // Colors for the segments
        hoverBackgroundColor: ["#27AE60", "#FF4D4D"],
        borderWidth: 1,
      },
    ],
  };

  const chartOptions = {
    plugins: {
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            const label = chartData.labels[tooltipItem.dataIndex];
            const value = chartData.datasets[0].data[tooltipItem.dataIndex];
            return `${label}: ${value}`;
          },
        },
      },
    },
  };

  const handleNextQuestionOpen = () => {
    if (selectedIndex !== null) {
      SetQuestionnaireSection(!questionnaireSection);
      alert(`Div ${selectedIndex + 1} is selected!`);
    } else {
      alert("Please select a div first.");
    }
  };

  const handleBackQuestionOpen = () => {
    alert("hello back grid");
    setBackView(backview);
  };

  const AttemptedBox = ({ color, label, count }) => (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "16px",
        justifyContent: "space-between",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "8px",
        }}
      >
        <Box
          sx={{
            width: "12px",
            height: "12px",
            backgroundColor: color,
            borderRadius: "50%",
          }}
        ></Box>
        <Typography>{label}</Typography>
      </Box>
      <Typography sx={{ fontWeight: "bold" }}>{count}</Typography>
    </Box>
  );

  const data = [
    { color: "#13DEB9", label: "Attempted", count: 20 },
    { color: "#E74C3C", label: "Not Attempted", count: 40 },
  ];

  const handleNext = () => {
    if (selectedIndex !== null) {
      setShowBothButtons(true);
      alert(`Div ${selectedIndex + 1} is selected!`);
    } else {
      alert("Please select a div first.");
    }
  };

  const handleNextClick = () => {
    setShowButtons(true); // Show the three buttons
  };

  const handleNextQuestion = () => {
    setQuestionIndex((prevIndex) => prevIndex + 1); // Change the state to display the "Yes/No" question
  };

  const handleBackClick = () => {
    // setShowButtons(false);
    if (questionIndex > 0) {
      setQuestionIndex(questionIndex - 1); // Go to the previous question
    } // Hide the three buttons and show the initial button
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleOpen = () => {
    console.log("Flag Icon clicked!");
    setDrawerOpen(true);
    setIsClicked(true); // Change the state to true
  };

  const handleCommentOpen = () => {
    console.log("Chat Icon clicked!");
    setDrawerCommentOpen(true);
    setIsCommentClicked(true); // Change the state to true
  };

  const handleAttachmentOpen = () => {
    console.log("Attachment Icon clicked!");
    setDrawerAttachmentOpen(true);
    setIsAttachmentClicked(true); // Change the state to true
  };

  const handleClose = () => {
    setDrawerOpen(false);
    setIsClicked(false); // Reset the flag icon color
  };

  const handleCommentClose = () => {
    setDrawerCommentOpen(false);
    setIsCommentClicked(false); // Reset the chat icon color
  };

  const handleAttachmentClose = () => {
    setDrawerAttachmentOpen(false);
    setIsAttachmentClicked(false); // Reset the attachment icon color
  };

  return (
    <Box
      ref={tabsRef}
      sx={{
        width: "95%",
        overflowX: "auto",
        whiteSpace: "nowrap",
        padding: "10px",
        marginBottom: "10px",
      }}
    >
      <StyledTabs
        value={selectedTab}
        onChange={handleChange}
        aria-label="custom tabs"
        scrollButtons={false}
      >
        {tabData.map((tab, index) => (
          <StyledTab
            key={index}
            label={
              <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
                {tab.icon}
                <Typography>{tab.label}</Typography>
              </Box>
            }
            sx={{
              backgroundColor: selectedTab === index ? "#09A2E3" : "#FCFCFC",
              color: selectedTab === index ? "#fff" : "#000",
              borderRadius: "40px",
              transition: "background-color 0.3s, color 0.3s",
            }}
            onClick={() => setSelectedTab(index)} // Handle tab selection
          />
        ))}
      </StyledTabs>

      {/* Conditional rendering based on the selected tab */}
      {!questionnaireSection && (
        <>
          <Box
            sx={{
              backgroundColor: "#ffffff",
              borderRadius: "8px",
              padding: "16px",
              minHeight: "auto",
              marginTop: "20px",
            }}
          >
            <Grid container spacing={2} sx={{ display: "flex" }}>
              <Grid
                item
                xs={12}
                md={5}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box
                  sx={{
                    borderRadius: "12px",
                    border: "0.5px solid #B7C8E5",
                    background: "#FFF",
                    boxShadow: "0px 2.318px 4.636px 0px rgba(0, 0, 0, 0.16)",
                    padding: "30px",
                    flex: 1, // Ensure this box stretches to fill the height
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{ color: "#222", fontWeight: "700" }}
                  >
                    Questionnaire /{" "}
                    <span style={{ color: "#9D9D9D", fontWeight: "500" }}>
                      {" "}
                      H2S Hazard Control
                    </span>
                  </Typography>

                  <Grid container spacing={2}>
                    {gridItems.map((item, index) => (
                      <Grid item xs={2} sm={2} md={1.5} key={index}>
                        <Box
                          onClick={() => handleClick(index)}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "40px",
                            width: "40px",
                            padding: "12px",
                            borderRadius: "4px",
                            boxShadow:
                              "0px 2.318px 4.636px 0px rgba(0, 0, 0, 0.16)",
                            backgroundColor:
                              selectedIndex === index ? "#E7F6FD" : "#FFF", // Highlight selected
                            cursor: "pointer",
                            fontSize: "12px",

                            border:
                              selectedIndex === index
                                ? "1px solid #09A2E3"
                                : "none",
                            fontWeight: "600",
                            marginTop: "10px",
                          }}
                        >
                          <Typography
                            variant="body1"
                            sx={{
                              fontWeight: "600",
                              color:
                                selectedIndex === index ? "#09A2E3" : "#525966",
                            }} // Dynamic text color }}
                          >
                            {item}
                          </Typography>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: 3,
                    }}
                  >
                    {!showBothButtons ? (
                      // Initially display only the "Next" button

                      <>
                        <Button
                          variant="contained"
                          sx={{
                            width: "50%",
                            backgroundColor: "#09A2E3",
                            color: "#FFF",
                            padding: "8px",
                            width: "100%",
                            borderRadius: "24px",
                            marginTop: "10px",
                            "&:hover": {
                              backgroundColor: "#09A2E3",
                            },
                          }}
                          onClick={handleNext}
                          disabled={selectedIndex === null} // Disable if no div is selected
                        >
                          Next
                        </Button>
                      </>
                    ) : (
                      // Display "Next" and "Back" buttons
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          gap: "10px",
                          width: "100%",
                        }}
                      >
                        {!backview && (
                          <>
                            <Button
                              sx={{
                                width: "50%",
                                border: "1px solid #09A2E3",
                                color: "#09A2E3",
                                padding: "8px",
                                flex: 1,
                                borderRadius: "24px",
                                marginBottom: "10px",
                              }}
                              onClick={handleBackQuestionOpen}
                            >
                              Back
                            </Button>
                            <Button
                              variant="contained"
                              sx={{
                                backgroundColor: "#09A2E3",
                                color: "#FFF",
                                padding: "8px",
                                flex: 1,
                                borderRadius: "24px",
                                "&:hover": {
                                  backgroundColor: "#09A2E3",
                                },
                              }}
                              onClick={handleNextQuestionOpen}
                            >
                              Next
                            </Button>
                          </>
                        )}
                      </Box>
                    )}
                  </Box>
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                md={7}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box
                  sx={{
                    borderRadius: "12px",
                    border: "0.5px solid #B7C8E5",
                    background: "#FFF",
                    boxShadow: "0px 2.318px 4.636px 0px rgba(0, 0, 0, 0.16)",
                    padding: "30px",
                    flex: 1, // Ensure this box stretches to fill the height
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "16px",
                    }}
                  >
                    <Typography variant="h6" sx={{ fontWeight: "600" }}>
                      PSA - 007
                    </Typography>
                    <Typography variant="body1" sx={{ fontWeight: "500" }}>
                      0/1
                    </Typography>
                  </Box>

                  <Box>
                    <Typography
                      sx={{
                        color: "#6E6E6E",
                        lineHeight: 1.5,
                        whiteSpace: "pre-line",
                        marginBottom: "20px",
                      }}
                    >
                      Lorem ipsum dolor sit amet, consectetur adipiscing
                      elit.Lorem ipsum dolor sit amet, consectetur adipiscing
                      elit.Lorem ipsum dolor sit amet, consectetur adipiscing
                      elit.Lorem ipsum dolor sit amet.
                    </Typography>
                  </Box>

                  <Box>
                    {/* Text Field */}
                    <TextField
                      placeholder="Response"
                      multiline
                      variant="standard"
                      InputProps={{
                        disableUnderline: true, // Removes the default underline
                      }}
                      sx={{
                        flex: 1,
                        padding: "1px",
                        color: "#667085",
                        borderRadius: "50px",
                        boxShadow:
                          "0px 1.446px 2.892px 0px rgba(16, 24, 40, 0.05)",
                        border: "1.446px solid #D0D5DD",
                        background: "#FFF",
                        width: "100%",
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      marginTop: "50px",
                    }}
                  >
                    <Button
                      variant="contained"
                      sx={{
                        width: "30%",
                        backgroundColor: "#09A2E3",
                        color: "#FFF",
                        padding: "8px 24px",
                        borderRadius: "24px",
                        "&:hover": {
                          backgroundColor: "#007bb2",
                        },
                      }}
                    >
                      Start Audit
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            {/* {selectedTab === 0 && <Typography variant="h6">Hello</Typography>}
        {selectedTab === 1 && (
          <Typography variant="h6">Welcome to Section 2</Typography>
        )}
        {selectedTab === 2 && (
          <Typography variant="h6">This is Section 3</Typography>
        )} */}
          </Box>
        </>
      )}

      {questionnaireSection && (
        <>
          <Box
            sx={{
              backgroundColor: "#ffffff",
              borderRadius: "8px",
              padding: "16px",
              minHeight: "auto",
              marginTop: "20px",
            }}
          >
            <Box>
              <Grid
                container
                spacing={2}
                sx={{ display: "flex", alignItems: "stretch" }}
              >
                <Grid
                  item
                  xs={12}
                  md={7}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Box
                    sx={{
                      borderRadius: "12px",
                      border: "0.5px solid #B7C8E5",
                      background: "#FFF",
                      boxShadow: "0px 2.318px 4.636px 0px rgba(0, 0, 0, 0.16)",
                      padding: "30px",
                      flex: 1, // Ensure this box stretches to fill the height
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: "500",
                        fontSize: "16px",
                        color: "#222",
                      }}
                    >
                      Questionnaire{" "}
                      <span
                        sx={{
                          fontWeight: "500",
                          fontSize: "16px",
                          color: "#9D9D9D",
                        }}
                      >
                        / H2S Hazard Control
                      </span>
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginBottom: "16px",
                      }}
                    >
                      <Typography variant="h6" sx={{ fontWeight: "600" }}>
                        PSA - 007
                      </Typography>
                      <Typography variant="body1" sx={{ fontWeight: "500" }}>
                        0/1
                      </Typography>
                    </Box>

                    {questionIndex === 0 && (
                      <>
                        <Box>
                          <Typography
                            sx={{
                              color: "#6E6E6E",
                              lineHeight: 1.5,
                              whiteSpace: "pre-line",
                              marginBottom: "10px",
                            }}
                          >
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Lorem ipsum dolor sit amet, consectetur
                            adipiscing elit.
                          </Typography>
                        </Box>
                        <Box>
                          <TextField
                            placeholder="Response"
                            multiline
                            variant="standard"
                            InputProps={{
                              disableUnderline: true,
                            }}
                            sx={{
                              flex: 1,
                              padding: "1px",
                              color: "#667085",
                              borderRadius: "50px",
                              boxShadow:
                                "0px 1.446px 2.892px 0px rgba(16, 24, 40, 0.05)",
                              border: "1.446px solid #D0D5DD",
                              background: "#FFF",
                              width: "100%",
                            }}
                          />
                        </Box>
                      </>
                    )}

                    {questionIndex === 1 && (
                      <>
                        <Typography
                          variant="body1"
                          sx={{
                            color: "#6E6E6E",
                            lineHeight: 1.5,
                            whiteSpace: "pre-line",
                            marginBottom: "10px",
                          }}
                        >
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Lorem ipsum dolor sit amet.
                        </Typography>

                        {/* Yes/No Options */}
                        <RadioGroup
                          aria-labelledby="yes-no-options"
                          name="yesNoOptions"
                          sx={{ marginBottom: "20px" }}
                        >
                          <FormControlLabel
                            value="yes"
                            control={<Radio />}
                            label="Yes"
                            onClick={() => setComplianceStatus("Compliant")}
                          />
                          <FormControlLabel
                            value="no"
                            control={<Radio />}
                            label="No"
                            onClick={() => setComplianceStatus("Non-Compliant")}
                          />
                        </RadioGroup>
                      </>
                    )}

                    {questionIndex === 2 && (
                      <>
                        <Typography
                          variant="body1"
                          sx={{
                            color: "#6E6E6E",
                            lineHeight: 1.5,
                            whiteSpace: "pre-line",
                            marginBottom: "10px",
                          }}
                        >
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit.Lorem ipsum dolor sit amet, consectetur
                          adipiscing elit.Lorem ipsum dolor sit amet,
                          consectetur adipiscing elit.Lorem ipsum dolor sit
                          amet.
                        </Typography>

                        {/* Compliance Options */}
                        <Box
                          display="flex"
                          flexDirection="column"
                          alignItems="center"
                        >
                          <Box
                            display="flex"
                            justifyContent="center"
                            mt={2}
                            sx={{
                              borderRadius: "32px",
                              border: "0.43px solid #B7C8E5",
                              padding: "8px",
                              width: "100%",
                              gap: "2",
                            }}
                          >
                            <Button
                              sx={{
                                background: "#EFF6F9",
                                borderRadius: "20px",
                                color: "#09A2E3",
                                fontSize: "14px",
                                fontWeight: "600",
                              }}
                              onClick={() => console.log("Compliant Selected")}
                            >
                              Compliant
                            </Button>
                            <Button
                              sx={{
                                background: "#EFF6F9",
                                borderRadius: "20px",
                                color: "#09A2E3",
                                fontSize: "14px",
                                fontWeight: "600",
                              }}
                              onClick={() =>
                                console.log("Non-Compliant Selected")
                              }
                            >
                              Non-Compliant
                            </Button>
                            <Button
                              sx={{
                                background: "#EFF6F9",
                                borderRadius: "20px",
                                color: "#09A2E3",
                                fontSize: "14px",
                                fontWeight: "600",
                              }}
                              onClick={() =>
                                console.log("Not Applicable Selected")
                              }
                            >
                              Not Applicable
                            </Button>
                            <Button
                              sx={{
                                background: "#EFF6F9",
                                borderRadius: "20px",
                                color: "#09A2E3",
                                fontSize: "14px",
                                fontWeight: "600",
                              }}
                              onClick={() =>
                                console.log("Partially Compliant Selected")
                              }
                            >
                              Partially Compliant
                            </Button>
                          </Box>
                        </Box>
                      </>
                    )}

                    {questionIndex === 3 && (
                      <>
                        <Typography
                          variant="body1"
                          sx={{
                            color: "#6E6E6E",
                            lineHeight: 1.5,
                            whiteSpace: "pre-line",
                            marginBottom: "10px",
                          }}
                        >
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit.Lorem ipsum dolor sit amet, consectetur
                          adipiscing elit.Lorem ipsum dolor sit amet,
                          consectetur adipiscing elit.Lorem ipsum dolor sit
                          amet.
                        </Typography>

                        {/* Compliance Options */}
                        <Box>
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            mb={1}
                            sx={{
                              border: "1.446px solid  #D0D5DD",
                              background: "#FFF",
                              boxShadow:
                                "0px 1.446px 2.892px 0px rgba(16, 24, 40, 0.05)",
                              borderRadius: "24px",
                              padding: "12px 16px",
                              color: "#667085",
                              fontSize: "12px",
                            }}
                          >
                            <Typography variant="h6">Response</Typography>
                            <IconButton onClick={handleExpandClick}>
                              <ExpandMoreIcon />
                            </IconButton>
                          </Box>
                          <Collapse in={expanded} timeout="auto" unmountOnExit>
                            <Typography>hhhh</Typography>
                          </Collapse>
                        </Box>
                      </>
                    )}

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                      }}
                    >
                      {/* Icons */}
                      <IconButton onClick={handleOpen}>
                        <FlagIcon
                          sx={{ color: isClicked ? "#09A2E3" : "#000" }}
                        />
                      </IconButton>
                      <IconButton onClick={handleCommentOpen}>
                        <ChatBubbleIcon
                          sx={{ color: isCommentClicked ? "#09A2E3" : "#000" }}
                        />
                      </IconButton>
                      <IconButton onClick={handleAttachmentOpen}>
                        <AttachFileIcon
                          sx={{
                            color: isAttachmentOpenClicked ? "#09A2E3" : "#000",
                          }}
                        />
                      </IconButton>

                      {/* Drawers */}
                      <AddActionDrawer
                        anchor="right"
                        open={drawerOpen}
                        onClose={handleClose}
                      />

                      <CommentDrawer
                        anchor="right"
                        open={drawerCommentOpen}
                        onClose={handleCommentClose}
                      />

                      <AttachmentsDrawer
                        anchor="right"
                        open={drawerAttachmentOpen} // Fixed: use the state
                        onClose={handleAttachmentClose}
                      />
                    </Box>

                    <Box
                      sx={{
                        // display: "flex",
                        // justifyContent: "flex-end",
                        marginTop: "50px",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          marginTop: "20px",
                          width: "100%",
                        }}
                      >
                        {!showButtons ? (
                          <Button
                            variant="contained"
                            sx={{
                              width: "100%",
                              backgroundColor: "#09A2E3",
                              color: "#FFF",
                              padding: "8px 24px",
                              borderRadius: "24px",
                            }}
                            onClick={handleNextClick} // On click, show the three buttons
                          >
                            Next
                          </Button>
                        ) : (
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              gap: "16px", // Space between buttons
                              width: "100%",
                            }}
                          >
                            {/* Back Button */}
                            <Button
                              variant="outlined"
                              sx={{
                                width: "35%",
                                border: "2px solid #09A2E3",
                                color: "#09A2E3",
                                padding: "8px 16px",
                                borderRadius: "24px",
                                textTransform: "none", // Disable uppercase text
                                fontWeight: "600",
                                "&:hover": {
                                  backgroundColor: "#E7F6FD",
                                  borderColor: "#007bb2",
                                },
                              }}
                              onClick={handleBackClick} // On click, hide the three buttons
                            >
                              Back
                            </Button>

                            {/* Save Button */}
                            <Button
                              variant="outlined"
                              sx={{
                                width: "35%",
                                border: "2px solid #09A2E3",
                                color: "#09A2E3",
                                padding: "8px 16px",
                                borderRadius: "24px",
                                textTransform: "none", // Disable uppercase text
                                fontWeight: "600",
                                "&:hover": {
                                  backgroundColor: "#E7F6FD",
                                  borderColor: "#007bb2",
                                },
                              }}
                              onClick={() => alert("Save clicked")}
                            >
                              Save
                            </Button>

                            {/* Next Button */}
                            <Button
                              variant="contained"
                              sx={{
                                width: "25%",
                                backgroundColor: "#09A2E3",
                                color: "#FFF",
                                padding: "8px 16px",
                                borderRadius: "24px",
                                textTransform: "none", // Disable uppercase text
                                fontWeight: "600",
                                "&:hover": {
                                  backgroundColor: "#007bb2",
                                },
                              }}
                              onClick={handleNextQuestion}
                            >
                              Next
                            </Button>
                          </Box>
                        )}
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={5}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Box
                    sx={{
                      borderRadius: "12px",
                      border: "0.5px solid #B7C8E5",
                      background: "#FFF",
                      boxShadow: "0px 2.318px 4.636px 0px rgba(0, 0, 0, 0.16)",
                      padding: "30px",
                      flex: 1, // Ensure this box stretches to fill the height
                    }}
                  >
                    <Grid container spacing={2}>
                      {gridItems.map((item, index) => (
                        <Grid item xs={2} sm={2} md={1.5} key={index}>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              height: "40px",
                              width: "40px",
                              padding: "12px",
                              borderRadius: "8px",
                              boxShadow:
                                "0px 2.318px 4.636px rgba(0, 0, 0, 0.16)",
                              backgroundColor:
                                selectedIndex === index ? "#E5F6FF" : "#FFF",
                              cursor: "pointer",
                              fontSize: "12px",
                              color: "#525966",
                              fontWeight: "600",
                              marginTop: "10px",
                              "&:hover": {
                                backgroundColor: "#F1F4F8",
                              },
                            }}
                            onClick={() => setSelectedIndex(index)}
                          >
                            <Typography
                              variant="body1"
                              sx={{ fontWeight: "600" }}
                            >
                              {selectedIndex === index ? "01" : item}
                            </Typography>
                          </Box>
                        </Grid>
                      ))}
                    </Grid>

                    {/* Pie Chart Section */}
                    <Box
                      sx={{
                        marginTop: "30px",
                        display: "flex",
                        alignItems: "center",
                        width: "411px",
                        borderRadius: "8px",
                        border: "0.394px solid #B7C8E5",
                        background: "#FFF",
                        padding: "10px",
                      }}
                    >
                      <Box sx={{ flex: 1 }}>
                        <Typography
                          variant="h6"
                          sx={{
                            fontWeight: "500",
                            marginBottom: "10px",
                            fontSize: "12px",
                            color: "#222",
                          }}
                        >
                          H2S Hazard Control
                        </Typography>
                        <Box>
                          {data?.map((item, index) => (
                            <AttemptedBox
                              key={index}
                              color={item.color}
                              label={item.label}
                              count={item.count}
                            />
                          ))}
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: "16px",
                              justifyContent: "space-between",
                              background: "#E6F6FC",
                              padding: "10px",
                              borderRadius: "12px",
                              marginTop: "10px",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: "8px",
                              }}
                            >
                              <Typography>Total Project</Typography>
                            </Box>
                            <Typography sx={{ fontWeight: "bold" }}>
                              60
                            </Typography>
                          </Box>
                        </Box>
                      </Box>

                      <Divider
                        orientation="vertical"
                        flexItem
                        sx={{ margin: "0 20px" }}
                      />

                      <Box sx={{ width: "150px", height: "150px" }}>
                        <Doughnut data={chartData} options={chartOptions} />
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: 3,
                      }}
                    ></Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

export default QuestionnairSection;
