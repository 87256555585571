import React, { useState } from "react";
import {
  Drawer,
  Grid,
  Typography,
  Button,
  Fab,
  Box,
  IconButton,
  TextField,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import CloseIcon from "@mui/icons-material/Close";
import UploadIcon from "@mui/icons-material/Upload";
import CustomInput from "../CustomInputField";
import { useNavigate } from "react-router-dom";
import {CAPAAcceptAndReturn} from "../../Apis/apiCall";
import {fileToBase64} from "../../utils/helper";

export default function CAPADrawer({ open, onClose, title,capaId }) {
  const navigate = useNavigate();
  // Unified state for managing all inputs
  const [formData, setFormData] = useState({
    selectedDate: dayjs(),
    actionTaken: "",
    files: [],
  });

  // Handle changes for date picker
  const handleDateChange = (newDate) => {
    setFormData((prev) => ({ ...prev, selectedDate: newDate }));
  };

  // Handle changes for text fields
  const handleInputChange = (field, value) => {
    setFormData((prev) => ({ ...prev, [field]: value }));
  };

  // Handle file upload
  const handleFileUpload = (event) => {
    const newFiles = Array.from(event.target.files);
    setFormData((prev) => ({ ...prev, files: [...prev.files, ...newFiles] }));
  };

  // Handle file deletion
  const handleDeleteFile = (index) => {
    setFormData((prev) => ({
      ...prev,
      files: prev.files.filter((_, i) => i !== index),
    }));
  };

  

const handleCAPAAccept = async () => {
  // Convert files to the required evidence format
  const evidencePromises = formData.files.map(async (file) => {
    const base64 = await fileToBase64(file);
    return {
      base64: base64,
      name: file.name,
      type: file.type,
    };
  });

  // Wait for all file conversions to complete
  const evidence = await Promise.all(evidencePromises);

  let data = {
    action: "completed",
    completionDate: formData.selectedDate,
    actionTaken: formData.actionTaken,
    evidence: evidence,
  };

  const res = await returnCAPA(data, capaId);
  if (res === 200) {
    setFormData({});
    navigate("/my-actions");
  }
};

  const returnCAPA = async (data,id) => {
    try {
      const result = await CAPAAcceptAndReturn(data, id);
      return result.data.status_code
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      PaperProps={{ sx: { width: "36%", padding: "0px !important" } }}
    >
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        sx={{
          paddingY: "16px",
          paddingX: "24px",
          borderBottom: "1px solid rgba(171, 171, 171, 0.50)",
          boxShadow: "0px 2px 4px 0px #ABABAB",
        }}
      >
        <Typography sx={{ fontSize: "16px", fontWeight: "500" }}>
          CAPA Completion Details
        </Typography>
      </Grid>

      <div style={{ padding: "12px 16px" }}>
        <Box
          display="flex"
          flexDirection="column"
          gap={2}
          my={2}
          p={3}
          sx={{ boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.10)" }}
          borderRadius={4}
        >
          {/* Date Picker */}
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Completion Date"
              value={formData.selectedDate}
              onChange={handleDateChange}
              slotProps={{ textField: { fullWidth: true } }}
              format="DD-MM-YYYY"
            />
          </LocalizationProvider>

          {/* Action Taken Input */}
          <CustomInput
            label="Action Taken"
            multiline
            rows={4}
            type="text"
            value={formData.actionTaken}
            onChange={(e) => handleInputChange("actionTaken", e.target.value)}
          />

          {/* File Upload */}
          <Box display="flex" flexDirection="column" gap={0}>
            <Typography gutterBottom sx={{ fontSize: "14px", fontWeight: "600" }}>
              Completion Proof
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                border: "1px dashed #09A2E3",
                padding: 3,
                borderRadius: 1,
                textAlign: "center",
              }}
            >
              <IconButton component="label">
                <UploadIcon fontSize="large" sx={{ color: "#000000" }} />
                <input
                  hidden
                  accept="image/*,.doc,.pdf,.mp4"
                  type="file"
                  onChange={handleFileUpload}
                  multiple
                />
              </IconButton>
              <Typography
                variant="caption"
                sx={{
                  mt: 1,
                  color: "#348BD2",
                  fontWeight: "600",
                  textDecoration: "underline",
                }}
              >
                Click to upload
              </Typography>
              <Typography variant="caption" sx={{ fontWeight: "600" }}>
                PNG, JPG, Docx, PDF, and MP4 (max. 10 MB)
              </Typography>
            </Box>
          </Box>

          {/* File Preview */}
          <Box mt={2}>
            <Grid container spacing={2}>
              {formData?.files?.map((file, index) => {
                const fileType = file.type;

                return (
                  <Grid item xs={4} key={index} sx={{ position: "relative" }}>
                    {/* Delete Button (Cross Icon) */}
                    <IconButton
                      onClick={() => handleDeleteFile(index)}
                      size="small"
                      sx={{
                        position: "absolute",
                        top: 4,
                        right: 4,
                        backgroundColor: "rgba(255, 255, 255, 0.7)",
                        zIndex: 1,
                      }}
                    >
                      <CloseIcon fontSize="small" />
                    </IconButton>

                    {/* File Preview */}
                    <Box
                      sx={{
                        width: "100%",
                        height: 100,
                        border: "1px solid #ddd",
                        borderRadius: "4px",
                        overflow: "hidden",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "relative",
                        backgroundColor: "#f5f5f5",
                      }}
                    >
                      {/* Render Image Preview */}
                      {fileType.startsWith("image/") && (
                        <img
                          src={URL.createObjectURL(file)}
                          alt={`preview-${index}`}
                          style={{ width: "100%", height: "100%", objectFit: "cover" }}
                        />
                      )}

                      {/* Render Video Preview */}
                      {fileType.startsWith("video/") && (
                        <video
                          controls
                          style={{ width: "100%", height: "100%", objectFit: "cover" }}
                        >
                          <source src={URL.createObjectURL(file)} type={fileType} />
                          Your browser does not support the video tag.
                        </video>
                      )}

                      {/* Render PDF Placeholder */}
                      {fileType === "application/pdf" && (
                        <Box
                          sx={{
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: "#e0e0e0",
                          }}
                        >
                          <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                            PDF File
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
          </Box>

        </Box>

        <Button
          variant="contained"
          disabled={!formData.actionTaken}
          sx={{ float: "right", color: "#ffffff", borderRadius: "50px", height: "32px" }}
          onClick={handleCAPAAccept}
        >
          CAPA Completed
        </Button>
      </div>

      <Fab
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "fixed",
          top: "100px",
          right: open ? "37.3%" : "0",
          transform: "translateX(50%)",
          zIndex: 1200,
          height: "36px",
          width: "36px",
          backgroundColor: "#0AA2E3",
          borderRadius: "60px 0 0 60px",
        }}
      >
        <CloseIcon />
      </Fab>
    </Drawer>
  );
}
