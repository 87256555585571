import { SET_ATTACHMENT_DATA, SET_COMMENTS_DATA } from "./constant";


export const setAttachmentData = (data) => {
  return {
    type: SET_ATTACHMENT_DATA,
    payload: data,
  };
};

export const setCommentsData = (comment) => {
  return {
    type: SET_COMMENTS_DATA,
    payload: comment,
  };
};
