export const setLocalStorageData = (data) =>{
    //const data = JSON.parse(value);
    console.log("hello", data);
    if (data) {  
        const workflowRoles = data?.config?.permissionDetails?.workflowRoles || [];
        const incidentRoles = workflowRoles.find((workflowRole) => workflowRole.moduleName === "Incident Management")?.roles?.map(role => role.name) || [];
        const lossControlRoles = workflowRoles.find((workflowRole) => workflowRole.moduleName === "Loss Control")?.roles?.map(role => role.name) || [];
        const incidentRole = JSON.stringify(incidentRoles);
        const lossRole = JSON.stringify(lossControlRoles);
        localStorage.setItem("IncidentRoles", incidentRole);
        localStorage.setItem("lossControlRoles", lossRole);
    }
  }  // Runs only once on initial mount
