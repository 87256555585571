

export const incidentClassificationFormFields=[
  {
    formFieldID: 30,
    formFieldName: "DGMS",
    dataType: "String",
    type: "Dropdown",
    isEditable: 0,
    options: [
      "Minor (Medicine & Discharge)",
      "Reportable",
      "Serious",
      "Fatal",
      "Dangerous Occurrence",
      "Not Applicable"
    ],
  },
  {
    formFieldID: 30,
    formFieldName: "Factories Act",
    dataType: "String",
    type: "Dropdown",
    isEditable: 0,
    options: [
      "Minor (Medicine & Discharge)",
      "Reportable",
      "Serious",
      "Fatal",
      "Dangerous Occurrence",
      "Not Applicable"
    ],
  },
  {
    formFieldID: 30,
    formFieldName: "OISD",
    dataType: "String",
    type: "Dropdown",
    isEditable: 0,
    options: [
      "Minor",
      "Major",
      "Not Applicable",
    ],
  },
  {
    formFieldID: 38,
    formFieldName: "OISD Fire",
    dataType: "boolean",
    type: "checkBox",
    isEditable: 0,
    options: null,
  },
 
  {
    formFieldID: 30,
    formFieldName: "OISD Major Fire",
    dataType: "String",
    type: "Dropdown",
    isEditable: 0,
    options: [
      "Company Installation",
      "Company Office Area",
      "Company Domestic Area",
      "Public Fire"
    ],
  },
  

  {
    formFieldID: 30,
    formFieldName: "PNGRB",
    dataType: "String",
    type: "Dropdown",
    isEditable: 0,
    options: [
      "Minor",
      "Major",
      "Not Applicable",
    ],
  },
  {
    formFieldID: 38,
    formFieldName: "PNGRB Fire",
    dataType: "boolean",
    type: "checkBox",
    isEditable: 0,
    options: null,
  },
  {
    formFieldID: 30,
    formFieldName: "PNGRB Major Fire",
    dataType: "String",
    type: "Dropdown",
    isEditable: 0,
    options: [
      "Company Installation",
      "Company Office Area",
      "Company Domestic Area",
      "Public Fire"
    ],
  },
  

]


export const firstReviwerNextDrawerFields = [
  {
    formFieldID: 29,
    formFieldName: "Severity",
    dataType: "Number",
    type: "ButtonGroup",
    isEditable: 0,
    options: ["1", "2", "3", "4", "5", "NA"],
  },
  {
    formFieldID: 30,
    formFieldName: "Incident Type",
    dataType: "String",
    type: "ButtonGroup",
    isEditable: 0,
    options: ["UA", "UC", "NM", "Minor", "MR", "SI", "DO", "Fatal"],
  },
  {
    formFieldID: 36,
    formFieldName: "Review Date",
    dataType: "Date",
    type: "Date",
    isEditable: 0,
    options: null,
  },
  
 
  

  {
    formFieldID: 37,
    formFieldName: "Review Input",
    dataType: "String",
    type: "TextArea",
    isEditable: 0,
    options: null,
  },
  {
    formFieldID: 38,
    formFieldName: "High Potential Near Miss",
    dataType: "boolean",
    type: "toggleSwitch",
    isEditable: 0,
    options: null,
  },
  {
    formFieldID: 38,
    formFieldName: "Investigation Recommended",
    dataType: "boolean",
    type: "toggleSwitch",
    isEditable: 0,
    options: null,
  },
  {
    formFieldID: 38,
    formFieldName: "Review completed",
    dataType: "boolean",
    type: "toggleSwitch",
    isEditable: 0,
    options: null,
  },
];

export const firstReviwerInvestionReviewNextDrawerFields = [
  {
    formFieldID: 29,
    formFieldName: "Severity",
    dataType: "Number",
    type: "ButtonGroup",
    isEditable: 0,
    options: ["1", "2", "3", "4", "5", "NA"],
  },
  {
    formFieldID: 30,
    formFieldName: "Incident Type",
    dataType: "String",
    type: "ButtonGroup",
    isEditable: 0,
    options: ["UA", "UC", "NM", "Minor", "MR", "SI", "DO", "Fatal"],
  },
  {
    formFieldID: 36,
    formFieldName: "Review Date",
    dataType: "Date",
    type: "Date",
    isEditable: 0,
    options: null,
  },

  {
    formFieldID: 37,
    formFieldName: "Review Input",
    dataType: "String",
    type: "TextArea",
    isEditable: 0,
    options: null,
  },
  {
    formFieldID: 39,
    formFieldName: "Input On Severity and Incident Type",
    dataType: "String",
    type: "TextArea",
    isEditable: 0,
    options: null,
  },
 
  {
    formFieldID: 38,
    formFieldName: "Investigation Reviewed",
    dataType: "boolean",
    type: "toggleSwitch",
    isEditable: 0,
    options: null,
  },
];



export const secondReviwerInvestionReviewNextDrawerFields =
  [
    {
      formFieldID: 29,
      formFieldName: "Severity",
      dataType: "Number",
      type: "ButtonGroup",
      isEditable: 0,
      reported: "2",
      options: ["1", "2", "3", "4", "5"],
    },
    {
      formFieldID: 29,
      formFieldName: "Final Severity",
      dataType: "Number",
      type: "ButtonGroup",
      isEditable: 0,
      reported: "2",
      options: ["1", "2", "3", "4", "5"],
    },
    {
      formFieldID: 30,
      formFieldName: "Incident Type",
      dataType: "String",
      type: "ButtonGroup",
      isEditable: 0,
      reported: "Fatal",
      options: ["UA", "UC", "NM", "Minor", "MR", "DO", "SI", "Fatal"],
    },
    {
      formFieldID: 30,
      formFieldName: "Final Incident Type",
      dataType: "String",
      type: "ButtonGroup",
      isEditable: 0,
      reported: "Fatal",
      options: ["UA", "UC", "NM", "Minor", "MR", "DO", "SI", "Fatal"],
    },

    {
      formFieldID: 36,
      formFieldName: "Review Date",
      dataType: "Date",
      type: "Date",
      isEditable: 0,
      options: null,
    },
   
    {
      formFieldID: 37,
      formFieldName: "Review Input",
      dataType: "String",
      type: "TextArea",
      isEditable: 0,
      options: null,
    },
    {
      formFieldID: 39,
      formFieldName: "Input On Severity and Incident Type",
      dataType: "String",
      type: "TextArea",
      isEditable: 0,
      options: null,
    },
    {
      formFieldID: 38,
      formFieldName: "Approved Investigation",
      dataType: "boolean",
      type: "toggleSwitch",
      isEditable: 0,
      options: null,
    },
    {
      formFieldID: 39,
      formFieldName: "Initiate CAPA",
      dataType: "boolean",
      type: "toggleSwitch",
      isEditable: 0,
      options: null,
    },

  ];



export const firstReviwerReturnDrawerFields = [
  {
    formFieldID: 36,
    formFieldName: "Review Date",
    dataType: "Date",
    type: "Date",
    isEditable: 0,
    options: null,
  },
  {
    formFieldID: 37,
    formFieldName: "Review Input",
    dataType: "String",
    type: "TextArea",
    isEditable: 0,
    options: null,
  },
  {
    formFieldID: 40,
    formFieldName: "Additional Remarks",
    dataType: "String",
    type: "TextArea",
    isEditable: 0,
    options: null,
  },
  {
    formFieldID: 38,
    formFieldName: "Return to previous level",
    dataType: "boolean",
    type: "toggleSwitch",
    isEditable: 0,
    options: null,
  },
];


export const firstReviwerReturnInvestionReviewDrawerFields =
  [
    {
      formFieldID: 29,
      formFieldName: "Severity",
      dataType: "Number",
      type: "ButtonGroup",
      isEditable: 0,
      reported: "2",
      options: ["1", "2", "3", "4", "5"],
    },
    {
      formFieldID: 30,
      formFieldName: "Incident Type",
      dataType: "String",
      type: "ButtonGroup",
      isEditable: 0,
      reported: "Fatal",
      options: ["UA", "UC", "NM", "Minor", "MR", "DO", "SI", "Fatal"],
    },
    {
      formFieldID: 36,
      formFieldName: "Review Date",
      dataType: "Date",
      type: "Date",
      isEditable: 0,
      options: null,
    },

    {
      formFieldID: 37,
      formFieldName: "Review Input",
      dataType: "String",
      type: "TextArea",
      isEditable: 0,
      options: null,
    },

    {
      formFieldID: 38,
      formFieldName: "Return to previous level",
      dataType: "boolean",
      type: "toggleSwitch",
      isEditable: 0,
      options: null,
    },

  ];



export const assignInvestigationFormFields = [
  {
    formFieldID: 65,
    formFieldName: 'Investigation Leader',
    dataType: 'String',
    type: 'Dropdown',
    options: ['1', '2', '3'],
    isEditable: 0
  },
  {
    formFieldID: 65,
    formFieldName: 'Investigation Members',
    dataType: 'String',
    type: 'Dropdown',
    options: ['EMP1', 'EMP2', 'EMP3'],
    isEditable: 0
  },

  {
    formFieldID: 36,
    formFieldName: "Target completion date",
    dataType: "Date",
    type: "Date",
    isEditable: 0,
    options: null,
  },


];

export const assignCAPAFormFields = [
  {
    formFieldID: 59,
    formFieldName: 'CAPA Type',
    dataType: 'String',
    type: 'ButtonGroup',
    options: ['Corrective', 'Preventive'],
    isEditable: 0
  },
  {
    formFieldID: 63,
    formFieldName: 'CAPA Owner',
    dataType: 'String',
    type: 'Dropdown',
    isEditable: 0
  },


  {
    formFieldID: 64,
    formFieldName: 'CAPA Priority',
    dataType: 'String',
    type: 'Dropdown',
    options: ['High', 'Medium', 'Low'],
    isEditable: 0
  },
  {
    formFieldID: 65,
    formFieldName: 'Due Date',
    dataType: 'Date',
    type: 'Date',
    isEditable: 0
  },
  {
    formFieldID: 61,
    formFieldName: 'Area',
    dataType: 'String',
    type: 'Dropdown',
    options: ['Mine Working', 'Workshop', 'OB Dump', 'Stack Yard', 'Crusher & Screening Unit', 'ETP', 'Electrical Installation', 'Store', 'Office', 'Others', 'Drilling & Blasting', 'Exploration'],
    isEditable: 1
  },
  {
    formFieldID: 62,
    formFieldName: 'Sub Area',
    dataType: 'String',
    type: 'Dropdown',
    isEditable: 1
  },
  {
    formFieldID: 60,
    formFieldName: 'CAPA Description',
    dataType: 'String',
    type: 'TextArea',
    isEditable: 0
  },
];

export const mitigationFormFields = [
  {
    formFieldID: 1,
    formFieldName: 'Mitigation Measures',
    dataType: 'String',
    type: 'InputBox',
    isEditable: 0
  },
]

export const lessonsLearnedFields = [
  {
    formFieldID: 1,
    formFieldName: 'Lessons Learned',
    dataType: 'String',
    type: 'InputBox',
    isEditable: 0
  },
]

export const incidentResponsibilityfields = [
  {
    formFieldID: 1,
    formFieldName: 'Person Responsible',
    dataType: 'Number',
    type: 'InputBox',
    isEditable: 0
  },
  {
    formFieldID: 2,
    formFieldName: 'Designation Code',
    dataType: 'String',
    type: 'Dropdown',
    isEditable: 0
  },
  {
    formFieldID: 3,
    formFieldName: 'Designation',
    dataType: 'String',
    type: 'InputBox',
    isEditable: 0
  },
  {
    formFieldID: 4,
    formFieldName: 'Rules Contravened',
    dataType: 'String',
    type: 'InputBox',
    isEditable: 0
  },
  {
    formFieldID: 5,
    formFieldName: 'Nature of Contravention',
    dataType: 'String',
    type: 'InputBox',
    isEditable: 0
  },
  {
    formFieldID: 6,
    formFieldName: 'Action Taken',
    dataType: 'String',
    type: 'TextArea',
    isEditable: 0
  },

]


export const contributingFactorfields = [
  {
    formFieldID: 1,
    formFieldName: 'Contributing Factor',
    type: 'ButtonGroup',
    options: ['Environment', 'Man', 'Matrial', 'Method', 'Machine'],
    isEditable: 0
  },
  {
    formFieldID: 2,
    formFieldName: 'Contributing Factor',
    type: 'ButtonGroup',
    options: ['Environment', 'Man', 'Matrial', 'Method', 'Machine'],
    isEditable: 0
  },
]



export const incidentResponsibilityfield = [
  {
    formFieldID: 1,
    formFieldName: 'Person Responsible',
    dataType: 'String',
    type: 'InputBox',
    options: ['Environment', 'Man', 'Matrial', 'Method', 'Machine'],
    isEditable: 0
  },
  {
    formFieldID: 2,
    formFieldName: 'Designation Code',
    dataType: 'String',
    type: 'Dropdown',
    options: ['Environment', 'Man', 'Matrial', 'Method', 'Machine'],
    isEditable: 0
  },

  {
    formFieldID: 3,
    formFieldName: 'Designation',
    dataType: 'String',
    type: 'Dropdown',
    isEditable: 0
  },
  {
    formFieldID: 4,
    formFieldName: 'Rules contravened',
    dataType: 'String',
    type: 'InputBox',
    isEditable: 0
  },
  {
    formFieldID: 5,
    formFieldName: 'Nature of contravention',
    dataType: 'String',
    type: 'InputBox',
    isEditable: 0
  },
  {
    formFieldID: 6,
    formFieldName: 'Action taken',
    dataType: 'String',
    type: 'InputBox',
    isEditable: 0
  }
]


export const witnessStatementField = [
  {
    formFieldID: 1,
    formFieldName: 'Staff Type',
    dataType: 'String',
    type: 'ButtonGroup',
    options: ['Employee', 'Contractor', 'Visitor'],
    isEditable: 0
  },
  {
    formFieldID: 2,
    formFieldName: 'Employee Type',
    dataType: 'String',
    type: 'ButtonGroup',
    options: ['Workplace', 'Executive'],
    isEditable: 0
  },

  {
    formFieldID: 3,
    formFieldName: 'Date of Statement',
    dataType: 'Date',
    type: 'Date',
    isEditable: 0
  },
  {
    formFieldID: 4,
    formFieldName: 'Witness Name',
    dataType: 'String',
    type: 'InputBox',
    isEditable: 0
  },
  {
    formFieldID: 1,
    formFieldName: 'Category',
    dataType: 'String',
    type: 'ButtonGroup',
    options: ['Photo', 'Video', 'Document'],
    isEditable: 0
  },
  {
    formFieldID: 5,
    formFieldName: 'Description',
    dataType: 'String',
    type: 'InputBox',
    isEditable: 0
  },

]


export const assignInvestigationCAPAFormFields = [
  {
    formFieldID: 1,
    formFieldName: 'CAPA Type',
    dataType: 'String',
    type: 'ButtonGroup',
    options: ['Corrective', 'Preventive'],
    isEditable: 0
  },
  {
    formFieldID: 2,
    formFieldName: 'CAPA Owner',
    dataType: 'String',
    type: 'Dropdown',
    isEditable: 0
  },

  {
    formFieldID: 3,
    formFieldName: 'Area',
    dataType: 'String',
    type: 'Dropdown',
    options: ['Mine Working', 'Workshop', 'OB Dump', 'Stack Yard', 'Crusher & Screening Unit', 'ETP', 'Electrical Installation', 'Store', 'Office', 'Others', 'Drilling & Blasting', 'Exploration'],
    isEditable: 1
  },
  {
    formFieldID: 4,
    formFieldName: 'Sub Area',
    dataType: 'String',
    type: 'Dropdown',
    isEditable: 1
  },
  {
    formFieldID: 60,
    formFieldName: 'CAPA Description',
    dataType: 'String',
    type: 'TextArea',
    isEditable: 0
  },
];



export const workFlowInputDrawerFields = [
  {
    formFieldID: 29,
    formFieldName: "Severity",
    dataType: "Number",
    type: "ButtonGroup",
    isEditable: 0,
    options: ["1", "2", "3", "4", "5", "NA"],
  },
  {
    formFieldID: 30,
    formFieldName: "Incident Type",
    dataType: "String",
    type: "ButtonGroup",
    isEditable: 0,
    options: ["UA", "UC", "NM", "Minor", "MR", "SI", "DO", "Fatal"],
  },
  {
    formFieldID: 36,
    formFieldName: "Review Date",
    dataType: "Date",
    type: "Date",
    isEditable: 0,
    options: null,
  },
  
 

  {
    formFieldID: 37,
    formFieldName: "Review Input",
    dataType: "String",
    type: "TextArea",
    isEditable: 0,
    options: null,
  },

  {
    formFieldID: 38,
    formFieldName: "Investigation complete",
    dataType: "boolean",
    type: "toggleSwitch",
    isEditable: 0,
    options: null,
  },
];


export const regulatoryFormFields = [
  {
    formFieldID: 1,
    formFieldName: "State whether danger is still present",
    dataType: "String",
    type: "ButtonGroup",
    isEditable: 0,
    options: ["Yes", "No"],
  },
  {
    formFieldID: 2,
    formFieldName: 'Fatalities Count (Employee)',
    dataType: 'Number',
    type: 'InputBox',
    isEditable: 0,
    options: null,
  },
  {
    formFieldID: 3,
    formFieldName: "Injuries Count (Employee)",
    dataType: "Number",
    type: "InputBox",
    isEditable: 0,
    options: null,
  },
  {
    formFieldID: 4,
    formFieldName: "Fatalities Count (Contract personnel)",
    dataType: "Number",
    type: "InputBox",
    isEditable: 0,
    options: null,
  },
  {
    formFieldID: 5,
    formFieldName: "Injuries Count (Contract personnel)",
    dataType: "Number",
    type: "InputBox",
    isEditable: 0,
    options: null,
  },
  {
    formFieldID: 6,
    formFieldName: "No. of people affected by gas",
    dataType: "Number",
    type: "InputBox",
    isEditable: 0,
    options: null,
  },

  {
    formFieldID: 7,
    formFieldName: "Date of Immediate last Accident",
    dataType: "Date",
    type: "Date",
    isEditable: 0,
    options: null,
  },
  {
    formFieldID: 8,
    formFieldName: "Immediate last accident Type",
    dataType: "String",
    type: "InputBox",
    isEditable: 0,
    options: null,
  },

  {
    formFieldID: 9,
    formFieldName: "Incident Cause",
    dataType: "String",
    type: "InputBox",
    isEditable: 0,
    options: null,
  },


  {
    formFieldID: 10,
    formFieldName: "whether Plant shut down/ cause outrage of the facility?",
    dataType: "String",
    type: "ButtonGroup",
    isEditable: 0,
    options: ["Yes", "No"],
  },
  {
    formFieldID: 11,
    formFieldName: 'was the incident avoidable?',
    dataType: "String",
    type: "ButtonGroup",
    isEditable: 0,
    options: ["Yes", "No"],
  },
  {
    formFieldID: 12,
    formFieldName: "Reason for categorization",
    dataType: "String",
    type: "InputBox",
    isEditable: 0,
    options: null,
  },
  {
    formFieldID: 13,
    formFieldName: "loss of production (in tonnes)",
    dataType: "Number",
    type: "InputBox",
    isEditable: 0,
    options: null,
  },
  {
    formFieldID: 14,
    formFieldName: "loss of Manshifts (in hours)",
    dataType: "Number",
    type: "InputBox",
    isEditable: 0,
    options: null,
  },
  {
    formFieldID: 15,
    formFieldName: "Action taken to prevent similar accident",
    dataType: "String",
    type: "InputBox",
    isEditable: 0,
    options: null,
  },

  {
    formFieldID: 16,
    formFieldName: "Recommendation by ISO or any other",
    dataType: "String",
    type: "InputBox",
    isEditable: 0,
    options: null,
  },
  {
    formFieldID: 17,
    formFieldName: "How accident could have been avoided?",
    dataType: "String",
    type: "InputBox",
    isEditable: 0,
    options: null,
  },

  {
    formFieldID: 18,
    formFieldName: "Sector",
    dataType: "String",
    type: "InputBox",
    isEditable: 0,
    options: null,
  },
  {
    formFieldID: 19,
    formFieldName: "Whether same incident happen at same location in the past",
    dataType: "String",
    type: "TextArea",
    isEditable: 0,
    options: null,
  },
  {
    formFieldID: 20,
    formFieldName: "Whether internal investigation report to OISD",
    dataType: "String",
    type: "TextArea",
    isEditable: 0,
    options: null,
  },
  {
    formFieldID: 21,
    formFieldName: "Whether internal investigation report is submitted to PNGRB",
    dataType: "String",
    type: "TextArea",
    isEditable: 0,
    options: null,
  },
];


export const compentionsFormFields = [
  {
    formFieldID: 18,
    formFieldName: "Name",
    dataType: "String",
    type: "InputBox",
    isEditable: 0,
    options: null,
  },


  {
    formFieldID: 19,
    formFieldName: "Date Of Return To Duty",
    dataType: "Date",
    type: "Date",
    isEditable: 0,
    options: null,
  },

  {
    formFieldID: 20,
    formFieldName: "Return For Regular Job",
    dataType: "String",
    type: "Dropdown",
    isEditable: 0,
    options: ["Yes", "No"],
  },



  {
    formFieldID: 21,
    formFieldName: "Compensation Paid",
    dataType: "Number",
    type: "InputBox",
    isEditable: 0,
    options: null,
  },
  {
    formFieldID: 22,
    formFieldName: "Cause Of Death",
    dataType: "String",
    type: "Dropdown",
    isEditable: 0,
    options: null,
  },
  {
    formFieldID: 13,
    formFieldName: "Statutory Compensation Paid (Rs) ",
    dataType: "Number",
    type: "InputBox",
    isEditable: 0,
    options: null,
  },
  {
    formFieldID: 24,
    formFieldName: "Ex Gratia Paid",
    dataType: "Number",
    type: "InputBox",
    isEditable: 0,
    options: null,
  },
  {
    formFieldID: 25,
    formFieldName: "Other Benefits",
    dataType: "String",
    type: "InputBox",
    isEditable: 0,
    options: null,
  },
  {
    formFieldID: 26,
    formFieldName: "Job Done At The Time Of Accident",
    dataType: "String",
    type: "InputBox",
    isEditable: 0,
    options: null,
  },
  {
    formFieldID: 27,
    formFieldName: "Experinece At The Time Of Accident",
    dataType: "String",
    type: "InputBox",
    isEditable: 0,
    options: null,
  },
  {
    formFieldID: 28,
    formFieldName: "Total Experience",
    dataType: "Number",
    type: "InputBox",
    isEditable: 0,
    options: null,
  },
  {
    formFieldID: 29,
    formFieldName: "Experience Of Victim",
    dataType: "String",
    type: "InputBox",
    isEditable: 0,
    options: null,
  },
  {
    formFieldID: 18,
    formFieldName: "Hours At Work",
    dataType: "Number",
    type: "InputBox",
    isEditable: 0,
    options: null,
  },

  {
    formFieldID: 18,
    formFieldName: "Body Parts Gone Out Of Use",
    dataType: "String",
    type: 'Dropdown',
    options: ['handLeft', 'right left', 'nose'],
    isEditable: 0,
  },
  {
    formFieldID: 18,
    formFieldName: "Body Parts(Loss)",
    dataType: "String",
    type: 'Dropdown',
    options: ['handLeft', 'right left', 'nose'],
    isEditable: 0,
  },



  {
    formFieldID: 21,
    formFieldName: "Other Benefits Or Benefits To Dependents",
    dataType: "String",
    type: "InputBox",
    isEditable: 0,
    options: null,
  },
  {
    formFieldID: 18,
    formFieldName: "Date Of Refresher Training",
    dataType: "Date",
    type: "Date",
    isEditable: 0,
    options: null,
  },
  {
    formFieldID: 18,
    formFieldName: "Initial Vocational Training",
    dataType: "String",
    type: "ButtonGroup",
    isEditable: 0,
    options: ["Yes", "No"]
  },
  {
    formFieldID: 18,
    formFieldName: "Job Nature",
    dataType: "String",
    type: "ButtonGroup",
    isEditable: 0,
    options: ["Contractual", "Departmental"]
  },
  {
    formFieldID: 18,
    formFieldName: "Vocationally Trained",
    dataType: "String",
    type: "ButtonGroup",
    isEditable: 0,
    options: ["Yes", "No"]
  },
  {
    formFieldID: 18,
    formFieldName: "Permanent Disablement",
    dataType: "String",
    type: "ButtonGroup",
    isEditable: 0,
    options: ["Yes", "No"]
  },

]

export const homePageRoutingData = [
  {
    title: 'Incident Management',
    items: [
      { label: 'Report Incident', route: '/module/incident-management/report-incident' },
      { label: 'Past Incident', route: '/past-incident' },
      { label: 'View Actions', route: '/all-actions' },
      { label: 'Dashboard', route: '/module/incident-management' },
      { label: 'Report', route: '/report' },
    ],
  },
  {
    title: 'Loss Control',
    items: [
      { label: 'New Tour', route: '/module/loss-control/create-tour' },
      { label: 'Past Tours', route: '/module/loss-control/create-tour' },
      { label: 'View Actions', route: '/all-actions' },
      { label: 'Dashboard', route: '/module/loss-control' },
      { label: 'Report', route: '/report' },
    ],
  },
  {
    title: 'CAPA',
    items: [
      { label: 'Active CAPAs', route: '/active-capas' },
      { label: 'Past CAPAs', route: '/past-capas' },
      { label: 'Actions', route: '/all-actions' },
      { label: 'Dashboard', route: '/dashboard' },
      { label: 'Report', route: '/reports' },
    ],
  },
  {
    title: 'Investigation',
    items: [
      { label: 'Report Incident', route: '/report-incident' },
      { label: 'View Incident', route: '/view-incident' },
      { label: 'Incident', route: '/incident' },
      { label: 'View Incident', route: '/view-incident' },
      { label: 'All Reports', route: '/reports' },
    ],
  },
  // Add more card data as needed
];