import React, { useEffect } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import PrivateRoutes from "./utils/PrivateRoute";
import LoginPage from "./Pages/LoginPage";
import { ThemeProvider, CssBaseline } from "@mui/material";
import Theme from "./theme";
import ReportIncidentForm from "./Pages/ReportIncidentForm";
import MyActionPage from "./Pages/MyActionPage";
import LossControlPage from "./Pages/LossControlPage";
import MainLayout from "./Components/Layout/MainContent";
import NotFoundPage from "./Pages/NotFoundPage";
import IncidentView from "./Pages/IncidentView";
import Dashboard from "./Pages/LossControlDashboard";
import LossControlView from "./Pages/LossControlView";
import PastLossControl from "./Pages/PastLossControl";
import LossControlMapView from "./Pages/LossControlMap/LossControlMapView";
import ModulePage from "./Pages/ModulePage";
import IncidentDashboard from "./Pages/IncidentDashboard";
import AllActionPage from "./Pages/AllActionPage";
import AllViewTourPage from "./Pages/ViewAllTours";
import CalendarView from "./Pages/Calendar/CalendarView";
import HomePage from "./Pages/HomePage";
import HelpPage from "./Pages/HelpPage";
import ForgotPassword from "./Pages/ForgotPassword";
import ChangePassword from "./Pages/Profile/ChangePassword";
import ViewProfile from "./Pages/Profile/ProfilePage";
import ReportPage from "./Pages/ReportPage";
import TicketTable from "./Pages/Tickets/TicketTable";
import CreateTicket from "./Pages/Tickets/CreateTicket";
import HelpManual from "./Pages/HelpManual";
import LandingDashboard from "./Pages/LandingDashboard";
import Flashpage from "./Pages/Flashpage";
import ViewTicket from "./Pages/Tickets/ViewTicket";
import EditTicket from "./Pages/Tickets/EditTicket";
import CAPAView from "./Pages/CAPAView";
import AuditDashboard from "./Pages/Audit/AuditDashboard";
import AuditPerform from "./Pages/Audit/AuditPerform";
import AuditCreateDashboard from "./Pages/Audit/AuditCreateDashboard";
import AuditPastDetails from "./Pages/Audit/AuditPastDetails";
import ScheduleAudit from "./Pages/Audit/ScheduleAudit";
import NewAuditScheduleCreate from "./Pages/Audit/NewAuditScheduleCreate";
import AgentsList from "./Pages/Tickets/AgentsList";
import TicketDashboard from "./Pages/Tickets/TicketDashboard";
import AgentTicketTable from "./Pages/Tickets/AgentTicketTable";
import ConfigurationIndex from "./Pages/Configuration/ConfigurationIndex";

const App = () => {
  return (
    <ThemeProvider theme={Theme}>
      <CssBaseline />
      <div className="App">
        <Router>
          <Routes>
            <Route path="/flashpage" element={<Flashpage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/help" element={<HelpPage />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route element={<PrivateRoutes />}>
              <Route path="/" element={<MainLayout />}>
                <Route index element={<Navigate to="home" replace />} />
                <Route path="home" element={<HomePage />} />
                <Route path="module" element={<ModulePage />}>
                  <Route
                    path="incident-management"
                    element={<IncidentDashboard />}
                  />
                  <Route
                    path="incident-management/report-incident"
                    element={<ReportIncidentForm />}
                  />
                  <Route path="loss-control" element={<Dashboard />} />
                  <Route
                    path="loss-control/create-tour"
                    element={<LossControlPage />}
                  />
                  <Route
                    path="loss-control/create-tour/:id"
                    element={<PastLossControl />}
                  />
                  <Route
                    path="loss-control/view-tours"
                    element={<AllViewTourPage />}
                  />
                  <Route path="audit-management" element={<AuditDashboard />} />
                  <Route
                    path="audit-management/audit-perform"
                    element={<AuditPerform />}
                  />

                  <Route
                    path="audit-management/audit-perform/create"
                    element={<AuditCreateDashboard />}
                  />

                  <Route
                    path="audit-management/past-audit/:id"
                    element={<AuditPastDetails />}
                  />
                  <Route
                    path="audit-management/audit-schedule"
                    element={<ScheduleAudit />}
                  />
                  <Route
                    path="audit-management/audit-schedule/new-audit-schedule"
                    element={<NewAuditScheduleCreate />}
                  />


                </Route>

                <Route path="report" element={<ReportPage />} />

                <Route path="my-actions" element={<MyActionPage />} />
                <Route
                  path="my-actions/incident-edit"
                  element={<IncidentView />}
                />
                <Route
                  path="my-actions/loss-control-edit"
                  element={<LossControlView />}
                />
                <Route path="my-actions/capa-edit" element={<CAPAView />} />
                <Route path="all-actions" element={<AllActionPage />} />
                <Route
                  path="all-actions/incident-view"
                  element={<IncidentView />}
                />
                <Route
                  path="all-actions/loss-control-view"
                  element={<LossControlView />}
                />
                <Route path="all-actions/capa-view" element={<CAPAView />} />

                <Route
                  path="loss-control/map-view"
                  element={<LossControlMapView />}
                />
                <Route path="calender" element={<CalendarView />} />
                <Route
                  path="profile/change-password"
                  element={<ChangePassword />}
                />
                <Route path="profile/view-profile" element={<ViewProfile />} />

                {/* Routes for Tickets */}
                <Route path="support" element={<TicketTable />} />
                <Route
                  path="support/create-ticket"
                  element={<CreateTicket />}
                />
                <Route
                  path="/support/view-ticket/:id"
                  element={<ViewTicket />}
                />
                
                <Route
                  path="/support/edit-ticket/:id"
                  element={<EditTicket />}
                />

                <Route path="help-center" element={<HelpManual />} />

                {/* Routes for Landing Dashborad  */}
                <Route
                  path="/landing-dashboard"
                  element={<LandingDashboard />}
                />

                {/* Routes for AuditDashboard */}

                {/* <Route
                  path="/audit-dashboard"
                  element={<AuditDashboard />}
                /> */}
                <Route path="/ticket-dashboard" element={<TicketDashboard />} />
                <Route path="/agents" element={<AgentsList />} />
                <Route path="/agents/:id" element={<AgentTicketTable />} />
                
                <Route path="/configuration" element={<ConfigurationIndex />} />

                <Route path="*" element={<NotFoundPage />} />
              </Route>
            </Route>
          </Routes>
        </Router>
      </div>
    </ThemeProvider>
  );
};

export default App;
