
import {
  INCIDENTFWORKLOW_UPDATE_FORM_FIELD,
  INCIDENTFWORKLOW_ADD_CHECKBOX,
  INCIDENTFWORKLOW_REMOVE_CHECKBOX,
  INCIDENTFWORKLOW_UPDATE_DATE_TIME,
  INCIDENTFWORKLOW_ADD_PERSONNEL_DETAIL,
  INCIDENTFWORKLOW_SET_CAPA_DETAILS,
  INCIDENTFWORKLOW_ONCHANGE_DRAWER_FORM,
  INCIDENTFWORKLOW_ADD_CAPA_DETAIL,
  INCIDENTFWORKLOW_ADD_EVIDENCE_DETAIL,
  INCIDENTFWORKLOW_UPDATE_SWITCH_STATE,
  INCIDENTFWORKLOW_EDIT_CAPA_DETAIL,
  INCIDENTFWORKLOW_DELETE_CARD_DETAIL,
  INCIDENTFWORKLOW_SET_ALL_REPORT_DATA,
  INCIDENTFWORKLOW_UPDATE_EVIDENCE_FILE,
  INCIDENTFWORKLOW_RESET_STATE,
  INCIDENTFWORKLOW_REMOVE_INVESTIGATION_MEMBER,
  INCIDENTFWORKLOW_SET_REVIEW_DATA,
  INCIDENTFWORKLOW_SET_EDIT_CAPA
} from './constant';




export const resetState = () => ({
  type: INCIDENTFWORKLOW_RESET_STATE,
});

export const updateFormField = ({ fieldName, value }) => ({
  type: INCIDENTFWORKLOW_UPDATE_FORM_FIELD,
  payload: { fieldName, value },
});

export const setEditCAPA = (data) => {
  return {
    type: INCIDENTFWORKLOW_SET_EDIT_CAPA,
    payload: data[0],
  };
};

export const addCheckbox = (label) => ({
  type: INCIDENTFWORKLOW_ADD_CHECKBOX,
  payload: label,
});

export const removeCheckbox = (label) => ({
  type: INCIDENTFWORKLOW_REMOVE_CHECKBOX,
  payload: label,
});



export const updateIncidentDateTime = (fieldName, value, title) => ({
  type: INCIDENTFWORKLOW_UPDATE_DATE_TIME,
  payload: { fieldName, value, title},
});

export const addPersonnelDetail = (PersonnelDetail) => {
  return {
    type: INCIDENTFWORKLOW_ADD_PERSONNEL_DETAIL,
    payload: PersonnelDetail
  };
};




export const addEvidenceDetail = (evidenceDetail) => {
  return {
    type: INCIDENTFWORKLOW_ADD_EVIDENCE_DETAIL,
    payload: evidenceDetail
  };
};

export const addCAPADetail = (CAPADetail) => {
  return {
    type: INCIDENTFWORKLOW_ADD_CAPA_DETAIL,
    payload: CAPADetail
  };
};


 
export const handleChangeWorkfLowDrawerFormData = ({fieldName, value, title}) => ({
  type: INCIDENTFWORKLOW_ONCHANGE_DRAWER_FORM,
  payload: { fieldName, value, title},
});

export const updateSwitchState = (sectionName, value) => ({
  type: INCIDENTFWORKLOW_UPDATE_SWITCH_STATE,
  payload: {
    sectionName,
    value,
  },
});

export const editDetail = ({id ,title}) => {
  return {
    type: INCIDENTFWORKLOW_EDIT_CAPA_DETAIL,
    payload: { id ,title }  // Pass the ID of the LTI detail you want to edit
  };
};

export const deleteCardDetail = ({id ,title}) => {
  return {
    type: INCIDENTFWORKLOW_DELETE_CARD_DETAIL,
    payload: { id ,title }  // Pass the ID of the LTI detail you want to edit
  };
};

export const setAllReportData = (data) => {
  return {
      type: INCIDENTFWORKLOW_SET_ALL_REPORT_DATA,
      payload: data
  };
};

export const updateEvidenceFile = (file) => ({
  type: INCIDENTFWORKLOW_UPDATE_EVIDENCE_FILE,
  payload: file,
});


export const setCAPADetails = (capaDetails) => {
  return {
    type: INCIDENTFWORKLOW_SET_CAPA_DETAILS,
    payload: capaDetails,
  };
};

export const removeInvestigationMember = (member) => {
  return {
    type: INCIDENTFWORKLOW_REMOVE_INVESTIGATION_MEMBER,
    payload: member,
  };
};


export const setReviewData = (reviewData) => ({
  type: INCIDENTFWORKLOW_SET_REVIEW_DATA,
  payload: reviewData,
});



