export const INCIDENTFWORKLOW_UPDATE_FORM_FIELD = 'INCIDENTFWORKLOW_UPDATE_FORM_FIELD';
export const INCIDENTFWORKLOW_ADD_CHECKBOX = 'INCIDENTFWORKLOW_ADD_CHECKBOX';
export const INCIDENTFWORKLOW_REMOVE_CHECKBOX = 'INCIDENTFWORKLOW_REMOVE_CHECKBOX';
export const INCIDENTFWORKLOW_UPDATE_DATE_TIME = 'INCIDENTFWORKLOW_UPDATE_DATE_TIME';
export const INCIDENTFWORKLOW_ONCHANGE_DRAWER_FORM = "INCIDENTFWORKLOW_ONCHANGE_DRAWER_FORM";
export const INCIDENTFWORKLOW_ADD_PERSONNEL_DETAIL='INCIDENTFWORKLOW_ADD_PERSONNEL_DETAIL';
export const INCIDENTFWORKLOW_ADD_VEHICLE_DETAIL= 'INCIDENTFWORKLOW_ADD_VEHICLE_DETAIL';
export const INCIDENTFWORKLOW_ADD_EQUIPMENT_DETAIL= 'INCIDENTFWORKLOW_ADD_EQUIPMENT_DETAIL';
export const INCIDENTFWORKLOW_ADD_EVIDENCE_DETAIL = "INCIDENTFWORKLOW_ADD_EVIDENCE_DETAIL";
export const INCIDENTFWORKLOW_ADD_CAPA_DETAIL = "INCIDENTFWORKLOW_ADD_CAPA_DETAIL";
export const INCIDENTFWORKLOW_RESET_STATE = "INCIDENTFWORKLOW_RESET_STATE";
export const INCIDENTFWORKLOW_UPDATE_SWITCH_STATE = 'INCIDENTFWORKLOW_UPDATE_SWITCH_STATE';
export const INCIDENTFWORKLOW_EDIT_CAPA_DETAIL = "INCIDENTFWORKLOW_EDIT_CAPA_DETAIL";
export const INCIDENTFWORKLOW_DELETE_CARD_DETAIL = 'INCIDENTFWORKLOW_DELETE_CARD_DETAIL';
export const INCIDENTFWORKLOW_SET_ALL_REPORT_DATA = 'INCIDENTFWORKLOW_SET_ALL_REPORT_DATA';
export const INCIDENTFWORKLOW_REMOVE_INVESTIGATION_MEMBER = "INCIDENTFWORKLOW_REMOVE_INVESTIGATION_MEMBER";
export const INCIDENTFWORKLOW_UPDATE_EVIDENCE_FILE ="INCIDENTFWORKLOW_UPDATE_EVIDENCE_FILE";
export const INCIDENTFWORKLOW_SET_CAPA_DETAILS = 'INCIDENTFWORKLOW_SET_CAPA_DETAILS';
export const INCIDENTFWORKLOW_SET_REVIEW_DATA = 'INCIDENTFWORKLOW_SET_REVIEW_DATA';
export const INCIDENTFWORKLOW_SET_EDIT_CAPA = "INCIDENTFWORKLOW_SET_EDIT_CAPA";