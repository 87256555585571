import {
  ADD_SITE_LOCATION,
  ADD_SURVEY_DATE,
  ADD_DISCIPLINE_NAME,
  ADD_DEFAULT_QUESTIONS,
  ADD_NEW_CHECKPOINTS,
  DELETE_CHECKPOINT,
  EDIT_CHECKPOINT,
  ADD_OBSERVATION,
  ADD_DATA,
  DELETE_IMAGE,
  ADD_LOCATION_ID,
  UPDATE_DASHBOARD_FILTER,
  UPDATE_DASHBOARD_FILTER_ARRAY,
  ADD_SATISFACTORY_REMARK,
  ADD_NOTAPPLICABLE_REMARK,
  ADD_VERFICATION_IMG,
  SET_TOUR_QUESTIONNAIRE_DRAFT,
  SET_TOUR_METADATA_DRAFT
} from "./constant";

export const updateDashboardFilter = ({key, value}) => {
  return {
      type: UPDATE_DASHBOARD_FILTER,
      payload: {
          key,
          value,
      },
  };
};
export const updateDashboardLocation = (data) => {
  return {
      type: UPDATE_DASHBOARD_FILTER_ARRAY,
      payload: {
          value:data,
      },
  };
};

export const addSiteLocation = (siteLocation) => ({
  type: ADD_SITE_LOCATION,
  payload: siteLocation,
});

export const addSurveyDate = (date) => ({
  type: ADD_SURVEY_DATE,
  payload: date,
});

export const addLocationID = locationID => ({
  type: ADD_LOCATION_ID,
  payload: locationID
})

export const addDisciplineName = (disciplineName) => ({
  type: ADD_DISCIPLINE_NAME,
  payload: disciplineName,
});

export const addDefaultQuestions = (questions) => ({
  type: ADD_DEFAULT_QUESTIONS,
  payload: questions,
});

export const addVerificationImg = (imgArray) => ({
  type: ADD_VERFICATION_IMG,
  payload: imgArray,
});

export const addNewCheckpoints = (checkpoints) => ({
  type: ADD_NEW_CHECKPOINTS,
  payload: checkpoints,
});

export const deleteCheckpoint = (id) => ({
  type: DELETE_CHECKPOINT,
  payload: id,
});

export const editCheckpoint = (id, quesName) => ({
  type: EDIT_CHECKPOINT,
  payload: { id, quesName },
});

export const addObservation = (questionId, observation) => ({
  type: ADD_OBSERVATION,
  payload: { questionId, observation },
});

export const addData = (questionId, data) => ({
  type: ADD_DATA,
  payload: { questionId, data },
});

export const addSatisfactoryRemark = (questionId, remark) => ({
  type: ADD_SATISFACTORY_REMARK,
  payload: { questionId, remark },
});

export const addNotApplicableRemark = (questionId, remark) => ({
  type: ADD_NOTAPPLICABLE_REMARK,
  payload: { questionId, remark },
});

export const deleteImage = (questionId, indexToDelete) => ({
  type: DELETE_IMAGE,
  payload: {questionId, indexToDelete}
})

export const setDraftQuestionnaire = (data) => ({
  type: SET_TOUR_QUESTIONNAIRE_DRAFT,
  payload: data
})

export const setDraftTourMetadata = (metadata) => ({
  type: SET_TOUR_METADATA_DRAFT,
  payload: metadata
});

