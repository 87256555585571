import React from 'react';
import { createTheme} from '@mui/material';


// Define your custom theme
const Theme = createTheme({
  palette: {
    primary: {
      main: '#0AA2E3',  // Primary button color (default blue)
    },
    secondary: {
      main: '#d32f2f',  // Secondary button color (default red)
    },
  },
 
  typography: { 
    fontFamily: 'Roboto, Arial, sans-serif', // Global font family
    h1: {
      fontSize: '2rem',       // Heading 1 size
      fontWeight: 600,        // Heading 1 weight
      color: '#000000',       // Global color for H1
      lineHeight: 1.2,        // Line height for H1
    },
    h2: {
      fontSize: '1.5rem',     // Heading 2 size
      fontWeight: 500,        // Heading 2 weight
      color: '#000000',
      lineHeight: 1.2,        // Line height for H2
    },
    body1: {
      fontSize: '16px',       // Standard body text size
      fontWeight: 400,        // Global body text weight
      color: '#000000',       // Global body text color
      lineHeight: 1.3,        // Line height for body1
    },
    body2: {
      fontSize: '14px',       // Body 2 text size
      fontWeight: 400,        // Body 2 text weight
      color: '#000000',       // Body 2 text color
     
    },
   
    
    caption: {
      fontSize: '12px',       // Caption text size
      fontWeight: 400,        // Caption text weight
      color: '#525966',       // Caption text color
          // Line height for caption
    },
    button: {
      textTransform: 'none',  // Disable uppercase text transformation for buttons
      fontWeight: 600,        // Button font weight
      color: '#FFFFFF',       // Button text color
          // Line height for button
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '8px',  // Apply custom border radius to all buttons
        },
        containedPrimary: {
          backgroundColor: '#1976d2', // Custom primary button background color
          '&:hover': {
            backgroundColor: '#155a9f', // Primary button hover color
          },
        },
        containedSecondary: {
          backgroundColor: '#d32f2f', // Custom secondary button background color
          '&:hover': {
            backgroundColor: '#9a2727', // Secondary button hover color
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: '14px',     // Set label font size to 14px
          color: '#525966', 
          lineHeight:"36px",    // Custom input label color
          '&.Mui-focused': {
            color: '#000000',   // Change label color when focused
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          padding: '12.5px 14px', // Update padding for input
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          padding: '12.5px 14px', // Update padding specifically for outlined inputs
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          padding: '16px !important',  // Set padding for MuiPaper
        },
      },
    },
  },
});

export default Theme;
