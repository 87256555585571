import React from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
  IconButton,
} from '@mui/material';
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import KeyboardBackspaceOutlinedIcon from "@mui/icons-material/KeyboardBackspaceOutlined";
import EastIcon from '@mui/icons-material/East';
import CircleIcon from "@mui/icons-material/Circle";
import { capitalizeFirstChar } from "../utils/helper";


const getStepIcon = (status) => {
  if (status === "Incident Closed" || status === "Investigation Completed" || status === "Incident Review" || status === "Investigation Under Approval" || status === "Investigation Approval Resubmission" || status === "Investigation Initiated" || status === "Resubmitted for Review" || status === "Investigation Reinitiated" || status === "Investigation Resubmission" || status === "CAPA Assigned") {
    return (
      <CheckOutlinedIcon
        sx={{
          backgroundColor: "#FFFFFF",
          borderRadius: "50%",
          color: "#4591F5",
          fill: "#4591F5",
          border: "2px solid #4591F5",
        }}
      />
    );
  } else if (status === "New Incident" || status === "Incident Resubmission") {
    return (
      <EastIcon
        sx={{
          backgroundColor: "#FFFFFF",
          borderRadius: "50%",
          color: "#FFAC07",
          fill: "#FFAC07",
          border: "2px solid #FFAC07",
        }}
      />
    );
  } else if (status === "New Incident Clarification" || status === "Investigation Query" || status === "Incident Revalidation" || status === "Investigation Validation" || status === "Investigation Clarification") {
    return (
      <KeyboardBackspaceOutlinedIcon
        sx={{
          backgroundColor: "#FFFFFF",
          borderRadius: "50%",
          color: "red",
          fill: "red",
          border: "2px solid red",
        }}
      />
    );
  }
  return (
    <CircleIcon
      sx={{
        backgroundColor: "#FFFFFF",
        borderRadius: "50%",
        color: "#FFAC07",
        fill: "#FFFFFF",
        border: "2px solid #B7C8E5",
      }}
    />
  );
};



export default function CustomWorkflowAccordion({ data }) {
  return (
    <>
      {data?.map((step, index) => (
        step.status != "incomplete" && (
          <Box key={index} sx={{ paddingBottom: "0px" }}>
            <Accordion
              elevation={0} // Removes the shadow from the Accordion (Paper)
              sx={{ padding: '0px !important', border: '0.5px solid #B7C8E5', borderRadius: "8px!important" }}
            >
              {/* Collapsed View */}
              <AccordionSummary
                expandIcon={getStepIcon(step.status)} // Dynamically set icon based on status
                aria-controls={`panel${index}-content`}
                id={`panel${index}-header`}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  backgroundColor: '#F9FAFC',
                  borderRadius: '20px',
                  paddingX: '12px',
                  '& .MuiAccordionSummary-expandIconWrapper': {
                    transform: 'none !important', // Prevents the wrapper rotation
                  },
                }}
              >
                <Box>
                  <Typography variant="body2" sx={{ color: '#222222' }}>
                    {step?.status || 'Unknown'} / {step?.roleName}
                  </Typography>
                  <Typography variant="caption" sx={{ color: '#525966' }}>
                    {`${step?.firstName} ${step?.lastName}`} / {step?.email} / {step?.employeeID}
                  </Typography>
                </Box>
              </AccordionSummary>

              {/* Expanded View */}
              <AccordionDetails
                sx={{
                  backgroundColor: '#f9fafc',
                  borderRadius: '8px',
                  paddingX: '12px',
                }}
              >
                {/* Recommended Section */}
                <Box display="flex" alignItems="center" marginBottom="4px" gap={0.5}>
                  <Typography variant="caption" sx={{ color: '#222222' }}>
                    Recommended:
                  </Typography>
                  <Box display="flex" gap={1}>
                    <Box display="flex" gap={0}>
                      <Box
                        sx={{
                          backgroundColor: '#ffffff',
                          color: '#09A2E3',
                          p: 0.5,
                          border: '0.5px solid #B7C8E5',
                          borderTopLeftRadius: '4px',
                          borderBottomLeftRadius: '4px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <Typography sx={{ color: '#222222', fontSize: "10px" }}>
                          Incident Type
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          backgroundColor: '#09A2E3',
                          color: '#ffffff',
                          p: 0.5,
                          border: '0.5px solid #B7C8E5',
                          borderTopRightRadius: '4px',
                          borderBottomRightRadius: '4px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <Typography variant="caption" sx={{ color: '#ffffff' }}>
                          {step?.type || 'N/A'}
                        </Typography>
                      </Box>
                    </Box>
                    <Box display="flex" gap={0}>
                      <Box
                        sx={{
                          backgroundColor: '#ffffff',
                          color: '#09A2E3',
                          p: 0.5,
                          border: '0.5px solid #B7C8E5',
                          borderTopLeftRadius: '4px',
                          borderBottomLeftRadius: '4px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <Typography sx={{ color: '#222222', fontSize: "10px" }}>
                          Severity
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          backgroundColor: '#09A2E3',
                          color: '#ffffff',
                          p: 0.5,
                          border: '0.5px solid #B7C8E5',
                          borderTopRightRadius: '4px',
                          borderBottomRightRadius: '4px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <Typography variant="caption" sx={{ color: '#ffffff' }}>
                          {step?.severity || 'N/A'}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>

                {/* Remarks Section */}

                {step?.remarks && <Box display="flex" flexDirection="column">
                  <Typography variant="caption" sx={{ color: '#222222' }}>
                    Remarks
                  </Typography>
                  <Typography variant="caption" sx={{ color: '#525966' }}>
                    {capitalizeFirstChar(step?.remarks) || "NA"}
                  </Typography>
                </Box>
                }

              </AccordionDetails>
            </Accordion>
          </Box>
        )
      ))}
    </>
  );
}
