import React, { useState, useEffect } from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
  Typography, Chip, Tooltip, Box, TablePagination
} from '@mui/material';
import { actionIcon, Investigation, SafetyOfficer, ReportIncident, MinesManager, Capa, person_outline, setting_outline, car_outline, overDueImage } from '../constant';
import SeverityTag from './SeverityTag';
import { useNavigate } from 'react-router-dom';
import StatusChip from "../Components/Chip";
import { formatTableDate, formatDateToIndianLocale } from '../utils/helper';
import PageviewOutlinedIcon from '@mui/icons-material/PageviewOutlined';
import { useDispatch, useSelector } from "react-redux";





const getStatusChip = (status) => {
  switch (status) {
    // all losss control status 
    case 'In_Progress':
      return <StatusChip label="In Progress" color="#0B7F6A" bgColor="rgba(11, 127, 106, 0.1)" />;
    case 'New_Survey':
      return <StatusChip label="New Survey" color="#FF8A00" bgColor="rgba(255, 138, 0, 0.1)" />;
    case 'Under_Review':
      return <StatusChip label="Under Review" color="#009FF5" bgColor="rgba(0, 159, 245, 0.1)" />;
    case 'Revalidation':
      return <StatusChip label="Revalidation" color="#FF7742" bgColor="rgba(255, 119, 66, 0.1)" />;
    case 'Resubmission':
      return <StatusChip label="Resubmission" color="#FF7742" bgColor="rgba(255, 119, 66, 0.1)" />;
    case 'Recommendation_Resubmission':
      return <StatusChip label="For Approval" color="#FF7742" bgColor="rgba(255, 119, 66, 0.1)" />;
    case 'Returned_By_MM':
      return <StatusChip label="Returned" color="#FF7742" bgColor="rgba(255, 119, 66, 0.1)" />;
    case 'Completed':
      return <StatusChip label="Completed" color="#0B7F6A" bgColor="rgba(11, 127, 106, 0.1)" />;

    // all incident status 
    // Reported statuses
    case 'New Incident':
      return <StatusChip label="New Incident" color="#009FF5" bgColor="#E7F6FD" />;

    case 'Incident Resubmission':
      return <StatusChip label="Incident Resubmission" color="#FF8A00" bgColor="rgba(255, 138, 0, 0.1)" />;

    case 'New Incident Clarification':
      return <StatusChip label="New Incident Clarification" color="#FF231F" bgColor="rgba(255, 87, 83, 0.15)" />;

    // Review statuses
    case "Incident Review":
      return <StatusChip label="Incident Review" color="#FF7742" bgColor="#FFEFE8" />;

    case 'Resubmitted for Review':
      return <StatusChip label="Resubmitted for Review" color="#7400CC" bgColor="#F2E6FA" />;

    case 'Incident Revalidation':
      return <StatusChip label="Incident Revalidation" color="#FF7742" bgColor="#FFEFE8" />;

    // Investigation statuses
    case 'Investigation Initiated':
      return <StatusChip label="Investigation Initiated" color='#FFA800' bgColor='#FDF7E6' />;
    case 'Investigation Validation':
      return <StatusChip label="Investigation Return" color='#FF7742' bgColor='#FFEFE8' />;
    case 'Investigation Reinitiated':
      return <StatusChip label="Investigation Reinitiated" color='#FFA800' bgColor='#FDF7E6' />;
    case 'Investigation Resubmission':
      return <StatusChip label="Investigation Resubmission" color='#7400CC' bgColor='#F2E6FA' />;
    case "Investigation Approval Resubmission":
      return <StatusChip label="Investigation Approval Resubmission" color='#FF7742' bgColor='#FFEFE8' />;
    case 'Investigation Clarification':
      return <StatusChip label="Investigation Clarification" color='#FFA800' bgColor='#FDF7E6' />;
    case 'Investigation Under Approval':
      return <StatusChip label="Investigation Under Approval" color='#FF7742' bgColor='#FFEFE8' />;
    case "Investigation Completed":
      return <StatusChip label="Investigation Completed" color='#FFA800' bgColor='#FDF7E6' />;

    case "Investigation Query":
      return <StatusChip label="Investigation Query " color='#FF7742' bgColor='#FFEFE8' />;
    // CAPA statuses
    case 'Review':
      return <StatusChip label="CAPA Review" color='#FFA800' bgColor='#FDF7E6' />;
    case 'CAPA Assigned':
      return <StatusChip label="CAPA Assigned" color='#FF7742' bgColor='#FFEFE8' />;
    case 'CAPA In Progress':
      return <StatusChip label="CAPA In Progress" color='#FFA800' bgColor='#FDF7E6' />;
    case 'resubmitted':
      return <StatusChip label="CAPA Resubmitted" color='#FF7742' bgColor='#FFEFE8' />;
    case 'revalidation':
      return <StatusChip label="CAPA Revalidation" color='#7400CC' bgColor='#F2E6FA' />;
    case 'clarification':
      return <StatusChip label="CAPA Clarification" color='#FFA800' bgColor='#FDF7E6' />;
    case 'resubmission':
      return <StatusChip label="CAPA Resubmission" color='#7400CC' bgColor='#F2E6FA' />;
    case 'completed':
      return <StatusChip label="CAPA Completed" color="#4CAF50" bgColor="#E8F9F0" />;
    case 'approved':
      return <StatusChip label="CAPA Completed" color="#4CAF50" bgColor="#E8F9F0" />;
    case 'returned':
      return <StatusChip label="CAPA Return" color='#7400CC' bgColor='#F2E6FA' />;
    // Closed status
    case 'Incident Closed':
      return <StatusChip label="Incident Closed" color="#4CAF50" bgColor="#E8F9F0" />;

    default:
      return <StatusChip label="" color="#888" />;
  }
};

export default function CustomTable({ data = [], title, mode }) {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);



  const [datas, setDatas] = useState(data);
  const { searchQuery } = useSelector((state) => state.loadingReducer);
  const paginatedData = datas.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  useEffect(() => {
    const filteredData = data?.filter((card) =>
      Object.values(card).some((value) =>
        value?.toString().toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
    setDatas(filteredData); // Update filtered state
  }, [searchQuery, data]); // Depend on both searchQuery and cardData

  // Handle page change
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Handle rows per page change
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Function to get date based on the module type
  const getDate = (row) => {
    if (row["Incident Time"]) return formatDateToIndianLocale(row["Incident Time"]);
    if (row["Due Date"]) return formatDateToIndianLocale(row["Due Date"]);
    if (row["Tour Date"]) return formatDateToIndianLocale(row["Tour Date"]);
    return "-";
  };

  return (
    <Paper sx={{ backgroundColor: '#F9FAFC' }}>
      <TableContainer>
        {title === 'All' && (<Table>
          <TableHead>
            <TableRow>
              <TableCell><Typography variant='body1' fontWeight="bold" sx={{ color: "#525966" }}>Module</Typography></TableCell>
              <TableCell><Typography variant='body1' fontWeight="bold" sx={{ color: "#525966" }}>ID</Typography></TableCell>
              <TableCell><Typography variant='body1' fontWeight="bold" sx={{ color: "#525966" }}>Date</Typography></TableCell>
              <TableCell><Typography variant='body1' fontWeight="bold" sx={{ color: "#525966" }}>Role</Typography></TableCell>
              <TableCell><Typography variant='body1' fontWeight="bold" sx={{ color: "#525966" }}>Status</Typography></TableCell>
              {mode === "action" && <TableCell><Typography variant='body1' fontWeight="bold" sx={{ color: "#525966" }}>Action</Typography></TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedData.length === 0 ? (
              <TableRow>
                <TableCell colSpan={6} sx={{ textAlign: 'center', color: '#525966', fontWeight: 'bold' }}>
                  No data found
                </TableCell>
              </TableRow>
            ) : (
              paginatedData.map((row, index) => (
                <TableRow key={index}>
                  <TableCell sx={{ color: "#525966" }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                      <img src={Investigation} alt="icon" width="24" height="24" />
                      {row?.moduleName === "Incident Management" ? "Incident" : row?.moduleName}
                    </Box>
                  </TableCell>
                  <TableCell sx={{ color: "#525966", textDecoration: "underline" }}>{row["customReportId"]}</TableCell>
                  <TableCell sx={{ color: "#525966" }}>{(getDate(row))}</TableCell>
                  <TableCell sx={{ color: "#525966", fontWeight: '500' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                      <img
                        src={
                          row.role === "Safety manager" ? SafetyOfficer :
                            row.role === "Mines manager" ? MinesManager :
                              row.role === "Area Owner" ? Capa :
                                Investigation
                        }
                        alt="role icon"
                        width="24"
                        height="24"
                      />
                      {row.role}
                    </Box>
                  </TableCell>
                  <TableCell sx={{ color: "#525966" }}>{Array.isArray(row?.status) ? (
                    row.status.map((status, index) => (
                      <React.Fragment key={index}>
                        {getStatusChip(status)}
                      </React.Fragment>
                    ))
                  ) : (
                    // Fallback if data.Status is not an array (you can handle this however you want)
                    <Typography variant="body2" sx={{ color: '#423B50' }}>
                      No status available
                    </Typography>
                  )}
                    {row?.dueTaskCount > 0 && <img src={overDueImage} />}
                  </TableCell>
                  <TableCell sx={{ color: "#525966" }}>
                    {mode === "action" ? (
                      <div>
                        <Tooltip title="Action">
                          <img
                            src={actionIcon}
                            alt="actionIcon"
                            onClick={() => {
                              let data = { ...row, mode };
                              if (row.moduleName === "Incident Mangement") {
                                navigate("/my-actions/incident-edit", { state: { data } });
                              } else {
                                navigate("/my-actions/loss-control-edit", { state: { data } });
                              }
                            }}
                          />
                        </Tooltip>
                      </div>
                    ) : (
                      <div>
                        <Tooltip title="View">
                          <PageviewOutlinedIcon
                            alt="viewicon"
                            onClick={() => {
                              let data = { ...row, mode };
                              if (row.moduleName === "Incident Mangement") {
                                navigate("/all-actions/incident-view", { state: { data } });
                              } else {
                                navigate("/all-actions/loss-control-view", { state: { data } });
                              }
                            }}
                          />
                        </Tooltip>
                      </div>
                    )}
                  </TableCell>

                </TableRow>
              ))
            )}
          </TableBody>
        </Table>)}

        {title === 'Incident Management' && (<Table>
          <TableHead>
            <TableRow>
              <TableCell><Typography variant='body1' fontWeight="bold" sx={{ color: "#525966" }}>Incident ID</Typography></TableCell>
              <TableCell><Typography variant='body1' fontWeight="bold" sx={{ color: "#525966" }}>Location</Typography></TableCell>

              <TableCell><Typography variant='body1' fontWeight="bold" sx={{ color: "#525966" }}>Reported By</Typography></TableCell>
              <TableCell><Typography variant='body1' fontWeight="bold" sx={{ color: "#525966" }}>Incident Type</Typography></TableCell>
              <TableCell><Typography variant='body1' fontWeight="bold" sx={{ color: "#525966" }}>Incident Date</Typography></TableCell>
              <TableCell><Typography variant='body1' fontWeight="bold" sx={{ color: "#525966" }}>Severity</Typography></TableCell>
              <TableCell><Typography variant='body1' fontWeight="bold" sx={{ color: "#525966" }}>Status</Typography></TableCell>
              {mode === "action" && <TableCell><Typography variant='body1' fontWeight="bold" sx={{ color: "#525966" }}>Action</Typography></TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedData.length === 0 ? (
              <TableRow>
                <TableCell colSpan={6} sx={{ textAlign: 'center', color: '#525966', fontWeight: 'bold' }}>
                  No data found
                </TableCell>
              </TableRow>
            ) : (
              paginatedData.map((row, index) => (
                <TableRow key={index}>
                  <TableCell sx={{ color: "#525966" }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                      <img src={Investigation} alt="icon" width="24" height="24" />
                      {row?.["customReportId"]}
                    </Box>
                  </TableCell>
                  <TableCell sx={{ color: "#525966", textDecoration: "underline" }}>{row?.locationName}</TableCell>
                  <TableCell sx={{ color: "#525966", textDecoration: "underline" }}>{row?.reportingOfficerName}</TableCell>

                  <TableCell sx={{ color: "#525966" }}>
                    <Box display="flex" alignItems="center" gap={0.5} mb="8px">
                      {row?.["Incident Type"]}
                      {row?.formData?.PersonnelDetail?.length > 0 && (<img src={person_outline} alt='personicon' />)}
                      {row?.formData?.EquipmentDetail?.length > 0 && (<img src={setting_outline} alt='settingicon' />)}
                      {row?.formData?.VehicleDetail?.length > 0 && (<img src={car_outline} alt='caricon' />)}
                    </Box>
                  </TableCell>
                  <TableCell sx={{ color: "#525966" }}>{formatDateToIndianLocale(row?.["Incident Date"])}</TableCell>
                  <TableCell sx={{ color: "#525966" }}> <SeverityTag severity={data?.Severity == 0 ? "N/A" :data?.Severity} /></TableCell>
                  <TableCell sx={{ color: "#525966" }}>{Array.isArray(row?.status) ? (
                    row.status.map((status, index) => (
                      <React.Fragment key={index}>
                        {getStatusChip(status)}
                      </React.Fragment>
                    ))
                  ) : (
                    // Fallback if data.Status is not an array (you can handle this however you want)
                    <Typography variant="body2" sx={{ color: '#423B50' }}>
                      No status available
                    </Typography>
                  )}
                    {" "}
                    {row?.dueTaskCount > 0 && <img src={overDueImage} />}

                  </TableCell>
                  {mode === "action" ?
                    <TableCell sx={{ color: "#525966" }}>
                      <Tooltip title="Action">
                        <img src={actionIcon} alt='actionIcon' onClick={() => {
                          let data = { ...row, mode }
                          navigate('/my-actions/incident-edit', { state: { data } });
                        }
                        }
                        />
                      </Tooltip>
                    </TableCell> :
                    <TableCell sx={{ color: "#525966" }}>
                      <Tooltip title="View">
                        <PageviewOutlinedIcon onClick={() => {
                          let data = { ...row, mode }
                          navigate('/all-actions/incident-view', { state: { data } })
                        }
                        }
                        />
                      </Tooltip>
                    </TableCell>}
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>)}
        {title === 'Loss Control' &&
          (<Table>
            <TableHead>
              <TableRow>
                <TableCell><Typography variant='body1' fontWeight="bold" sx={{ color: "#525966" }}>Discipline name</Typography></TableCell>
                <TableCell><Typography variant='body1' fontWeight="bold" sx={{ color: "#525966" }}>Location</Typography></TableCell>
                <TableCell><Typography variant='body1' fontWeight="bold" sx={{ color: "#525966" }}>Reported By</Typography></TableCell>
                <TableCell><Typography variant='body1' fontWeight="bold" sx={{ color: "#525966" }}>Reporting Date</Typography></TableCell>
                <TableCell><Typography variant='body1' fontWeight="bold" sx={{ color: "#525966" }}>Status</Typography></TableCell>
                {mode === "action" && <TableCell><Typography variant='body1' fontWeight="bold" sx={{ color: "#525966" }}>Action</Typography></TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedData.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={6} sx={{ textAlign: 'center', color: '#525966', fontWeight: 'bold' }}>
                    No data found
                  </TableCell>
                </TableRow>
              ) : (
                paginatedData.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell sx={{ color: "#525966" }}>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                        {row?.discipline}
                      </Box>
                    </TableCell>
                    <TableCell sx={{ color: "#525966" }}>{row?.locationName}</TableCell>
                    <TableCell sx={{ color: "#525966" }}>{row?.firstName + " " + row?.lastName}</TableCell>
                    <TableCell sx={{ color: "#525966" }}>{formatDateToIndianLocale(row?.["Tour Date"])}</TableCell>
                    <TableCell sx={{ color: "#525966" }}>{Array.isArray(row?.status) ? (
                      row.status.map((status, index) => (
                        <React.Fragment key={index}>
                          {getStatusChip(status)}
                        </React.Fragment>
                      ))
                    ) : (
                      // Fallback if data.Status is not an array (you can handle this however you want)
                      <Typography variant="body2" sx={{ color: '#423B50' }}>
                        No status available
                      </Typography>
                    )}
                      {" "}
                      {row?.dueTaskCount > 0 && <img src={overDueImage} />}

                    </TableCell>
                    {mode === "action" ?
                      <TableCell sx={{ color: "#525966" }}>
                        <Tooltip title="Action">
                          <img src={actionIcon} alt='actionIcon' onClick={() => {
                            let data = { ...row, mode }
                            navigate('/my-actions/loss-control-edit', { state: { data } });
                          }
                          }
                          />
                        </Tooltip>
                      </TableCell> :
                      <TableCell sx={{ color: "#525966" }}>
                        <Tooltip title="View">
                          <PageviewOutlinedIcon onClick={() => {
                            let data = { ...row, mode }
                            navigate('/all-actions/loss-control-view', { state: { data } })
                          }
                          }
                          />
                        </Tooltip>
                      </TableCell>}
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
          )
        }
        {title === 'CAPA Management' && (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell><Typography variant='body1' fontWeight="bold" sx={{ color: "#525966" }}>Source Type</Typography></TableCell>
                <TableCell><Typography variant='body1' fontWeight="bold" sx={{ color: "#525966" }}>CAPA ID</Typography></TableCell>
                <TableCell><Typography variant='body1' fontWeight="bold" sx={{ color: "#525966" }}>Area</Typography></TableCell>
                <TableCell><Typography variant='body1' fontWeight="bold" sx={{ color: "#525966" }}>CAPA Type</Typography></TableCell>
                <TableCell><Typography variant='body1' fontWeight="bold" sx={{ color: "#525966" }}>Priority</Typography></TableCell>
                <TableCell><Typography variant='body1' fontWeight="bold" sx={{ color: "#525966" }}>Due Date</Typography></TableCell>
                <TableCell><Typography variant='body1' fontWeight="bold" sx={{ color: "#525966" }}>Status</Typography></TableCell>
                {mode === "action" && <TableCell><Typography variant='body1' fontWeight="bold" sx={{ color: "#525966" }}>Action</Typography></TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedData.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={8} sx={{ textAlign: 'center', color: '#525966', fontWeight: 'bold' }}>
                    No data found
                  </TableCell>
                </TableRow>
              ) : (
                paginatedData.map((row, index) => (
                  <TableRow key={index}>
                    {/* Source Type */}
                    <TableCell sx={{ color: "#525966" }}>{row?.moduleName || 'N/A'}</TableCell>

                    {/* Customer Report ID */}
                    <TableCell sx={{ color: "#525966" }}>{row?.customReportID || 'N/A'}</TableCell>

                    {/* Area */}
                    <TableCell sx={{ color: "#525966" }}>{row?.area || 'N/A'}</TableCell>

                    {/* CAPA Type */}
                    <TableCell sx={{ color: "#525966" }}>{row?.capaType || 'N/A'}</TableCell>

                    {/* Priority */}
                    <TableCell sx={{ color: "#525966" }}>{row?.capaPriority || 'N/A'}</TableCell>

                    {/* Due Date */}
                    <TableCell sx={{ color: "#525966" }}>{formatDateToIndianLocale(row?.dueDate) || 'N/A'}</TableCell>

                    {/* Status */}
                    <TableCell sx={{ color: "#525966" }}>
                      {Array.isArray(row?.status) ? (
                        row.status.map((status, index) => (
                          <React.Fragment key={index}>
                            {getStatusChip(status)}
                          </React.Fragment>
                        ))
                      ) : (
                        <Typography variant="body2" sx={{ color: '#423B50' }}>
                          {getStatusChip(row.status)}
                        </Typography>
                      )}
                      {" "}
                      {row?.dueTaskCount > 0 && <img src={overDueImage} alt="Overdue" />}
                    </TableCell>

                    {/* Action or View */}
                    {mode === "action" ? (
                      <TableCell sx={{ color: "#525966" }}>
                        <Tooltip title="Action">
                          <img
                            src={actionIcon}
                            alt='actionIcon'
                            onClick={() => {
                              let data = { ...row, mode };
                              navigate('/my-actions/loss-control-edit', { state: { data } });
                            }}
                          />
                        </Tooltip>
                      </TableCell>
                    ) : (
                      <TableCell sx={{ color: "#525966" }}>
                        <Tooltip title="View">
                          <PageviewOutlinedIcon
                            onClick={() => {
                              let data = { ...row, mode };
                              navigate('/all-actions/loss-control-view', { state: { data } });
                            }}
                          />
                        </Tooltip>
                      </TableCell>
                    )}
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        )}


      </TableContainer>

      <TablePagination
        component="div"
        count={datas.length}  // Total number of rows
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};
