import React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TextField } from '@mui/material';
import dayjs from 'dayjs';
import 'dayjs/locale/en-gb'; // Optional for locale if needed
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

export default function BasicDatePicker({ label, onChange, value }) {
  // Get the report date from local storage and today's date
  const reportDate = localStorage.getItem('ReportDate');
  const parsedReportDate = reportDate ? dayjs(reportDate, 'DD-MM-YYYY') : null;
  const todayDate = dayjs(); // Today's date

  const handleDateChange = (newValue) => {
    onChange(newValue, label); // Pass the new date value to the onChange callback
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        label={label}
        value={value ? dayjs(value, 'DD-MM-YYYY') : null} // Parse value in 'DD-MM-YYYY' format
        onChange={handleDateChange}
        format="DD-MM-YYYY"
        shouldDisableDate={(date) => {
          if (label === 'Target Completion Date' || label === 'Due Date') {
            // Allow dates from the report date to all future dates
            return parsedReportDate && date.isBefore(parsedReportDate, 'day');
          } else {
            // Disable future dates only
            return date.isAfter(todayDate, 'day');
          }
        }}
        slotProps={{
          textField: {
            InputLabelProps: { shrink: true }, // Always keep label shrunk
            size: 'small',
            fullWidth: true, // Full width input field
            fontSize: '12px',
          },
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            margin="normal"
            variant="outlined"
            fullWidth
            sx={{
              '& .MuiOutlinedInput-input': {
                padding: '8px 12px', // Adjust padding for small size input
              },
              '& .MuiFormLabel-root': {
                transform: 'translate(14px, -9px) scale(0.75)', // Adjust label positioning
              },
              '&.MuiInputBase-input': {
                fontSize: '12px',
              },
            }}
          />
        )}
      />
    </LocalizationProvider>
  );
}
