import {
    Box,
    Button,
    InputBase,
    Typography,
    MenuItem,
    Select,
    Tabs,
    Tab,
    IconButton,
  } from "@mui/material";
  import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
  import ListOutlinedIcon from "@mui/icons-material/ListOutlined";
  import GridViewOutlinedIcon from "@mui/icons-material/GridViewOutlined";
  import SearchIcon from "@mui/icons-material/Search";
  import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
  import { useEffect, useState } from "react";
  import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
  import MarkEmailUnreadOutlinedIcon from "@mui/icons-material/MarkEmailUnreadOutlined";
  import ForwardToInboxOutlinedIcon from "@mui/icons-material/ForwardToInboxOutlined";
  import MarkEmailReadOutlinedIcon from "@mui/icons-material/MarkEmailReadOutlined";
  import PropTypes from "prop-types";
  import { useNavigate } from "react-router-dom";
  import TicketTableCard from "./TicketTableCard";
  import { getTickets } from "../../Apis/apiCall";
  import TicketTableComp from "../../Components/TicketTableComp";
  import Loader from "../../Components/Loader/Loader";
  import { useSelector, useDispatch } from "react-redux"; 
  import { START_LOADING, STOP_LOADING } from "../../redux/Loader/constant";
  import { useParams } from "react-router-dom";

  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
        style={{ padding: 0 }}
      >
        {value === index && <Box>{children}</Box>}
      </div>
    );
  }
  
  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  

export default function AgentTicketTable() {
    const {id} = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const loading = useSelector((state) => state.loadingReducer.loading);
    const [selectedTab, setSelectedTab] = useState(0);
    const [tickets, setTickets] = useState([]);
    const [filteredTickets, setFilteredTickets] = useState({
      all: [],
      new: [],
      ongoing: [],
      resolved: [],
    });
  
    const [searchQuery, setSearchQuery] = useState("");
    const [priorityFilter, setPriorityFilter] = useState("");
    const [timeFilter, setTimeFilter] = useState("");
    const [viewMode, setViewMode] = useState('card');
    
    const loginDataString = localStorage.getItem('loginData');
    const loginData = JSON.parse(loginDataString);
    const role = loginData.config.permissionDetails.applicationRoles[0].name;
  
    const [flow, setFlow] = useState(null)
    useEffect(() => {
      if (role === "Support Agent") {
        setFlow(2);
      }
      else if (role === "Support Admin") {
        setFlow(3);
      }
      else{
        setFlow(1);
      }
    }, []);
  
    useEffect(() => {
      fetchAllTickets();
    }, []);
  
    const fetchAllTickets = async () => {
      dispatch({ type: START_LOADING }); 
      try {
        const res = await getTickets(id);
        const allTickets = res.data.data;
        const categorizedTickets = {
          all: allTickets.filter((ticket) => (ticket.status !== "open" && ticket.status !== "escalated")),
          new: allTickets.filter((ticket) => (ticket.status === "open" || ticket.status === "escalated")),
          ongoing: allTickets.filter((ticket) => ticket.status === "assigned"),
          resolved: allTickets.filter((ticket) => ticket.status === "closed"),
        };
  
        setTickets(allTickets);
        setFilteredTickets(categorizedTickets);
      } catch (error) {
        console.error("Error while fetching tickets", error);
      }
      finally{
        dispatch({ type: STOP_LOADING }); 
      }
    };
  
    const handleTabChange = (event, newValue) => {
      setSelectedTab(newValue);
    };
  
    const handleSearchChange = (e) => {
      setSearchQuery(e.target.value);
    };
  
    const handlePriorityChange = (e) => {
      setPriorityFilter(e.target.value);
    };
  
    const handleTimeChange = (e) => {
      setTimeFilter(e.target.value);
    };
  
    const applyFilters = (tickets) => {
      let filtered = [...tickets];
  
      // Apply search filter
      if (searchQuery) {
        filtered = filtered.filter((ticket) =>
          ticket.issueSummary?.toLowerCase().includes(searchQuery.toLowerCase())
        );
      }
  
      // Apply priority filter
      if (priorityFilter) {
        filtered = filtered.filter(
          (ticket) =>
            ticket.priority?.toLowerCase() === priorityFilter.toLowerCase()
        );
      }
  
      // Apply time filter
      if (timeFilter) {
        const now = new Date();
        filtered = filtered.filter((ticket) => {
          const ticketDate = new Date(ticket.createdAt);
          if (timeFilter === "Today") {
            return ticketDate.toDateString() === now.toDateString();
          } else if (timeFilter === "This Week") {
            const weekStart = new Date(now.setDate(now.getDate() - now.getDay()));
            const weekEnd = new Date(
              weekStart.getFullYear(),
              weekStart.getMonth(),
              weekStart.getDate() + 6
            );
            return ticketDate >= weekStart && ticketDate <= weekEnd;
          } else if (timeFilter === "This Month") {
            return (
              ticketDate.getFullYear() === now.getFullYear() &&
              ticketDate.getMonth() === now.getMonth()
            );
          }
          return true;
        });
      }
  
      return filtered;
    };
  
    useEffect(() => {
      if (tickets.length) {
        const categorizedTickets = {
          all: applyFilters(tickets.filter((ticket) => (ticket.status !== "open" && ticket.status !== "escalated"))),
          new: applyFilters(tickets.filter((ticket) => (ticket.status === "open" || ticket.status === "escalated"))),
          ongoing: applyFilters(
            tickets.filter((ticket) => ticket.status === "assigned")
          ),
          resolved: applyFilters(
            tickets.filter((ticket) => ticket.status === "closed")
          ),
        };
        setFilteredTickets(categorizedTickets);
      }
    }, [searchQuery, priorityFilter, timeFilter, tickets]);
  
    const handleCreateTicket = () => {
      navigate(`/support/create-ticket`);
    };
  
    const handleViewChange = (newView) => {
      setViewMode(newView);
    };
  
    console.log("filteredTickets",filteredTickets);
  return (
    <>
      <Box>
        <Box mb={2}>
          <Typography
            sx={{ fontSize: "20px", fontWeight: 600, color: "#2E2C34" }}
          >
            Tickets
          </Typography>
        </Box>

        <Box
          sx={{
            backgroundColor: "#fff",
            width: "100%",
            minHeight: "75vh",
            borderRadius: "4px",
            padding: 2,
          }}
        >
          {loading && ( 
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            zIndex: 1000,
          }}
        >
          <Loader />
        </Box>
      )}
          {/* Top Elements Box */}
          <Box sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            alignItems: { xs: 'stretch', md: 'center' },
            gap: { xs: 2, md: 0 },
          }}>
            {/* Search Box */}
            <Box sx={{
              display: 'flex',
              alignItems: 'center',
              bgcolor: '#F4F4F4',
              borderRadius: '10px',
              minWidth: { xs: '100%', md: '35%' },
              height: '40px',
            }}>
              <SearchIcon sx={{ color: '#B0B0B0', ml: 1 }} />
              <InputBase
                placeholder="Search for ticket"
                sx={{ flex: 1, ml: 1 }}
                value={searchQuery}
                onChange={handleSearchChange}
              />
            </Box>


            <Box sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              alignItems: { xs: 'stretch', md: 'center' },
              gap: { xs: 2, md: 2 },
              ml: { xs: 0, md: 'auto' },
              width: { xs: '100%', md: 'auto' }
            }}>
            {/* Filters */}
            <Box sx={{
              display: 'flex',
              gap: 2,
              width: { xs: '100%', md: 'auto' }
            }}>
              <Select
                displayEmpty
                value={priorityFilter}
                onChange={handlePriorityChange}
                IconComponent={(props) => (
                  <KeyboardArrowDownIcon {...props} sx={{ fontSize: '20px', color: '#B0B0B0' }} />
                )}
                sx={{
                  fontSize: '14px',
                  height: '40px',
                  width: { xs: '50%', md: '140px' },
                  bgcolor: 'white',
                  '& .MuiSelect-select': {
                    padding: '8px 14px',
                  },
                  border: '0.794px solid #E7E7E7',
                  borderRadius: '4px',
                  color: '#9A9FA5'
                }}
              >
                <MenuItem value="">Select Priority</MenuItem>
                <MenuItem value="High">High</MenuItem>
                <MenuItem value="Medium">Medium</MenuItem>
                <MenuItem value="Low">Low</MenuItem>
              </Select>

              <Select
                displayEmpty
                value={timeFilter}
                onChange={handleTimeChange}
                IconComponent={(props) => (
                  <KeyboardArrowDownIcon {...props} sx={{ fontSize: '20px', color: '#B0B0B0' }} />
                )}
                sx={{
                  fontSize: '14px',
                  height: '40px',
                  width: { xs: '50%', md: '140px' },
                  bgcolor: 'white',
                  '& .MuiSelect-select': {
                    padding: '8px 14px',
                  },
                  border: '0.794px solid #E7E7E7',
                  borderRadius: '4px',
                  color: '#9A9FA5'
                }}
              >
                <MenuItem value="">This Week</MenuItem>
                <MenuItem value="Today">Today</MenuItem>
                <MenuItem value="This Month">This Month</MenuItem>
              </Select>
            </Box>

            {/* New Ticket Button */}
            {/* <Button
              variant="contained"
              onClick={handleCreateTicket}
              startIcon={<EditOutlinedIcon />}
              sx={{
                bgcolor: '#09A2E3',
                height: '40px',
                width: { xs: '100%', md: 'auto' },
                minWidth: '140px',
                textTransform: 'none',
                '&:hover': {
                  bgcolor: '#09A2E3'
                },
                color:'white'
              }}
            >
              New Ticket
            </Button> */}

            {/* View Toggle */}
            <Box sx={{
              display: 'flex',
              gap: 1,
              ml: { md: 'auto' },
              justifyContent: { xs: 'center', md: 'flex-end' }
            }}>
              <IconButton
                onClick={() => handleViewChange('table')}
                sx={{
                  bgcolor: viewMode === 'table' ? 'rgba(0, 159, 245, 0.10)' : 'transparent',
                  color: '#09A2E3',
                  width: { xs: '45%', sm: '40px' },
                  height: '40px',
                  '&:hover': {
                    bgcolor: 'rgba(0, 159, 245, 0.15)'
                  }
                }}
              >
                <ListOutlinedIcon />
              </IconButton>
              <IconButton
                onClick={() => handleViewChange('card')}
                sx={{
                  bgcolor: viewMode === 'card' ? 'rgba(0, 159, 245, 0.10)' : 'transparent',
                  color: '#09A2E3',
                  width: { xs: '45%', sm: '40px' },
                  height: '40px',
                  '&:hover': {
                    bgcolor: 'rgba(0, 159, 245, 0.15)'
                  }
                }}
              >
                <GridViewOutlinedIcon />
              </IconButton>
            </Box>
            </Box>

          </Box>

          {/* Tabs Box */}
          <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 2, mt: 3 }}>
            <Box>
              <Tabs
                value={selectedTab}
                onChange={handleTabChange}
                textColor="primary"
                indicatorColor="primary"
                sx={{
                  "& .MuiTabs-indicator": {
                    backgroundColor: "#09A2E3",
                    height: "1px",
                    padding: "0px !important",
                  },
                  "& .MuiTab-root": {
                    textTransform: "none",
                    fontWeight: 600,
                    fontSize: "16px",
                    color: "#9A9FA5",
                    minWidth: "auto",
                    //padding: "8px 16px",
                    background: "transparent",
                    minHeight: 0,
                  },
                  "& .Mui-selected": {
                    color: "#09A2E3",
                    padding: "0px !important",
                  },
                }}
              >
                <Tab
                  label="All Tickets"
                  iconPosition="start"
                  icon={<EmailOutlinedIcon />}
                  sx={{
                    "& .MuiTab-label": {
                      fontSize: "10px",
                      fontWeight: 400,
                    },
                  }}
                  {...a11yProps(0)}
                />
                {/* <Tab
                  label="Open"
                  iconPosition="start"
                  icon={<MarkEmailUnreadOutlinedIcon />}
                  sx={{
                    "& .MuiTab-label": {
                      fontSize: "10px",
                      fontWeight: 400,
                    },
                  }}
                  {...a11yProps(1)}
                /> */}
                <Tab
                  label="In-progress"
                  iconPosition="start"
                  icon={<ForwardToInboxOutlinedIcon />}
                  sx={{
                    "& .MuiTab-label": {
                      fontSize: "10px",
                      fontWeight: 400,
                    },
                  }}
                  {...a11yProps(2)}
                />
                <Tab
                  label="Closed"
                  iconPosition="start"
                  icon={<MarkEmailReadOutlinedIcon />}
                  sx={{
                    "& .MuiTab-label": {
                      fontSize: "10px",
                      fontWeight: 400,
                    },
                  }}
                  {...a11yProps(3)}
                />
              </Tabs>
            </Box>
            <Box>
              <CustomTabPanel value={selectedTab} index={0}>
              {viewMode === 'table' ? (<TicketTableComp data={filteredTickets?.all} />
              ) : (
                <>
                {filteredTickets.all.map((ticket) => (
                  <TicketTableCard key={ticket.id} ticket={ticket} />
                ))}
                </>
              )}
              </CustomTabPanel>
              {/* <CustomTabPanel value={selectedTab} index={1}>
                  {filteredTickets?.new?.length > 0 ? (
                    viewMode === 'table' ? (
                      <TicketTableComp data={filteredTickets?.new} />
                    ) : (
                      <>
                        {filteredTickets.new.map((ticket) => (
                          <TicketTableCard key={ticket.id} ticket={ticket} />
                        ))}
                      </>
                    )
                  ) : (
                    <Typography
                      sx={{
                        textAlign: "center",
                        width: "100%",
                        py: 4, // padding vertical
                        color: "text.secondary", // optional: makes text slightly lighter
                      }}
                    >
                      No Records
                    </Typography>
                  )}
                </CustomTabPanel> */}
              <CustomTabPanel value={selectedTab} index={2}>
                {filteredTickets?.ongoing?.length > 0 ? (
                  viewMode === 'table' ? (
                      <TicketTableComp data={filteredTickets?.ongoing} />
                    ) : (
                      <>
                  {filteredTickets.ongoing.map((ticket) => (
                    <TicketTableCard key={ticket.id} ticket={ticket} />
                  ))}
                  </>
                )
                ) : (
                  <Typography
                    sx={{
                      textAlign: "center",
                      width: "100%",
                      py: 4, // padding vertical
                      color: "text.secondary", // optional: makes text slightly lighter
                    }}
                  >
                    No Records
                  </Typography>
                )}
              </CustomTabPanel>
              <CustomTabPanel value={selectedTab} index={3}>
                {filteredTickets?.resolved?.length > 0 ? (
                  viewMode === 'table' ? (
                      <TicketTableComp data={filteredTickets?.resolved} />
                    ) : (
                      <>
                  {filteredTickets.resolved.map((ticket) => (
                    <TicketTableCard key={ticket.id} ticket={ticket} />
                  ))}
                  </>
                    )
                ) : (
                  <Typography
                    sx={{
                      textAlign: "center",
                      width: "100%",
                      py: 4, // padding vertical
                      color: "text.secondary", // optional: makes text slightly lighter
                    }}
                  >
                    No Records
                  </Typography>
                )}
              </CustomTabPanel>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  )
}
