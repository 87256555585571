import React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TextField, Typography, Box } from '@mui/material';
import dayjs from 'dayjs';
import 'dayjs/locale/en-gb'; // Optional for locale if needed
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

export default function DateInput({ label, onChange, value }) {
  // Get the report date from local storage and today's date
  const reportDate = localStorage.getItem('IncidentDate');
  const parsedReportDate = reportDate ? dayjs(reportDate, 'DD-MM-YYYY') : null;
  const todayDate = dayjs(); // Today's date

  const handleDateChange = (newValue) => {
    onChange(newValue, label); // Pass the new date value to the onChange callback
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
      {/* Render label above the date picker input */}
      {label && (
        <Typography variant="body2" sx={{ color: '#7A8699' }}>
          {label}
        </Typography>
      )}
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          value={value ? dayjs(value, 'DD-MM-YYYY') : null} // Parse value in 'DD-MM-YYYY' format
          onChange={handleDateChange}
          format="DD-MM-YYYY"
          shouldDisableDate={(date) => {
            // Check for "Target completion date" or "Due date"
            if (label === 'Target completion date' || label === 'Due Date') {
              // Disable dates before the report date
              return parsedReportDate && date.isBefore(parsedReportDate, 'day');
            } else {
              // Default behavior: disable dates before report date or after today
              return (
                (parsedReportDate && date.isBefore(parsedReportDate, 'day')) || 
                date.isAfter(todayDate, 'day')
              );
            }
          }}
          slotProps={{
            textField: {
              InputLabelProps: { shrink: true },
              size: 'small',
              fullWidth: true,
            },
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              margin="normal"
              variant="outlined"
              fullWidth
              sx={{
                '& .MuiOutlinedInput-input': {
                  padding: '8px 12px',
                },
                '&.MuiInputBase-input': {
                  fontSize: '12px',
                },
              }}
            />
          )}
        />
      </LocalizationProvider>
    </Box>
  );
}
