import React, { useEffect, useState } from "react";
import {
  Box,
  Modal,
  TextField,
  Typography,
  IconButton,
  Button,
  Divider,
} from "@mui/material";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import { todayDate } from "../../constant";
import { useDispatch, useSelector } from "react-redux";
import { addNewCheckpoints } from "../../redux/LossControl/actions";
import DeleteIcon from "@mui/icons-material/Delete";

const AddPointModal = ({ open, handleClose, surveyDate }) => {
  const localData = localStorage.getItem("loginData");
  const data = localData ? JSON.parse(localData):{};
   const userFullName = `${data?.firstName || ""} ${data?.lastName || ""}`;
  const [questions, setQuestions] = useState([]);
  const [cards, setCards] = useState([]);

  const dispatch = useDispatch();
  const tourData = useSelector((state) => state.lossControlReducer.tours);

  useEffect(() => {
    setCards(tourData.questionnaire);
  }, [tourData.questionnaire]);

  const initialValues = {
    point: "",
  };

  const validationSchema = Yup.object().shape({
    point: Yup.string()
      .min(6, "Point should be at least 6 characters")
      .required("Point is required"),
  });

  const handleAddQuestion = (values, { resetForm }) => {
    if (values.point.trim()) {
      const isDuplicate = questions.some((question) => question === values.point.trim());
      if (!isDuplicate) {
        setQuestions([...questions, values.point]);
      }
      resetForm();
    }
  };

  const handleDeletePoint = (pointToDelete) => {
    setQuestions(questions.filter((question) => question !== pointToDelete));
  };

  const addCard = (newCard) => {
    dispatch(addNewCheckpoints(newCard))
    setCards([...cards, newCard]);
  };

  const handleSubmit = (values) => {
    // console.log("vaaalues",values);
    const allQuestions = [...questions];

    if (values.point?.trim() && values.point?.length > 5) {
      const isDuplicate = questions.some(
        (question) => question === values.point.trim()
      );
      if (!isDuplicate) {
        allQuestions.push(values.point);
      }
    }

    const newQuestions = allQuestions.map((question, index) => ({
      id: cards.length + index + 1,
      quesName: question,
      observation: "",
      data: null,
    }));

    newQuestions.forEach((newQuestion) => {
      addCard(newQuestion);
    });

    setQuestions([]);
    handleClose();
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 543,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          maxHeight: "75vh",
          overflowY: "auto",
        }}
      >
        <IconButton
          sx={{ position: "absolute", top: 8, right: 8 }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
        <Typography sx={{ mb: 0.5, color: "#222222", fontSize: "20px" }}>
          Add Loss Control Point
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
          <Box sx={{ display: "flex", alignItems: "center", mr: 2 }}>
            <PersonOutlineOutlinedIcon sx={{ fontSize: 20, mr: 0.5 }} />
            <Typography variant="body2" sx={{ color: "#525966" }}>
              {userFullName}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <CalendarMonthOutlinedIcon sx={{ fontSize: 20, mr: 0.5 }} />
            <Typography variant="body2" sx={{ color: "#525966" }}>
            {surveyDate
              ? new Date(surveyDate).toLocaleDateString('en-GB', {
                  day: '2-digit',
                  month: '2-digit',
                  year: 'numeric',
                })
              : 'N/A'}
            </Typography>
          </Box>
        </Box>
        <Divider sx={{ mb: 2 }} />
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleAddQuestion}
        >
          {({ values, errors, touched }) => (
            <Form>
              <Field
                as={TextField}
                name="point"
                placeholder="Enter the additional Point"
                fullWidth
                variant="outlined"
                error={touched.point && Boolean(errors.point)}
                helperText={touched.point && errors.point}
                sx={{
                  mb: 2,
                  borderRadius: "4px",
                  border: "0.5px solid  #B7C8E5",
                  backgroundColor: "#FFF",
                  boxShadow: "0px 1px 4px 0px rgba(0, 0, 0, 0.17)",
                }}
              />

              <Button
                type="submit"
                endIcon={<AddIcon />}
                sx={{ mb: 2, color: "#09A2E3", textTransform: "none" }}
              >
                Add
              </Button>

              {questions?.map((question, index) => (
                <Box
                  key={index}
                  sx={{
                    borderRadius: "0px 8px 8px 0px",
                    borderTop: "0.5px solid #09A2E3",
                    borderRight: "0.5px solid #09A2E3",
                    borderBottom: "0.5px solid #09A2E3",
                    borderLeft: "4px solid #09A2E3",
                    background: "rgba(230, 246, 254, 0.40)",
                    p: 2,
                    mb: 2,
                    wordWrap: "break-word",
                    position: "relative",
                  }}
                >
                  <Typography variant="body2">{question}</Typography>
                  <IconButton
                    sx={{
                      position: "absolute",
                      top: 4,
                      right: 4,
                      fontSize: 16,
                      color: "#FF4D4D95",
                      borderRadius:'50%',
                      backgroundColor:'#FF4D4D10'
                    }}
                    onClick={() => handleDeletePoint(question)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              ))}

              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  onClick={() => handleSubmit(values)}
                  disabled = {questions?.length === 0 && values?.point?.length < 6}
                  variant="contained"
                  sx={{
                    backgroundColor: "#09A2E3",
                    borderRadius: "24px",
                    fontWeight: "700",
                    color: "white",
                    width: "130px",
                  }}
                >
                  Save
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

export default AddPointModal;
