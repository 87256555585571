import axiosInstance from "./axiosInterceptor";

// ------------------------ Super Admin Modules APIs-------------------------//

export const addModule = (values) => {
  return axiosInstance({
    method: "POST",
    url: `/api/v1/superadmin/addModule`,
    data: values,
  });
};

export const getStaffAndEntity = (staffType) => {
  return axiosInstance({
    method: "GET",
    url: `/api/v1/user/getStaff?staffType=${staffType}`,
  });
};

export const getEntity = (type, id) => {
  return axiosInstance({
    method: "GET",
    url: `/api/v1/user/getEntity?staffType=${type}&staffId=${id}`,
  });
};

export const getSubActivity = (data) => {
  return axiosInstance({
    method: "GET",
    url: `/api/v1/user/getDropdowns?fieldName=activity&title=${data}`,
  });
};

export const getSubArea = (data) => {
  return axiosInstance({
    method: "GET",
    url: `/api/v1/user/getDropdowns?fieldName=Area&title=${data}`,
  });
};

export const getCAPAOwner = () => {
  return axiosInstance({
    method: "GET",
    url: `api/v1/user/getCAPAOwners`,
  });
};

// ------------------------ Super Admin Roles APIs----------------------------------------//

export const getRoles = () => {
  return axiosInstance({
    method: "GET",
    url: `/api/v1/superadmin/listRoles`,
  });
};

export const addRole = (values) => {
  return axiosInstance({
    method: "POST",
    url: `/api/v1/superadmin/addRoles`,
    data: values,
  });
};

export const updateRole = (roleID, values) => {
  return axiosInstance({
    method: "PATCH",
    url: `/api/v1/superadmin/updateRole/${roleID}`,
    data: values,
  });
};

//---------------------------------------Map APIs-------------------------------------------------//

export const getUserNearbyLocations = () => {
  return axiosInstance({
    method: "GET",
    url: `/api/v1/user/getUserNearbyLocations?search=`,
  });
};

export const acceptOrRevertReportByMM = (values, id) => {
  return axiosInstance({
    method: "POST",
    url: `/api/v1/user/acceptOrRevertTask/${id}`,
    data: values,
  });
};

/* ---------------------------- Loss Control API ---------------------------- */

export const submitLossControlReport = (values) => {
  return axiosInstance({
    method: "POST",
    url: `/api/v1/user/submitLossControlReport`,
    data: values,
  });
};

export const acceptOrRevertLossControlReport = (values, id) => {
  return axiosInstance({
    method: "POST",
    url: `/api/v1/user/acceptOrRevertLossControlReport/${id}`,
    data: values,
  });
};

export const getLossControlReport = (id, status) => {
  return axiosInstance({
    method: "GET",
    url: `/api/v1/user/getLossControlReport/${id}`,
    params: {
      questionStatus: status,
    },
  });
};

export const getPastLossControlTours = (locationID) => {
  return axiosInstance({
    method: "GET",
    url: `/api/v1/user/getPastLossControlTours/${locationID}`,
  });
};
export const completeOrSaveTask = (values) => {
  return axiosInstance({
    method: "POST",
    url: `/api/v1/user/completeOrSaveTask`,
    data: values,
  });
};
export const revalidateLossControlReport = (reportID, values) => {
  return axiosInstance({
    method: "POST",
    url: `api/v1/user/revalidateLossControlReport/${reportID}`,
    data: values,
  });
};
export const revalidateTask = (values) => {
  return axiosInstance({
    method: "POST",
    url: `api/v1/user/revalidateTask`,
    data: values,
  });
};

// get repost catd data api for action page

export const getAllReports = () => {
  return axiosInstance({
    method: "GET",
    url: `/api/v1/user/getReports`,
  });
};

export const getAllReportsByModule = (ModuleID) => {
  return axiosInstance({
    method: "GET",
    url: `/api/v1/user/getReports/?moduleID=${ModuleID}`,
  });
};

export const getAllStatiCard = () => {
  return axiosInstance({
    method: "GET",
    url: `/api/v1/user/getReportsStats`,
  });
};

export const getMyStatiCard = () => {
  return axiosInstance({
    method: "GET",
    url: `/api/v1/user/getMyReportsStats`,
  });
};

export const getMyStatiCardByModule = (ModuleID) => {
  return axiosInstance({
    method: "GET",
    url: `/api/v1/user/getMyReportsStats/?moduleID=${ModuleID}`,
  });
};

export const getAllStatiCardByModule = (ModuleID) => {
  return axiosInstance({
    method: "GET",
    url: `/api/v1/user/getReportsStats/?moduleID=${ModuleID}`,
  });
};

export const getHistoryStatis = (moduleID, roleIDs, status, action) => {
  return axiosInstance({
    method: "GET",
    url: `api/v1/user/getHistoryStats?action=${action}&completed=${status}&role=${roleIDs}&moduleID=${moduleID}`,
  });
};

export const getLossControlMapData = (values) => {
  const unselectedDisciplinesArray = values.unselectedDisciplines
    ? values.unselectedDisciplines.split(",").map((item) => item.trim())
    : [];

  return axiosInstance({
    method: "POST",
    url: `/api/v1/user/getLossControlMapData`,
    data: {
      discipline: unselectedDisciplinesArray,
    },
  });
};

export const getMyReports = () => {
  return axiosInstance({
    method: "GET",
    url: `/api/v1/user/getMyReports`,
  });
};

export const getMyReportsByModule = (ModuleID) => {
  return axiosInstance({
    method: "GET",
    url: `/api/v1/user/getMyReports/?moduleID=${ModuleID}`,
  });
};

export const getFilterdropValues = () => {
  return axiosInstance({
    method: "GET",
    url: `api/v1/user/getFilters`,
  });
};

export const DashBoardData = () => {
  return axiosInstance({
    method: "GET",
    url: `/api/v1/user/getLossControlDashboardData`,
  });
};

export const DashBoardDataWithFilter = (month, locationID) => {
  return axiosInstance({
    method: "GET",
    url: `/api/v1/user/getLossControlDashboardData`,
    params: {
      month,
      locationID: Array.isArray(locationID) ? locationID.join(",") : locationID, // Convert array to comma-separated string
    },
    paramsSerializer: (params) => {
      return new URLSearchParams(params).toString();
    },
  });
};

export const getAssignedLocationsTree = () => {
  return axiosInstance({
    method: "GET",
    url: `/api/v1/user/getAssignedLocationsTree`,
  });
};

export const ReportHistoryList = (moduleID, roleIDs, status = false) => {
  return axiosInstance({
    method: "GET",
    url: `/api/v1/user/getHistory?moduleID=${moduleID}&role=${roleIDs}&completed=${status}`,
  });
};

export const ReportCompletedHistoryList = (
  moduleID,
  roleIDs,
  status = true
) => {
  return axiosInstance({
    method: "GET",
    url: `/api/v1/user/getHistory?moduleID=${moduleID}&role=${roleIDs}&completed=${status}`,
  });
};

export const getOverallStatusTimeline = (reportID) => {
  return axiosInstance({
    method: "GET",
    url: `/api/v1/user/getOverallStatus/${reportID}?moduleID=2`,
  });
};

/* -------------------------------- Calendar -------------------------------- */

export const getCalendarDateActions = (month, year) => {
  return axiosInstance({
    method: "GET",
    url: `/api/v1/user/getAllModulesActionsBasedOnDate`,
    params: {
      month,
      year,
    },
  });
};

/* --------------------------------- PROFILE --------------------------------- */

export const logoutUser = () => {
  return axiosInstance({
    method: "GET",
    url: `/api/v1/auth/logout`,
  });
};

export const changePassword = (passwords) => {
  return axiosInstance({
    method: "POST",
    url: `/api/v1//user/changePassword`,
    data: passwords,
  });
};

export const changeDefaultLocation = (location) => {
  return axiosInstance({
    method: "PUT",
    url: `/api/v1/user/changeDefaultLocation`,
    data: location,
  });
};

export const getUserAssignedLocations = () => {
  return axiosInstance({
    method: "GET",
    url: `/api/v1/user/getUserRoles`,
  });
};

export const getSiteLocationName = (id) => {
  return axiosInstance({
    method: "GET",
    url: `api/v1/user/getLocationData/${id}`,
  });
};

export const getAllLocationName = () => {
  return axiosInstance({
    method: "GET",
    url: "/api/v1/user/getLocationsTree",
  });
};

export const dashboardBroadCast = () => {
  return axiosInstance({
    method: "GET",
    url: "/api/v1/user/getDashboardMetaInformation",
  });
};

export const homePageTreeData = (month, locationID) => {
  return axiosInstance({
    method: "GET",
    url: `/api/v1/user/getMainDashboardData`,
    params: {
      month,
      locationID: Array.isArray(locationID) ? locationID.join(",") : locationID, // Convert array to comma-separated string
    },
    paramsSerializer: (params) => {
      return new URLSearchParams(params).toString();
    },
  });
};

export const getAllReportsPDFByModule = (moduleID) => {
  return axiosInstance({
    method: "GET",
    url: `/api/v1/user/getAllReports`,
    params: {
      moduleID: moduleID, // Convert array to comma-separated string
    },
    paramsSerializer: (params) => {
      return new URLSearchParams(params).toString();
    },
  });
};

export const getModuleWithReportCount = () => {
  return axiosInstance({
    method: "GET",
    url: `api/v1/user/getReportsCount`,
  });
};

/* --------------------------------- HELP PAGE API --------------------------------- */

export const helpTicket = (values) => {
  return axiosInstance({
    method: "POST",
    url: `api/v1/auth/helpTicket`,
    data: values,
  });
};

/* --------------------------------- FORGET PASSWORD APIS --------------------------------- */

export const sendOtp = (email) => {
  return axiosInstance({
    method: "POST",
    url: `api/v1/auth/sendOtp`,
    data: email,
  });
};

export const verifyOtp = (values) => {
  return axiosInstance({
    method: "POST",
    url: `api/v1/auth/verifyOtp`,
    data: values,
  });
};

export const setNewPassword = (values) => {
  return axiosInstance({
    method: "POST",
    url: `api/v1/auth/setNewPassword`,
    data: values,
  });
};

/* ----------------------------- Support Ticket ----------------------------- */

export const createTicket = (values) => {
  return axiosInstance({
    method: "POST",
    url: `/api/v1/user/createTicket`,
    data: values,
  });
};

export const getAllTickets = () => {
  return axiosInstance({
    method: "GET",
    url: `/api/v1/user/getTickets`,
  });
};

export const viewTicket = (id) => {
  return axiosInstance({
    method: "GET",
    url: `/api/v1/user/getTicket/${id}`,
  });
};

export const withdrawTicket = (id) => {
  return axiosInstance({
    method: "PUT",
    url: `/api/v1/user/withdrawTicket/${id}`,
  });
};

export const getModuleSections = (id) => {
  return axiosInstance({
    method: "GET",
    url: `/api/v1/user/getTicketSections/${id}`,
  });
};

export const updateProfile = (values) => {
  return axiosInstance({
    method: "PUT",
    url: `/api/v1/user/updateProfile`,
    data: values,
  });
};

export const updateTicket = (values, id) => {
  return axiosInstance({
    method: "PUT",
    url: `/api/v1/user/updateTicket/${id}`,
    data: values,
  });
};

export const updateTicketStatusByUser = (values) => {
  return axiosInstance({
    method: "PUT",
    url: `/api/v1/user/updateTicketStatusByUser`,
    data: values,
  });
};

export const getMessages = (ticketID) => {
  return axiosInstance({
    method: "GET",
    url: `/api/v1/user/getMessages/${ticketID}`,
  });
};

export const getUsersForOrganization = () => {
  return axiosInstance({
    method: "GET",
    url: `/api/v1/user/getUsersForOrganization`,
  });
};

//----------Support Admin Tickets---------------//

export const getIEDAgents = () => {
  return axiosInstance({
    method: "GET",
    url: `/api/v1/supportAdmin/getIEDAgents`,
  });
};

export const getTickets = (id) => {
  return axiosInstance({
    method: "GET",
    url: `/api/v1/supportAdmin/getTickets`,
    params: {
      agentID: id,
    },
  });
};

export const updateTicketStatusByAdmin = (values) => {
  return axiosInstance({
    method: "PUT",
    url: `/api/v1/supportAdmin/updateTicketStatusByAdmin`,
    data: values,
  });
};

export const createTicketbyAdmin = (values) => {
  return axiosInstance({
    method: "POST",
    url: `/api/v1/supportAdmin/createTicket`,
    data: values,
  });
};

/* ----------------------------- Help Center ----------------------------- */

export const addVideoTutorial = (values) => {
  return axiosInstance({
    method: "POST",
    url: `/api/v1/iedClientAdmin/addVideoTutorial`,
    data: values,
  });
};

export const addHelpManual = (values) => {
  return axiosInstance({
    method: "POST",
    url: `/api/v1/iedClientAdmin/addHelpManual`,
    data: values,
  });
};

export const addFAQ = (values) => {
  return axiosInstance({
    method: "POST",
    url: `/api/v1/iedClientAdmin/addFAQ`,
    data: values,
  });
};

// --------------incident APis ------------------------------//
//--------------------------------------------------------------------//

export const getAllLocation = () => {
  return axiosInstance({
    method: "GET",
    url: `/api/v1/user/getUserNearbyLocations`,
  });
};

export const createIncident = (data) => {
  return axiosInstance({
    method: "POST",
    url: `api/v1/user/reportIncident`,
    data: data,
  });
};

// landing page Api

export const getLandingPageStats = () => {
  return axiosInstance({
    method: "GET",
    url: `api/v1/user/getLandingPageStats`,
  });
};




export const getIncidentViewData = (incidentID) => {
  return axiosInstance({
    method: "GET",
    url: `api/v1/user/getIncidentReportData/${incidentID}`,
  });
};


//--------------------incident workflow apis -----------------------//

export const firstReviwerReturnAndAccept = (data,incidentId) => {
  return axiosInstance({
    method: "POST",
    url: `api/v1/user/acceptOrRevertIncidentReportByFR/${incidentId}`,
    data: data,
  });
};



export const secondReviwerReturnAndAccept = (data,incidentId) => {
  return axiosInstance({
    method: "POST",
    url: `api/v1/user/acceptOrRevertIncidentReportBySR/${incidentId}`,
    data: data,
  });
};


export const investigatorReturnAndAccept = (data,incidentId) => {
  return axiosInstance({
    method: "POST",
    url: `api/v1/user/completeOrRevertInvestigation/${incidentId}`,
    data: data,
  });
};



export const firstReviwerInvestigationReturnAndAccept = (data,incidentId) => {
  return axiosInstance({
    method: "POST",
    url: `/api/v1/user/acceptOrRevertInvestigationByFR/${incidentId}`,
    data: data,
  });
};


export const secondReviwerInvestigationReturnAndAccept = (data,incidentId) => {
  return axiosInstance({
    method: "POST",
    url: `/api/v1/user/acceptOrRevertInvestigationBySR/${incidentId}`,
    data: data,
  });
};






//------------------second reviwer workflow invest asifn drop value api  -----------------//

export const getInvestigationLeadersList = (locationID) => {
  return axiosInstance({
    method: "GET",
    url: `api/v1/user/getInvestigationLeaders/${locationID}`,
  });
};

export const getInvestigationEmployees = (locationID) => {
  return axiosInstance({
    method: "GET",
    url: `/api/v1/user/getInvestigationEmployees/${locationID}`,
  });
};


export const getOverallIncidentStatusTimeline = (reportID) => {
  return axiosInstance({
    method: "GET",
    url: `/api/v1/user/getOverallStatus/${reportID}?moduleID=1`,
  });
};


export const updateIncidentReportForm = (data,incidentId ,type) => {
  return axiosInstance({
    method: "POST",
    url: `/api/v1/user/resubmitIncident/${incidentId}?action=${type}`,
    data: data,
  });
};


//------------------Help manual APIs--------------------//

export const getVideoTutorials = () => {
  return axiosInstance({
    method: "GET",
    url: `/api/v1/user/getVideoTutorials`,
  });
};

export const getFAQs = () => {
  return axiosInstance({
    method: "GET",
    url: `/api/v1/user/getFAQs`,
  });
};

export const getHelpManuals = () => {
  return axiosInstance({
    method: "GET",
    url: `/api/v1/user/getHelpManuals`,
  });
};


//  ----------------CAPA apis ===------------------------//


export const getCAPAViewData = (capaId) => {
  return axiosInstance({
    method: "GET",
    url: `/api/v1/user/getCapaData/${capaId}`,
  });
};



export const CAPAAcceptAndReturn = (data ,capaId) => {
  return axiosInstance({
    method: "POST",
    url: `api/v1/user/completeOrRevertCAPA/${capaId}`,
    data:data,
  });
};

export const acceptAndReturnCAPAByFR = (data ,capaId) => {
  return axiosInstance({
    method: "POST",
    url: `api/v1/user/acceptOrRevertCAPAByFR/${capaId}`,
    data:data,
  });
};

export const acceptAndReturnCAPABySR = (data ,capaId) => {
  return axiosInstance({
    method: "POST",
    url: `api/v1/user/acceptOrRevertCAPABySR/${capaId}`,
    data:data,
  });
};







export const getAllCAPAListData = (incidentId) => {
  return axiosInstance({
    method: "GET",
    url: `/api/v1/user/getCapasForReport/${incidentId}`
  });
};



export const resubmitCAPABySR = (data,capaId) => {
  return axiosInstance({
    method: "POST",
    url: `api/v1/user/resubmitCAPA/${capaId}`,
    data:data,
  });
};


export const uiConfigData =()=>{
  return axiosInstance({
    method: "GET",
    url: `api/v1/auth/getUserConfigData`,
  });

}


/* ------------------------- Draft APIs Loss Control ------------------------ */

export const saveLossControlData = (values) => {
  return axiosInstance({
    method: 'POST',
    url:`/api/v1/user/saveLossControlDraft`,
    data: values,
  })
}

export const getSurveyDrafts = (id) => {
  return axiosInstance({
    method: 'GET',
    url: `/api/v1/user/getDrafts`,
    params: {
      moduleID: id
    },
  })
}

export const getLossControlDraft = (draftID) => {
  return axiosInstance({
    method: 'GET',
    url: `/api/v1/user/getLossControlDraftData/${draftID}`
  })
}







