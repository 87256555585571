import React, { useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import ButtonGroup from "@mui/material/ButtonGroup";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import CloseIcon from "@mui/icons-material/Close";
import Chip from "@mui/material/Chip";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const AddActionDrawer = ({ open, onClose }) => {
  const [CAPATypeselected, setCAPATypeSelected] = useState("Corrective");
  const [priorityselected, setPrioritySelected] = useState("Low");
  const [capaOwner, setCapaOwner] = useState("Select");

  const handleClick = (value) => {
    setCAPATypeSelected(value);
  };
  const priorityHandleClick = (value) => {
    setPrioritySelected(value);
  };

  const handleChange = (event) => {
    setCapaOwner(event.target.value);
  };

  const top100Films = [
    { title: "Move Forward" },
    { title: "EMP 24" },
    { title: "Auto" },
  ];

  const handleAddData = () => {
    console.log("Add data");
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      sx={{
        padding: 2,
        boxShadow: "-4px 0px 28px 0px rgba(0, 0, 0, 0.20)",
        "& .MuiDrawer-paper": {
          width: "469px", // Ensure the paper within the drawer also has the same width
        },
      }}
    >
      <>
        <Box>
          <Box
            sx={{
              marginLeft: "-16px",
              marginTop: "-16px",
              borderBottom: "1px solid rgba(171, 171, 171, 0.50)",
              background: "#FFF",
              boxShadow: "0px 2px 4px 0px #ABABAB",
              padding: "16px 24px",
              width: "500px",
            }}
          >
            <Typography
              variant="h6"
              sx={{ color: "#000", fontSize: "20px", fontWeight: "600" }}
            >
              Add Action
            </Typography>
          </Box>

          {/* CAPA Type */}

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: 2,
              padding: 2,
            }}
          >
            {/* CAPA Type */}
            <Box>
              <Typography
                variant="subtitle1"
                sx={{
                  mb: 1,
                  color: "#000000",
                  fontSize: "14px",
                  fontWeight: "400",
                }}
              >
                CAPA Type
              </Typography>
              <ButtonGroup
                variant="outlined"
                color="primary"
                sx={{
                  borderRadius: "0px 4px 4px 0px",
                  border: "1px solid #B7C8E5",
                  background: "#FFF",
                  border: "none",
                }}
              >
                <Button
                  sx={{
                    color:
                      CAPATypeselected === "Corrective" ? "#FFF" : "#367CFF",
                    fontSize: "12px",
                    fontWeight: "600",
                    backgroundColor:
                      CAPATypeselected === "Corrective" ? "#367CFF" : "#FFF",
                    "&:hover": {
                      backgroundColor:
                        CAPATypeselected === "Corrective"
                          ? "#367CFF"
                          : "#F0F0F0",
                    },
                  }}
                  onClick={() => handleClick("Corrective")}
                >
                  Corrective
                </Button>
                <Button
                  sx={{
                    color:
                      CAPATypeselected === "Preventive" ? "#FFF" : "#367CFF",
                    fontSize: "12px",
                    fontWeight: "600",
                    backgroundColor:
                      CAPATypeselected === "Preventive" ? "#367CFF" : "#FFF",
                    "&:hover": {
                      backgroundColor:
                        CAPATypeselected === "Preventive"
                          ? "#367CFF"
                          : "#F0F0F0",
                    },
                  }}
                  onClick={() => handleClick("Preventive")}
                >
                  Preventive
                </Button>
              </ButtonGroup>
            </Box>

            {/* Priority */}
            <Box>
              <Typography
                variant="subtitle1"
                sx={{
                  mb: 1,
                  color: "#000000",
                  fontSize: "14px",
                  fontWeight: "400",
                }}
              >
                Priority
              </Typography>
              <ButtonGroup
                variant="outlined"
                color="primary"
                sx={{
                  borderRadius: "0px 4px 4px 0px",
                  border: "1px solid #B7C8E5",
                  background: "#FFF",
                  border: "none",
                }}
              >
                <Button
                  sx={{
                    color: priorityselected === "Low" ? "#FFF" : "#367CFF",
                    fontSize: "12px",
                    fontWeight: "600",
                    backgroundColor:
                      priorityselected === "Low" ? "#367CFF" : "#FFF",
                    "&:hover": {
                      backgroundColor:
                        priorityselected === "Low" ? "#367CFF" : "#F0F0F0",
                    },
                  }}
                  onClick={() => priorityHandleClick("Low")}
                >
                  Low
                </Button>
                <Button
                  sx={{
                    color: priorityselected === "Medium" ? "#FFF" : "#367CFF",
                    fontSize: "12px",
                    fontWeight: "600",
                    backgroundColor:
                      priorityselected === "Medium" ? "#367CFF" : "#FFF",
                    "&:hover": {
                      backgroundColor:
                        priorityselected === "Medium" ? "#367CFF" : "#F0F0F0",
                    },
                  }}
                  onClick={() => priorityHandleClick("Medium")}
                >
                  Medium
                </Button>
                <Button
                  sx={{
                    color: priorityselected === "High" ? "#FFF" : "#367CFF",
                    fontSize: "12px",
                    fontWeight: "600",
                    backgroundColor:
                      priorityselected === "High" ? "#367CFF" : "#FFF",
                    "&:hover": {
                      backgroundColor:
                        priorityselected === "High" ? "#367CFF" : "#F0F0F0",
                    },
                  }}
                  onClick={() => priorityHandleClick("High")}
                >
                  High
                </Button>
              </ButtonGroup>
            </Box>
          </Box>
          {/* Observation */}
          <TextField
            label="Observation"
            placeholder="Observation"
            fullWidth
            sx={{
              mb: 2,
              "& .MuiOutlinedInput-root": {
                borderRadius: "4px", // Border radius
                "& fieldset": {
                  border: "1px solid #C1C1C1", // Border color and style
                },
                "&:hover fieldset": {
                  borderColor: "#949494", // Border color on hover
                },
                "&.Mui-focused fieldset": {
                  border: "1px solid #C1C1C1", // Border color and style, // Border color when focused
                },
              },
              "& .MuiInputBase-input": {
                color: "#949494", // Input text color
                fontWeight: "500",
                fontSize: "12px",
                height: "2.4375em",
              },
              "& .MuiFormLabel-root": {
                color: "#525966",
                fontWeight: "400",
                letterSpacing: "0.15px",
              },
            }}
          />

          <TextField
            label="Recommendation"
            placeholder="Recommendation"
            fullWidth
            sx={{
              mb: 2,
              "& .MuiOutlinedInput-root": {
                borderRadius: "4px", // Border radius
                "& fieldset": {
                  border: "1px solid #C1C1C1", // Border color and style
                },
                "&:hover fieldset": {
                  borderColor: "#949494", // Border color on hover
                },
                "&.Mui-focused fieldset": {
                  border: "1px solid #C1C1C1", // Border color and style, // Border color when focused
                },
              },
              "& .MuiInputBase-input": {
                color: "#949494", // Input text color
                fontWeight: "500",
                fontSize: "12px",
                height: "2.4375em",
              },
              "& .MuiFormLabel-root": {
                color: "#525966",
                fontWeight: "400",
                letterSpacing: "0.15px",
              },
            }}
          />
          {/* CAPA Owner */}
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Capa Owner</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={capaOwner}
              onChange={handleChange}
              input={<OutlinedInput />}
              sx={{
                mb: 2,
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "1px solid #C1C1C1", // Default border
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  border: "1px solid #949494", // Border on hover
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  border: "1px solid #949494", // Border when focused
                },
                "& .MuiSelect-select": {
                  color: "#757575", // Text color of the selected option
                  fontSize: "14px",
                  padding: "10px", // Padding inside the dropdown
                },
                borderRadius: "4px",
              }}
            >
              <MenuItem value="" disabled>
                Select
              </MenuItem>
              <MenuItem value={10}>CAPA Owner 1</MenuItem>
              <MenuItem value={20}>CAPA Owner 2</MenuItem>
              <MenuItem value={30}>CAPA Owner 3</MenuItem>
            </Select>
          </FormControl>

          {/* Team Members */}
          <Autocomplete
            multiple
            id="checkboxes-tags-demo"
            options={top100Films}
            disableCloseOnSelect
            getOptionLabel={(option) => option.title}
            renderOption={(props, option, { selected }) => {
              const { key, ...optionProps } = props;
              return (
                <li key={key} {...optionProps}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.title}
                </li>
              );
            }}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  key={option.title}
                  label={option.title}
                  {...getTagProps({ index })}
                  onDelete={getTagProps({ index }).onDelete}
                  deleteIcon={<CloseIcon style={{ color: "#4591F4" }} />}
                  style={{
                    backgroundColor: "#E3EFFD", // Light blue background
                    color: "#4591F4", // Blue text color
                    borderRadius: "20px",
                    padding: "5px 10px",
                    margin: "2px",
                    display: "flex",
                    alignItems: "center",
                  }}
                />
              ))
            }
            style={{ width: 440, marginBottom: "20px" }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Team Members"
                placeholder="Team Members"
                sx={{
                  "& .MuiInputBase-input": {
                    color: "#949494", // Set the input field text color
                  },
                }}
              />
            )}
          />

          {/* Department */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              gap: 2,
            }}
          >
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Department</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={capaOwner}
                label="Department"
                onChange={handleChange}
                input={<OutlinedInput label="Name" />}
                sx={{
                  mb: 2,
                  color: "#949494",
                  fontFamily: "Roboto",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "24px", // 171.429%
                  borderRadius: "4px",
                  width: "250px",
                  // border: "1px solid #C1C1C1",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "1px solid #C1C1C1",
                    },
                    "&.Mui-focused fieldset": {
                      border: "1px solid #C1C1C1", // Border color and style, // Border color when focused
                    },
                  },
                }}
              >
                <MenuItem value={10}>Select</MenuItem>
                <MenuItem value={20}>CAPA Owner 1</MenuItem>
                <MenuItem value={30}>CAPA Owner 2</MenuItem>
              </Select>
            </FormControl>
            <TextField
              label="Due Date"
              type="date"
              defaultValue="2024-08-29"
              InputLabelProps={{ shrink: true }}
              sx={{ mb: 2, width: "300px" }}
            />
          </Box>

          {/* Assets */}
          <Autocomplete
            multiple
            id="checkboxes-tags-demo"
            options={top100Films}
            disableCloseOnSelect
            getOptionLabel={(option) => option.title}
            renderOption={(props, option, { selected }) => {
              const { key, ...optionProps } = props;
              return (
                <li key={key} {...optionProps}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.title}
                </li>
              );
            }}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  key={option.title}
                  label={option.title}
                  {...getTagProps({ index })}
                  onDelete={getTagProps({ index }).onDelete}
                  deleteIcon={<CloseIcon style={{ color: "#4591F4" }} />}
                  style={{
                    backgroundColor: "#E3EFFD", // Light blue background
                    color: "#4591F4", // Blue text color
                    borderRadius: "20px",
                    padding: "5px 10px",
                    margin: "2px",
                    display: "flex",
                    alignItems: "center",
                  }}
                />
              ))
            }
            style={{ width: 440, marginBottom: "20px" }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Assets"
                placeholder="Assets"
                sx={{
                  "& .MuiInputBase-input": {
                    color: "#949494", // Set the input field text color
                  },
                }}
              />
            )}
          />

          {/* Due Date */}

          {/* Cost Involved */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Box>
              <Typography
                variant="subtitle1"
                sx={{
                  mb: 1,
                  color: "#000000",
                  fontSize: "14px",
                  fontWeight: "400",
                }}
              >
                Cost Involved
              </Typography>
              <ButtonGroup
                variant="outlined"
                color="primary"
                sx={{
                  borderRadius: "0px 4px 4px 0px",
                  border: "1px solid #B7C8E5",
                  background: "#FFF",
                  border: "none",
                }}
              >
                <Button
                  sx={{
                    color:
                      CAPATypeselected === "Corrective" ? "#FFF" : "#367CFF",
                    fontSize: "12px",
                    fontWeight: "600",
                    backgroundColor:
                      CAPATypeselected === "Corrective" ? "#367CFF" : "#FFF",
                    "&:hover": {
                      backgroundColor:
                        CAPATypeselected === "Corrective"
                          ? "#367CFF"
                          : "#F0F0F0",
                    },
                  }}
                  onClick={() => handleClick("Corrective")}
                >
                  Yes
                </Button>
                <Button
                  sx={{
                    color:
                      CAPATypeselected === "Preventive" ? "#FFF" : "#367CFF",
                    fontSize: "12px",
                    fontWeight: "600",
                    backgroundColor:
                      CAPATypeselected === "Preventive" ? "#367CFF" : "#FFF",
                    "&:hover": {
                      backgroundColor:
                        CAPATypeselected === "Preventive"
                          ? "#367CFF"
                          : "#F0F0F0",
                    },
                  }}
                  onClick={() => handleClick("Preventive")}
                >
                  No
                </Button>
              </ButtonGroup>
            </Box>

            <TextField
              label="Approx Cost"
              placeholder="Approx Cost"
              sx={{
                width: "75%",
                mb: 2,
                "& .MuiOutlinedInput-root": {
                  borderRadius: "4px", // Border radius
                  "& fieldset": {
                    border: "1px solid #C1C1C1", // Border color and style
                  },
                  "&:hover fieldset": {
                    borderColor: "#949494", // Border color on hover
                  },
                  "&.Mui-focused fieldset": {
                    border: "1px solid #C1C1C1", // Border color and style, // Border color when focused
                  },
                },
                "& .MuiInputBase-input": {
                  color: "#949494", // Input text color
                  fontWeight: "500",
                  fontSize: "12px",
                  height: "2.4375em",
                },
                "& .MuiFormLabel-root": {
                  color: "#525966",
                  fontWeight: "400",
                  letterSpacing: "0.15px",
                },
              }}
            />
          </Box>
          {/* Attachment */}
          <Box
            sx={{
              border: "1px dashed gray",
              borderRadius: 2,
              textAlign: "center",
              p: 2,
              mb: 2,
            }}
          >
            <Typography
              variant="subtitle1"
              sx={{
                mb: 1,
                color: "#000000",
                fontSize: "14px",
                fontWeight: "400",
              }}
            >
              Attachment
            </Typography>
            <IconButton color="primary" component="label">
              <div
                style={{
                  background: "#E9E9E9",
                  borderRadius: "50%",
                  width: "30px", // Specify a fixed width
                  height: "30px", // Same height as width to make it a circle
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: 0, // Remove padding to keep the circle's size consistent
                }}
              >
                <CloudUploadIcon sx={{ color: "#000", fontSize: "20px" }} />
              </div>

              <input type="file" hidden />
            </IconButton>
            <Typography
              variant="body2"
              sx={{
                mb: 1,
                color: "#000000",
                fontSize: "9px",
                fontWeight: "600",
              }}
            >
              <span
                style={{
                  color: "#348BD2",
                  fontWeight: "700",
                  textDecorationLine: "underline",
                  textDecorationStyle: "solid",
                }}
              >
                Click to upload
              </span>
              <br />
              PNG, JPG, Docx, Pdf and Mp4 (max. 10 MB)
            </Typography>
          </Box>
          {/* Add Button */}
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddData}
            sx={{
              background: "#367CFF",
              color: "#FFFFFF",
              fontSize: "16px",
              fontWeight: "600",
              borderRadius: "60px",
              border: "1px solid #367cff",
              "&:hover": {
                background: "#367CFF", // Remove the hover background
                border: "1px solid #367CFF", // Keep the border as it is
                color: "#FFFFFF", // Maintain the text color
              },
            }}
          >
            Add
          </Button>
        </Box>
      </>

      {/* Drawer Content */}
    </Drawer>
  );
};

export default AddActionDrawer;
