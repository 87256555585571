import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import Typography from "@mui/material/Typography";
import { Link, useLocation } from "react-router-dom";
import CalendarTodayOutlined from "@mui/icons-material/CalendarTodayOutlined";
import HomeIcon from "@mui/icons-material/Home"; // Assuming you have other icons imported similarly
import { Box, Divider, Snackbar } from "@mui/material";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import TaskOutlinedIcon from "@mui/icons-material/TaskOutlined";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import MenuBookOutlinedIcon from "@mui/icons-material/MenuBookOutlined";
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import LogoutIcon from '@mui/icons-material/Logout';
import { useNavigate } from "react-router-dom";
import { logoutUser } from "../../Apis/apiCall";

const drawerWidth = 227;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  borderRight: "none",
  backgroundColor: "#FFF",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(9)} + 1px)`,
  borderRight: "10px",
  backgroundColor: "#FFF",
  padding: "12px !important",
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: open ? drawerWidth : `calc(${theme.spacing(9)} + 1px)`,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const TicketSideMenu = ({ open, handleDrawerOpen }) => {
  const navigate = useNavigate();

  const data = JSON.parse(localStorage.getItem("loginData"));
  const role = data.config.permissionDetails.applicationRoles[0].name;
  const sidebarLogo = data?.config?.organizationConfig?.logo;
  const orgName = data?.config?.organizationConfig?.name;
  const currWorkLocation = data?.currentWorkLocation;
  const location = useLocation();
  const [menuItems, setMenuItems] = useState([]);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "",
  });

  const getBasePath = (pathname) => {
    const segments = pathname.split("/"); // Split by "/"
    return `/${segments[1]}`; // Get the first two segments like "/module/incident-management"
  };

  const currentBasePath = getBasePath(location.pathname);

  useEffect(() => { 

    const hardcodedMenuItems = [
      {
        text: "Home",
        path: "/ticket-dashboard",
        icon: <HomeOutlinedIcon />
      },
      {
        text: "Tickets",
        path: "/support",
        icon: <DashboardOutlinedIcon />
      },
      {
        text: "New Ticket",
        path: "/support/create-ticket",
        icon: <ModeEditOutlineOutlinedIcon />
      },
      {
        text: "Agents",
        path: "/agents",
        icon: <PersonOutlineIcon />
      },
      {
        text: "Profile",
        path: "/profile/view-profile",
        icon: <PersonOutlineIcon />
      },
      {
        text: "Logout",
        path: "",
        icon: <LogoutIcon />
      },
    ];

    if(role === "Support Admin"){
      setMenuItems(hardcodedMenuItems);
    }else{
      setMenuItems(hardcodedMenuItems.filter((item) => item.text !== 'Agents'));
    }

  }, []);

  const getSectionTitle = (index) => {
    if (index === 2) {
      return (
        <>
          <Divider sx={{ borderBottomWidth: 1 }} />
          <Typography
            sx={{ padding: "8px 16px", fontWeight: 500, color: "#000000" }}
          >
            Actions
          </Typography>
        </>
      );
    }
    if (role==="Support Admin" && index === 4) {
      return (
        <>
          <Divider sx={{ borderBottomWidth: 1 }} />
          <Typography
            sx={{ padding: "8px 16px", fontWeight: 500, color: "#000000" }}
          >
            User
          </Typography>
        </>
      );
    }
    if (role==="Support Agent" && index === 3) {
      return (
        <>
          <Divider sx={{ borderBottomWidth: 1 }} />
          <Typography
            sx={{ padding: "8px 16px", fontWeight: 500, color: "#000000" }}
          >
            User
          </Typography>
        </>
      );
    }
    return null;
  };

  const handleLogout = async () => {
    try {
      const result = await logoutUser();
      if (result.data.is_error === 0) {
        localStorage.clear();
        window.location.href = "/login";
      } else {
        setSnackbar({
          open: true,
          message: "Error while logging out super admin",
          severity: "error",
        });
      }
    } catch (error) {
      setSnackbar({
        open: true,
        message: error.response?.data?.message || "Error logging out",
        severity: "error",
      });
      console.error("Error logging out:", error);
    }
  };

  return (
    <>
    <Drawer variant="permanent" open={open}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          padding: "5px",
          textAlign: "center",
          height: "50px", // Set height to ensure consistent spacing
          marginBottom: "16px",
        }}
      >
        <Box sx={{ flex: "0 0 auto", cursor: "pointer" }}>
          <img
            src={sidebarLogo}
            height="50px"
            alt="logo"
            onClick={handleDrawerOpen}
          />
        </Box>

        {open && (
          <Box sx={{ display: "flex", flexDirection: "column", pl: "16px" }}>
            {/* First Row: Organization Name */}
            <Typography
              variant="subtitle2"
              color="#000"
            //   onClick={handleRedirect}
            >
              {orgName}
            </Typography>

            {/* Second Row: Icon and Location Name inline */}
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <LocationOnOutlinedIcon fontSize="14px" />
              <Typography variant="caption" color="#0AA2E3">
                {currWorkLocation}
              </Typography>
            </Box>
          </Box>
        )}
      </Box>

      {menuItems.map((item, index) => (
        <div key={index}
        style={{cursor: "pointer"}}>
            {open && getSectionTitle(index)}
            <Link
            to={item.text === "Logout" ? "#" : item.path} // Prevent navigation for Logout
            onClick={item.text === "Logout" ? handleLogout : undefined}
            style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                width: open ? "178.333px" : "48px",
                height: "45px",
                flexShrink: 0,
                borderRadius: "10px",
                backgroundColor:
                location.pathname === item.path ? "#0AA2E3" : "transparent",
                padding: open ? "10px 16px" : "10px 12px",
                textDecoration: "none",
                color: location.pathname === item.path ? "#FFFFFF" : "#808080",
                fontSize: "14px",
                marginBottom: "4px",
            }}
            >
            {item.icon}
            {open && (
                <Typography variant="subtitle2" sx={{ ml: 2 }}>
                {item.text.length > 13
                    ? item.text.slice(0, 13) + "…"
                    : item.text}
                </Typography>
            )}
            </Link>
        </div>
        ))}

    </Drawer>
    {/* Snackbar for notifications */}
    <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        message={snackbar.message}
        severity={snackbar.severity}
        sx={{ zIndex: 1000 }}
    />
    </>
  );
};

export default TicketSideMenu;
