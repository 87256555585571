import React from 'react';
import { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  IconButton,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ConfirmationModal from './LossControl/ConfirmationModal';
import {acceptAndReturnCAPAByFR,acceptAndReturnCAPABySR} from "../Apis/apiCall";

export default function FRApprovalCommentModal ({ open, onClose,level,id }) {
  const [confirmation, setConfirmation] = useState(false);
  const [comment, setComment] = useState("");
  const isFirstReviewer = level === "4" ? true :false;
  console.log("card dataa", level,isFirstReviewer);

  const handleReturnCAPAReview = async()=>{
    const data={
      action:"revert",
      comment:comment
    }
    const result = await returnCAPAReviewData(data);
    console.log(result);
    if (result.data.status_code === 200) {
      window.location.reload();
    }

  }

  

  const returnCAPAReviewData = async (data) => {
    try {
      let result;
      if (isFirstReviewer) {
        result = await acceptAndReturnCAPAByFR(data, id);
      }
      else {
        result = await acceptAndReturnCAPABySR(data, id);
      }
      return result;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  return (
    <>
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      PaperProps={{
        style: {
          borderRadius: 12,
          padding: '16px',
        },
      }}
    >
      <DialogTitle
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '8px 16px',
        }}
      >
        <Typography variant="h6" style={{ fontWeight: 'bold' }}>
          Return Comments
        </Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent style={{ padding: '16px' }}>
        <TextField
          value={comment}
          multiline
          rows={4}
          placeholder="Max. 200 Characters"
          variant="outlined"
          fullWidth
          InputProps={{
            style: {
              borderRadius: 8,
            },
          }}
          onChange={(e)=>setComment(e.target.value)}
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '16px',
          }}
        >
          <Button
            variant="contained"
            color="primary"
            style={{
              borderRadius: 20,
              textTransform: 'none',
              padding: '8px 16px',
              color:'white'
            }}
            onClick={()=>setConfirmation(true)}
            disabled={comment?.length === 0}
          >
            Return
          </Button>
        </div>
      </DialogContent>
    </Dialog>
    <ConfirmationModal open={confirmation} title="Are you sure?" message="Do you really want to do this?" onCancel={()=>setConfirmation(false)} onConfirm={handleReturnCAPAReview} />
    </>
  );
};
