import React from 'react';
import { Autocomplete, TextField, MenuItem, Typography, Box } from '@mui/material';

const DropDownInput = ({ label, placeholder, value, options = [], onChange, name, disabled = false }) => {
    const safeOptions = Array.isArray(options) ? options : [];

    // Helper function to normalize option value and label for both string and object formats
    const getOptionLabel = (option) => {
        return typeof option === 'object' && option !== null ? option.label : option;
    };

    // Determine the value object based on whether the option is a string or object
    const findOption = (value) => {
        if (!value) return null;
        return safeOptions?.find((opt) =>
            typeof opt === 'object' && opt !== null
                ? opt.value === value
                : opt === value
        ) || null;
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {/* Render label above the input field */}
            {label && (
                <Typography variant="body2" sx={{ color: '#7A8699' ,mb:"0px" }}>
                    {label}
                </Typography>
            )}
            <Autocomplete
                options={safeOptions}
                disabled={disabled}
                ListboxProps={{ style: { maxHeight: 160 } }}
                getOptionLabel={(option) => getOptionLabel(option)}
                value={findOption(value)} // Handles both object and string options
                onChange={(event, newValue) => {
                    const selectedValue = newValue
                        ? typeof newValue === 'object'
                            ? newValue.value
                            : newValue
                        : '';
                    onChange({ target: { name, value: selectedValue } });
                }}
                isOptionEqualToValue={(option, value) =>
                    typeof option === 'object' && option !== null
                        ? option.value === value
                        : option === value
                }
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="outlined"
                        placeholder={placeholder ? placeholder : "Select"}
                        size="small"
                        inputProps={{
                            ...params.inputProps, style: {
                                fontSize: "12px", 
                                '& .MuiInputBase-input': {
                                    fontSize: '12px',
                                    color: value ? '#000000' : '#A4B2CC', // Black for entered text, placeholder color otherwise
                                },
                                '& .MuiInputBase-input::placeholder': {
                                    fontSize: '12px',
                                    color: '#A4B2CC', // Placeholder color
                                },
                            }
                        }}
                    />
                )}
                renderOption={(props, option) => (
                    <MenuItem {...props} key={typeof option === 'object' ? option.value : option}>
                        <span style={{ fontSize: "12px" }}>
                            {getOptionLabel(option)}
                        </span>
                    </MenuItem>
                )}
                disableClearable
                sx={{
                    minHeight: '44px',

                    '& .MuiAutocomplete-inputRoot': {
                        height: '44px',
                        display: 'flex',
                        alignItems: 'center',
                    },
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                            top: 0,
                            borderColor: '#B7C8E5',
                        },
                        '&:hover fieldset': {
                            borderColor: '#B7C8E5',
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: '#B7C8E5',
                        },
                    },
                }}
            />
        </Box>
    );
};

export default DropDownInput;
