// Import all necessary components and icons from MUI
import {
  Box,
  Grid,
  Typography,
  Button,
  Switch
} from '@mui/material';
import FRApprovalCommentModal from './FRApprovalCommentModal';
import {
  AccountCircle as AccountCircleIcon,
  Edit as EditIcon,
  DeleteOutline,
  ContactPageOutlined as ContactPageOutlinedIcon,
  DateRangeOutlined as DateRangeOutlinedIcon,
} from '@mui/icons-material';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import DescriptionIcon from '@mui/icons-material/Description';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import ConfirmationModal from './LossControl/ConfirmationModal';
import { acceptAndReturnCAPAByFR, acceptAndReturnCAPABySR } from "../Apis/apiCall";
import { formatDateToIndianLocale, formatTableDate } from "../utils/helper";
import DraggableMediaDialog from "./DraggableImageDialog";


import { cardHeadBG } from '../constant';
import { useState } from 'react';

const CustomBox = ({ children, size = "30px", bgColor = "#F6F6F6" }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: size,
        height: size,
        borderRadius: "50%",
        backgroundColor: bgColor,
      }}
    >
      {children}
    </Box>
  );
};

export default function FRCAPACard({ data, level, mode }) {
  const [frApprovalCommentModal, setFrApprovalCommentModal] = useState(false);
  const [tickConfirmation, setTickConfirmation] = useState(false);
  const isFirstReviewer = level === "4" ? true : false;
  const [openMediaDialog, setOpenMediaDialog] = useState(false);
  const [imageSrc, setImageSrc] = useState('');

  // Handle image click to open the dialog
  const handleImageClick = (url) => {
    setImageSrc(url); // Set the clicked image URL
    setOpenMediaDialog(true); // Open the media dialog
  };


  const handleAcceptCAPAReview = async () => {
    const data1 = {
      action: "accept",
    }
    const result = await acceptReviewCAPAData(data1, data.id);
    console.log(result);
    if (result.data.status_code === 200) {
      window.location.reload();
    }

  }



  const acceptReviewCAPAData = async (data, id) => {
    try {
      let result;
      if (isFirstReviewer) {
        result = await acceptAndReturnCAPAByFR(data, id);
      }
      else {
        result = await acceptAndReturnCAPABySR(data, id);
      }
      return result;
    } catch (error) {
      console.log(error);
      return null;
    }
  }


  return (
    <Box

      sx={{
        borderRadius: "8px",
        background: "#FFFFFF",
        border: "1px solid rgba(0, 0, 0, 0.1)",
        boxShadow: "0px 0px 8px 0px rgba(0, 0, 0, 0.20)",
        minWidth: "370px",
        maxWidth: "100%",
        minHeight: "270px"
      }}
    >
      <Box
        position="relative"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          backgroundColor: "#09A2E3",
          minHeight: "56px",
          borderRadius: "8px 8px 0 0",
          padding: "8px",
          overflow: "hidden",
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box display="flex" alignItems="center">
            {/* Profile image */}
            <Box
              sx={{
                width: "40px",
                height: "40px",
                borderRadius: "50%",
                backgroundColor: "none",
                marginRight: "8px",
                overflow: "hidden",
              }}
            >
              {/* {person.image ? (
                        <Box
                          component="img"
                          src={person.image}
                          alt="Profile"
                          sx={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                          }}
                        />
                      ) : (
                      )} */}
              <AccountCircleIcon
                sx={{
                  width: "100%",
                  height: "100%",
                  color: "#ffffff",
                }}
              />
            </Box>
            {/* Title and ID */}
            <Box>
              <Box display="flex" alignItems="center" gap={1}>
                <Typography
                  sx={{
                    fontSize: "20px",
                    fontWeight: "600",
                    color: "#ffffff",
                  }}
                >
                  {data?.capaType}
                </Typography>
              </Box>
              <Typography
                sx={{
                  fontSize: "12px",
                  fontWeight: "400",
                  color: "#ffffff",
                }}
              >
                {data.capaOwner}
              </Typography>
            </Box>
          </Box>
        </Box>
        {mode === "action" &&
          <Box sx={{ display: 'flex', gap: '6px', justifyContent: 'center', alignItems: 'center' }}>
            {/* Check Button */}
            <Button
              sx={{
                backgroundColor: '#13DEB9',
                borderRadius: '4px',
                width: '24px',
                height: '24px',
                minWidth: 0,
                color: 'white',
                boxShadow: '0px 4px 6px rgba(0,0,0,0.2)',
                '&:hover': {
                  backgroundColor: '#11C8A4',
                },
              }}
              onClick={() => setTickConfirmation(true)}
            >
              <CheckIcon sx={{ fontSize: '16px' }} />
            </Button>

            {/* Close Button */}
            <Button
              sx={{
                backgroundColor: '#FF5753',
                borderRadius: '4px',
                width: '24px',
                height: '24px',
                minWidth: 0,
                color: 'white',
                boxShadow: '0px 4px 6px rgba(0,0,0,0.2)',
                '&:hover': {
                  backgroundColor: '#E44D4A',
                },
              }}
              onClick={() => setFrApprovalCommentModal(true)}
            >
              <CloseIcon sx={{ fontSize: '16px' }} />
            </Button>
          </Box>
        }

        <img
          src={cardHeadBG}
          alt="bg"
          style={{
            position: "absolute",
            bottom: "-410px",
            left: "-360px",
            width: "727px",
            height: "560px",
            zIndex: 0,
          }}
        />

      </Box>
      <Box sx={{ padding: "16px" }}>
        <Grid container spacing={0.5}>

          <Grid item xs={7} mb={2}>
            <Box display="flex" alignItems="center" gap={1}>
              <CustomBox>
                <DateRangeOutlinedIcon
                  sx={{ width: "20px", height: "20px" }}
                />
              </CustomBox>
              <Box display="flex" flexDirection="column" gap={0.5}>
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: "400",
                    color: "#222222",
                  }}
                >
                  Created Date
                </Typography>
                <Typography
                  sx={{
                    fontSize: "11px",
                    fontWeight: "600",
                    color: "#4D4D4D",
                  }}
                >
                  {formatDateToIndianLocale(data?.completionDate)}
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={5} mb={2}>
            <Box display="flex" flexDirection="column" gap={0.5}>
              <Typography
                sx={{
                  fontSize: "12px",
                  fontWeight: "400",
                  color: "#222222",
                }}
              >
                Due Date
              </Typography>
              <Typography
                sx={{
                  fontSize: "11px",
                  fontWeight: "600",
                  color: "#4D4D4D",
                }}
              >
                {formatDateToIndianLocale(data?.dueDate)}

              </Typography>
            </Box>
          </Grid>
          <Grid item xs={7} mb={2}>
            <Box display="flex" alignItems="center" gap={1}>
              <CustomBox>
                <LocationOnOutlinedIcon
                  sx={{ width: "20px", height: "20px" }}
                />
              </CustomBox>
              <Box display="flex" flexDirection="column" gap={0.5}>
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: "400",
                    color: "#222222",
                  }}
                >
                  Source Area
                </Typography>
                <Typography
                  sx={{
                    fontSize: "11px",
                    fontWeight: "600",
                    color: "#4D4D4D",
                  }}
                >
                  {data?.area}
                </Typography>

              </Box>
            </Box>
          </Grid>
          <Grid item xs={5} mb={2}>
            <Box display="flex" flexDirection="column" gap={0.5}>
              <Typography
                sx={{
                  fontSize: "12px",
                  fontWeight: "400",
                  color: "#222222",
                }}
              >
                Sub-Area
              </Typography>
              <Typography
                sx={{
                  fontSize: "11px",
                  fontWeight: "600",
                  color: "#4D4D4D",
                }}
              >
                {data?.subArea}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} mb={2}>
            <Box display="flex" alignItems="center" gap={1}>
              <CustomBox>
                <DescriptionIcon
                  sx={{ width: "20px", height: "20px" }}
                />
              </CustomBox>
              <Box display="flex" flexDirection="column" gap={0.5}>
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: "400",
                    color: "#222222",
                  }}
                >
                  Description
                </Typography>
                <Typography
                  sx={{
                    fontSize: "11px",
                    fontWeight: "600",
                    color: "#4D4D4D",
                  }}
                >
                  {data?.capaDescription}
                </Typography>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} mb={1}>
            <Box display="flex" flexDirection="column" gap={0.5}>
              <Typography
                sx={{
                  fontSize: "12px",
                  fontWeight: "400",
                  color: "#222222",
                }}
              >
                Completion Date
              </Typography>
              <Typography
                sx={{
                  fontSize: "11px",
                  fontWeight: "600",
                  color: "#4D4D4D",
                }}
              >
                {formatTableDate(data?.completionDate)}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} mb={1}>
            <Box display="flex" flexDirection="column" gap={0.5}>
              <Typography
                sx={{
                  fontSize: "12px",
                  fontWeight: "400",
                  color: "#222222",
                }}
              >
                Action Taken
              </Typography>
              <Typography
                sx={{
                  fontSize: "11px",
                  fontWeight: "600",
                  color: "#4D4D4D",
                }}
              >
                {data?.actionTaken}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} mb={1}>
            <Box display="flex" flexDirection="column" gap={0.5}>
              <Typography
                sx={{
                  fontSize: "12px",
                  fontWeight: "400",
                  color: "#222222",
                }}
              >
                Evidence
              </Typography>
            </Box>

            <Box sx={{ display: 'flex', flexWrap: 'wrap', mt: 2 }}>
        {
          data?.evidence?.map((url, index) => (
            <Box
              key={index}
              sx={{
                width: 80,
                height: 80,
                borderRadius: 2,
                overflow: 'hidden',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                border: '1px solid #ccc',
                mr: 1,
                mb: 1,
                cursor: 'pointer',
                transition: 'box-shadow 0.3s ease',
                '&:hover': {
                  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)', // Add shadow on hover
                },
              }}
              onClick={() => handleImageClick(typeof url === 'string' ? url : url.base64)}
            >
              <img
                src={url}
                alt={`Evidence ${index + 1}`}
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                }}
              />
            </Box>
          ))
        }
      </Box>
          </Grid>
        </Grid>
      </Box>
      <FRApprovalCommentModal
        open={frApprovalCommentModal}
        level={level}
        id={data.id}
        onClose={() => setFrApprovalCommentModal(false)}
      />
      <ConfirmationModal open={tickConfirmation} title="Are you sure?" message="Do you really want to approve this?" onCancel={() => setTickConfirmation(false)} onConfirm={handleAcceptCAPAReview} />
      <DraggableMediaDialog
        imageSrc={imageSrc} 
        open={openMediaDialog}
        onClose={() => setOpenMediaDialog(false)}
      />
    </Box>

  )
}