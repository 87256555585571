export const INCIDENTREPORT_UPDATE_FORM_FIELD = 'INCIDENTREPORT_UPDATE_FORM_FIELD';
export const INCIDENTREPORT_ADD_CHECKBOX = 'INCIDENTREPORT_ADD_CHECKBOX';
export const INCIDENTREPORT_REMOVE_CHECKBOX = 'INCIDENTREPORT_REMOVE_CHECKBOX';
export const INCIDENTREPORT_UPDATE_INCIDENT_DATE_TIME = 'INCIDENTREPORT_UPDATE_INCIDENT_DATE_TIME';
export const INCIDENTREPORT_ONCHANGE_DRAWER_FORM = "INCIDENTREPORT_ONCHANGE_DRAWER_FORM";
export const INCIDENTREPORT_ADD_PERSONNEL_DETAIL='INCIDENTREPORT_ADD_PERSONNEL_DETAIL';
export const INCIDENTREPORT_ADD_VEHICLE_DETAIL= 'INCIDENTREPORT_ADD_VEHICLE_DETAIL';
export const INCIDENTREPORT_ADD_EQUIPMENT_DETAIL= 'INCIDENTREPORT_ADD_EQUIPMENT_DETAIL';
export const INCIDENTREPORT_ADD_EVIDENCE_DETAIL = "INCIDENTREPORT_ADD_EVIDENCE_DETAIL";
export const INCIDENTREPORT_ADD_CAPA_DETAIL = "INCIDENTREPORT_ADD_CAPA_DETAIL";
export const INCIDENTREPORT_ADD_LTI_DETAIL = "INCIDENTREPORT_ADD_LTI_DETAIL";
export const INCIDENTREPORT_UPDATE_SWITCH_STATE = 'INCIDENTREPORT_UPDATE_SWITCH_STATE';
export const INCIDENTREPORT_EDIT_CAPA_DETAIL = "INCIDENTREPORT_EDIT_CAPA_DETAIL";
export const INCIDENTREPORT_DELETE_CARD_DETAIL = 'INCIDENTREPORT_DELETE_CARD_DETAIL';
export const INCIDENTREPORT_SET_ALL_REPORT_DATA = 'INCIDENTREPORT_SET_ALL_REPORT_DATA';
export const INCIDENTREPORT_SET_LTI_DETAILS = "INCIDENTREPORT_SET_LTI_DETAILS";
export const INCIDENTREPORT_UPDATE_EVIDENCE_FILE ="INCIDENTREPORT_UPDATE_EVIDENCE_FILE";
export const INCIDENTREPORT_UPDATE_FILTER = 'INCIDENTREPORT_UPDATE_FILTER';
export const INCIDENTREPORT_RESET_FILTER = "INCIDENTREPORT_RESET_FILTER";
export const INCIDENTREPORT_RESET_STATE = "INCIDENTREPORT_RESET_STATE";
export const INCIDENTREPORT_SET_REPORT_FORM_DATA ="INCIDENTREPORT_SET_REPORT_FORM_DATA";