import React, { useEffect, useState } from 'react';
import {
  Stepper,
  Step,
  StepButton,
  StepConnector,
  Typography,
  Box,
  StepIcon,
  Alert,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { updateActiveStep, completeForm1, completeForm2 } from '../redux/stepper/action'; // Your action to update the active step
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import DoneIcon from '@mui/icons-material/Done';
import { styled } from '@mui/system';

// Custom Dashed Connector
const CustomDashedConnector = styled(StepConnector)(({ theme }) => ({
  '& .MuiStepConnector-line': {
    display: 'block',
    borderColor: '#09A2E3',
    borderTopStyle: 'dashed',
    borderTopWidth: 2,
  },
}));

const requiredKeys1 = [
  'Severity',
  'Activity',
  'Sub Activity',
  'Sub Area',
  'Area',
  'Cause of Accident',
  'Date of Accident',
  'Department',
  'Incident Time',
  'Incident Type',
  'Incident Location',
  'Losses Type',
  'Reported By - Entity Name',
  'Reported By - Staff Name',
  'Shift',
  'Well No.',
  'Staff Type',
  'Operation Status',
];

const requiredKeys2 = ['Incident Short Summary', 'Immediate Measures', 'Incident Summary', "GeneralInfo"];

const CustomStepIcon = (props) => {
  const { active, completed } = props;
  console.log(active, completed);

  return (
    <Box
      sx={{
        width: 22,
        height: 22,
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: completed
          ? '#0AA2E3'
          : active
            ? '#0AA2E3'
            : 'gray', // Conditional background color
      }}
    >
      {completed ? (
        <DoneIcon sx={{ color: '#ffffff', fontSize: 12 }} />
      ) : (
        <HourglassEmptyIcon sx={{ color: '#ffffff', fontSize: 12 }} />
      )}
    </Box>
  );
};

const CustomStepper = ({ steps }) => {
  const dispatch = useDispatch();
  const [showAlert, setShowAlert] = useState(false);
  const formData = useSelector((state) => state.incidentReportFromReducer);
  const activeStep = useSelector((state) => state.stepReducer.activeStep);
  const { isForm1Complete, isForm2Complete } = useSelector((state) => state.stepReducer);

  const [completed, setCompleted] = useState({});

  useEffect(() => {
    const allRequiredFieldsFilled1 = requiredKeys1.every((key) => {
      const value = formData[key];
      return Array.isArray(value) ? value.length > 0 : Boolean(value);
    });

    const allRequiredFieldsFilled2 = requiredKeys2.every((key) => {
      const value = formData[key];
      
      console.log(`Checking key: ${key}, value:`, value); // Debugging line
      
      if (key === 'General Info') {
          // Check if 'General Info' is an array with at least one non-empty string
          const isValid = Array.isArray(value) && value.some((item) => typeof item === 'string' && item.trim() !== '');
          console.log(`'General Info' valid: ${isValid}`); // Debugging line
          return isValid;
      } else {
          // For other keys, check if they have a value (non-empty string or non-empty array)
          const isValid = Array.isArray(value) ? value.length > 0 : Boolean(value);
          console.log(`${key} valid: ${isValid}`); // Debugging line
          return isValid;
      }
  });

    if (activeStep == 0) {
      if (allRequiredFieldsFilled1) {
        setCompleted((prev) => ({ ...prev, 0: true }));
        dispatch(completeForm1(true));
      } else {
        setCompleted((prev) => ({ ...prev, 0: false }));
        dispatch(completeForm1(false));
      }

    }
    if (activeStep == 1) {
      if (allRequiredFieldsFilled2) {
        setCompleted((prev) => ({ ...prev, 1: true }));
        dispatch(completeForm2(true));
      } else {
        setCompleted((prev) => ({ ...prev, 1: false }));
        dispatch(completeForm2(false));
      }

    }

  }, [formData, dispatch]);

  useEffect(() => {
    if (showAlert) {
      const timer = setTimeout(() => setShowAlert(false), 1000);
      return () => clearTimeout(timer);
    }
  }, [showAlert]);

  const handleStep = (step) => () => {
    if ((step === 1 && !isForm1Complete) || (step === 2 && !isForm2Complete)) {
      setShowAlert(true);
      return;
    }

    dispatch(updateActiveStep(step));
  };

  return (
    <>
      <Box sx={{ width: '100%', pb: '12px' }}>
        <Stepper activeStep={activeStep} connector={<CustomDashedConnector />}>
          {steps.map((label, index) => (
            <Step key={label} completed={completed[index]}>
              <StepButton
                onClick={handleStep(index)}
                icon={
                  <CustomStepIcon
                    active={activeStep === index}
                    completed={completed[index]}
                  />
                }
                sx={{
                  '& .MuiStepLabel-label': {
                    color: activeStep === index ? '#0AA2E3' : 'inherit',
                    fontSize: '14px',
                    fontWeight: 500,
                  },
                }}
              >
                <Typography variant="body2" sx={{ color: activeStep === index ? '#0AA2E3' : 'inherit' }}>{label}</Typography>
              </StepButton>
            </Step>
          ))}
        </Stepper>
      </Box>
      {showAlert && (
        <Alert
          severity="warning"
          onClose={() => setShowAlert(false)}
          sx={{
            position: 'fixed',
            top: '40px',
            left: '50%',
            transform: 'translateX(-50%)',
            width: '100%',
            maxWidth: '600px',
            zIndex: 9999,
            textAlign: 'center',
          }}
        >
          Please fill the form before moving to the next step.
        </Alert>
      )}
    </>
  );
};

export default CustomStepper;
