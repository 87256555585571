import React from 'react';
import { TextField, Typography } from '@mui/material';

const TextAreaInput = ({ label, value, onChange, multiline = false, rows = 1, placeholder, type }) => {
    let isDisable = false;
    if (label === "Injury Count" || label === "Fatality Count") {
        isDisable = true;
    }

    return (
        <div style={{ marginBottom: '4px' }}>
            {/* Render label separately */}
            {label  && (
                <Typography 
                    variant="body2"       
                    style={{
                        fontSize: '14px',
                        color: '#7A8699', // Match the placeholder color              
                    }}
                >
                    {label}
                </Typography>
            )}
            <TextField
                disabled={isDisable}
                name={label}
                type={type}
                value={value}
                onChange={(e) => {
                    const inputValue = e.target.value;
                    if (label === "No. of People" && inputValue.length > 0 && inputValue[0] === '0') {
                        return;
                    }
                    onChange(e);
                }}
                multiline={multiline}
                inputProps={{ inputMode: 'numeric' }}
                rows={rows}
                fullWidth
                margin="normal"
                variant="outlined"
                size="small"
                placeholder={placeholder}
                sx={{
                    mt:"4px",
                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                        display: "none",
                    },
                    "& input[type=number]": {
                        MozAppearance: "textfield",
                    },
                    '& .MuiOutlinedInput-root': {
                        minHeight: '40px',
                        '& fieldset': {
                            borderColor: '#B7C8E5', // Default border color
                        },
                        '&:hover fieldset': {
                            borderColor: '#8DA4C6', // Hover state border color
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: '#4A90E2', // Focused state border color
                        },
                    },
                    '& .MuiInputBase-input': {
                        fontSize: '12px',
                        color: value ? '#000000' : '#A4B2CC', // Black for entered text, placeholder color otherwise
                    },
                    '& .MuiInputBase-input::placeholder': {
                        fontSize: '12px',
                        color: '#A4B2CC', // Placeholder color
                    },
                }}
            />
        </div>
    );
};

export default TextAreaInput;
