import React, { useState } from 'react';
import { Button, Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { updateActiveStep } from '../redux/stepper/action';
import AlertModel from '../Components/AlertModel';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { resetState } from '../redux/incidentWorkflow/actions';
import { resetReportFormstate } from '../redux/incidentReport/actions';
import { createIncident, firstReviwerReturnAndAccept, secondReviwerReturnAndAccept, investigatorReturnAndAccept, secondReviwerInvestigationReturnAndAccept, firstReviwerInvestigationReturnAndAccept, updateIncidentReportForm } from "../Apis/apiCall";
import { useNavigate } from 'react-router-dom';
import { convertToMilliseconds } from "../utils/helper";
import RemarkModal from "../Components/LossControl/RemarkModal";

const FloatingButton = ({ title, subtitle, level, incidentId, status }) => {
  const navigate = useNavigate();
  const loginDataString = localStorage.getItem('loginData');
  const data = JSON.parse(loginDataString);
  const [openRemarkModal, setOpenRemarkModal] = useState(false);
  const [comment, setComment] = useState("");
  //const workflowRoles = data?.config.permissionDetails.workflowRoles;
  const isFirstReviewer = level === '1';
  const isInvestigator = level === '3';
  const isSecondReviwer = level === '2';
  console.log("subtitle", subtitle);
  const dispatch = useDispatch();
  const [description, setDescription] = useState("");
  const [open, setOpen] = useState(false);
  const activeStep = useSelector((state) => state.stepReducer.activeStep);
  const { isForm1Complete, isForm2Complete } = useSelector((state) => state.stepReducer);
  const formData = useSelector(state => state.incidentReportFromReducer);
  const workflowData = useSelector(state => state.incidentWorkFlowReducer);
  const workInvestflowData = useSelector(state => state.incidentInvestigationFlowReducer);
  const afterInvestigationCompleted = (status === "Investigation Completed" || status === "Investigation Resubmission" || status === "Investigation Clarification") ? true : false;
  const afterFRInvestigationCompleted = (status === "Investigation Under Approval" || status === "Investigation Approval Resubmission") ? true : false;


  // Destructure formData
  const {
    PersonnelDetail,
    VehicleDetail,
    EquipmentDetail,
    EvidenceDetail,
    Regulatory,
    CAPADetail: formCAPADetail  // Rename CAPADetail to formCAPADetail
  } = formData;

  // Destructure workflowData and rename conflicting variables
  const {
    Return: workflowReturn,    // Rename Return to workflowReturn
    Review,
    switchStates: workflowSwitchStates,   // Rename switchStates to workflowSwitchStates
    Investigation,
    Assign_Investigation,
    CAPADetail: workflowCAPADetail  // Rename CAPADetail to workflowCAPADetail
  } = workflowData;

  // Destructure workInvestflowData and rename conflicting variables
  const {
    Return: investReturn,   // Rename Return to investReturn
    switchStates: investSwitchStates,  // Rename switchStates to investSwitchStates
    WorkflowInput,
    RootCauseAnalysis,
    RegulatoryInfo,
    CAPADetail: investCAPADetail,  // Rename CAPADetail to investCAPADetail
    InvestigationEvidenceDetail,
    WitnessStatementDetail,
    IncidentResponsibilityDetail,
    MitigationMeasuresDetail,
    LessonsLearnedDetail,
    CompensationInfoDetail,
    ContributingFactorDetail
  } = workInvestflowData;

  let formCompleted = 0;
  if (isFirstReviewer) {
    if (subtitle === "Return") {
      formCompleted = workflowReturn?.["Return to previous level"] === true ? 1 : 0;
    } else {
      formCompleted = subtitle === "Review"
        ? (Review?.["Review completed"] === true || Review?.["Investigation Reviewed"] === true ? 1 : 0)
        : 0;
    }
  }

  if (isInvestigator) {
    if (subtitle === "Return") {
      formCompleted = investReturn?.["Return to previous level"] === true ? 1 : 0;
    }
    if (subtitle === "Review") {
      formCompleted =
        (
          (investSwitchStates.CAPA === "No" || (investCAPADetail && investCAPADetail.length > 0)) &&
          (investSwitchStates.InvestigationEvidence === "No" || (InvestigationEvidenceDetail && InvestigationEvidenceDetail.length > 0)) &&
          (investSwitchStates.WitnessStatement === "No" || (WitnessStatementDetail && WitnessStatementDetail.length > 0)) &&
          (investSwitchStates.IncidentResponsibility === "No" || (IncidentResponsibilityDetail && IncidentResponsibilityDetail.length > 0)) &&
          (investSwitchStates.MitigationMeasures === "No" || (MitigationMeasuresDetail && MitigationMeasuresDetail.length > 0)) &&
          (investSwitchStates.LessonsLearned === "No" || (LessonsLearnedDetail && LessonsLearnedDetail.length > 0)) &&
          (investSwitchStates.CompensationInfo === "No" || (CompensationInfoDetail && CompensationInfoDetail.length > 0)) &&
          (investSwitchStates.ContributingFactor === "No" || (ContributingFactorDetail && ContributingFactorDetail.length > 0)) &&
          (investSwitchStates.RegulatoryInfo === "No" || (RegulatoryInfo && Object.keys(RegulatoryInfo).length > 0)) &&
          (investSwitchStates.RootCauseAnalysis === "No" || (RootCauseAnalysis && Object.keys(RootCauseAnalysis).length > 0)) &&
          (WorkflowInput && WorkflowInput?.["Investigation complete"] === true)
        ) ? 1 : 0;
    }
  }




  if (isSecondReviwer && afterFRInvestigationCompleted) {
    if (subtitle === "Return") {
      formCompleted = workflowReturn?.["Return to previous level"] === true ? 1 : 0;
    } else {
      formCompleted = (
        Object.keys(Review).length > 0 && (Review?.["Investigation Reviewed"] || Review?.["Investigation Approved"] === true) &&
        ((workflowSwitchStates.CAPA === "No") || (workflowCAPADetail?.length > 0))
      ) ? 1 : 0;
    }
  }

  if (isSecondReviwer) {
    if (subtitle === "Return") {
      formCompleted = workflowReturn?.["Return to previous level"] === true ? 1 : 0;
    } else {
      formCompleted = (
        Object.keys(Review).length > 0 && Review?.["Review completed"] === true &&
        ((workflowSwitchStates.CAPA === "No") || (workflowCAPADetail?.length > 0)) &&
        ((workflowSwitchStates.Investigation === "No") || (Object.keys(Assign_Investigation).length > 0))) ? 1 : 0;
    }
  }
  console.log("formCompleted", formCompleted);
  console.log(formData);



  let empEntity = [];
  // Function to check if an object is empty
  const isEmptyObject = (obj) => {
    return !obj || Object.keys(obj).length === 0;
  };

  if (isEmptyObject(PersonnelDetail)) {
    empEntity.push('Personnel');
  }

  if (isEmptyObject(VehicleDetail)) {
    empEntity.push('Vehicle');
  }

  if (isEmptyObject(EquipmentDetail)) {
    empEntity.push('Equipment');
  }

  if (isEmptyObject(EvidenceDetail)) {
    empEntity.push('Evidence');
  }

  if (isEmptyObject(Regulatory)) {
    empEntity.push('Regulatory');
  }

  if (isEmptyObject(formCAPADetail)) {
    empEntity.push('CAPA');
  }



  const handleNavigation = () => {

    if (activeStep === 1 && isForm2Complete) {
      if (empEntity.length > 0) {
        const emptyEntitiesList = empEntity.join(", ");
        let message;
        // Handle different cases for emptyEntitiesList
        if (empEntity.length === 1) {
          // When there's only one empty entity
          message = `You have not entered any ${emptyEntitiesList} information.`;
        } else {
          // When there are multiple empty entities
          const lastEntity = empEntity.pop(); // Remove the last entity
          message = `You have not entered any ${emptyEntitiesList.replace(`, ${lastEntity}`, '')} and ${lastEntity} information.`;
        }
        setOpen(true);
        setDescription(message);
      } else {
        dispatch(updateActiveStep(2));
      }
    }
    if (activeStep === 0 && isForm1Complete) {
      dispatch(updateActiveStep(1));
    }
  };


  const handleReportFormSubmit = () => {
    if (activeStep === 2 && isForm1Complete && isForm2Complete) {
      if (formData.incidentReportID && !comment) {
        setOpenRemarkModal(true)
      } else {
        setOpen(true);
        setDescription("Are you sure you want to submit the form?");
      }
    }
    if (title === "ActionPage") {
      let message = "You are sure want to submit your review."
      setOpen(true);
      setDescription(message);

    }
  };

  const handleCloseRemarkModal = () => setOpenRemarkModal(false);

  const handleSubmitRemarkModal = (remark) => {
    setComment(remark);
  }




  const handleProceed = async () => {
    if (activeStep === 1 && isForm2Complete) {
      setOpen(false);
      dispatch(updateActiveStep(2));
    }

  if (activeStep === 2 && isForm2Complete) {
    let reportIncidentData;
      if(formData?.["incidentReportID"]){
        reportIncidentData= {
          reportIncidentData: {
          incidentReportID:formData?.["incidentReportID"],
          "Incident Type": formData?.["Incident Type"],
          Severity: formData?.["Severity"] === "N/A" ? 0 : parseInt(formData?.["Severity"]),
          "Incident Time": convertToMilliseconds(formData?.["Incident Time"]),
          "Report Time": convertToMilliseconds(formData?.["Report Time"]),
          "Incident Location": formData?.["Incident Location"],
          "Staff Type": formData?.["Staff Type"],
          "Employee Type": formData?.["Employee Type"],
          "Reported By - Entity Name": formData?.["Reported By - Entity Name"],
          "Reported By - Staff Name": formData?.["Reported By - Staff Name"],
          "Well No": formData?.["Well No"],
          Activity: formData?.["Activity"],
          "Sub Activity": formData?.["Sub Activity"],
          Area: formData?.["Area"],
          "Sub Area": formData?.["Sub Area"],
          Department: formData?.["Department"],
          "Immediate Last Accident": formData?.["Immediate Last Accident"],
          "Date of Accident": convertToMilliseconds(formData?.["Date of Accident"]),
          "Cause of Accident": formData?.["Cause of Accident"],
          Shift: formData?.["Shift"],
          "Losses Type": formData?.["Losses Type"],
          "Operation Status": formData?.["Operation Status"],
          "Incident Short Summary": formData?.["Incident Short Summary"],
          "Incident Summary": formData?.["Incident Summary"],
          "Immediate Measures": formData?.["Immediate Measures"],
          GeneralInfo: formData?.["GeneralInfo"] || [],
          Others: formData?.["Others"],
          Personnel: formData?.["Personnel"] || {},
          Regulatory: formData?.["Regulatory"] || {},
          VehicleDetail: formData?.["VehicleDetail"] || [],
          PersonnelDetail: formData?.["PersonnelDetail"] || [],
          CAPADetail: formData?.["CAPADetail"] || [],
          EquipmentDetail: formData?.["EquipmentDetail"] || [],
          EvidenceDetail: formData?.["EvidenceDetail"] || [],
          LTIDetail: formData?.["LTIDetail"] || [],
        },
        comments: comment || "",
      }}else{
        reportIncidentData= {
          reportIncidentData: {
          incidentReportID:formData?.["incidentReportID"],
          "Incident Type": formData?.["Incident Type"],
          Severity: formData?.["Severity"] === "N/A" ? 0 : parseInt(formData?.["Severity"]),
          "Incident Time": convertToMilliseconds(formData?.["Incident Time"]),
          "Report Time": convertToMilliseconds(formData?.["Report Time"]),
          "Incident Location": formData?.["Incident Location"],
          "Staff Type": formData?.["Staff Type"],
          "Employee Type": formData?.["Employee Type"],
          "Reported By - Entity Name": formData?.["Reported By - Entity Name"],
          "Reported By - Staff Name": formData?.["Reported By - Staff Name"],
      
          "Well No.": formData?.["Well No."],
          Activity: formData?.["Activity"],
          "Sub Activity": formData?.["Sub Activity"],
          Area: formData?.["Area"],
          "Sub Area": formData?.["Sub Area"],
          Department: formData?.["Department"],
          "Immediate Last Accident": formData?.["Immediate Last Accident"],
          "Date of Accident": convertToMilliseconds(formData?.["Date of Accident"]),
          "Cause of Accident": formData?.["Cause of Accident"],
          Shift: formData?.["Shift"],
          "Losses Type": formData?.["Losses Type"],
          "Operation Status": formData?.["Operation Status"],
          "Incident Short Summary": formData?.["Incident Short Summary"],
          "Incident Summary": formData?.["Incident Summary"],
          "Immediate Measures": formData?.["Immediate Measures"],
          GeneralInfo: formData?.["GeneralInfo"] || [],
          Others: formData?.["Others"],
          Personnel: formData?.["Personnel"] || {},
          Regulatory: formData?.["Regulatory"] || {},
          VehicleDetail: formData?.["VehicleDetail"] || [],
          PersonnelDetail: formData?.["PersonnelDetail"] || [],
          CAPADetail: formData?.["CAPADetail"] || [],
          EquipmentDetail: formData?.["EquipmentDetail"] || [],
          EvidenceDetail: formData?.["EvidenceDetail"] || [],
          LTIDetail: formData?.["LTIDetail"] || [],
        },
       
      }
      
    };
    
    const result = await handleIncidentReportCreate(reportIncidentData);
    if (result.data.status_code == 200) {
      dispatch(resetReportFormstate());
      setOpen(false);
      dispatch(updateActiveStep(0));
      navigate("/module/incident-management");
    }
  }

  if (title === "ActionPage") {
    let result;
    let data;
    if (isFirstReviewer) {
      if (subtitle === "Return") {
        if (afterInvestigationCompleted) {
          data = {
            action: "revert",
            investigationFirstReviewerReviewDate: convertToMilliseconds(workflowReturn["Review Date"]),
            investigationFirstReviewerComment: workflowReturn?.["Review Input"],
          }

        } else {
          data = {
            action: "revert",
            firstReviewerReviewDate: convertToMilliseconds(workflowReturn["Review Date"]),
            firstReviewerReturnReasons: workflowReturn?.["Review Input"],
            firstReviewerComment: workflowReturn?.["Additional Remarks"] ? workflowReturn?.["Additional Remarks"] : ""
          };
        }

        result = await handleFirstReviewerAcceptAndReturn(data);
      } else {

        if (afterInvestigationCompleted) {
          data = {
            action: "accept",
            investigationFirstReviewerReviewDate: convertToMilliseconds(Review["Review Date"]),
            investigationFirstReviewerReviewInput: Review["Review Input"],
            firstReviewerInputOnSeverityAndIncident: Review?.["Input On Severity and Incident Type"],
            frReviewData: {
              DGMS: Review?.DGMS,
              "Factories Act": Review?.["Factories Act"],
              OISD: Review?.OISD,
              "OISD Fire": Review?.["OISD Fire"],
              "OISD Major Fire": Review?.["OISD Major Fire"],
              PNGRB: Review?.PNGRB,
              "PNGRB Fire": Review?.["PNGRB Fire"],
              "PNGRB Major Fire": Review?.["PNGRB Major Fire"]
            }
          }
        } else {
          data = {
            action: "accept",
            firstReviewerRecommendedSeverity: Review?.["Severity"],
            firstReviewerRecommendedIncidentType: Review["Incident Type"],
            firstReviewerReviewDate: convertToMilliseconds(Review["Review Date"]),
            firstReviewerReviewInput: Review["Review Input"],
            investigationRecommended: Review?.["Investigation Recommended"] ? 1 : 0,
            frReviewData: {
              DGMS: Review?.DGMS,
              "Factories Act": Review?.["Factories Act"],
              OISD: Review?.OISD,
              "OISD Fire": Review?.["OISD Fire"],
              "OISD Major Fire": Review?.["OISD Major Fire"],
              PNGRB: Review?.PNGRB,
              "PNGRB Fire": Review?.["PNGRB Fire"],
              "PNGRB Major Fire": Review?.["PNGRB Major Fire"]
            },
            highPotentialNearMiss: Review?.["High Potential Near Miss"]
          }
        }
        result = await handleFirstReviewerAcceptAndReturn(data);
      }
      if (result.data.status_code == 200) {
        dispatch(resetState());
        setOpen(false);
        navigate("/my-actions");
      }
    }



    if (isSecondReviwer) {
      if (subtitle === "Return") {
        if (afterFRInvestigationCompleted) {
          data = {
            action: "revert",
            investigationSecondReviewerReviewDate: convertToMilliseconds(workflowReturn["Review Date"]),
            investigationSecomdReviewerComment: workflowReturn?.["Review Input"]
          }

        }
        else {
          data = {
            action: "revert",
            secondReviewerReviewDate: convertToMilliseconds(workflowReturn["Review Date"]),
            secondReviewerReturnReasons: workflowReturn?.["Review Input"],
            secondReviewerComment: workflowReturn?.["Additional Remarks"] ? workflowReturn?.["Additional Remarks"] : ""
          };
        }

        result = await handleSecondReviewerAcceptAndReturn(data);
      } else {
        if (afterFRInvestigationCompleted) {
          const CAPAs = workflowCAPADetail.map(item => ({
            ...item,
            "Due Date": convertToMilliseconds(item?.["Due Date"]),
          }));
          data = {
            action: "accept",
            finalSeverity: Review?.["Final Severity"],
            finalIncidentType: Review["Final Incident Type"],
            investigationSecondReviewerReviewDate: convertToMilliseconds(Review["Review Date"]),
            investigationSecondReviewerReviewInput: Review["Review Input"],
            secondReviewerInputOnSeverityAndIncident: Review?.["Input On Severity and Incident Type"],
            srReviewData: {
              DGMS: Review?.DGMS,
              "Factories Act": Review?.["Factories Act"],
              OISD: Review?.OISD,
              "OISD Fire": Review?.["OISD Fire"],
              "OISD Major Fire": Review?.["OISD Major Fire"],
              PNGRB: Review?.PNGRB,
              "PNGRB Fire": Review?.["PNGRB Fire"],
              "PNGRB Major Fire": Review?.["PNGRB Major Fire"],
            },
            capas: [...CAPAs],
          };

        } else {
          const CAPAs = workflowCAPADetail.map(item => ({
            ...item,
            "Due Date": convertToMilliseconds(item?.["Due Date"]),
          }));
          data = {
            action: "accept",
            secondReviewerRecommendedSeverity: Review?.["Severity"],
            secondReviewerRecommendedIncidentType: Review["Incident Type"],
            secondReviewerReviewDate: convertToMilliseconds(Review["Review Date"]),
            secondReviewerReviewInput: Review["Review Input"],
            investigationRecommended: Review?.["Investigation Recommended"] ? 1 : 0,
            srReviewData: {
              DGMS: Review?.DGMS,
              "Factories Act": Review?.["Factories Act"],
              OISD: Review?.OISD,
              "OISD Fire": Review?.["OISD Fire"],
              "OISD Major Fire": Review?.["OISD Major Fire"],
              PNGRB: Review?.PNGRB,
              "PNGRB Fire": Review?.["PNGRB Fire"],
              "PNGRB Major Fire": Review?.["PNGRB Major Fire"]
            },
            highPotentialNearMiss: Review?.["High Potential Near Miss"],
            investigation: { ...Assign_Investigation, "Target completion date": convertToMilliseconds(Assign_Investigation["Target completion date"]) },
            CAPAs: [...CAPAs],
          };
        }
        result = await handleSecondReviewerAcceptAndReturn(data);
      }
      if (result.data.status_code == 200) {
        dispatch(resetState());
        setOpen(false);
        navigate("/my-actions");
      }
    }


    if (isInvestigator) {
      if (subtitle === "Return") {
        data = {
          action: "revert",
          returnData: {
            investigationReviewDate: convertToMilliseconds(investReturn["Review Date"]),
            investigationReturnReasons: investReturn?.["Review Input"],
            investigationComment: investReturn?.["Additional Remarks"] ? investReturn?.["Additional Remarks"] : "",
          }

        };
        result = await handleInvestigatorAcceptAndReturn(data);
      } else {
        const CAPAs = investCAPADetail.map(item => ({
          ...item,
          "Due Date": convertToMilliseconds(item?.["Due Date"]),
        }));
        data = {
          action: "completed",
          workflowInput: {
            investigatorRecommendedSeverity: WorkflowInput?.["Severity"],
            investigatorRecommendedType: WorkflowInput?.["Incident Type"],
            investigationReviewDate: convertToMilliseconds(WorkflowInput?.["Review Date"]),
            investigationReviewInput: WorkflowInput?.["Review Input"],
            invData: {
              DGMS: WorkflowInput?.DGMS,
              "Factories Act": WorkflowInput?.["Factories Act"],
              OISD: WorkflowInput?.OISD,
              "OISD Fire": WorkflowInput?.["OISD Fire"],
              "OISD Major Fire": WorkflowInput?.["OISD Major Fire"],
              PNGRB: WorkflowInput?.PNGRB,
              "PNGRB Fire": WorkflowInput?.["PNGRB Fire"],
              "PNGRB Major Fire": WorkflowInput?.["PNGRB Major Fire"]
            }
          },
          rootCauseAnalysis: { ...RootCauseAnalysis }, // Assuming this is an array
          regulatoryInfo: {
            ...RegulatoryInfo,
            ...(RegulatoryInfo?.["Date of Immediate last Accident"] && {
              "Date of Immediate last Accident": convertToMilliseconds(
                RegulatoryInfo["Date of Immediate last Accident"]
              ),
            }),
          },
          investigationCAPA: [...CAPAs], // Assuming this is an array
          investigationEvidence: [...InvestigationEvidenceDetail], // Assuming this is an array
          witnessStatement: WitnessStatementDetail.map((item) => ({
            ...item,
            ...(item?.["Date of Statement"] && {
              "Date of Statement": convertToMilliseconds(item["Date of Statement"]),
            }),
          })),
          incidentResponsibility: [...IncidentResponsibilityDetail], // Assuming this is an array
          mitigationMeasures: [...MitigationMeasuresDetail], // Assuming this is an array
          lessonsLearned: [...LessonsLearnedDetail], // Assuming this is an array
          compensationInfo: CompensationInfoDetail.map((item) => ({
            ...item,
            ...(item?.["Date Of Return To Duty"] && {
              "Date Of Return To Duty": convertToMilliseconds(item["Date Of Return To Duty"]),
            }),
            ...(item?.["Date Of Refresher Training"] && {
              "Date Of Refresher Training": convertToMilliseconds(item["Date Of Refresher Training"]),
            }),
          })),
          contributingFactor: [...ContributingFactorDetail], // Assuming this is an array
        };
        result = await handleInvestigatorAcceptAndReturn(data);
      }
      if (result?.data?.status_code == 200) {
        dispatch(resetState());
        setOpen(false);
        navigate("/my-actions");
      }
    }


    console.log("Form submitted!", Review, workflowReturn);

    // dispatch(resetState());
    // setOpen(false);
  }

};


const handleSecondReviewerAcceptAndReturn = async (data) => {
  try {
    let result;
    if (afterFRInvestigationCompleted) {
      result = await secondReviwerInvestigationReturnAndAccept(data, incidentId);
    }
    else {
      result = await secondReviwerReturnAndAccept(data, incidentId);
    }
    return result;

  } catch (error) {
    console.log(error);
    return null;
  }
}


const handleFirstReviewerAcceptAndReturn = async (data) => {
  try {
    let result;
    if (afterInvestigationCompleted) {
      result = await firstReviwerInvestigationReturnAndAccept(data, incidentId);
    }
    else {
      result = await firstReviwerReturnAndAccept(data, incidentId);
    }
    return result;
  } catch (error) {
    console.log(error);
    return null;
  }
}


const handleInvestigatorAcceptAndReturn = async (data) => {
  try {
    const result = await investigatorReturnAndAccept(data, incidentId);
    return result;
  } catch (error) {
    console.log(error);
    return null;
  }
}

const handleIncidentReportCreate = async (data) => {
  try {
    let result;
    console.log(data?.reportIncidentData.incidentReportID, "pppppp");
    if (data?.reportIncidentData.incidentReportID) {
      result = await updateIncidentReportForm(data, data?.reportIncidentData.incidentReportID, "edit");
    } else {
      result = await createIncident(data);
    }
    return result;
  } catch (error) {
    console.log(error);
    return null;
  }
}



const handleClose = () => {
  setOpen(false); // Close dialog on cancel
};

return (
  <Box>
    <Button
      variant="contained"
      color="primary"
      sx={{
        fontSize: "17px",
        backgroundColor: '#09A2E3',
        color: '#fff',
        padding: '8px 20px',
        fontWeight: '500',
        borderRadius: '12px',
        boxShadow: '0px 0px 15px 4px #09A2E3',
        textTransform: "none",

      }}
      endIcon={activeStep === 0 && <ArrowForwardIosIcon />}
      onClick={title === "ActionPage" ? handleReportFormSubmit : activeStep === 0 ? handleNavigation : activeStep === 1 ? handleNavigation : handleReportFormSubmit}
      disabled={
        title === "ActionPage" && Review?.hasOwnProperty("Approved Investigation") && Review["Approved Investigation"] === true
          ? false // Enable the button if "Approved Investigation" key exists and its value is true
          : title === "ActionPage"
            ? formCompleted <= 0 // Disable if form is incomplete
            : activeStep === 0 ? !isForm1Complete : !isForm2Complete// Use the existing condition for other cases
      }      >
      {title === "ActionPage" ? "Submit" : activeStep === 0 ? "Next" : activeStep === 1 ? "Review" : "Submit"}

    </Button>

    {/* Confirmation Dialog */}
    <AlertModel
      open={open}
      onClose={handleClose}
      onProceed={handleProceed}
      // title={title}
      description={description}
    />

    {/* comment resubmit model Dialog */}

    <RemarkModal
      title={"Add Comment"}
      open={openRemarkModal}
      onClose={handleCloseRemarkModal}
      onSubmit={handleSubmitRemarkModal}
    />
  </Box>
);
};

export default FloatingButton;
