import React from "react";
import { Box, Tooltip } from "@mui/material";

export default function SeverityTag({ severity }) {
  const getSeverityStyles = (severity) => {
    switch (severity) {
      case "1":
        return { backgroundColor: "#FC5858" };
      case "2":
        return { backgroundColor: "#E53939" };
      case "3":
        return { backgroundColor: "#CC1F1F" };
      case "4":
        return { backgroundColor: "#B20909" };
      case "N/A":
        return { backgroundColor: "#CCCCCC" }; // Grey color for "Not Applicable"
      default:
        return { backgroundColor: "#921010" };
    }
  };

  const displayValue = severity === "N/A" ? "Not Applicable" : severity;

  return (
    <Tooltip title={`Severity: ${displayValue}`}>
      <Box
        sx={{
          color: "#FFFFFF",
          fontSize: "14px",
          fontWeight: "500",
          width: "24px",
          height: "24px",
          borderRadius: "50%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: 0,
          ...getSeverityStyles(severity),
        }}
      >
        {displayValue === "Not Applicable" ? "" : displayValue}
      </Box>
    </Tooltip>
  );
}
