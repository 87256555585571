import React, {useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import AppBar from './AppBar';
import SideMenu from './SideMenu';
import BasicBreadcrumbs from '../Layout/Breadcumb'; // Import the Breadcumb component
import TicketSideMenu from './TicketSideMenu';

const MainLayout = () => {
  const [open, setOpen] = useState(true);

  const handleDrawerOpen = () => {
    setOpen(!open);
  };
  const loginDataString = localStorage.getItem('loginData');
  const loginData = JSON.parse(loginDataString);
  const role = loginData.config.permissionDetails.applicationRoles[0].name;
 
  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      <div style={{ position: 'fixed', width: '100%', zIndex: 1100, top: 0 }}>
        <AppBar open={open} handleDrawerOpen={handleDrawerOpen} />
        <BasicBreadcrumbs open={open} />
      </div>
      <div style={{ display: 'flex', flexGrow: 1, marginTop: '90px', overflow: 'hidden' }}>
        {(role === "Support Admin" || role === "Support Agent") ? (
          <TicketSideMenu open={open} handleDrawerOpen={handleDrawerOpen}/>
        ) : (
        <SideMenu open={open} handleDrawerOpen={handleDrawerOpen} />
        )}
        <div style={{ flexGrow: 1, padding: '24px', paddingTop: '24px', paddingBottom: '12px', overflowY: 'auto', display: 'flex', flexDirection: 'column' }}>
          <Outlet /> {/* This will render the matched child route */}
        </div>
      </div>
    </div>
  );
};

export default MainLayout;
