import React, { useEffect, useState } from "react";
import { Box, Stepper, Step, StepLabel, Typography } from "@mui/material";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import KeyboardBackspaceOutlinedIcon from "@mui/icons-material/KeyboardBackspaceOutlined";
import CircleIcon from "@mui/icons-material/Circle";
import { formatDateToIndianLocale2 } from "../utils/helper";

const stepHeading = [
  "Incident Reported",
  "Initial Review",
  "Investigation",
  "CAPA",
  "Final Review",
  "Incident Closed",
];

export default function CustomStepperBox({ stepData }) {
  const [activeSteps, setActiveSteps] = useState([]);
  const [selectedStep, setSelectedStep] = useState(0);

  const [currentState, setCurrentState] = useState(null);
  const [currentRole, setCurrentRole] = useState(null);
  const [currentFullName, setCurrentFullName] = useState(null);
  const [surveyDate, setSurveyDate] = useState(null);
  const [currentEmail, setCurrentEmail] = useState(null);

  //names and email of the users
  const [roName, setROName] = useState(null);
  const [frName, setFRName] = useState(null);
  const [srName, setSRName] = useState(null);
  const [investigatorName, setInvestigatorName] = useState(null);
  const [roEmail, setROEmail] = useState(null);
  const [frEmail, setFREmail] = useState(null);
  const [srEmail, setSREmail] = useState(null);
  const [investigatorEmail, setInvestigatorEmail] = useState(null);

  //for first time rendering, what timeline has to show
  useEffect(() => {
    if (stepData.length > 0) {
      const lastEntry = stepData[stepData.length - 1];
      setCurrentState(lastEntry.status);
      setCurrentRole(lastEntry.roleName);
      setCurrentFullName(
        `${lastEntry.firstName || ""} ${lastEntry.middleName || ""} ${
          lastEntry.lastName || ""
        }`
      );
      setSurveyDate(formatDateToIndianLocale2(lastEntry.createdAt));
      setCurrentEmail(lastEntry.email);
      determineStatuses();
      findNamesMails(stepData);
    }
  }, [stepData]);

  //function to set name and emails for the users
  function findNamesMails(stepData) {
    for (let i = 0; i < stepData.length; i++) {
      const curr = stepData[i];
      const fullName = `${curr.firstName || ""} ${curr.middleName || ""} ${
        curr.lastName || ""
      }`.trim();

      if (curr.roleName === "Reporting Officer") {
        setROName(fullName);
        setROEmail(curr.email);
      } else if (curr.roleName === "First Reviewer") {
        setFRName(fullName);
        setFREmail(curr.email);
      } else if (curr.roleName === "Second Reviewer") {
        setSRName(fullName);
        setSREmail(curr.email);
      } else if (curr.roleName === "Reviewer") {
        setInvestigatorName(fullName);
        setInvestigatorEmail(curr.email);
      }
    }
  }

  // this is the function for getting step icons as it is mentioned in statuses
  const getStepIcon = (status, index) => {
    const isSelected = selectedStep === index;
    const boxShadow = isSelected
      ? "0px 0px 6px 2px rgba(30, 144, 255, 0.6)"
      : "none";

    if (status === "completed") {
      return (
        <CheckOutlinedIcon
          sx={{
            backgroundColor: "#FFFFFF",
            borderRadius: "50%",
            color: "#4591F5",
            fill: "#4591F5",
            border: "2px solid #4591F5",
            boxShadow,
            cursor: "pointer",
          }}
        />
      );
    } else if (status === "pending") {
      return (
        <CircleIcon
          sx={{
            backgroundColor: "#FFFFFF",
            borderRadius: "50%",
            color: "#FFAC07",
            fill: "#FFAC07",
            border: "2px solid #FFAC07",
            boxShadow,
            cursor: "pointer",
          }}
        />
      );
    } else if (status === "waiting") {
      return (
        <CircleIcon
          sx={{
            backgroundColor: "#FFFFFF",
            borderRadius: "50%",
            color: "#808080",
            fill: "#808080",
            border: "2px solid #808080",
            boxShadow,
            cursor: "pointer",
          }}
        />
      );
    } else if (status === "returned") {
      return (
        <KeyboardBackspaceOutlinedIcon
          sx={{
            backgroundColor: "red",
            borderRadius: "50%",
            color: "white",
            fill: "white",
            border: "2px solid red",
            boxShadow,
            cursor: "pointer",
          }}
        />
      );
    } else if (status === "inactive") {
      return (
        <CircleIcon
          sx={{
            backgroundColor: "#FFFFFF",
            borderRadius: "50%",
            color: "#gray",
            fill: "#FFFFFF",
            border: "2px solid gray",
            cursor: "not-allowed",
            opacity: 0.5,
          }}
        />
      );
    } else if (status === "disable") {
      return (
        <CircleIcon
          sx={{
            backgroundColor: "#FFFFFF",
            borderRadius: "50%",
            color: "#gray",
            fill: "#FFFFFF",
            border: "2px solid gray",
            cursor: "not-allowed",
            opacity: 0.5,
          }}
        />
      );
    }
    return (
      <CircleIcon
        sx={{
          backgroundColor: "#FFFFFF",
          borderRadius: "50%",
          color: "#FFAC07",
          fill: "#FFFFFF",
          border: "2px solid #B7C8E5",
          boxShadow,
        }}
      />
    );
  };

  // this function is to check whether investigation has investigator or not
  function checkInvestigator(stepData) {
    return stepData[stepData.length - 1].investigationApplicable !== 0;
  }

  // this function is to check whether investigation has capaOwner or not
  function checkCAPAOwner(stepData) {
    return stepData[stepData.length - 1].capaApplicable !== 0;
  }

  //this function will return most recent status for FR
  function findFRLatestStatus(stepData) {
    for (let i = stepData.length - 1; i >= 0; i--) {
      const curr = stepData[i];
      if (curr.roleName === "First Reviewer") {
        return curr.status;
      }
    }
    return "";
  }

  // this function will return most recent status for FR
  function findSRLatestStatus(stepData) {
    for (let i = stepData.length - 1; i >= 0; i--) {
      const curr = stepData[i];
      if (curr.roleName === "Second Reviewer") {
        return curr.status;
      }
    }
    return "";
  }

  //this is the function where we are stating which status will have what affect on timeline, and their states
  const determineStatuses = () => {
    if (stepData.length > 0) {
      const currentState = stepData[stepData.length - 1].status;
      const currRole = stepData[stepData.length - 1].roleName;
      let statuses = [];
      const investigatorCheck = checkInvestigator(stepData);
      const capaCheck = checkCAPAOwner(stepData);

      switch (currentState) {
        case "New Incident":
          statuses = [
            "completed",
            "inactive",
            "inactive",
            "inactive",
            "inactive",
            "inactive",
          ];
          setSelectedStep(0);
          break;
        case "New Incident Clarification":
          statuses = [
            "pending",
            "returned",
            "inactive",
            "inactive",
            "inactive",
            "inactive",
          ];
          setSelectedStep(1);
          break;
        case "Incident Resubmission":
          statuses = [
            "completed",
            "pending",
            "inactive",
            "inactive",
            "inactive",
            "inactive",
          ];
          setSelectedStep(0);
          break;
        case "Incident Review":
          statuses = [
            "completed",
            "pending",
            "inactive",
            "inactive",
            "inactive",
            "inactive",
          ];
          setSelectedStep(1);
          break;
        case "Incident Revalidation":
          statuses = [
            "completed",
            "pending",
            "inactive",
            "inactive",
            "inactive",
            "inactive",
          ];
          setSelectedStep(1);
          break;
        case "Resubmitted for Review":
          statuses = [
            "completed",
            "completed",
            "inactive",
            "inactive",
            "inactive",
            "inactive",
          ];
          setSelectedStep(1);
          break;
        case "Investigation Initiated":
          statuses = [
            "completed",
            "completed",
            "pending",
            "inactive",
            "inactive",
            "inactive",
          ];
          setSelectedStep(2);
          break;
        case "Investigation Validation":
          statuses = [
            "completed",
            "pending",
            "returned",
            "inactive",
            "inactive",
            "inactive",
          ];
          setSelectedStep(1);
          break;
        case "Investigation Reinitiated":
          statuses = [
            "completed",
            "completed",
            "pending",
            "inactive",
            "inactive",
            "inactive",
          ];
          setSelectedStep(2);
          break;
        case "Investigation Completed":
          statuses = [
            "completed",
            "pending",
            "completed",
            "inactive",
            "inactive",
            "inactive",
          ];
          setSelectedStep(1);
          break;
        case "Investigation Query":
          statuses = [
            "completed",
            "returned",
            "pending",
            "inactive",
            "inactive",
            "inactive",
          ];
          setSelectedStep(2);
          break;
        case "Investigation Resubmission":
          statuses = [
            "completed",
            "pending",
            "completed",
            "inactive",
            "inactive",
            "inactive",
          ];
          setSelectedStep(1);
          break;
        case "Investigation Under Approval":
          statuses = [
            "completed",
            "pending",
            "completed",
            "inactive",
            "inactive",
            "inactive",
          ];
          setSelectedStep(1);
          break;
        case "Investigation Clarification":
          statuses = [
            "completed",
            "pending",
            "completed",
            "inactive",
            "inactive",
            "inactive",
          ];
          setSelectedStep(1);
          break;
        case "Investigation Approval Resubmission":
          statuses = [
            "completed",
            "pending",
            "completed",
            "inactive",
            "inactive",
            "inactive",
          ];
          setSelectedStep(1);
          break;
        case "CAPA Assigned":
          statuses = [
            "completed",
            "completed",
            "completed",
            "pending",
            "inactive",
            "inactive",
          ];
          setSelectedStep(3);
          break;
        case "CAPA In Progress":
          //Here there will be many cases to be handles for both first and second reviewer
          statuses = [
            "completed",
            "completed",
            "completed",
            "pending",
            "inactive",
            "inactive",
          ];
          setSelectedStep(3);
          break;
        case "Incident Closed":
          statuses = [
            "completed",
            "completed",
            "completed",
            "completed",
            "completed",
            "completed",
          ];
          setSelectedStep(5);
          break;
        default:
          statuses = [
            "inactive",
            "inactive",
            "inactive",
            "inactive",
            "inactive",
            "inactive",
          ];
          setSelectedStep(0);
      }

      if (!investigatorCheck) {
        statuses[2] = "inactive";
      }

      if (!capaCheck) {
        statuses[3] = "inactive";
      }

      if (currentState === "Incident Closed") {
        statuses[2] = investigatorCheck === false ? "waiting" : statuses[2];
        statuses[3] = capaCheck ? statuses[3] : "waiting";
      }

      setActiveSteps(statuses);
    }
  };

  //this function is to determine what to show when a particular step is clicked, there will be another function to modify the details
  const getDetailsForStep = (stepLabel, index) => {
    if (!stepData.length || activeSteps[index] === "inactive") return null;

    switch (stepLabel.toLowerCase()) {
      case "incident reported":
        return [...stepData]
          .reverse()
          .find((item) => item.roleName === "Reporting Officer");

      case "initial review":
        return [...stepData]
          .reverse()
          .find((item) =>
            ["First Reviewer", "Second Reviewer"].includes(item.roleName)
          );

      case "investigation":
        const investigatorCheck = checkInvestigator(stepData);
        if (investigatorCheck) {
          return [...stepData]
            .reverse()
            .find((item) => item.roleName === "Investigator");
        } else {
          return { status: "Investigation Not Applicable" };
        }

      case "capa":
        const capaCheck = checkCAPAOwner(stepData);
        if (capaCheck) {
          return [...stepData]
            .reverse()
            .find((item) => item.roleName === "CAPA Owner");
        } else {
          return { status: "CAPA Not Applicable" };
        }

      case "final review":
        if (capaCheck) {
          return [...stepData]
            .reverse()
            .find((item) => item.roleName === "CAPA Owner");
        }else {
          return stepData[stepData.length - 1];
        }
      case "incident closed":
        return stepData[stepData.length - 1];

      default:
        return null;
    }
  };

  //this function is to determine what we have to detail
  const getStatusText = (currentRole, currentState, selectedStep) => {
    if (currentState === "Incident Closed") {
      switch (selectedStep) {
        case 0:
          return `Incident Reported/Reporting Officer: ${roName} / ${roEmail}`;
        case 1:
          return `Reviewed/First Reviewer: ${frName} / ${frEmail}
                    Reviewed/Second Reviwer: ${srName} / ${srEmail}`;
        case 2:
          return `Investigation Reviewed/First Reviewer: ${frName} / ${frEmail}
                    Investigation Approved/Second Reviewer: ${srName} / ${srEmail}`;
        case 3:
          return `All CAPA Reviewed/First Reviewer: ${frName} / ${frEmail}
                    All CAPA Approved/Second Reviwer: ${srName} / ${srEmail}`;
        case 4:
          return `Investigation Approved/Second Reviewer: ${frName} / ${frEmail}
                    All CAPA Approved/Second Reviewer: ${srName} / ${srEmail}`;
        case 5:
          return `Incident Closed/Second Reviewer
                    Email Update Sent`;
        default:
          return `Completed / ${currentRole}`;
      }
    }

    if (selectedStep === 1) {
      const frStatus = findFRLatestStatus(stepData);
      const srStatus = findSRLatestStatus(stepData);
      if (srStatus !== "") {
        return [
          `${frStatus} / First Reviewer: ${frName}`,
          `${srStatus} / Second Reviewer: ${srName}`,
        ];
      } else{
        return [`${currentState} / ${currentRole} : ${frName}`]
      }
    }

    if(currentRole === "Reporting Officer"){
      return [`${currentState} / ${currentRole} : ${roName}`]
    }

    if(currentRole === "Investigator"){
      return [`${currentState} / ${currentRole} : ${investigatorName}`]
    }

    return [`${currentState} / ${currentRole}`];
  };

  //this is the function to handle the step and set what to show in details
  const handleStepClick = (step, index) => {
    if (activeSteps[index] === "inactive") return;

    setSelectedStep(index);

    const details = getDetailsForStep(step, index);
    const convertedDate = formatDateToIndianLocale2(details?.createdAt);
    if (details) {
      setCurrentState(details?.status);
      setCurrentRole(details?.roleName || "");
      setCurrentFullName(
        `${details.firstName || ""} ${details?.middleName || ""} ${
          details?.lastName || ""
        }`.trim()
      );
      setSurveyDate(convertedDate || "");
      setCurrentEmail(details?.email || "");
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        paddingX: "24px",
        paddingY: "12px",
        backgroundColor: "#ffffff",
        borderRadius: "8px",
        position: "relative",
        pt: 3.5,
        height: "110px",
      }}
    >
      {stepData?.length === 0 ? (
        <Typography sx={{ textAlign: "center", color: "#888" }}>
          No step data available.
        </Typography>
      ) : (
        <>
          <Stepper
            activeStep={activeSteps}
            alternativeLabel
            sx={{
              position: "absolute",
              top: 10,
              left: 0,
              right: 0,
              transform: "translateY(-50%)",
              width: "90%",
            }}
          >
            {stepHeading?.map((stepName, index) => {
              const isInactive = activeSteps[index] === "inactive";
              const isActive = activeSteps === index;

              return (
                <Step
                  key={index}
                  onClick={() => handleStepClick(stepName, index)}
                  sx={{
                    cursor: isInactive ? "not-allowed" : "pointer",
                    opacity: isInactive ? 0.5 : 1,
                  }}
                >
                  <StepLabel
                    StepIconComponent={() => (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          position: "relative",
                        }}
                      >
                        {getStepIcon(activeSteps[index], index)}
                      </Box>
                    )}
                    optional={
                      <Typography
                        variant="caption"
                        sx={{
                          color: isInactive ? "#52596690" : "#525966",
                          fontSize: {
                            xs: "0.6rem",
                            sm: "0.65rem",
                            md: "0.7rem",
                            lg: "0.8rem",
                            xl: "0.85rem",
                          },
                          lineHeight: 1.2,
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                          maxWidth: "100%",
                        }}
                      >
                        {stepName}
                      </Typography>
                    }
                  />
                </Step>
              );
            })}
          </Stepper>

          <Box px={1} display="flex" flexDirection="column" gap={0.5} mt={2}>
            <Box>
              <Box display="flex" flexDirection="column" alignItems="start">
                {(() => {
                  const statusTexts = getStatusText(
                    currentRole,
                    currentState,
                    selectedStep
                  );
                  return (
                    <>
                      <Typography
                        variant="body2"
                        sx={{ fontWeight: 600, marginRight: 2 ,color:"#525966" }}
                      >
                        {statusTexts[0] ?? ""}
                      </Typography>
                      <Typography variant="body2"  sx={{ fontWeight: 600, marginRight: 2 ,color:"#525966" }}>
                        {statusTexts[1] ?? ""}
                      </Typography>
                    </>
                  );
                })()}
              </Box>
              <Box>
                <Typography variant="caption">{surveyDate}</Typography>
              </Box>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
}
