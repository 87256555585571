import React, { useEffect, useState } from 'react';
import { Tabs, Tab, Box, Switch, Typography, Grid } from '@mui/material';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import GavelIcon from '@mui/icons-material/Gavel';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import MenuIcon from '@mui/icons-material/Menu';
import EngineeringIcon from '@mui/icons-material/Engineering';
import Slider from "react-slick";
import { styled } from '@mui/system';
import CardDrawer from './CardDrawer1';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            {...other}
            style={{ overflowY: 'auto' }} // Enable vertical scrolling
        >
            <Box
                px={3}
                pl={3}
                sx={{
                    maxHeight: '250px',
                    overflowY: 'scroll', // Keep scroll functionality
                    scrollbarWidth: 'none', // Hide scrollbar in Firefox
                    '&::-webkit-scrollbar': {
                        display: 'none', // Hide scrollbar in Chrome, Safari, Edge
                    },
                }}
            >
                <h2>{children}</h2>
            </Box>
        </div>
    );
}

export default function IncidentReviewTabsSection({ data, title }) {
    const [value, setValue] = useState(0);

    const resources = [
        ...(data[0].PersonnelDetail || []).filter(item => item), // Check if PersonnelDetail exists and filter out falsy values
        ...(data[0].VehicleDetail || []).filter(item => item),  // Check if VehicleDetail exists and filter out falsy values
        ...(data[0].EquipmentDetail || []).filter(item => item) // Check if EquipmentDetail exists and filter out falsy values
    ];

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    console.log(value, "gggghhh")

    // Slider settings
    const settings = {
        dots: true, // Show dots below the slider
        infinite: false, // Infinite looping of slides
        speed: 400, // Transition speed
        slidesToShow: 2, // Show 2 slides if subtitle, otherwise 1
        slidesToScroll: 1,
        arrows: false,
        responsive: [
            {
                breakpoint: 1200, // At screen widths below 1100px
                settings: {
                    slidesToShow: 1, // Show 1 slide below 1100px
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600, // At screen widths below 600px
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480, // At screen widths below 480px
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    const StyledBox = styled(Box)({
        '.slick-dots': {
            position: 'absolute',
            bottom: '-22px'
        },
        '.slick-slide': {
            paddingRight: "12px",
            maxHeight: '184px'
        },
        // '.slick-list': {              
        //     overflow: 'visible', 
        // } 
    });


    const tabStyle = {
        padding: '6px 16px',  // Reduces padding
        minHeight: '28px',  // Adjusts height of each tab
        // width: '132px',
       // borderTop: '1px solid #ccc',  // Border on top
        // borderRight: index === tabData.length - 1 ? 'none' : '1px solid #ccc',  // Border on right
        borderRight: '1px solid #ccc',  // Border on right
        borderRadius: '0px',  // No rounded corners
        margin: '0px',  // No space between tabs
    }


    //lg: '420px', xl: '350px'

    return (
        <>
            <Tabs
                value={value}
                onChange={handleChange}
                aria-label="icon tabs"
                variant="fullWidth"
                textColor="primary"
                indicatorColor="primary"
                sx={{
                    borderBottom: 1,
                    borderColor: 'divider',
                    minHeight: '32px',  // Adjusts the height of the tab bar
                   
                    ".MuiButtonBase-root": {textTransform: "none"}
                }}
            >
                <Tab
                    icon={
                        <>
                            {value === 0 ? (
                                <Box display='flex' alignItems='center' sx={{ position: 'relative', width: '100%' }}>
                                    <EngineeringIcon />
                                    <Typography variant='body2' sx={{ color: '#09A2E3', ml: '4px' }}>Resources</Typography>
                                    <Switch size="small"

                                        checked={true} disabled />
                                </Box>
                            ) : (
                                <Box display='flex' alignItems='center' gap={0.5}>
                                    <PersonOutlineOutlinedIcon />
                                    <Typography variant='body2' sx={{ color: '#525966' }}>Resources</Typography>
                                </Box>
                            )}
                        </>
                    }
                    iconPosition="start"
                    sx={{ ...tabStyle, backgroundColor: value === 0 ? 'rgba(9, 162, 227, 0.12)' : 'transparent' }}
                />


                <Tab
                    icon={
                        <>
                            {value === 1 ? (
                                <Box display='flex' alignItems='center' sx={{ position: 'relative', width: '100%' }}>
                                    <MenuIcon />
                                    <Typography variant='body2' sx={{ color: '#09A2E3', ml: '4px' }}>Evidence</Typography>
                                    <Switch size="small"

                                        checked={true} disabled />
                                </Box>
                            ) : (
                                <Box display='flex' alignItems='center' gap={0.5}>
                                    <MenuIcon />
                                    <Typography variant='body2' sx={{ color: '#525966' }}>Evidence</Typography>
                                </Box>
                            )}
                        </>
                    }
                    iconPosition="start"
                    sx={{ ...tabStyle, backgroundColor: value === 1 ? 'rgba(9, 162, 227, 0.12)' : 'transparent' }}
                />

                {/* Evidence Tab */}
                <Tab
                    icon={
                        <>
                            {value === 2 ? (
                                <Box display='flex' alignItems='center' sx={{ position: 'relative', width: '100%' }}>
                                    <PendingActionsIcon />
                                    <Typography variant='body2' sx={{ color: '#09A2E3', ml: '4px' }}>CAPA</Typography>
                                    <Switch size="small"

                                        checked={true} disabled />
                                </Box>
                            ) : (
                                <Box display='flex' alignItems='center' gap={0.5}>
                                    <PendingActionsIcon />
                                    <Typography variant='body2' sx={{ color: '#525966' }}>CAPA</Typography>
                                </Box>
                            )}
                        </>
                    }
                    iconPosition="start"
                    sx={{ ...tabStyle, backgroundColor: value === 2 ? 'rgba(9, 162, 227, 0.12)' : 'transparent' }}
                />

                {/* CAPA Tab */}
                <Tab
                    icon={
                        <>
                            {value === 3 ? (
                                <Box display='flex' alignItems='center' sx={{ position: 'relative', width: '100%' }}>
                                    <GavelIcon />
                                    <Typography variant='body2' sx={{ color: '#09A2E3', ml: '4px' }}>Regulatory</Typography>
                                    <Switch size="small"

                                        checked={true} disabled />
                                </Box>
                            ) : (
                                <Box display='flex' alignItems='center' gap={0.5}>
                                    <GavelIcon />
                                    <Typography variant='body2' sx={{ color: '#525966' }}>Regulatory</Typography>
                                </Box>
                            )}
                        </>
                    }
                    iconPosition="start"
                    sx={{ ...tabStyle, backgroundColor: value === 3 ? 'rgba(9, 162, 227, 0.12)' : 'transparent' }}
                />

            </Tabs>

            <TabPanel>


                {value === 0 ?
                    (
                        <>
                            {resources?.length > 0 && (
                                <CardDrawer
                                    cardData={resources}
                                    title="Resources"
                                    subtitle="review"
                                    check="Resources"
                                />
                            )}
                        </>
                    ) : value === 1 ? (
                        <>
                            <Grid item xs={12} md={12}>
                                <StyledBox>
                                    {data[0]?.EvidenceDetail?.length > 1 ? (
                                        <Slider {...settings} style={{ width: '100%' }}>
                                            {data[0]?.EvidenceDetail?.map((image, index) => (
                                                <>
                                                    <Box key={index} sx={{ display: 'flex', justifyContent: 'center' }}>
                                                        <img
                                                            src={image?.file ?? ""}
                                                            alt={`Slide ${index}`}
                                                            style={{ width: '100%', maxHeight: "200px", borderRadius: '4px' }}
                                                        />
                                                    </Box>
                                                    <Typography variant='body2' sx={{ color: 'rgba(82, 89, 102, 1)', mt: '24px' }}>
                                                        {image.Description ?? "No description available"}
                                                    </Typography>
                                                </>
                                            ))}
                                        </Slider>
                                    ) : (
                                        data[0]?.EvidenceDetail?.map((image, index) => (
                                            <>
                                                <Box key={index} sx={{ display: 'flex', justifyContent: 'center' }}>
                                                    <img
                                                        src={image?.file ?? ""}
                                                        alt={`Slide ${index}`}
                                                        style={{ width: '100%', height: '200px', borderRadius: '4px' }}
                                                    />
                                                </Box>
                                                <Typography variant='body2' sx={{ color: 'rgba(82, 89, 102, 1)', mt: '24px' }}>
                                                    {image.Description ?? "No description available"}
                                                </Typography>
                                            </>
                                        ))
                                    )}
                                </StyledBox>

                            </Grid>
                        </>
                    ) : value === 2 ? (
                        <>
                            {data && data.length > 0 && data[0].CAPADetail && data[0].CAPADetail.length > 0 && (<CardDrawer cardData={data[0].CAPADetail} title={"CAPA"} subtitle={"review"} />)}
                        </>
                    ) : value === 3 ? (
                        <>
                        <Grid container spacing={1} sx={{lineHeight: 0.5 }} >
                            <Grid container spacing={1} item xs={12} md={5} >
                                <Grid item xs={12} >
                                    <Typography variant='body2' sx={{ color: '#1E1E1E',fontWeight: 600,}}>
                                        Distance from the face
                                    </Typography>
                                    <Typography variant='caption' sx={{ color: '#09A2E3'}}>
                                        {data[0]?.Regulatory?.["Distance from the face"] || 'N/A'}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant='body2'  sx={{ color: '#1E1E1E',fontWeight: 600,}}>
                                        Dip Number
                                    </Typography>
                                    <Typography variant='caption' sx={{ color: '#09A2E3'}}>
                                        {data[0]?.Regulatory?.["Dip Number"] || 'N/A'}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant='body2' sx={{ color: '#1E1E1E',fontWeight: 600,}} >
                                        Pit/incline No.
                                    </Typography>
                                    <Typography variant='caption' sx={{ color: '#09A2E3'}}>
                                        {data[0]?.Regulatory?.["Pit/incline No."] || 'N/A'}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant='body2' sx={{ color: '#1E1E1E',fontWeight: 600,}} >
                                        Seam
                                    </Typography>
                                    <Typography variant='caption' sx={{ color: '#09A2E3'}}>
                                        {data[0]?.Regulatory?.["Seam"] || 'N/A'}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant='body2' sx={{ color: '#1E1E1E',fontWeight: 600,}} >
                                        Level
                                    </Typography>
                                    <Typography variant='caption' sx={{ color: '#09A2E3'}}>
                                        {data[0]?.Regulatory?.["Level"] || 'N/A'}
                                    </Typography>
                                </Grid>

                            </Grid>
                            <Grid item  xs={12} md={7}>

                                <Grid item xs={12} sx={{mb:"8px"}}>
                                    <Typography variant='body2' sx={{ color: '#1E1E1E',fontWeight: 600,}} >
                                        District/Panel/Bench outside work District
                                    </Typography>
                                    <Typography variant='caption' sx={{ color: '#09A2E3'}}>
                                        {data[0]?.Regulatory?.["District/Panel/Bench outside work District"] || 'N/A'}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant='body2' sx={{ color: '#1E1E1E',fontWeight: 600,}} >
                                        Details not covered above
                                    </Typography>
                                    <Typography variant='caption' sx={{ color: '#09A2E3'}}>
                                        {data[0]?.Regulatory?.["Details not covered above"] || 'N/A'}
                                    </Typography>
                                </Grid>
                            </Grid>

                        </Grid>
                    </>
                    ) : (
                        <></>
                    )}
            </TabPanel>




        </>
    )
}
