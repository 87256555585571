import React, { useState, useEffect, useRef, useCallback } from "react";
import io from "socket.io-client";
import {
  Box,
  Avatar,
  Typography,
  TextField,
  IconButton,
  Button, Dialog, DialogTitle, DialogContent, DialogActions
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import AttachmentIcon from "@mui/icons-material/AttachFile";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AssignTicketDialog from "./AssignTicketDialog";
import { getMessages, updateTicketStatusByAdmin, updateTicketStatusByUser } from "../../Apis/apiCall";
import ConfirmationModal from "../../Components/LossControl/ConfirmationModal";
import CloseIcon from "@mui/icons-material/Close";


const ChatBox = ({ open, isOpen, ticketDetails }) => {

  const socketRef = useRef(null);
  
  const ticketID = ticketDetails.id;
  const logindata = JSON.parse(localStorage.getItem("loginData"));
  const role = logindata.config.permissionDetails.applicationRoles[0].name;
  const [flow, setFlow] = useState(null)
  useEffect(() => {
    if (role === "Support Agent") {
      setFlow(2);
    }
    else if (role === "Support Admin") {
      setFlow(3);
    }
    else{
      setFlow(1);
    }
  }, []);
  
  const [messages, setMessages] = useState([]);

  const [newMessage, setNewMessage] = useState("");

  const [closeTicketMode, setCloseTicketMode] = useState(false);
  const [closingStatement, setClosingStatement] = useState("");

  const [confirmationModal, setConfirmationModal] = useState(false);

  const [isReassignModalOpen, setIsReassignModalOpen] = useState(false);
  

  const senderID = logindata.id;
  const [receiverID, setReceiverID] = useState(null);
  const [receiverName, setReceiverName] = useState(null);
  const [senderName, setSenderName] = useState(null);

  useEffect(()=>{
    if(ticketDetails.reportedBy === senderID){
      setReceiverID(ticketDetails.assignedTo);
      setReceiverName(ticketDetails.agentName)
      setSenderName(ticketDetails.name)
    }else{
      setReceiverID(ticketDetails.reportedBy);
      setReceiverName(ticketDetails.name)
      setSenderName(ticketDetails.agentName)
    }
  },[ticketDetails,senderID])
  console.log("ticketDetails",ticketDetails);

  useEffect(() => {
    // Create socket connection only when needed
    if (open && receiverID && ticketID && flow !== 3 && (ticketDetails.status === "assigned" || ticketDetails.status === "reassigned")) {
      socketRef.current = io("http://13.232.214.184:5001", {
        reconnection: true,
        reconnectionAttempts: 5,
        reconnectionDelay: 1000
      });
      
      socketRef.current.emit("join_chat", { ticketID, senderID, receiverID });
  
      // Listen for incoming messages
      const handleReceiveMessage = (data) => {
        console.log("Received message:", data);
        
        // Check if the message is for this ticket
        if (data.ticketID === ticketID) {
          setMessages(prevMessages => {
            // Avoid duplicate messages
            const isDuplicate = prevMessages.some(
              msg => msg.text === data.message && 
              msg.sender === (data.senderID === senderID ? "Me" : receiverName)
            );
            
            if (isDuplicate) return prevMessages;

            return [
              ...prevMessages,
              {
                sender: data.senderID === senderID ? "Me" : receiverName,
                text: data.message,
                isSender: data.senderID === senderID,
              }
            ];
          });
        }
      };

      // Attach message listener
      socketRef.current.on("receive_message", handleReceiveMessage);

      // Connection error handling
      socketRef.current.on("connect_error", (error) => {
        console.error("Socket connection error:", error);
      });

      // Cleanup function
      return () => {
        if (socketRef.current) {
          socketRef.current.off("receive_message", handleReceiveMessage);
          socketRef.current.disconnect();
          socketRef.current = null;
        }
      };
    }

    // If conditions are not met, ensure socket is disconnected
    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
        socketRef.current = null;
      }
    };
  }, [open, flow, ticketID, senderID, receiverID]);


  const handleSend = useCallback(() => {
    if (newMessage.trim() && socketRef.current) {
      const messageData = {
        ticketID,
        senderID,
        receiverID,
        message: newMessage
      };

      // Emit message to server
      socketRef.current.emit("send_message", messageData);

      // Immediately update local messages
      setMessages(prevMessages => [
        ...prevMessages,
        {
          sender: "Me",
          text: newMessage,
          isSender: true
        }
      ]);

      // Clear input
      setNewMessage("");
    }
  }, [newMessage, ticketID, senderID, receiverID]);
  const fetchMessages = async (ticketID) => {
    try {
      const result = await getMessages(ticketID);
      console.log(result.data.data);
      const data = result.data.data;
      const updatedMessages = data?.map((item) => ({
        sender: item.senderID === senderID ? "Me" : receiverName,
        text: item.message,
        isSender: item.senderID === senderID,
      }));
      
      setMessages((prevMessages) => [...prevMessages, ...updatedMessages]);

    } catch (error) {
      console.log(error);
    }
  }
  useEffect(()=>{
    if (open) {
    fetchMessages(ticketID);
    }
  },[open])

  const handleCloseTicketConfirm = async () => {
    if(flow === 3){
      try {      
        const values = {
          ticketID : ticketID,
          status : "closed",
          closingStatement : closingStatement,
        };
        const response = await updateTicketStatusByAdmin(values);
        window.location.reload();
      } catch (error) {
        console.error("Error closing ticket.", error);
      }
    } else{
        try {      
          const values = {
            ticketID : ticketID,
            status : "closed",
            closingStatement : closingStatement,
          };
          const response = await updateTicketStatusByUser(values);
          window.location.reload();
        } catch (error) {
          console.error("Error closing ticket.", error);
        }
    }
  };
  
  const [reopenConfirmation,setReopenConfirmation] = useState(false);
  const handleReopen = () => {
    if(flow === 3){
      setIsReassignModalOpen(true);
    }
    else{
      setReopenConfirmation(true);
    }
  }

  const handleReopenTicketConfirm = async () => {
        try {
          const response = await updateTicketStatusByUser({ticketID, status:"reopen"});
          window.location.reload();
        } catch (error) {
          console.error("Error Reopening ticket.", error);
        }
  };

  const messagesEndRef = useRef(null);
  // New function to scroll to the bottom of messages
  const scrollToBottom = useCallback(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, []);

  // Scroll to bottom when messages change or chat is opened
  useEffect(() => {
    if (messages.length > 0) {
      scrollToBottom();
    }
  }, [messages, scrollToBottom]);
  // console.log("messsssages",messages);

  return (
    <>
      <Box
        sx={{
          position: "absolute",
          bottom: "14px",
          right: "16px",
          width: "400px",
          height: "560px",
          border: "1px solid #E7E7E7",
          borderRadius: "12px",
          display: "flex",
          flexDirection: "column",
          boxShadow: "4px 4px 8px 2px rgba(0, 0, 0, 0.25)",
          backgroundColor: "white",
          overflow: "hidden",
        }}
      >
        {/* Header */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "10px",
            borderBottom: "1px solid #ddd",
            backgroundColor: "#ffffff",
          }}
        >
          <Box display="flex" alignItems="center">
            <Avatar
              src="https://via.placeholder.com/40"
              alt="receiverName"
              sx={{ width: 40, height: 40 }}
            />
            <Box ml={2}>
              <Typography variant="subtitle1" fontWeight="bold">
                {receiverName}
              </Typography>
              {/* <Box display="flex" flexDirection="row" alignItems="center" gap={0.5}>
                <CircleIcon sx={{ color: "#68D391", fontSize: "12px" }} />
                <Typography variant="body2" sx={{ color: "#68D391" }}>
                  Online
                </Typography>
              </Box> */}
            </Box>
          </Box>
          <Box display="flex" flexDirection="row" alignItems="center" gap={1}>
            {((flow === 1 || flow===3) && ticketDetails?.status === "closed") ? (
              <Button
                variant="contained"
                size="small"
                sx={{ borderRadius: "4px", backgroundColor: "#09A2E3", color: "white" }}
                onClick={() => handleReopen()}
              >
                Reopen
              </Button>
            ) : flow === 3 ? (
              <Button
                variant="contained"
                size="small"
                sx={{ borderRadius: "4px", backgroundColor: "#09A2E3", color: "white" }}
                onClick={() => setIsReassignModalOpen(true)}
              >
                Reassign
              </Button>
            ) : null}

            {(ticketDetails?.status !== "open" && ticketDetails?.status !== "reopen" && ticketDetails?.status !== "closed" && ticketDetails?.status !== "escalated") &&
            (<Button
              variant="contained"
              size="small"
              sx={{ borderRadius: "4px", backgroundColor: "#09A2E3", color: "white" }}
              onClick={() => setCloseTicketMode(true)}
            >
              Close
            </Button>)}

            {flow === 3 && (
              <IconButton onClick={() => isOpen(false)}>
                <ExpandMoreIcon />
              </IconButton>
            )}
          </Box>
        </Box>

        {/* Messages */}
        <Box
          sx={{
            flex: 1,
            overflowY: "auto",
            padding: "10px",
            backgroundColor: "#ffffff",
          }}
        >
          {messages?.map((message, index) => (
            <Box
              key={`${message.text}-${index}`}
              sx={{
                display: "flex",
                justifyContent: message.isSender ? "flex-end" : "flex-start",
                marginBottom: "10px",
              }}
            >
              {!message.isSender && (
                <Avatar
                  src="https://via.placeholder.com/40"
                  alt="receiver"
                  sx={{ width: 30, height: 30, marginRight: "8px" }}
                />
              )}
              <Box>
                <Typography variant="body2" sx={{color:'#757575'}}>{message.isSender ? senderName : receiverName}</Typography>
                <Box
                  sx={{
                    backgroundColor: message.isSender ? "#09A2E3" : "#F1F1F1",
                    color: message.isSender ? "white" : "black",
                    borderRadius: "12px",
                    padding: "8px 12px",
                    // maxWidth: "70%",
                  }}
                >
                  {message.text}
                </Box>
              </Box>
            </Box>
          ))}
          <div ref={messagesEndRef} />
        </Box>

        {/* Message Input */}
        {(flow !== 3 && ticketDetails?.status !== "closed") && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            padding: "10px",
            borderTop: "1px solid #ddd",
          }}
        >
          <IconButton>
            <AttachmentIcon />
          </IconButton>
          <TextField
            fullWidth
            size="small"
            placeholder="Type a message"
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            sx={{
              ml: "8px",
              borderRadius: "12px",
              border: "2px solid #E2E8F0",
            }}
          />
          <IconButton onClick={()=>handleSend()}>
            <SendIcon sx={{ color: "#09A2E3" }} />
          </IconButton>
        </Box>)}
      </Box>

      {/* Closing Statement Dialog */}
      <Dialog open={closeTicketMode} onClose={() => setCloseTicketMode(false)} maxWidth="sm" fullWidth>
        <DialogTitle>
          <Typography variant="h6">Closing Statement</Typography>
          <IconButton
            aria-label="close"
            onClick={() => setCloseTicketMode(false)}
            sx={{ position: "absolute", right: 8, top: 8, color: (theme) => theme.palette.grey[500] }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Enter closing statement"
            value={closingStatement}
            onChange={(e) => setClosingStatement(e.target.value)}
            multiline
            rows={4}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" sx={{color:'white'}} disabled={closingStatement?.length === 0} onClick={() => setConfirmationModal(true)}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      <AssignTicketDialog open={isReassignModalOpen} onClose={() => setIsReassignModalOpen(false)} ticketID={ticketID} />

      {/* Close Confirmation */}
      <ConfirmationModal open={confirmationModal} title={"Are you sure?"} message={"Do you really want to close this ticket?"} onCancel={()=>setConfirmationModal(false)} onConfirm={()=>handleCloseTicketConfirm()} />
      {/* Reopen Confirmation */}
      <ConfirmationModal open={reopenConfirmation} title={"Are you sure?"} message={"Do you really want to reopen this ticket?"} onCancel={()=>setReopenConfirmation(false)} onConfirm={()=>handleReopenTicketConfirm()} />
    </>
  );
};

export default ChatBox;
