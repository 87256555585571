import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import Typography from "@mui/material/Typography";
import { Link, useLocation } from "react-router-dom";
import CalendarTodayOutlined from "@mui/icons-material/CalendarTodayOutlined";
import HomeIcon from "@mui/icons-material/Home"; // Assuming you have other icons imported similarly
import { Box, Divider,Tooltip } from "@mui/material";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import TaskOutlinedIcon from "@mui/icons-material/TaskOutlined";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import MenuBookOutlinedIcon from "@mui/icons-material/MenuBookOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import { useNavigate } from "react-router-dom";

const drawerWidth = 227;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  borderRight: "none",
  backgroundColor: "#FFF",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(9)} + 1px)`,
  borderRight: "10px",
  backgroundColor: "#FFF",
  padding: "12px !important",
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: open ? drawerWidth : `calc(${theme.spacing(9)} + 1px)`,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const SideMenu = ({ open, handleDrawerOpen }) => {
  const navigate = useNavigate();

  const data = JSON.parse(localStorage.getItem("loginData"));
  const sidebarLogo = data?.config?.organizationConfig?.logo;
  const orgName = data?.config?.organizationConfig?.name;
  const currWorkLocation = data?.currentWorkLocation;
  const location = useLocation();
  const [menuItems, setMenuItems] = useState([]);

  const getBasePath = (pathname) => {
    const segments = pathname.split("/"); // Split by "/"
    return `/${segments[1]}`; // Get the first two segments like "/module/incident-management"
  };

  const currentBasePath = getBasePath(location.pathname);

  useEffect(() => {
    const incidentRoles = JSON.parse(localStorage.getItem('IncidentRoles')) || [];
    const lossRoles = JSON.parse(localStorage.getItem('lossControlRoles')) || [];

    const hardcodedMenuItems = [
      {
        text: "Home",
        path: "/home",
        icon: <HomeOutlinedIcon />,
        section: "Home",
      },
      {
        text: "Modules",
        path: "/module",
        icon: <DashboardOutlinedIcon />,
        section: "Home",
      },
      {
        text: "My Actions",
        path: "/my-actions",
        icon: <TaskOutlinedIcon />,
        section: "Actions",
      },
      {
        text: "All Actions",
        path: "/all-actions",
        icon: <TaskOutlinedIcon />,
        section: "All Actions",
      },
      {
        text: "Reports",
        path: "/report",
        icon: <InsertDriveFileOutlinedIcon />,
        section: "Reports",
      },
      {
        text: "Help Center",
        path: "/help-center",
        icon: <MenuBookOutlinedIcon />,
        section: "Configurations",
      },
    ];

    // Remove "My Actions" if incidentRoles and lossRoles are empty
    const filteredMenuItems = hardcodedMenuItems.filter(item => {
      if (item.text === "My Actions") {
        return incidentRoles.length > 0 || lossRoles.length > 0;
      }
      return true;  // Keep other items unchanged
    });

    setMenuItems(filteredMenuItems);
  }, []);

  const getSectionTitle = (section, index, prevSection) => {
    if (index === 2) {
      return (
        <>
          <Divider sx={{ borderBottomWidth: 2 }} />
          <Typography
            sx={{ padding: "8px 16px", fontWeight: 500, color: "#000000" }}
          >
            Actions
          </Typography>
        </>
      );
    }
    if (section ==="Reports") {
      return (
        <>
          <Divider sx={{ borderBottomWidth: 2 }} />
          <Typography
            sx={{ padding: "8px 16px", fontWeight: 500, color: "#000000" }}
          >
            Others
          </Typography>
        </>
      );
    }
    return null;
  };

  const handleRedirect = () => {
    navigate("/landing-dashboard"); // Redirect to the landing-dashboard page
  };

  return (
    <Drawer variant="permanent" open={open}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          padding: "5px",
          textAlign: "center",
          height: "50px", // Set height to ensure consistent spacing
          marginBottom: "16px",
        }}
      >
        <Box sx={{ flex: "0 0 auto", cursor: "pointer" }}>
          <img
            src={sidebarLogo}
            height="50px"
            alt="logo"
            onClick={handleDrawerOpen}
          />
        </Box>

        {open && (
          <Box sx={{ display: "flex", flexDirection: "column", pl: "16px" }}>
            {/* First Row: Organization Name */}
            <Typography
              variant="subtitle2"
              color="#000"
              onClick={handleRedirect}
            >
              {orgName}
            </Typography>

            {/* Second Row: Icon and Location Name inline */}
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <LocationOnOutlinedIcon fontSize="14px" />
              <Tooltip title={currWorkLocation}>
                <Typography
                  variant="caption"
                  color="#0AA2E3"
                  sx={{
                    whiteSpace: 'nowrap',  // Prevents wrapping
                    overflow: 'hidden',    // Hides overflowed content
                    textOverflow: 'ellipsis', // Adds ellipsis for overflowed text
                    maxWidth: '18ch', // Limits the text width to 18 characters
                  }}
                >
                  {currWorkLocation}
                </Typography>
              </Tooltip>
            </Box>
          </Box>
        )}
      </Box>

      {menuItems.map((item, index) => {
        const prevItem = menuItems[index - 1];
        const prevSection = prevItem ? prevItem.section : null;

        return (
          <div key={item.text}>
            {open && getSectionTitle(item.section, index, prevSection)}
            <Link
              to={item.path}
              style={{
                display: "flex",
                alignItems: "center",
                width: open ? "178.333px" : "48px", // Added width
                height: "45px", // Added height
                flexShrink: 0, // Added flex-shrink
                borderRadius: "10px", // Changed border radius
                backgroundColor:
                  currentBasePath === item.path ? "#0AA2E3" : "transparent",
                padding: open ? "10px 16px" : "10px 12px",
                textDecoration: "none",
                color: currentBasePath === item.path ? "#FFFFFF" : "#808080",
                fontSize: "14px",
                marginBottom: "4px",
              }}
            >
              {item.icon}
              {open && (
                <Typography variant="subtitle2" sx={{ ml: 2 }}>
                  {item.text.length > 13
                    ? item.text.slice(0, 13) + "…"
                    : item.text}
                </Typography>
              )}
            </Link>
          </div>
        );
      })}
    </Drawer>
  );
};

export default SideMenu;
