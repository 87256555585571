import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogTitle,
  IconButton,
  Typography,
  DialogContent,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box
} from '@mui/material';
import FRCAPADrawer from './FRCAPADrawer';
import { ChatBubbleOutline } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import { getAllCAPAListData } from "../Apis/apiCall";
import { convertMillisecondsToDate, capitalizeFirstChar, convertToMilliseconds, formatDateToIndianLocale, mapDataToFormFields } from "../utils/helper";
import {
  ReturnIcon,
  AcceptIcon,
} from "../constant";
import { setEditCAPA } from '../redux/incidentWorkflow/actions';
import { useDispatch, useSelector } from 'react-redux';


export default function CAPADetailTable({ id, level, mode }) {
  const dispatch = useDispatch();
  const [fRCAPADrawerOpen, setFRCAPADrawerOpen] = useState(false);
  const [commentsModal, setCommentsModal] = useState(false);
  const [comments, setComments] = useState([]);
  const [capaDetails, setCapaDetails] = useState([]);
  const [selectedCAPA, setSelectedCAPA] = useState({});
  const [capaStatus, setCapaStatus] = useState("");


  useEffect(() => {
    if (id) {
      fetchCapaDetailsTableData(id);
    }

  }, [id]);

  const fetchCapaDetailsTableData = async (incidentId) => {
    if (!incidentId) return; // Prevent execution if `incidentId` is undefined

    try {
      let result = await getAllCAPAListData(incidentId);
      setCapaDetails(result?.data?.data);
    } catch (error) {
      console.log(error);
      setCapaDetails([]);
    }
  };

  const handleFRCAPADrawerOpen = (row) => {
    setCapaStatus(row.capaStatus);
  
    // Get the selected CAPA object
    const filteredComments = capaDetails.filter((item) => item.id === row.id);
    const selectedCAPA = { ...filteredComments[0] };
    setSelectedCAPA(selectedCAPA);
  
    if (row.capaStatus === "returned") {
      // Transform the selectedCAPA using mapDataToFormFields
      const mappedData = mapDataToFormFields(selectedCAPA);
  
      if (mappedData && typeof mappedData === "object") {
        // Format the "Due Date" if it exists
        if (mappedData["Due Date"]) {
          mappedData["Due Date"] = formatDateToIndianLocale(mappedData["Due Date"]);
        }
  
        // Dispatch the transformed and formatted data
        dispatch(setEditCAPA([mappedData]));
      } else {
        console.error("Error: mapDataToFormFields did not return an object");
      }
    }

    if((row.capaStatus === "completed" || row.capaStatus === "revalidation") && level === "4"){
      setFRCAPADrawerOpen(true);
    }
    if((row.capaStatus === "approved" || row.capaStatus === "reviwed" || row.capaStatus === "clarification" ||row.capaStatus === "returned") && level === "5"){
      setFRCAPADrawerOpen(true);
    }
  
    
  };
  

  const handleFRCAPADrawerClose = () => {
    setFRCAPADrawerOpen(false);
    setCapaStatus("")
  };
  const handleOpenChartModel = (id) => {
    const filteredComments = capaDetails.filter((item) => item.id === id);
    setComments(filteredComments);
    setCommentsModal(true);
  }



  return (
    <>
      <TableContainer
        sx={{
          borderRadius: '8px',
          maxWidth: '800px', // Set maximum width to 700px
          border: '1px solid #ccc',
          margin: 'auto', // Center the table if needed
        }}
      >
        <Table size="small"> {/* Reduces overall table cell height */}
          <TableHead>
            <TableRow sx={{ backgroundColor: '#F2F2F2' }}>
              {['CAPA ID', 'Type', 'Area', 'Sub Area', 'Owner', 'Priority', 'Completion Date', 'Status', ''].map(
                (header, index) => (
                  <TableCell
                    key={index}
                    sx={{
                      padding: '4px 8px',
                      borderRight: index !== 8 ? '1px solid #e0e0e0' : 'none',
                      fontWeight: 'bold',
                      color: '#525966',
                      whiteSpace: 'nowrap',
                      fontSize: '12px', // Smaller font size for headers
                    }}
                  >
                    {header}
                  </TableCell>
                )
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {capaDetails?.map((row, rowIndex) => (
              <TableRow
                key={rowIndex}
                onClick={() => handleFRCAPADrawerOpen(row)}
                sx={{
                  cursor: 'pointer',
                  '&:hover': { backgroundColor: 'rgba(82, 89, 102, 0.05)' },
                }}
              >
                {[
                  row?.id,
                  capitalizeFirstChar(row?.capaType),
                  capitalizeFirstChar(row?.area),
                  capitalizeFirstChar(row?.subArea),
                  capitalizeFirstChar(row?.capaOwner),
                  capitalizeFirstChar(row?.capaPriority),
                  row?.completionDate ? new Date(row?.completionDate).toLocaleDateString() : 'N/A',
                  capitalizeFirstChar(row?.capaStatus),
                ].map((cellData, cellIndex) => (
                  <TableCell
                    key={cellIndex}
                    sx={{
                      padding: '6px 8px',
                      borderRight: cellIndex !== 8 ? '1px solid #e0e0e0' : 'none',
                      fontSize: '12px', // Smaller font size for row data
                    }}
                  >
                    {cellData}
                  </TableCell>
                ))}
                <TableCell
                  sx={{
                    padding: '4px 8px',
                    fontSize: '12px',
                    display: 'flex',          // Use flex layout
                    alignItems: 'center',     // Vertically center items
                    justifyContent: 'center', // Horizontally center items
                    gap: '8px',               // Add space between icons
                  }}
                >
                  {row?.capaStatus === 'reviewed' && <img src={AcceptIcon} alt="Reviewed" />}
                  {row?.capaStatus === 'revalidation' && <img src={ReturnIcon} alt="Return" />}

                  {row?.capaStatus === 'approved' && (
                    <>
                      <img src={AcceptIcon} alt="Approved" />
                      <img src={AcceptIcon} alt="Approved" />

                    </>
                  )}
                  {row?.capaStatus === 'clarification' && (
                    <>
                      <img src={AcceptIcon} alt="Approved" />
                      <img src={ReturnIcon} alt="Return" />
                    </>
                  )}

                  {row.comments && row.comments.length > 0 && (
                    <IconButton
                      onClick={(event) => {
                        event.stopPropagation();
                        handleOpenChartModel(row.id);
                      }}
                    >
                      <ChatBubbleOutline />
                    </IconButton>
                  )}
                </TableCell>

              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>


      <FRCAPADrawer
        open={fRCAPADrawerOpen}
        onClose={handleFRCAPADrawerClose}
        title={"Review CAPA"}
        level={level}
        mode={mode}
        status={capaStatus}
        data={selectedCAPA}
      />
      <Dialog open={commentsModal} onClose={() => setCommentsModal(false)} fullWidth maxWidth="md" sx={{ maxHeight: '700px' }}>
        <DialogTitle>
          <Typography variant="h6">Comments</Typography>
          <IconButton
            aria-label="close"
            onClick={() => setCommentsModal(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {comments?.map((commentObj, commentIndex) => (
            commentObj?.comments?.map((item, index) => (
              <Accordion
                key={`${commentIndex}-${index}`}
                elevation={0}
                sx={{ mb: 1, padding: '0px !important', border: '0.5px solid #B7C8E5', borderRadius: '8px !important' }}
              >
                {/* Collapsed View */}
                <AccordionSummary
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    backgroundColor: '#F9FAFC',
                    borderRadius: '8px',
                    paddingX: '12px',
                    '& .MuiAccordionSummary-expandIconWrapper': {
                      transform: 'none !important', // Prevents the wrapper rotation
                    },
                  }}
                >
                  <Box>
                    <Typography variant="body2" sx={{ color: '#222222' }}>
                      {capitalizeFirstChar(item?.status)} / {capitalizeFirstChar(item?.role)}
                    </Typography>
                    <Typography variant="caption" sx={{ color: '#525966' }}>
                      {capitalizeFirstChar(item?.role)}
                    </Typography>
                  </Box>
                </AccordionSummary>

                {/* Expanded View */}
                <AccordionDetails
                  sx={{
                    backgroundColor: '#F9FAFC',
                    borderRadius: '8px',
                    paddingX: '12px',
                  }}
                >
                  {/* Remarks Section */}
                  <Box display="flex" flexDirection="column">
                    <Typography sx={{ fontSize: '12px', color: '#222222', mb: 1 }}>
                      Review Input
                    </Typography>
                    <Typography sx={{ fontSize: '12px', color: '#525966' }}>
                      {capitalizeFirstChar(item?.comment)}
                    </Typography>
                  </Box>

                  {/* Timestamp Section */}
                  <Box
                    sx={{
                      position: 'absolute',
                      bottom: '8px',
                      right: '12px',
                      color: '#525966',
                    }}
                  >
                    <Typography variant="caption">
                      {convertMillisecondsToDate(item?.timestamp)}
                    </Typography>
                  </Box>
                </AccordionDetails>
              </Accordion>
            ))
          ))}
        </DialogContent>

      </Dialog>
    </>
  );
};
