import { SET_ATTACHMENT_DATA, SET_COMMENTS_DATA } from "./constant";


  
  const initialState = {
    attachment: [],
    comment_data: [],
  };
  
  export const QuestionnaireReducer = (state = initialState, action) => {
    switch (action.type) {
      case SET_ATTACHMENT_DATA:
        console.log("action.payload", action.payload);
        return {
          ...state,
          attachment: action?.payload,
        };
        case SET_COMMENTS_DATA:
          console.log("action.payload", action.payload);
          return {
            ...state,
            comment_data: [...state.comment_data, action.payload], // Append new data
          };
      default:
        return state;
    }
  };
  