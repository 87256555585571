import React, { useState, useEffect } from 'react';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { Box, Paper, Typography, Grid, Divider, Button, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { overDueImage, cloudImage } from "../constant";
import { useNavigate } from 'react-router-dom';
import SafetyTipSlider from '../Components/AlertSliderShow';
import { homePageRoutingData } from "../utils/workFlowFormFields";
import WelcomeUserCard from "../Components/welcomeUserCard"
import { dashboardBroadCast, getAllLocationName, homePageTreeData } from '../Apis/apiCall';
import BroadcastSlideshow from "../Components/BroadCastInfoSlide";
import CloseIcon from '@mui/icons-material/Close';
import OrgTreeView from "../Components/OrgTree";
import { showTreeModel } from '../redux/Loader/actions';
import { useSelector, useDispatch } from 'react-redux';
import ApexChart from "../Components/ChartAndGraphs/TreeChart";
import BubbleChart from "../Components/ChartAndGraphs/BubbleChart";
import { updateDashboardLocation } from "../redux/LossControl/actions";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Loader from "../Components/Loader/Loader";
import { START_LOADING, STOP_LOADING } from "../redux/Loader/constant"

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#fff',
    padding: theme.spacing(1),
    borderRadius: "12px",
    ...theme.typography.body2,
    color: theme.palette.text.secondary,
    ...theme.applyStyles('dark', {
        backgroundColor: '#1A2027',
    }),
}));


const locations = Array.from(
    { length: 100 },
    (_, index) => `Location ${index + 1}`
);

// Generate random incident data for each location
const incidentData = locations.flatMap((location) => [
    {
        type: "UA",
        location: location,
        count: Math.floor(Math.random() * 100) + 1,
    },
    {
        type: "UC",
        location: location,
        count: Math.floor(Math.random() * 100) + 1,
    },
    {
        type: "NM",
        location: location,
        count: Math.floor(Math.random() * 100) + 1,
    },
    {
        type: "Minor",
        location: location,
        count: Math.floor(Math.random() * 100) + 1,
    },
    {
        type: "MR",
        location: location,
        count: Math.floor(Math.random() * 100) + 1,
    },
    {
        type: "DO",
        location: location,
        count: Math.floor(Math.random() * 100) + 1,
    },
    {
        type: "Serious",
        location: location,
        count: Math.floor(Math.random() * 100) + 1,
    },
    {
        type: "Fatal",
        location: location,
        count: Math.floor(Math.random() * 100) + 1,
    },
]);


const Dashboard = () => {
    const { isShowTreeModel } = useSelector(state => state.loadingReducer);
    const { dashboardFilter } = useSelector(state => state.lossControlReducer.tours);
    const [step, setStep] = useState(0);
    const navigate = useNavigate();
    const loginDataString = localStorage.getItem("loginData");
    const loginData = loginDataString ? JSON.parse(loginDataString) : null;
    const locationID = loginData?.currentLocationID;
    const dispatch = useDispatch();
    const [role, setRole] = useState(null);
    const [userName, setUserName] = useState(null);
    const [viewCount, setViewCount] = useState(null);
    const [overDueCount, setOverDueCount] = useState(null);
    const [boardCasteData, setBoardCasteData] = useState({
        broadcasts: [],
        safetyTips: [],
        greetings: []
    });
    const [chartModalData, setChartModalData] = useState([]);
    const [bubbleChartModalData, setBubbleChartModalData] = useState([]);

    const [treeData, setTreeData] = useState(null);
    const [allLoactionName, setAllLoactionName] = useState([]);
    const [page, setPage] = useState(1);
    const recordsPerPage = 100;
  const loading = useSelector((state) => state.loadingReducer.loading);

    // Slice the data to only show the current page
    const paginatedData = incidentData.slice(
        (page - 1) * recordsPerPage,
        page * recordsPerPage
    );

    const handleItemClick = (value, id, title) => {
        if (value.label === "Past Tours") {
            navigate(`${value.route}/${id}`);
        } else if (value.label === "View Actions") {
            navigate(`${value.route}`, {
                state: {
                    moduleID: title === "Incident Management" ? 1 : 2,
                    moduleName: title === "Incident Management" ? "Incident Management" : "Loss Control",
                    homepage: true,
                }
            });
        }
        else {
            navigate(value.route);
        }

    };


    const handleActionPage = ({ overDue }) => {
        const title = "Loss Control";
        if (overDue) {
            if (role?.includes("Executive Officer", "Mine Manager", "Installtion Manager")) {
                navigate('/my-actions', {
                    state: {
                        moduleID: title === "Incident Management" ? 1 : 2,
                        moduleName: title === "Incident Management" ? "Incident Management" : "Loss Control",
                        homepage: true,
                        overDue: 1,
                    }
                }
                );
            }else {
                navigate('/module/loss-control/view-tours', {
                    state: {
                        moduleID:2,
                        moduleName: "Loss Control",
                        homepage: true,
                        overDue: 1,
                    }
                }
                );
            }
        }
        else {
            navigate('/module/loss-control/view-tours',{
                state: {
                    moduleID:  2,
                    moduleName:  "Loss Control",
                    homepage: true,
                    overDue:0,
                }
            });
        }
    }


    const existingModules = loginData?.config?.modulesDetail?.map((module) => module.moduleName) || [];


    const handleStepchange = (value) => {
        setStep(value);
    };

    const handleButtonClick = () => {
        navigate('/module/loss-control/create-tour');
    };

    useEffect(() => {
        fetchBroadCasteData();
        const roles = localStorage.getItem("lossControlRoles");
        setRole(roles)
        if (loginDataString) {
            const data = JSON.parse(loginDataString);
            const { firstName } = data;
            setUserName(firstName);
        }
    }, []);


    useEffect(() => {
        (isShowTreeModel) && dispatch(showTreeModel());
        getallLocationName();
        const roles = localStorage.getItem("lossControlRoles");
        setRole(roles)

    }, []);

    useEffect(() => {
        if (dashboardFilter?.location.length > 0) {
            treeChartDataFilter(dashboardFilter?.period, dashboardFilter?.location);
        } else {
            const locations = loginData?.childData
            treeChartDataFilter(dashboardFilter?.period, locations);
        }

    }, [dashboardFilter]);


    const treeChartDataFilter = async (month, locationID) => {
        dispatch({type : START_LOADING})
        try {
            const result = await homePageTreeData(month, locationID);
            setChartModalData(result.data?.data?.treeMapData);
            setBubbleChartModalData(result.data?.data?.bubbleData);
            setViewCount(result.data?.data?.view);
            setOverDueCount(result.data?.data?.overdue);
        } catch (error) {
            console.log(error);

        }
        finally{
            dispatch({ type: STOP_LOADING }); 
          }
    }

    const getallLocationName = async () => {
        dispatch({type : START_LOADING})
        try {
            const result = await getAllLocationName();
            setAllLoactionName(result.data.data);
        } catch (error) {
            console.log(error);

        }
        finally{
            dispatch({ type: STOP_LOADING }); 
          }
    }

    const handleCloseOverlay = () => {
        dispatch(showTreeModel());
    };



    const fetchBroadCasteData = async () => {
        dispatch({type : START_LOADING})
        try {
            const result = await dashboardBroadCast();
            setBoardCasteData(result.data.data);
        } catch (error) {
            console.log(error);
        }
        finally{
            dispatch({ type: STOP_LOADING }); 
        }
    }


    return (
        <>
        {loading && ( 
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            zIndex: 1000,
          }}
        >
          <Loader />
        </Box>
      )}
            <Box display="flex" justifyContent="center" gap={2} alignItems="center" marginBottom={1} sx={{ zIndex: "1300" }}>
                <FiberManualRecordIcon onClick={() => handleStepchange(0)} sx={{ color: step === 0 ? "#0AA2E3" : "#AEAEB1", fontSize: '12px' }} />
                <FiberManualRecordIcon onClick={() => handleStepchange(1)} sx={{ color: step === 1 ? "#0AA2E3" : "#AEAEB1", fontSize: '12px' }} />
                <FiberManualRecordIcon onClick={() => handleStepchange(2)} sx={{ color: step === 2 ? "#0AA2E3" : "#AEAEB1", fontSize: '12px' }} />
            </Box>

            {step === 0 &&
                (
                    <>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={12} lg={9}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={12} lg={12}>
                                        <Item>
                                            <ApexChart data={chartModalData ? chartModalData : []} title={"Loss Control Statistics"} />
                                        </Item>
                                    </Grid>




                                </Grid>
                            </Grid>

                            {/* Main Grid of 4 columns */}
                            <Grid item xs={12} md={12} lg={3}>
                                <Grid container spacing={1}>
                                    {isShowTreeModel ? (
                                        <Grid item xs={12}>
                                             <Item sx={{
                                                minHeight: "600px",
                                                maxHeight: "600px",
                                                overflowY: "auto", // Enables vertical scrolling
                                                padding: "8px", // Optional: Adjust for inner content spacing
                                                scrollbarWidth: "none", // Hides scrollbar in Firefox
                                                "-ms-overflow-style": "none", // Hides scrollbar in Internet Explorer and Edge
                                                "&::-webkit-scrollbar": {
                                                    display: "none", // Hides scrollbar in Chrome, Safari, and Edge
                                                },
                                            }}>
                                                <Typography variant='body2' sx={{ fontWeight: 600, float: "left", mt: 1, ml: "6px" }}>
                                                    All Locations
                                                </Typography>

                                                <IconButton
                                                    onClick={handleCloseOverlay}
                                                    sx={{ float: "right" }}

                                                >
                                                    <CloseIcon />
                                                </IconButton>
                                                <OrgTreeView data={allLoactionName} />
                                            </Item>
                                        </Grid>
                                    ) : (
                                        <>
                                            <Grid item xs={12}>
                                                <Item >
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            justifyContent: 'space-between', // Ensures content is spread with text on left, image on right
                                                            width: '100%', // Full width to allow proper alignment
                                                            //sx={{ bgcolor: "rgba(9, 162, 227, 0.1)" }}
                                                        }}
                                                    >
                                                        {/* Left side: Typography content */}
                                                        <Box>
                                                            <Typography variant='body2' sx={{ fontWeight: 600 }}>
                                                                {boardCasteData?.greetings[0]?.message ? boardCasteData?.greetings[0]?.message : "Welcome"}
                                                            </Typography>
                                                            <Typography variant='caption'>
                                                                {userName ? userName.charAt(0).toUpperCase() + userName.slice(1) : "User"}
                                                            </Typography>
                                                        </Box>

                                                        {/* Right side: Image */}
                                                        <WelcomeUserCard />
                                                    </Box>
                                                </Item>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Item>
                                                    <BroadcastSlideshow broadcasts={boardCasteData?.broadcasts} />
                                                </Item>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Item>
                                                    <SafetyTipSlider data={boardCasteData?.safetyTips} />
                                                </Item>
                                            </Grid>
                                            <Grid item xs={12}>

                                                <Box
                                                    sx={{
                                                        backgroundColor: '#fff',
                                                        padding: "5px",
                                                        borderRadius: "12px",
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'space-between', // Spread content between left and right
                                                        width: '100%', // Ensure the box spans full width
                                                    }}
                                                    onClick={()=>handleActionPage({overDue:false})}

                                                >
                                                    {/* Left content: image and text */}
                                                    <Box sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                                                        <VisibilityOutlinedIcon />

                                                        <Typography
                                                            variant='body2'
                                                            sx={{
                                                                textAlign: 'left',
                                                                fontWeight: 600,
                                                                ml: 1,
                                                                // Smooth transition for color and font-size
                                                                '&:hover': {
                                                                    color: '#0AA2E3',

                                                                },
                                                            }}
                                                        >
                                                            View Tours
                                                        </Typography>
                                                    </Box>

                                                    {/* Right content: Divider and number */}
                                                    <Box sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} >
                                                        <Divider
                                                            orientation="vertical"
                                                            flexItem
                                                            sx={{ mx: 1, color: "#000" }}
                                                        />
                                                        <Typography
                                                            variant='body2'
                                                            sx={{ textAlign: 'right', fontWeight: 600 }}
                                                        >
                                                            {viewCount ? viewCount : 0}
                                                        </Typography>
                                                    </Box>

                                                </Box>

                                            </Grid>
                                            <Grid item xs={12}>

                                                <Box
                                                    sx={{
                                                        backgroundColor: '#fff',
                                                        padding: "5px",
                                                        borderRadius: "12px",
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'space-between', // Spread content between left and right
                                                        width: '100%', // Ensure the box spans full width
                                                    }}
                                                    onClick={()=>handleActionPage({overDue:true})}

                                                >
                                                    {/* Left content: image and text */}
                                                    <Box sx={{ display: 'flex', alignItems: 'center' }} >
                                                        <img src={overDueImage} alt="iconImageOverdue" />
                                                        <Typography
                                                            variant='body2'
                                                            sx={{
                                                                textAlign: 'left',
                                                                fontWeight: 600,
                                                                ml: 1,
                                                                // Smooth transition for color and font-size
                                                                '&:hover': {
                                                                    color: '#0AA2E3',

                                                                },
                                                            }}
                                                        >
                                                            Overdue Tours Action
                                                        </Typography>
                                                    </Box>

                                                    {/* Right content: Divider and number */}
                                                    <Box sx={{ display: 'flex', alignItems: 'center' }} >
                                                        <Divider
                                                            orientation="vertical"
                                                            flexItem
                                                            sx={{ mx: 1, color: "#000" }}  // Add space around the divider
                                                        />
                                                        <Typography
                                                            variant='body2'
                                                            sx={{ textAlign: 'right', color: "red", fontWeight: 600 }}
                                                        >
                                                            {overDueCount ? overDueCount : 0}
                                                        </Typography>
                                                    </Box>
                                                </Box>

                                            </Grid>
                                            {role?.includes("Executive Officer") && (
                                                <Grid item xs={12}>
                                                    <Button
                                                        variant="medium"
                                                        sx={{
                                                            color: "#fff",
                                                            bgcolor: "#0AA2E3",
                                                            textTransform: "none",
                                                            width: "100%",
                                                            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.3)',
                                                        }}
                                                        onClick={handleButtonClick}
                                                    >
                                                        Start New Tour
                                                    </Button>
                                                </Grid>
                                            )}

                                        </>
                                    )


                                    }


                                </Grid>
                            </Grid>
                        </Grid>

                    </>

                )}


                
            {step === 1 &&
                (
                    <>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={12} lg={isShowTreeModel ? 9 : 12}>
                                        <Item sx={{ position: 'relative', padding: 2, maxHeight: "580px" }}>
                                            {/* Button Container */}
                                            <Box
                                                sx={{
                                                    position: 'absolute',
                                                    bottom: 16,
                                                    right: 16,
                                                    display: 'flex',
                                                    gap: 1, // Space between buttons
                                                }}
                                            >
                                                <Box sx={{
                                                    color: "#0AA2E3",
                                                }}>
                                                    <KeyboardArrowLeftIcon />
                                                </Box>
                                                <Box sx={{
                                                    color: "#0AA2E3",
                                                }}
                                                    onClick={() =>
                                                        setPage((prevPage) =>
                                                            Math.min(
                                                                prevPage + 1,
                                                                Math.ceil(incidentData.length / recordsPerPage)
                                                            )
                                                        )
                                                    }
                                                >
                                                    <KeyboardArrowRightIcon />
                                                </Box>
                                            </Box>

                                            {/* Bubble Chart */}
                                            <BubbleChart incidentData={bubbleChartModalData} />
                                        </Item>

                                    </Grid>

                                    <Grid item xs={12} md={12} lg={3}>
                                        <Grid container spacing={1}>
                                            {isShowTreeModel && (
                                                <Grid item xs={12}>
                                                     <Item sx={{
                                                minHeight: "600px",
                                                maxHeight: "600px",
                                                overflowY: "auto", // Enables vertical scrolling
                                                padding: "8px", // Optional: Adjust for inner content spacing
                                                scrollbarWidth: "none", // Hides scrollbar in Firefox
                                                "-ms-overflow-style": "none", // Hides scrollbar in Internet Explorer and Edge
                                                "&::-webkit-scrollbar": {
                                                    display: "none", // Hides scrollbar in Chrome, Safari, and Edge
                                                },
                                            }}>
                                                <Typography variant='body2' sx={{ fontWeight: 600, float: "left", mt: 1, ml: "6px" }}>
                                                    All Locations
                                                </Typography>

                                                <IconButton
                                                    onClick={handleCloseOverlay}
                                                    sx={{ float: "right" }}

                                                >
                                                    <CloseIcon />
                                                </IconButton>
                                                <OrgTreeView data={allLoactionName} />
                                            </Item>
                                                </Grid>
                                            )
                                            }


                                        </Grid>
                                    </Grid>

                                </Grid>
                            </Grid>
                        </Grid>
                    </>
                )
            }
            {step === 2 &&
                (
                    <>
                        <Grid container spacing={4} sx={{ padding: 0 }}>
                            {homePageRoutingData.filter(mname => existingModules.includes(mname.title))
                                .map((card, index) => (
                                    <Grid item xs={12} sm={6} md={3} lg={2} key={index}>
                                        <Box
                                            sx={{
                                                boxShadow: 3,
                                                borderRadius: "12px",
                                                backgroundColor: '#fff',
                                            }}
                                        >
                                            <Typography
                                                variant="body1"
                                                component="div"
                                                sx={{
                                                    fontWeight: 500,
                                                    color: '#0AA2E3',
                                                    marginBottom: 1,
                                                    backgroundColor: '#CAEEFF',
                                                    padding: '8px 0',
                                                    textAlign: 'center',
                                                    borderRadius: "12px",
                                                    boxShadow: 3,
                                                }}
                                            >
                                                {card?.title}
                                            </Typography>
                                            {card.title === "Incident Management" ? (
                                                <Box>
                                                    {card?.items?.map((item, idx) => (
                                                        <React.Fragment key={idx}>
                                                            {idx !== 0 && <Divider />}
                                                            <Typography
                                                                variant="body1"
                                                                sx={{
                                                                    ml: 2,
                                                                    padding: '8px 0',
                                                                    cursor: !role?.includes("Reporting Officer") && item?.label === "Report Incident" ? 'default' : 'pointer',
                                                                    color: !role?.includes("Reporting Officer") && item?.label === "Report Incident" ? '#b0b0b0' : '#595959',
                                                                    '&:hover': {
                                                                        color: !role?.includes("Reporting Officer") && item?.label === "Report Incident" ? '#b0b0b0' : '#595959',
                                                                    },
                                                                }}
                                                                onClick={
                                                                    !role?.includes("Reporting Officer") && item?.label === "Report Incident"
                                                                        ? undefined
                                                                        : () => handleItemClick(item, locationID, card.title)
                                                                }
                                                            >
                                                                {item?.label}
                                                            </Typography>
                                                        </React.Fragment>
                                                    ))}
                                                </Box>
                                            ) : (
                                                <Box>
                                                    {card?.items?.map((item, idx) => (
                                                        <React.Fragment key={idx}>
                                                            {idx !== 0 && <Divider />}
                                                            <Typography
                                                                variant="body1"
                                                                sx={{
                                                                    ml: 2,
                                                                    padding: '8px 0',
                                                                    cursor: !role?.includes("Executive Officer") && item?.label === "New Tour" ? 'default' : 'pointer',
                                                                    color: !role?.includes("Executive Officer") && item?.label === "New Tour" ? '#b0b0b0' : '#595959',
                                                                    '&:hover': {
                                                                        color: !role?.includes("Executive Officer") && item?.label === "New Tour" ? '#b0b0b0' : '#595959',
                                                                    },
                                                                }}
                                                                onClick={
                                                                    !role?.includes("Executive Officer") && item?.label === "New Tour"
                                                                        ? undefined
                                                                        : () => handleItemClick(item, locationID, card.title)
                                                                }
                                                            >
                                                                {item?.label}
                                                            </Typography>
                                                        </React.Fragment>
                                                    ))}
                                                </Box>
                                            )}

                                        </Box>
                                    </Grid>
                                ))}
                        </Grid>
                    </>
                )
            }



        </>
    );
};

export default Dashboard;

