import React from 'react';
import { ButtonGroup, Button, Box } from '@mui/material';

const ButtonGroupInput = ({ buttonNames, selected, onSelect, name }) => {
    const gradientColors = [
        '#22C55E', // Green
        '#FDE047', // Yellow
        '#EF4444', // Red
    ];

    // Full gradient background across the container
    const fullGradient = 'linear-gradient(90deg, #22C55E 0%, #FDE047 50%, #EF4444 100%)';

    // Calculate button styles
    const getButtonStyle = (index) => ({
        fontSize: "12px",
        textTransform: 'none',
        color: selected === buttonNames[index] ? '#FFF' : '#1E1E1E',
        fontWeight: '600',

        background: selected === buttonNames[index]
            ? 'transparent'  // Make selected button transparent so gradient shows
            : '#FFF',  // White background for non-selected buttons
        border: selected === buttonNames[index] ? '#666F7F' : '1px solid #B7C8E5',
        '&:hover': {
            background: selected === buttonNames[index]
                ? 'transparent'  // Keep the button transparent on hover
                : '#f0f0f0',  // Hover effect for non-selected buttons
        },
    });
    const getButtonStyle2 = (index) => ({
        fontSize: "12px",
        textTransform: 'none',
        color: selected === buttonNames[5] ? '#FFF' : '#1E1E1E',
        fontWeight: '600',

        background: selected === buttonNames[5] ?"#0AA2E3"   // Make selected button transparent so gradient shows
            : '#FFF',  // White background for non-selected buttons
        border: selected === buttonNames[index] ? '#666F7F' : '1px solid #B7C8E5',
        '&:hover': {
            background: selected === buttonNames[5]?"#0AA2E3" 
                : '#f0f0f0',  // Hover effect for non-selected buttons
        },
    });

    return (
        <>
            {name === "Severity" ? (
                <>
                 <Box
            sx={{
                display: 'flex', // Align ButtonGroup and N/A button in a row
                alignItems: 'center',
                gap: '12px', // Space between ButtonGroup and N/A button
                width: '100%', // Ensure the layout spans the container width
            }}
        >
                <Box
                    sx={{
                        position: 'relative',
                        display: 'flex',  // Use flex to keep Box and ButtonGroup aligned
                        justifyContent: 'center',  // Center content horizontally
                        maxWidth: '190px',
                        background: fullGradient,  // Gradient background
                        borderRadius: '4px',  // Rounded corners for container
                        boxSizing: 'border-box',
                       
                    }}
                >
                    <ButtonGroup
                        size="medium"
                        sx={{
                            width: '100%',
                            '& .MuiButtonGroup-grouped': {
                                minWidth: '5px',
                            },
                        }}
                    >
                        {buttonNames
                            ?.filter((name) => name !== "N/A") // Exclude "N/A" from the ButtonGroup
                            .map((name, index) => (
                                <Button
                                    key={index}
                                    sx={getButtonStyle(index, name)}
                                    onClick={() => onSelect(name)}
                                    variant="outlined"
                                >
                                    {name}
                                </Button>
                            ))}
                    </ButtonGroup>
                </Box>
                    {/* Render the standalone "N/A" button */}
                    {buttonNames?.includes("N/A") && (
                        <Button
                            sx={{
                                ...getButtonStyle2(5, "N/A"),
                                width: '100%',
                                textAlign: 'center',
                                ml:"25px"
                            }}
                            size="medium"
                            onClick={() => onSelect("N/A")}
                            variant="outlined"
                        >
                            Not Applicable
                        </Button>
                    )}
                    </Box>
                </>
            ) :
                <ButtonGroup size="medium" sx={{
                    marginBottom: name === "Operation Status" ? "0px" : "7px",
                    '& .MuiButtonGroup-grouped': {
                        minWidth: '5px',  // Override min-width here

                    },
                }}>
                    {buttonNames?.map((name, index) => (
                        <Button
                            key={index}
                            sx={{
                                fontSize: "12px",
                                textTransform: 'none',
                                color: selected === name ? '#FFF' : '#1E1E1E',
                                fontWeight: '600',

                                border: selected === buttonNames[index] ? "none" : '1px solid #B7C8E5',
                                backgroundColor: selected === name ? '#0AA2E3' : 'transparent', // Background color for selected
                                '&:hover': {
                                    backgroundColor: selected === name ? '#0AA2E3' : '#f0f0f0' // Hover effect
                                },
                                boxShadow: "none",
                            }}
                            onClick={() => onSelect(name)}
                            variant={selected === name ? 'contained' : 'outlined'}
                        >
                            {name}
                        </Button>
                    ))}
                </ButtonGroup>}

        </>

    );
};

export default ButtonGroupInput;
