import React, { useEffect, useState } from "react";
import {
  Grid,
  Box,
  Chip,
  Typography,
  Divider,
  Button,
  Dialog, DialogTitle, DialogContent, DialogActions, IconButton, TextField
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import StatusChip from "../Components/Chip";
import AcceptReturnButtonGrp from "../Components/LossControl/AcceptReturnButtonGrp";
import CustomStepperBox from "../Components/CustomStepperBox";
import CustomWorkflowAccordion from "../Components/CAPACommentFlow";
import CAPADrawer from "../Components/LossControl/CAPADrawer";
import ConfirmationModal from "../Components/LossControl/ConfirmationModal";
import { getCAPAViewData,CAPAAcceptAndReturn } from "../Apis/apiCall";
import { useLocation, useNavigate } from 'react-router-dom';
import {formatDateToIndianLocale} from "../utils/helper";

const getStatusChip = (status) => {
  switch (status) {
    case "High":
      return (
        <StatusChip
          label="High"
          color="#FF7742"
          bgColor="rgba(255, 119, 66, 0.1)"
        />
      );
    case "Corrective":
      return (
        <StatusChip
          label="Corrective"
          color="#0B7F6A"
          bgColor="rgba(11, 127, 106, 0.1)"
        />
      );
  }
};

const workFlowInput = [
  {
    label: "Step 1",
    status: "completed",
    WorkFlowRole: "Reporting Officer",
    reportedByEmail: "emp24@gmail.com",
    reportedByUserName: "vijay",
    reportedByEmployeeID: "EMP 24",
    "Incident Type": "UC",
    Severity: "5",
    remarks:
      "Lorem ipsum dolor sit amet consectetur. Sit lorem proin enim pretium nisi quis. Lorem ipsum dolor sit amet consectetur.",
  },

  {
    label: "Step 2",
    status: "active",
    WorkFlowRole: "First Reviewer",
    reportedByEmail: "emp22@gmail.com",
    reportedByUserName: "Ajay",
    reportedByEmployeeID: "EMP 23",
    "Incident Type": "UC",
    Severity: "5",
    remark:
      "Lorem ipsum dolor sit amet consectetur. Sit lorem proin enim pretium nisi quis. Lorem ipsum dolor sit amet consectetur.",
  },
  {
    label: "step3",
    status: "returned",
    WorkFlowRole: "Investigator",
    reportedByEmail: "emp22@gmail.com",
    reportedByUserName: "Arjuna",
    reportedByEmployeeID: "EMP 22",
    "Incident Type": "UC",
    Severity: "5",
    remarks:
      "Lorem ipsum dolor sit amet consectetur. Sit lorem proin enim pretium nisi quis. Lorem ipsum dolor sit amet consectetur.",
  },

  { label: "Step 4", status: "incomplete" },
  { label: "Step 5", status: "incomplete" },
  { label: "Step 6", status: "incomplete" },
  { label: "Step 7", status: "incomplete" },
  { label: "Step 8", status: "incomplete" },
];

export default function CAPAView() {
  const location = useLocation();
  const navigate = useNavigate();
  const { id, customReportId, status, mode, role } = location?.state?.data;
  const [CAPADetails, setCAPADetails] = useState([]);  // view data
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerTitle, setDrawerTitle] = useState("");
  const [returnDialog, setReturnDialog] = useState(false);
  const [comment, setComment] = useState("");
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [confirmationTitle, setConfirmationTitle] = useState("Are you sure?");
  const [confirmationMessage, setConfirmationMessage] = useState("Do you really want to return?");
  console.log( id, customReportId, status, mode, role );
  


  useEffect(() => {
    fetchCAPAViewData(id);
  }, []);

  const fetchCAPAViewData = async (capaId) => {
    try {
      const result = await getCAPAViewData(capaId);
      setCAPADetails(result?.data?.data);
    } catch (error) {
      console.log(error);
    }
  }

  const handleDrawerOpen = (action) => {
    if (action === "return") {
      setReturnDialog(true);
    }
    if (action === "complete") {
      setDrawerTitle("complete");
      setDrawerOpen(true);
      setConfirmationMessage("Do you really want to complete this?")
    }
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const handleCAPAReturnAction= async()=>{
    let data={
      action:"revert",
      comment:comment
    }
     const res = await returnCAPA(data,id);
     if(res == 200){
      setConfirmationModal(false);
      setReturnDialog(false);
      navigate("/my-actions");
     }
    
  }

  const returnCAPA = async (data,capaId) => {
    try {
      const result = await CAPAAcceptAndReturn(data,capaId);
      return result.data.status_code
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <Grid container spacing={2} sx={{ p: 1 }}>
      <Grid item xs={12} md={8}>
        <Box display="flex" flexDirection="column" gap={1}>
          <Box display="flex" flexDirection="row" alignItems="center" gap={1}>
            <Typography variant="h2">CAPA {CAPADetails.moduleID === 1 ?"Incident":"Loss Control"}</Typography>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              gap={1}
            >
              <React.Fragment>
                {getStatusChip(CAPADetails?.capaPriority)}
              </React.Fragment>
            </Box>
          </Box>
          <Box display="flex" flexDirection="column" gap={0.5}>
            <Box display="flex" gap={1}>
              <Typography variant="body1" sx={{ color: "#222222" }}>
                ID:
              </Typography>
              <Typography variant="body1" sx={{ color: "#525966" }}>
                EMP 22 / emp22@gmail.com / Emp22
              </Typography>
            </Box>
            <Box display="flex" gap={1}>
              {/* <Typography variant="body1" sx={{ color: "#222222" }}>
                Executive:
                </Typography> */}
              <Typography variant="body1" sx={{ color: "#525966" }}>
                {CAPADetails?.reportID}
              </Typography>
              <React.Fragment>
                {getStatusChip(CAPADetails?.capaType)}
              </React.Fragment>
            </Box>

          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} md={4}>
        {(mode === "action" && CAPADetails?.capaStatus === "assigned") && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end", // Aligns horizontally to the right
              alignItems: "flex-end",
            }}
          >
            <AcceptReturnButtonGrp
              title={"CAPA Action"}
              defaultValue=""
              options={[{ value: "return", label: "Return" }, { value: "complete", label: "Complete" }]}
              onChange={handleDrawerOpen}
            //   isDisabled={revalidationDisabled}
            />
          </Box>
        )}
      </Grid>
      <Grid item xs={12} md={8}>
        <Grid container spacing={2}>
          <Grid item xs={12} mt={1}>
            <CustomStepperBox stepData={workFlowInput} />
          </Grid>

          <Grid item xs={12}>
            <Box
              sx={{
                width: "100%",
                maxHeight: '400px',
                padding: "24px",
                backgroundColor: "#ffffff",
                borderRadius: "8px",
              }}
            >
              <Typography sx={{ color: '#525966', fontSize: '16px', fontWeight: '400' }}>
                CAPA Details
              </Typography>
              <Divider sx={{ my: 1, mx: -3 }} />

              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Box
                    display='flex'
                    flexDirection='column'
                    justifyContent='space-between'
                    sx={{
                      width: "100%",
                      minHeight: "300px",
                      padding: "16px",
                      backgroundColor: "#ffffff",
                      borderRadius: "12px",
                      boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.17)'
                    }}
                  >
                    <Typography sx={{ color: "#525966", fontSize: '14px', fontWeight: '500' }}>{CAPADetails?.capaDescription}</Typography>
                    <Box display='flex' gap={1}>
                      <Typography sx={{ color: '#222', fontSize: '14px', fontWeight: '500' }}>Due Date:</Typography>
                      <Typography sx={{ color: '#525966', fontSize: '14px', fontWeight: '500' }}>{formatDateToIndianLocale(CAPADetails?.dueDate)}</Typography>
                    </Box>
                  </Box>
                </Grid>

               

                <Grid item xs={6}>
                  <Box
                    display='flex'
                    flexDirection='column'
                    gap={1}
                    sx={{
                      width: "100%",
                      minHeight: "300px",
                      padding: "16px",
                      backgroundColor: "#ffffff",
                      borderRadius: "12px",
                      boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.17)'
                    }}
                  >
                    <Box display='flex' gap={1}>
                      <Typography sx={{ color: '#222', fontSize: '14px', fontWeight: '500' }}>Location:</Typography>
                      <Typography sx={{ color: '#525966', fontSize: '14px', fontWeight: '500' }}>{CAPADetails?.locationName}</Typography>
                    </Box>
                    <Box display='flex' gap={1}>
                      <Typography sx={{ color: '#222', fontSize: '14px', fontWeight: '500' }}>CAPA Owner:</Typography>
                      <Typography sx={{ color: '#525966', fontSize: '14px', fontWeight: '500' }}>{CAPADetails?.capaOwnerName}</Typography>
                    </Box>
                    <Box display='flex' gap={1}>
                      <Typography sx={{ color: '#222', fontSize: '14px', fontWeight: '500' }}>Source Area :</Typography>
                      <Typography sx={{ color: '#525966', fontSize: '14px', fontWeight: '500' }}>{CAPADetails.area}</Typography>
                    </Box>
                    <Box display='flex' gap={1}>
                      <Typography sx={{ color: '#222', fontSize: '14px', fontWeight: '500' }}>Sub Area:</Typography>
                      <Typography sx={{ color: '#525966', fontSize: '14px', fontWeight: '500' }}>{CAPADetails?.subArea}</Typography>
                    </Box>
                    <Box display='flex' gap={1}>
                      <Typography sx={{ color: '#222', fontSize: '14px', fontWeight: '500' }}>Created Date:</Typography>
                      <Typography sx={{ color: '#525966', fontSize: '14px', fontWeight: '500' }}>{formatDateToIndianLocale(CAPADetails?.createdAt)}</Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>

            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={4} mt={1}>
        <Box
          sx={{
            width: "100%",
            height: "100%",
            // paddingX: "24px",
            paddingY: "16px",
            backgroundColor: "#ffffff",
            borderRadius: "8px",
          }}
        >
          <Typography variant="body1" sx={{ mb: '16px', ml: "24px" }}>
            CAPA Comment
          </Typography>
          <Divider
            sx={{
              width: "100%",
              border: "0.5px solid #B7C8E5",
              mb: "16px",
            }}
          />
          <Box
            display="flex"
            flexDirection="column"
            gap={1.5}
            sx={{
              paddingX: "24px",
              height: "430px",
              overflowY: "scroll",
              scrollbarWidth: "none",
              "&::-webkit-scrollbar": { display: "none" },
            }}
          >
            <CustomWorkflowAccordion data={CAPADetails?.comments} />
          </Box>
        </Box>
      </Grid>
      <CAPADrawer
        open={drawerOpen}
        onClose={handleDrawerClose}
        title={drawerTitle}
        capaId={id}
      />

{/* ----------------rerurn model of CAPA return-------------------------------- */}

      <Dialog open={returnDialog} onClose={() => setReturnDialog(false)} maxWidth="sm" fullWidth>
        <DialogTitle>
          CAPA Return Comment
          <IconButton
            aria-label="close"
            onClick={() => setReturnDialog(false)}
            sx={{ position: "absolute", right: 8, top: 8, color: (theme) => theme.palette.grey[500] }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Enter your comment"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            multiline
            rows={4}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" sx={{ color: 'white' }} disabled={comment?.length === 0} onClick={() => setConfirmationModal(true)}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmationModal open={confirmationModal} title={confirmationTitle} message={confirmationMessage} onCancel={() => setConfirmationModal(false)} onConfirm={handleCAPAReturnAction} />
    </Grid>
    
  )
}
