import React, { useState, useEffect } from "react";
import {
    Drawer,
    Grid,
    Typography,
    Button,
    Fab,
    Box,
    Divider,
    Switch,
    IconButton
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CustomSelect from "./CustomSelectField";
import CustomMultipleSelect from "./MutlipleSelect"
import CheckboxSelect from "./CustomeCheckInput";
import { useDispatch, useSelector } from 'react-redux';
import { handleChangeDrawerFormData } from '../redux/incidentInvestflow/actions';
import CustomMultiSelect from '../Components/CustomeMutliSelect';
import { handleChangeWorkfLowDrawerFormData } from '../redux/incidentWorkflow/actions';
import EditIcon from "@mui/icons-material/Edit";

const togleStyle =
{
    '& .MuiSwitch-switchBase.Mui-checked': {
        color: '#0AA2E3', // Color when checked (knob)
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: '#0AA2E3', // Color when checked (track)
    },
}

const AddButtonStyle = (isEditable) => ({
    fontSize: "14px",
    fontWeight: "500",
    paddingY: "8px",
    paddingX: "16px",
    color: "#fff",
    borderRadius: "60px",
    backgroundColor: isEditable ? "grey" : "#0AA2E3", // Grey when isEditable is true
    width: "70px",
    textTransform: "none",
    cursor: isEditable ? "not-allowed" : "pointer", // Optional: Change cursor for disabled state
    opacity: isEditable ? 0.6 : 1, // Optional: Adjust opacity for disabled state
});


const RightDrawerInvestigation = ({ open, onClose, title, fields = [], level,mode
}) => {
    const dispatch = useDispatch();
    const [isEditable, setIsEditable] = useState(true); // Track edit mode
    const investReviewData = useSelector((state) => state.incidentInvestigationFlowReducer);
    const workFlowReviewData = useSelector((state) => state.incidentWorkFlowReducer);
    let formData;
    if (level === "1" || level === "2") {
        formData = workFlowReviewData ?? {}; // Default to an empty object if `Review` is undefined
    } else {
        formData = investReviewData ?? {}; // Default to an empty object if `WorkfowInput` is undefined
    }

    const handleInputChange = (e, fieldName, title) => {
        const { value } = e.target;
        if (level === "1" || level === "2") {
            dispatch(handleChangeWorkfLowDrawerFormData({ fieldName, value, title }));
        } else {
            dispatch(handleChangeDrawerFormData({ fieldName, value, title }));
        }
    };

    const handleSelect = (value, fieldName) => {
        if (level === "1" || level === "2") {
            dispatch(handleChangeWorkfLowDrawerFormData({ fieldName, value, title }));
        } else {
            dispatch(handleChangeDrawerFormData({ fieldName, value, title }));
        }
    };


    const toggleEditMode = () =>{
        if(mode ==="action"){
            setIsEditable((prev) => !prev);
        }
       
    }



    const renderField = (field, title,isEditable) => {

        let isSelected;
        const fieldValue = formData?.[title]?.[field.formFieldName] || '';
        if (field.formFieldName === "Employee Type") {
            isSelected = formData?.[title]?.["Type"] ? "Yes" : null;
        }

        const isOISDMajorFire = field.formFieldName === "OISD Major Fire" &&
            (formData?.[title]?.["OISD"] === "Major") &&
            (formData?.[title]?.["OISD Fire"] === true);

        const isOISDMinorFire = field.formFieldName === "OISD Major Fire" &&
            (formData?.[title]?.["OISD"] === "Minor") &&
            (formData?.[title]?.["OISD Fire"] === true);

        const isPNGRBMajorFire = field.formFieldName === "PNGRB Major Fire" &&
            formData?.[title]?.["PNGRB"] === "Major" &&
            (formData?.[title]?.["PNGRB Fire"] === true);

        const isPNGRBMinorFire = field.formFieldName === "PNGRB Major Fire" &&
            (formData?.[title]?.["PNGRB"] === "Minor") &&
            (formData?.[title]?.["PNGRB Fire"] === true);




        switch (field.type) {

            case 'Dropdown':
                return (
                    <>
                        {(field?.formFieldName === "Body Parts Involved" || field?.formFieldName === "Body Parts(Loss)" || field?.formFieldName === "Body Parts Gone Out Of Use") &&
                            <CustomMultipleSelect
                                label={field.formFieldName}
                                value={fieldValue}
                                options={field?.options ? field?.options : ""}
                                onChange={(e) => handleInputChange(e, field.formFieldName, title)}
                                disabled={isEditable}
                            />
                        }
                        {field.formFieldName === "Investigation Members" &&
                            <CustomMultiSelect
                                label={field.formFieldName}
                                value={fieldValue}
                                options={field?.options ? field?.options : ""}
                                onChange={(e) => handleInputChange(e, field.formFieldName, title)}
                                disabled={isEditable}
                            />
                        }                           {isOISDMajorFire &&
                            <CustomSelect
                                label={"Major Fire"}
                                placeholder={"Major Fire Category"}
                                value={fieldValue}
                                options={field?.options || ""}
                                onChange={(e) => handleInputChange(e, field.formFieldName, title)}
                                disabled={isEditable}
                            />
                        } {
                            isOISDMinorFire &&
                            <CustomSelect
                                label={"Minor Fire"}
                                placeholder={"Minor Fire Category"}
                                value={fieldValue}
                                options={field?.options || ""}
                                onChange={(e) => handleInputChange(e, field.formFieldName, title)}
                                disabled={isEditable}
                            />
                        } {isPNGRBMajorFire &&
                            <CustomSelect
                                label={"Major Fire"}
                                placeholder={"Major Fire Category"}
                                value={fieldValue}
                                options={field?.options || ""}
                                onChange={(e) => handleInputChange(e, field.formFieldName, title)}
                                disabled={isEditable}
                            />
                        }{isPNGRBMinorFire &&
                            <CustomSelect
                                label={"Minor Fire"}
                                placeholder={"Minor Fire Category"}
                                value={fieldValue}
                                options={field?.options || ""}
                                onChange={(e) => handleInputChange(e, field.formFieldName, title)}
                                disabled={isEditable}
                            />
                        }
                        {
                            (field.formFieldName !== "PNGRB Major Fire" && field.formFieldName !== "OISD Major Fire" && field.formFieldName !== "Investigation Members" && field?.formFieldName !== "Body Parts Involved" && field?.formFieldName !== "Body Parts(Loss)" && field?.formFieldName !== "Body Parts Gone Out Of Use")
                            &&
                            (
                                <CustomSelect
                                    label={field.formFieldName}
                                    value={fieldValue}
                                    options={(field?.options) ? field.options : ""}
                                    onChange={(e) => handleInputChange(e, field.formFieldName, title)}
                                    disabled={isEditable}
                                />
                            )
                        }

                    </>

                );


            case 'toggleSwitch':
                return (
                    <>
                        <Box display='flex' alignItems='center'>
                            <Typography variant="body2" sx={{ color: '#525966', mr: "1px" }}>{(field.formFieldName === "Investigation Recommended" && formData?.[title]?.["High Potential Near Miss"] === true) ? "Investigation Required" : field.formFieldName}</Typography>
                            <Switch size="small" style={{ ...togleStyle }}
                                checked={fieldValue || false}
                                onChange={(e) => handleSelect(e.target.checked, field.formFieldName)}
                                disabled={isEditable}
                            />
                        </Box>
                    </>
                );
            case 'checkBox':
                return (
                    <>
                        {(field.formFieldName === "OISD Fire" && formData?.[title]?.["OISD"] && formData?.[title]?.["OISD"] != "Not Applicable") && (
                            <CheckboxSelect
                                label="fire"
                                checked={Boolean(fieldValue)} // Ensure checked is always a boolean
                                onChange={(newChecked) => handleSelect(newChecked, field.formFieldName)}
                                disabled={isEditable}
                            />
                        )}
                        {(field.formFieldName === "PNGRB Fire" && formData?.[title]?.["PNGRB"] && formData?.[title]?.["PNGRB"] != "Not Applicable") && (
                            <CheckboxSelect
                                label="fire"
                                checked={Boolean(fieldValue)} // Ensure checked is always a boolean
                                onChange={(newChecked) => handleSelect(newChecked, field.formFieldName)}
                                disabled={isEditable}
                            />
                        )}
                    </>
                );



            default:
                return null;
        }
    };


    const renderFields = (fields, title,isEditable) => {
        return (
            <Grid container spacing={2}>
                {fields.map((field) => (
                    <Grid
                        item
                        xs={12}
                        md={
                            [
                                "Investigation recommended",
                                "Review  completed",
                                "Investigation complete",
                            ].includes(field.formFieldName)
                                ? 12 :
                                ["OISD", "PNGRB", "PNGRB Major Fire", "OISD Major Fire"].includes(field.formFieldName) ? 5 : ["PNGRB Fire", "OISD Fire"].includes(field.formFieldName) ? 2
                                    : 6 // Half-width fields
                        }
                        key={field.formFieldName}
                    >
                        {renderField(field, title ,isEditable)}
                    </Grid>
                ))}
            </Grid>
        );
    };



    return (
        <Drawer
            anchor="right" // You can pass 'right', 'left', 'top', 'bottom'
            open={open}
            onClose={onClose}
            PaperProps={{
                sx: { width: "32%", padding: '0px !important' }, // Customize drawer style
            }}
        >
            <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                sx={{
                    paddingY: "16px",
                    paddingX: "24px",
                    borderBottom: "1px solid rgba(171, 171, 171, 0.50)",
                    boxShadow: "0px 2px 4px 0px #ABABAB",
                }}
            >
                <Typography sx={{ fontSize: "24px", fontWeight: "600" }}>
                    Incident Classification
                </Typography>
                <IconButton onClick={toggleEditMode}>
                    <EditIcon color={isEditable ? "action" :"primary" } />
                </IconButton>
            </Grid>
            <>
                <div style={{ padding: "28px 24px" }}>{fields.length > 0 && renderFields(fields, title,isEditable)}

                    <Grid item xs={12} sm={12} mt={3}>
                        <Divider sx={{ backgroundColor: '#C1C1C1', height: '2px' }} />
                    </Grid>

                    <Grid container spacing={3}>
                        <Grid item xs={12} mt={3}>
                            <Grid container justifyContent="flex-end">
                                <Button
                                    onClick={isEditable ? onClose:toggleEditMode}
                                    style={AddButtonStyle(isEditable)}
                                    
                                >
                                    Save
                                </Button>
                            </Grid>
                        </Grid>

                    </Grid>
                </div>
            </>
            <Fab
                aria-label="close"
                onClick={onClose}
                sx={{
                    position: "fixed",
                    top: "100px",
                    right: open ? "33.3%" : "0", // Adjust this value based on drawer width
                    transform: "translateX(50%)",
                    zIndex: 1200,
                    height: "36px",
                    width: "36px",
                    backgroundColor: "#0AA2E3",
                    borderRadius: "60px 0 0 60px",
                }}
            >
                <CloseIcon />
            </Fab>
        </Drawer>
    );
};

export default RightDrawerInvestigation;
