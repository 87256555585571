import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  IconButton,
  Divider,
  Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ConfirmationModal from './ConfirmationModal';

const RemarkModal = ({title, open, onClose, onSubmit }) => {
  const [remark, setRemark] = useState('');
  const [confirmationModal, setConfirmationModal] = useState(false);

  const handleSubmit = () => {
    // onSubmit(remark);
    // onClose();
    setConfirmationModal(1);
  };
  
  const handleConfirmationClose = () => setConfirmationModal(false);
  const handleConfirm = () => {
    onSubmit(remark);
    setConfirmationModal(false);
    onClose();
  };

  return (
    <>
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        Overall Remark
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{ position: 'absolute', right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
      <Divider sx={{mb:2}}/>
        <TextField
          fullWidth
          multiline
          rows={4}
          variant="outlined"
          placeholder="Please enter overall return remark"
          value={remark}
          onChange={(e) => setRemark(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={handleSubmit}
          sx={{ textTransform: 'none', borderRadius: '20px', color:'#ffffff', backgroundColor: '#09A2E3' }}
        >
          {title? title :"Return For Revalidation"}
        </Button>
      </DialogActions>
    </Dialog>

    <ConfirmationModal
      open={confirmationModal}
      title={"Confirm Action"}
      message={"Are you sure you want to proceed?"}
      onCancel={handleConfirmationClose}
      onConfirm={handleConfirm}
    />
    </>
  );
};

export default RemarkModal;
