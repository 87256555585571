import React from 'react';
import { Autocomplete, TextField, MenuItem } from '@mui/material';

const CustomSelect = ({ label,placeholder, value, options = [], onChange, name, disabled = false }) => {
    // console.log("Selected value:", value);
    const safeOptions = Array.isArray(options) ? options : [];

    // Helper function to normalize option value and label for both string and object formats
    const getOptionLabel = (option) => {
        return typeof option === 'object' && option !== null ? option.label : option;
    };

    // Determine the value object based on whether the option is a string or object
    const findOption = (value) => {
        if (!value) return null;
        return safeOptions?.find((opt) =>
            typeof opt === 'object' && opt !== null
                ? opt.value === value
                : opt === value
        ) || null;
    };

    return (
        <Autocomplete
            options={safeOptions}
            disabled={disabled}
            ListboxProps={{ style: { maxHeight: 160 } }}
            getOptionLabel={(option) => getOptionLabel(option)}
            value={findOption(value)} // Handles both object and string options
            onChange={(event, newValue) => {
                const selectedValue = newValue
                    ? typeof newValue === 'object'
                        ? newValue.value
                        : newValue
                    : '';
                onChange({ target: { name, value: selectedValue } });
            }}
            isOptionEqualToValue={(option, value) =>
                typeof option === 'object' && option !== null
                    ? option.value === value
                    : option === value
            }
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    variant="outlined"
                    placeholder= {placeholder? placeholder:"Select"}
                    size="small"
                    inputProps={{ ...params.inputProps, style: { fontSize: "12px" } }}
                    InputLabelProps={{
                        shrink: true,                       
                    }
                    
                }
                />
            )}
            renderOption={(props, option) => (
                <MenuItem {...props} key={typeof option === 'object' ? option.value : option}>
                    <span style={{ fontSize: "12px" }}>
                        {getOptionLabel(option)}
                    </span>
                </MenuItem>
            )}
            disableClearable
            sx={{
                minHeight: '44px',
                '& .MuiAutocomplete-inputRoot': {
                    height: '44px',
                    display: 'flex',
                    alignItems: 'center',
                },
                '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                        top: 0,
                    },
                    '&:hover fieldset': {
                        borderColor: '#3f51b5',
                    },
                    '&.Mui-focused fieldset': {
                        borderColor: '#3f51b5',
                    },
                },
            }}
            
        />
    );
};

export default CustomSelect;
