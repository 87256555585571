import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { setAttachmentData } from "../../../redux/Questionnaire/actions";
import CloseIcon from "@mui/icons-material/Close";

import { useSelector, useDispatch } from "react-redux";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const AttachmentsDrawer = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const [title, setTitle] = useState("");
  const [details, setDetails] = useState("");
  const [attachment, setAttachment] = useState("");
  const [imageUrls, setImageUrls] = useState([]);

  const CardData = useSelector(
    (state) => state.QuestionnaireReducer.attachment
  );
  // Slider settings for multiple images
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
  };

  console.log("CardData---", CardData);

  // Handle file change
  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    console.log("files---", files);

    if (files.length === 1) {
      // Handle single file upload
      const file = files[0];

      if (file instanceof Blob || file instanceof File) {
        const reader = new FileReader();

        reader.onloadend = () => {
          setAttachment(reader.result); // Set the data URL as the image source
          setImageUrls([]); // Clear previous images if a single file is selected
        };

        reader.readAsDataURL(file);
      } else {
        console.error("Selected file is not a valid Blob or File");
      }
    } else if (files.length > 1) {
      // Handle multiple files upload
      const validFiles = files.filter(
        (file) => file instanceof Blob || file instanceof File
      );

      //   setImageUrls([]); // Clear previous images
      validFiles.forEach((file) => {
        const reader = new FileReader();

        reader.onloadend = () => {
          setImageUrls((prevImages) => [...prevImages, reader.result]);
        };

        reader.readAsDataURL(file);
      });

      setAttachment(""); // Clear single attachment if multiple files are selected
    }
  };

  // Handle save
  const handleSave = () => {
    // Save the data to Redux, based on whether it's a single image or multiple images
    dispatch(
      setAttachmentData({ title, details, attachment: attachment || imageUrls })
    );
  };

  const handleRemoveImage = (indexToRemove) => {
    setImageUrls(imageUrls.filter((_, index) => index !== indexToRemove));
  };

  // Effect to handle pre-existing attachment data (if any)
  useEffect(() => {
    if (CardData?.attachment) {
    }
  }, [CardData?.attachment]);

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      sx={{
        padding: 2,
        boxShadow: "-4px 0px 28px 0px rgba(0, 0, 0, 0.20)",
        "& .MuiDrawer-paper": {
          width: "469px", // Ensure the paper within the drawer also has the same width
        },
      }}
    >
      <>
        <Box>
          <Box
            sx={{
              marginLeft: "-16px",
              marginTop: "-16px",
              borderBottom: "1px solid rgba(171, 171, 171, 0.50)",
              background: "#FFF",
              boxShadow: "0px 2px 4px 0px #ABABAB",
              padding: "16px 24px",
              width: "500px",
            }}
          >
            <Typography
              variant="h6"
              sx={{ color: "#000", fontSize: "20px", fontWeight: "600" }}
            >
              Attachments
            </Typography>
          </Box>

          <Box sx={{ padding: 3 }}>
            <Box
              sx={{
                "& .MuiTextField-root": {
                  m: 1, // Margin
                  width: "430px", // Adjust width for proper alignment
                  ml: -2,
                },
              }}
            >
              <TextField
                label="Title"
                placeholder="Title"
                fullWidth
                defaultValue="Title"
                onChange={(e) => setTitle(e.target.value)}
                sx={{
                  mb: 2,
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "4px", // Border radius
                    "& fieldset": {
                      border: "1px solid #C1C1C1", // Border color and style
                    },
                    "&:hover fieldset": {
                      borderColor: "#949494", // Border color on hover
                    },
                    "&.Mui-focused fieldset": {
                      border: "1px solid #C1C1C1", // Border color when focused
                    },
                  },
                  "& .MuiInputBase-input": {
                    color: "#949494", // Input text color
                    fontWeight: "500",
                    fontSize: "12px",
                    height: "2.4375em",
                  },
                  "& .MuiFormLabel-root": {
                    color: "#525966",
                    fontWeight: "400",
                    letterSpacing: "0.15px",
                  },
                }}
              />

              <TextField
                fullWidth
                id="outlined-required"
                label="Details"
                defaultValue="Details"
                onChange={(e) => setDetails(e.target.value)}
                variant="outlined" // To ensure the same style
                multiline // To achieve a taller text box
                rows={4} // Adjust the number of rows to match the height
                sx={{
                  mb: 2,
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "4px", // Border radius
                    "& fieldset": {
                      border: "1px solid #C1C1C1", // Border color and style
                    },
                    "&:hover fieldset": {
                      borderColor: "#949494", // Border color on hover
                    },
                    "&.Mui-focused fieldset": {
                      border: "1px solid #C1C1C1", // Border color and style, // Border color when focused
                    },
                  },
                  "& .MuiInputBase-input": {
                    color: "#949494", // Input text color
                    fontWeight: "500",
                    fontSize: "12px",
                    height: "2.4375em",
                  },
                  "& .MuiFormLabel-root": {
                    color: "#525966",
                    fontWeight: "400",
                    letterSpacing: "0.15px",
                  },
                }}
              />
            </Box>
            {/* Attachment */}
            <Box
              sx={{
                border: "2px dashed gray",
                borderRadius: 2,
                textAlign: "center",
                p: 2,
                mb: 2,
                ml: -2,
                width: "428px",
              }}
            >
              <Typography
                variant="subtitle1"
                sx={{
                  mb: 1,
                  color: "#000000",
                  fontSize: "14px",
                  fontWeight: "400",
                }}
              >
                Attachment
              </Typography>

              <IconButton color="primary" component="label">
                <div
                  style={{
                    background: "#E9E9E9",
                    borderRadius: "50%",
                    width: "30px",
                    height: "30px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: 0,
                  }}
                >
                  <CloudUploadIcon sx={{ color: "#000", fontSize: "20px" }} />
                </div>

                <input
                  type="file"
                  onChange={handleFileChange}
                  hidden
                  accept="image/png, image/jpeg, image/jpg, image/gif"
                  multiple
                />
              </IconButton>

              <Typography
                variant="body2"
                sx={{
                  mb: 1,
                  color: "#000000",
                  fontSize: "9px",
                  fontWeight: "600",
                }}
              >
                <span
                  style={{
                    color: "#348BD2",
                    fontWeight: "700",
                    textDecorationLine: "underline",
                    textDecorationStyle: "solid",
                  }}
                >
                  Click to upload
                </span>
                <br />
                PNG, JPG, Docx, Pdf, and Mp4 (max. 10 MB)
              </Typography>

              {/* Display single image */}
              {attachment && (
                <Box sx={{ mt: 2 }}>
                  <img
                    src={attachment}
                    alt="Uploaded Preview"
                    style={{
                      maxWidth: "100%",
                      maxHeight: "200px",
                      objectFit: "contain",
                    }}
                  />
                </Box>
              )}

              {/* Display multiple images */}
              {imageUrls.length > 0 && (
                <Box sx={{ mt: 2, display: "flex", flexWrap: "wrap" }}>
                  {imageUrls.map((url, index) => (
                    <Box
                      key={index}
                      sx={{
                        position: "relative",
                        mr: 2,
                        mb: 2,
                        width: "100px",
                        height: "100px",
                      }}
                    >
                      {/* Image Preview */}
                      <img
                        src={url}
                        alt={`Uploaded Preview ${index + 1}`}
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "contain", // Ensures the image fits without distortion
                        }}
                      />
                      {/* Remove Icon */}
                      <IconButton
                        size="small"
                        sx={{
                          position: "absolute",
                          top: "4px",
                          right: "4px",
                          backgroundColor: "rgba(255, 255, 255, 0.8)",
                        }}
                        onClick={() => handleRemoveImage(index)}
                      >
                        <CloseIcon fontSize="small" />
                      </IconButton>
                    </Box>
                  ))}
                </Box>
              )}
            </Box>

            <Button
              variant="contained"
              color="primary"
              onClick={handleSave}
              sx={{
                background: "#367CFF",
                color: "#FFFFFF",
                fontSize: "16px",
                fontWeight: "600",
                borderRadius: "60px",
                border: "1px solid #367cff",
                "&:hover": {
                  background: "#367CFF", // Remove the hover background
                  border: "1px solid #367CFF", // Keep the border as it is
                  color: "#FFFFFF", // Maintain the text color
                },
              }}
            >
              Add
            </Button>
          </Box>

          {/* Card Section */}
        
        
            <Box
              sx={{
                width: 400,
                margin: 2,
                borderRadius: 4, // Rounded corners
                overflow: "hidden", // Ensures content fits within rounded edges
                boxShadow: "0px 2.318px 4.636px 0px rgba(0, 0, 0, 0.16)",
              }}
            >
              {/* Title Section */}
              <Box
                sx={{
                  background: "#09A2E3", // Vibrant blue background
                  padding: "8px 16px",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    color: "white",
                    fontWeight: "600",
                    textAlign: "left",
                    fontSize: "24px",
                  }}
                >
                  {CardData?.title || "Title"}
                </Typography>
              </Box>

              {/* Content Section */}
              <Box sx={{ padding: "8px 16px", mb: 2 }}>
                {/* Image Section */}
                <Box sx={{ mt: 2, textAlign: "center" }}>
                  {Array.isArray(CardData?.attachment) &&
                  CardData.attachment.length > 1 ? (
                    // Display multiple images as a slider
                    <Slider {...sliderSettings}>
                      {CardData.attachment.map((image, index) => (
                        <div key={index}>
                          <img
                            src={image}
                            alt={`Attachment ${index + 1}`}
                            style={{
                              width: "100%",
                              height: "150px",
                              objectFit: "cover",
                              borderRadius: "8px",
                            }}
                          />
                        </div>
                      ))}
                    </Slider>
                  ) : CardData.attachment ? (
                    // Display single image
                    <img
                      src={CardData.attachment}
                      alt="Attachment"
                      style={{
                        width: "100%",
                        height: "150px",
                        objectFit: "cover",
                        borderRadius: "8px",
                      }}
                    />
                  ) : (
                    "No attachment"
                  )}
                </Box>

                {/* Details Section */}
                <Typography
                  variant="body1"
                  sx={{ mt: 2, textAlign: "left", color: "#666" }}
                >
                  {CardData?.details ||
                    "Lorem ipsum dolor sit amet consectetur."}
                </Typography>
              </Box>
            </Box>
         
        </Box>
      </>

      {/* Drawer Content */}
    </Drawer>
  );
};

export default AttachmentsDrawer;
