import React, { useState, useEffect } from "react";
import {
  Drawer,
  Grid,
  Typography,
  Box,
  Fab,
  Button,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Switch,
} from "@mui/material";
import ConfirmationModal from './LossControl/ConfirmationModal';
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import UploadIcon from "@mui/icons-material/Upload";
import FRCAPACard from "./FRCAPACard";
import { assignCAPAFormFields } from "../utils/workFlowFormFields"
import CustomInput from "./CustomInputField";
import CustomSelect from "./CustomSelectField";
import CustomButtonGroup from "./CustomButtonGroup";
import BasicDateTimePicker from "./CustomDateTime";
import CustomeDatePicker from "./CustomeDate"
import { getCAPAOwner, getSubArea, resubmitCAPABySR } from "../Apis/apiCall";
import { useDispatch, useSelector } from 'react-redux';
import { handleChangeWorkfLowDrawerFormData ,setEditCAPA} from '../redux/incidentWorkflow/actions';
import {mapDataToFormFields,convertToMilliseconds,formatDateToIndianLocale} from "../utils/helper";


const AddButtonStyle = {
  marginTop: "12px",
  fontSize: "14px",
  fontWeight: "500",
  padding: "4px 12px", // Combined top, right, bottom, and left padding
  color: "#fff",
  borderRadius: "100px", // Updated border radius
  backgroundColor: "#0AA2E3",
  width: "133px",        // Hug width of 133px
  height: "36px",        // Fixed height of 40px
  textTransform: "none",
  whiteSpace: 'nowrap',
};
const AddButtonStyle2 = {
  marginTop: "12px",
  fontSize: "14px",
  fontWeight: "500",
  padding: "4px 12px", // Combined top, right, bottom, and left padding
  borderRadius: "100px", // Updated border radius
  width: "133px",        // Hug width of 133px
  height: "36px",        // Fixed height of 40px
  textTransform: "none",
  whiteSpace: 'nowrap',
};

export default function FRCAPADrawer({ open, onClose, title, level, data, mode, status }) {
  const dispatch = useDispatch();
  const [confirmation, setConfirmation] = useState(false);
  const [subAreaList, setSubAreaList] = useState([]);
  const [CAPAOwnerList, setCAPAOwnerList] = useState([]);
  const [comment, setComment] = useState("");
  const CAPAId = data.id;

  const formData = useSelector(state => state.incidentWorkFlowReducer);
  const {
    CAPA
  } = formData
 


  useEffect(() => {
    if (status === "returned") {
      if (CAPA?.["Area"]) { // Ensure CAPA exists and has Area
        fetchSubArea(CAPA["Area"]);
      }
      fetchCAPAOwner();

    }
  }, [CAPA]);

  const fetchSubArea = async (data) => {
    try {
      const result = await getSubArea(data);
      console.log(result.data.data);
      setSubAreaList(result.data.data); // Set the formatted staff list
    } catch (error) {
      console.log(error);
    }
  }


  const fetchCAPAOwner = async () => {
    try {
      const result = await getCAPAOwner();
      console.log(result.data.data);
      const formattedStaffList = result.data.data.map(staffMember => ({
        value: staffMember.id,
        label: `${staffMember.firstName} ${staffMember.lastName}`
      }));
      setCAPAOwnerList(formattedStaffList);

    } catch (error) {
      console.log(error);
    }
  }


  const handleInputChange = (e, fieldName, title) => {
    const { value } = e.target;
    if (fieldName === 'Age' || fieldName === 'LTI Hours' || fieldName === "Hours at Work") {
      if (value.length <= 2) {
        dispatch(handleChangeWorkfLowDrawerFormData({ fieldName, value, title }));
      }
    } else {
      dispatch(handleChangeWorkfLowDrawerFormData({ fieldName, value, title }));

    }
  };

  const handleSelectChange = (label, value, title) => {
    dispatch(handleChangeWorkfLowDrawerFormData({ fieldName: label, value, title }));
  };

  const handleButtonChange = (fieldName, value, title) => {
    dispatch(handleChangeWorkfLowDrawerFormData({ fieldName, value, title }));
  };

  const handleDateChange = (newValue, label, title) => {
    const formattedDateTime = dayjs(newValue).format('DD-MM-YYYY');
    dispatch(handleChangeWorkfLowDrawerFormData({ fieldName: label, value: formattedDateTime, title }));
  };

  const handleUpdateCAPA = async () => {
    const CAPAs = {
      ...CAPA,
      "Due Date": convertToMilliseconds(CAPA?.["Due Date"]),
    };
    const data1 = {
      action: "edit",
      ...CAPAs,
      comment: comment
    }
    const result = await SRCAPAAction(data1);
    console.log(result);
    if (result.data.status_code === 200) {
      window.location.reload();
    }

  }

  const handleCAPADelete = async () => {
    const data1 = {
      action: "delete",
    }
    const result = await SRCAPAAction(data1);
    console.log(result);
    if (result.data.status_code === 200) {
      window.location.reload();
    }

  }



  const SRCAPAAction = async (data) => {
    try {
      const result = await resubmitCAPABySR(data, CAPAId);
      return result;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  const renderField = (field, title) => {

   
    let fieldValue = CAPA?.[field.formFieldName] ?? '';
   

    switch (field.type) {
      case 'InputBox':
        return (
          <CustomInput
            placeholder={field.formFieldName}
            label={field.formFieldName}
            name={field.formFieldName}
            value={fieldValue}
            type={field?.dataType === "String" ? "text" : "number"}
            onChange={(e) => handleSelectChange(field.formFieldName, e.target.value, title)}
          />
        );

      case 'Dropdown': {
        return (
          <>

            <CustomSelect
              label={field.formFieldName}
              value={fieldValue}
              options={


                field.formFieldName === "Sub Area"
                  ? subAreaList
                  : field.formFieldName === "CAPA Owner"
                    ? CAPAOwnerList : field?.options || ""
              }
              onChange={(e) => handleInputChange(e, field.formFieldName, title)}
            />

          </>
        );
      }

      case 'TextArea':
        return (
          <CustomInput
            placeholder={field.formFieldName}
            label={field.formFieldName}
            multiline
            rows={4}
            type={field?.dataType === "String" ? "text" : "number"}
            value={fieldValue}
            onChange={(e) => handleSelectChange(field.formFieldName, e.target.value, title)}
          />
        );


      case 'ButtonGroup':
        return (

          <>
            <Typography variant='body2'>
              {field.formFieldName}
            </Typography>
            <CustomButtonGroup
              buttonNames={field?.options || []}
              selected={fieldValue}
              onSelect={(name) => handleButtonChange(field.formFieldName, name, title)}
            />

          </>

        );


      case 'DateTime':
        return (
          <BasicDateTimePicker
            label={field.formFieldName}
            value={fieldValue ? fieldValue : ""}
            onChange={(e) => handleDateChange(e, field.formFieldName, title)}
          />
        );
      case 'Date':
        return (
          <CustomeDatePicker
            label={field.formFieldName}
            value={fieldValue ? fieldValue : ""}
            onChange={(e) => handleDateChange(e, field.formFieldName, title)}
          />
        );




      default:
        return null;
    }
  };


  const renderFields = (fields, title, data) => {
    return (
      <Grid container spacing={1}>
        {fields.map((field) => (
          <Grid
            item
            xs={12}
            md={
              field.formFieldName === "CAPA Description" ||
                field.formFieldName === "Incident Type" ||
                field.formFieldName === "Review Date" ||
                field.formFieldName === "Review Input" ||
                field.formFieldName === "Review  completed" ||
                field.type === "DesignerButtonGroup" ||
                field.formFieldName === "Input On Severity and Incident Type" ||
                field.formFieldName === "Approved Investigation" ||
                field.formFieldName === "Initiate CAPA" ||
                field.formFieldName === "Additional Remarks" ? 12 : 6
            }
            key={field.formFieldName}
          >
            {renderField(field, title)}
          </Grid>
        ))}
      </Grid>
    );
  };


  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      PaperProps={{ sx: { width: "32%", padding: "0px !important" } }}
    >
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        sx={{
          paddingY: "16px",
          paddingX: "24px",
          borderBottom: "1px solid rgba(171, 171, 171, 0.50)",
          boxShadow: "0px 2px 4px 0px #ABABAB",
        }}
      >
        <Typography sx={{ fontSize: "16px", fontWeight: "500" }}>
          {title}
        </Typography>
      </Grid>

      <Box sx={{ padding: '16px' }}>
        {status === 'returned' ?
          <>
            <div style={{ padding: "16px 8px" }}>{assignCAPAFormFields.length > 0 && renderFields(assignCAPAFormFields, "CAPA")}
              <Grid container spacing={3}>
                <Grid item xs={12} container justifyContent="flex-end" spacing={1}>
                  <Grid item>
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={()=>setConfirmation(true)}
                      sx={AddButtonStyle2}
                    >
                      Delete CAPA
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      onClick={handleUpdateCAPA}
                      sx={{...AddButtonStyle}}
                    >
                      Update CAPA
                    </Button>
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={12}>
                  <Divider sx={{ backgroundColor: '#C1C1C1', height: '2px', mb: "12px" }} />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    value={comment}
                    multiline
                    rows={4}
                    placeholder="Comment..."
                    variant="outlined"
                    fullWidth
                    InputProps={{
                      style: {
                        borderRadius: 8,
                      },
                    }}
                    onChange={(e) => setComment(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',

                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    style={{
                     ...AddButtonStyle

                    }}
                    onClick={handleUpdateCAPA}
                    disabled={comment?.length === 0}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </div>

          </>
          : <FRCAPACard level={level} data={data} mode={mode} />
        }
      </Box>

      <Fab
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "fixed",
          top: "100px",
          right: open ? "33.2%" : "0",
          transform: "translateX(50%)",
          zIndex: 1200,
          height: "36px",
          width: "36px",
          backgroundColor: "#0AA2E3",
          borderRadius: "60px 0 0 60px",
        }}
      >
        <CloseIcon />
      </Fab>
      <ConfirmationModal open={confirmation} title="Are you sure?" message="Do you really want to Delete CAPA?" onCancel={()=>setConfirmation(false)} onConfirm={handleCAPADelete} />

    </Drawer>
  );
}
