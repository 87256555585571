import React, { useState, useEffect } from 'react';
import { Grid, Typography, Button, Chip, Switch, Divider, Box, Paper, LinearProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {
  redMinesManager,
  yellowReportIncident,
  blueCAPA,
  blueInvestigator,
  greenSafetyManager,
  actionIcon,
  person_outline,
  setting_outline,
  car_outline,
  incidentManagementIcon,
  disciplineDrilling,
  disciplineFinance,
  disciplineDefaultIcon,
  disciplineGMS,
  disciplinePSS,
  disciplineOGPS,
  overDueImage,
  cardHeadBG
} from '../constant';
import { styled } from '@mui/system';
import Tooltip from '@mui/material/Tooltip';
import SeverityTag from './SeverityTag';
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import ApartmentIcon from "@mui/icons-material/Apartment";
import EngineeringIcon from "@mui/icons-material/Engineering";
import SecurityIcon from "@mui/icons-material/Security";
import PersonIcon from "@mui/icons-material/Person";
import MedicalServicesIcon from "@mui/icons-material/MedicalServices";
import StatusChip from "../Components/Chip";
import { formatDate, removeUnderScoreInString, formatDateToIndianLocale } from "../utils/helper";
import SignalCellularAltOutlinedIcon from '@mui/icons-material/SignalCellularAltOutlined';
import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined';
import NorthWestOutlinedIcon from '@mui/icons-material/NorthWestOutlined';
import { actionWhiteIcon } from '../constant';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import { useDispatch, useSelector } from "react-redux";

const LightGreyTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  '& .MuiTooltip-tooltip': {
    backgroundColor: '#007f6a1a', // Light grey background
    color: '#000', // Text color for better readability
    fontSize: '12px',
    padding: '8px',
    borderRadius: '4px',
  },
});


const iconStyle = {
  color: "#009FF5",
  width: "46px",
  height: "46px",
  backgroundColor: "#09A2E31A",
  borderRadius: "50%",
  padding: "6px"
};


export default function IncidentModuleCard({ cardData = [], title, mode, tab }) {
  const navigate = useNavigate();
  console.log("ffffff", cardData, title, mode);
  const [cardDatas, setCardDatas] = useState(cardData);
  const { searchQuery } = useSelector((state) => state.loadingReducer);

  useEffect(() => {
    // Filter data based on searchQuery
    const filteredData = cardData?.filter((card) =>
      Object.values(card).some((value) =>
        value?.toString().toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
    setCardDatas(filteredData); // Update filtered state
  }, [searchQuery, cardData]); // Depend on both searchQuery and cardData

  const getStatusChip = (status) => {
    switch (status) {
      // all losss control status 
      case 'In_Progress':
        return <StatusChip label="In Progress" color="#0B7F6A" bgColor="rgba(11, 127, 106, 0.1)" />;
      case 'New_Survey':
        return <StatusChip label="New Tour" color="#FF8A00" bgColor="rgba(255, 138, 0, 0.1)" />;
      case 'Under_Review':
        return <StatusChip label="Under Review" color="#009FF5" bgColor="rgba(0, 159, 245, 0.1)" />;
      case 'Revalidation':
        return <StatusChip label="Revalidation" color="#FF7742" bgColor="rgba(255, 119, 66, 0.1)" />;
      case 'Resubmission':
        return <StatusChip label="Resubmission" color="#FF7742" bgColor="rgba(255, 119, 66, 0.1)" />;
      case 'Recommendation_Resubmission':
        return (
          <StatusChip
            label={<NorthWestOutlinedIcon style={{ color: "#FF7742" }} />}
            bgColor="rgba(255, 119, 66, 0.1)"
          />
        );
      case 'Returned_By_MM':
        return <StatusChip label="Returned" color="#FF7742" bgColor="rgba(255, 119, 66, 0.1)" />;
      case 'Completed':
        return <StatusChip label="Completed" color="#0B7F6A" bgColor="rgba(11, 127, 106, 0.1)" />;
      case 'Draft' :
        return <StatusChip label="Draft" color="#FF7742" bgColor="rgba(255, 119, 66, 0.1)" />;
      // all incident status 
      // Reported statuses
      case 'New Incident':
        return <StatusChip label="New Incident" color="#009FF5" bgColor="#E7F6FD" />;

      case 'Incident Resubmission':
        return <StatusChip label="Incident Resubmission" color="#FF8A00" bgColor="rgba(255, 138, 0, 0.1)" />;

      case 'New Incident Clarification':
        return <StatusChip label="New Incident Clarification" color="#FF231F" bgColor="rgba(255, 87, 83, 0.15)" />;

      // Review statuses
      case "Incident Review":
        return <StatusChip label="Incident Review" color="#FF7742" bgColor="#FFEFE8" />;

      case 'Resubmitted for Review':
        return <StatusChip label="Resubmitted for Review" color="#7400CC" bgColor="#F2E6FA" />;

      case 'Incident Revalidation':
        return <StatusChip label="Incident Revalidation" color="#FF7742" bgColor="#FFEFE8" />;

      // Investigation statuses
      case 'Investigation Initiated':
        return <StatusChip label="Investigation Initiated" color='#FFA800' bgColor='#FDF7E6' />;
      case 'Investigation Validation':
        return <StatusChip label="Investigation Validation" color='#FF7742' bgColor='#FFEFE8' />;
      case 'Investigation Reinitiated':
        return <StatusChip label="Investigation Reinitiated" color='#FFA800' bgColor='#FDF7E6' />;
      case 'Investigation Resubmission':
        return <StatusChip label="Investigation Resubmission" color='#7400CC' bgColor='#F2E6FA' />;
      case "Investigation Approval Resubmission":
        return <StatusChip label="Investigation Approval Resubmission" color='#FF7742' bgColor='#FFEFE8' />;
      case 'Investigation Clarification':
        return <StatusChip label="Investigation Clarification" color='#FFA800' bgColor='#FDF7E6' />;
      case 'Investigation Under Approval':
        return <StatusChip label="Investigation Under Approval" color='#FF7742' bgColor='#FFEFE8' />;
      case "Investigation Completed":
        return <StatusChip label="Investigation Completed" color='#FFA800' bgColor='#FDF7E6' />;

      case "Investigation Query":
        return <StatusChip label="Investigation Query " color='#FF7742' bgColor='#FFEFE8' />;
      // CAPA statuses
      case 'Review':
        return <StatusChip label="CAPA Review" color='#FFA800' bgColor='#FDF7E6' />;
      case 'CAPA Assigned':
        return <StatusChip label="CAPA Assigned" color='#FF7742' bgColor='#FFEFE8' />;
      case 'CAPA In Progress':
        return <StatusChip label="CAPA In Progress" color='#FFA800' bgColor='#FDF7E6' />;
      case 'resubmitted':
        return <StatusChip label="CAPA Resubmitted" color='#FF7742' bgColor='#FFEFE8' />;
      case 'revalidation':
        return <StatusChip label="CAPA Revalidation" color='#7400CC' bgColor='#F2E6FA' />;
      case 'clarification':
        return <StatusChip label="CAPA Clarification" color='#FFA800' bgColor='#FDF7E6' />;
      case 'resubmission':
        return <StatusChip label="CAPA Review Resubmission" color='#7400CC' bgColor='#F2E6FA' />;
      case 'completed':
        return <StatusChip label="CAPA Completed" color="#4CAF50" bgColor="#E8F9F0" />;
      case 'approved':
        return <StatusChip label="CAPA Completed" color="#4CAF50" bgColor="#E8F9F0" />;
      case 'returned':
        return <StatusChip label="CAPA Return" color='#7400CC' bgColor='#F2E6FA' />;

      // Closed status
      case 'Incident Closed':
        return <StatusChip label="Incident Closed" color="#4CAF50" bgColor="#E8F9F0" />;

      default:
        return <StatusChip label="" color="#888" />;
    }
  };



  const getIcon = (title, data) => {
    if (title === "Incident Management") {
      return data.role === "Mines manager" ? <img src={incidentManagementIcon} alt="PSS" />
        : data.role === "Report Incidents" ? <img src={incidentManagementIcon} alt="PSS" />
          : data.role === "CAPA" ? <img src={incidentManagementIcon} alt="PSS" />
            : data.role === "Investigator" ? <img src={incidentManagementIcon} alt="PSS" />
              : <img src={incidentManagementIcon} alt="PSS" />

    } else if (title === "Loss Control") {
      switch (data.discipline?.toLowerCase()) {
        case "assets":
          return <ApartmentIcon style={iconStyle} />;
        case "engineering":
          return <EngineeringIcon style={iconStyle} />;
        case "security":
          return <SecurityIcon style={iconStyle} />;
        case "hr":
          return <PersonIcon style={iconStyle} />;
        case "medical":
          return <MedicalServicesIcon style={iconStyle} />;
        case "drilling":
          return <img src={disciplineDrilling} alt="Drilling" style={iconStyle} />;
        case "finance & other":
          return <SignalCellularAltOutlinedIcon style={iconStyle} />;
        case "ogps":
          return <img src={disciplineOGPS} alt="OGPS" style={iconStyle} />;
        case "pss":
          return <img src={disciplinePSS} alt="PSS" style={iconStyle} />;
        case "gms & lpg":
          return <img src={disciplineGMS} alt="GMS & LPG" style={iconStyle} />;
        default:
          return <img src={disciplineDefaultIcon} alt="default" style={iconStyle} />;
      }
    } else if (title === "CAPA Management") {
      return <TextSnippetOutlinedIcon style={iconStyle} />;
    }
    return data.moduleName === "Incident Management" ? <img src={incidentManagementIcon} alt="PSS" />
      : data.moduleName === "Loss Control" ? <img src={redMinesManager} alt="PSS" /> : data.moduleName === "CAPA Management" ? <TextSnippetOutlinedIcon style={iconStyle} />
        : <img src={greenSafetyManager} alt="PSS" />;
  };





  return (
    <>
      {cardDatas.length === 0 ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100%"  // Adjust this based on the container height
        >
          <Typography variant="h6" sx={{ color: '#525966', fontWeight: 'bold' }}>
            No data found
          </Typography>
        </Box>
      ) : (
        cardDatas.map((data, index) => (
          <Grid
            item
            key={index}
            xs={12}  // 1 card per row on mobile
            sm={6}   // 2 cards per row on small screens
            lg={title === "Loss Control" ? 4 : 4}   // 3 cards per row on medium screens
          //lg={title === "Loss Control" ? 4 : 3}   // 3 cards per row on large screens
          >
            <Paper
              elevation={1}
              sx={{
                backgroundColor: title === "Loss Control" ? '#FAFAFA' : '#FFFFFF',
                padding: '8px !important',
                minHeight: title === 'All' ? '180px' : '230px',
                flexGrow: 1,
                border: '0.876px solid #D5E0F6',
                borderRadius: '16px',
                cursor: 'pointer',
                boxShadow: '1.753px 1.753px 3.506px 0px rgba(174, 191, 237, 0.25)',
                transition: '0.3s', // Smooth transition for the hover effect
                '&:hover': {
                  backgroundColor: title === "Loss Control" ? '#FAFAFA' : '#FFFFFF',// Light blue color on hover
                  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)', // Stronger shadow on hover
                  borderColor: '#FAFAFA', // Slightly darker border on hover
                },
              }}

            >
              <Grid container onClick={() => {
                data["mode"] = mode;
                if (title === "Loss Control") {
                  console.log("Clicked data:", data);
                  if(data.status[0] === "Draft"){
                    { mode === "draft" ? navigate('/module/loss-control/create-tour', { state: { data } }) : navigate('/module/loss-control/create-tour', { state: { data } }) }

                  }else{
                    { mode === "action" ? navigate('/my-actions/loss-control-edit', { state: { data } }) : navigate('/all-actions/loss-control-view', { state: { data } }) }

                  }
                }
                if (title === "Incident Management") {
                  { mode === "action" ? navigate('/my-actions/incident-edit', { state: { data } }) : navigate('/all-actions/incident-view', { state: { data } }) }
                }
                if (title === "CAPA Management") {
                  { mode === "action" ? navigate('/my-actions/capa-edit', { state: { data } }) : navigate('/all-actions/capa-view', { state: { data } }) }
                }
                if (title === "All") {
                  if (data.moduleName === "Loss Control") {
                    { mode === "action" ? navigate('/my-actions/loss-control-edit', { state: { data } }) : navigate('/all-actions/loss-control-view', { state: { data } }) }
                  }
                  // } else if (data.role === "CAPA Owner") {
                  //   { mode === "action" ? navigate('/my-actions/capa-edit', { state: { data } }) : navigate('/all-actions/capa-view', { state: { data } }) }

                  // } 
                  else {
                    { mode === "action" ? navigate('/my-actions/incident-edit', { state: { data } }) : navigate('/all-actions/incident-view', { state: { data } }) }
                  }
                }
              }}>

                {title === "Loss Control" && (
                  <Grid item xs={12}>
                    <Box sx={{
                      borderRadius: '12px', backgroundColor: '#09A2E3', height: '80px', padding: '8px', mb: '32px', position: 'relative',
                      backgroundImage: `url(${cardHeadBG})`,
                      backgroundSize: "200%",
                      backgroundPosition: '-300px -100px',
                    }}>
                      <Grid container spacing={0} justifyContent="space-between">
                        <Grid item>
                          <Box display='flex' flexDirection='column' gap={0.5}>
                            <Typography sx={{ color: '#ffffff', fontWeight: '600', fontSize: '14px' }}>Loss Control Tour</Typography>
                            <Box display="flex" alignItems="center" gap={1}>
                              <LocationOnOutlinedIcon sx={{ color: "#ffffff", verticalAlign: 'middle', fontSize: "12px" }} />
                              <Typography sx={{ color: '#ffffff', fontSize: '10px', textAlign: 'left' }}>
                                {data?.locationName}
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>

                        {mode === "action" &&
                          <Grid item>
                            <Tooltip title="Take Action" arrow>
                              <img src={actionWhiteIcon} alt='actionIcon' style={{ bgColor: "#09A2E3", width: '20px', height: '20px', cursor: 'pointer' }}
                              />
                            </Tooltip>
                          </Grid>
                        }
                      </Grid>
                      <Box sx={{
                        position: 'absolute',
                        border: '8px solid white',
                        left: '16px',
                        bottom: '-25px',
                        zIndex: 10,
                        borderRadius: '50%',
                        backgroundColor: 'white',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '50px',
                        height: '50px',
                        padding: '0px',
                      }}>
                        {getIcon(title, data)}
                      </Box>
                      <Typography sx={{ position: 'absolute', fontSize: '12px', bottom: '8px', right: '8px', color: '#fff' }}>ID: {data?.customReportId || data?.draftID}</Typography>
                      {data?.overdueTaskCount > 0 && <WatchLaterIcon sx={{ fontSize: '28px', color: '#CC0000', position: 'absolute', right: '8px', bottom: '-34px' }} />}
                      {/* <img src={cardHeadBG} sx={{position:'absolute', top:'-60px', left:'0px'}} /> */}
                    </Box>
                  </Grid>)}

                {title === "Incident Management" && (
                  <Grid item xs={12}>
                    <Box sx={{
                      borderRadius: '12px', backgroundColor: '#09A2E3', height: '80px', padding: '8px', mb: '32px', position: 'relative',
                      backgroundImage: `url(${cardHeadBG})`,
                      backgroundSize: "200%",
                      backgroundPosition: '-300px -100px',
                    }}>
                      <Grid container spacing={0} justifyContent="space-between">
                        <Grid item>
                          <Box display='flex' flexDirection='column' gap={0.5}>
                            <Typography sx={{ color: '#ffffff', fontWeight: '600', fontSize: '14px' }}>Incident Report</Typography>
                            <Box display="flex" alignItems="center" gap={1}>
                              <LocationOnOutlinedIcon sx={{ color: "#ffffff", verticalAlign: 'middle', fontSize: "12px" }} />
                              <Typography sx={{ color: '#ffffff', fontSize: '10px', textAlign: 'left' }}>
                                {data?.locationName}
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>

                        {mode === "action" &&
                          <Grid item>
                            <Tooltip title="Take Action" arrow>
                              <img src={actionWhiteIcon} alt='actionIcon' style={{ bgColor: "#09A2E3", width: '20px', height: '20px', cursor: 'pointer' }}
                              />
                            </Tooltip>
                          </Grid>
                        }
                      </Grid>
                      <Box sx={{
                        position: 'absolute',
                        border: '8px solid white',
                        left: '16px',
                        bottom: '-25px',
                        zIndex: 10,
                        borderRadius: '50%',
                        backgroundColor: 'white',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '50px',
                        height: '50px',
                        padding: '0px',
                      }}>
                        {getIcon(title, data)}
                      </Box>
                      <Typography sx={{ position: 'absolute', fontSize: '12px', bottom: '8px', right: '8px', color: '#fff' }}>ID: {data?.customReportId}</Typography>
                      {data?.overdueTaskCount > 0 && <WatchLaterIcon sx={{ fontSize: '28px', color: '#CC0000', position: 'absolute', right: '8px', bottom: '-34px' }} />}
                    </Box>
                  </Grid>)}

                {title === "CAPA Management" && (
                  <Grid item xs={12}>
                    <Box sx={{
                      borderRadius: '12px', backgroundColor: '#09A2E3', height: '80px', padding: '8px', mb: '32px', position: 'relative',
                      backgroundImage: `url(${cardHeadBG})`,
                      backgroundSize: "200%",
                      backgroundPosition: '-300px -100px',
                    }}>
                      <Grid container spacing={0} justifyContent="space-between">
                        <Grid item>
                          <Box display='flex' flexDirection='column' gap={0.5}>
                            <Typography sx={{ color: '#ffffff', fontWeight: '600', fontSize: '14px' }}>CAPA (Incident)</Typography>
                            <Box display="flex" alignItems="center" gap={1}>
                              <LocationOnOutlinedIcon sx={{ color: "#ffffff", verticalAlign: 'middle', fontSize: "12px" }} />
                              <Typography sx={{ color: '#ffffff', fontSize: '10px', textAlign: 'left' }}>
                                {data?.locationName}
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>

                        {mode === "action" &&
                          <Grid item>
                            <Tooltip title="Take Action" arrow>
                              <img src={actionWhiteIcon} alt='actionIcon' style={{ bgColor: "#09A2E3", width: '20px', height: '20px', cursor: 'pointer' }}
                              />
                            </Tooltip>
                          </Grid>
                        }
                      </Grid>
                      <Box sx={{
                        position: 'absolute',
                        border: '8px solid white',
                        left: '16px',
                        bottom: '-25px',
                        zIndex: 10,
                        borderRadius: '50%',
                        backgroundColor: 'white',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '50px',
                        height: '50px',
                        padding: '0px',
                      }}>
                        {getIcon(title, data)}
                      </Box>
                      <Chip
                        label={data?.capaPriority}
                        sx={{
                          position: 'absolute',
                          bottom: '28px',
                          right: '8px',
                          backgroundColor: data?.capaPriority === "High" ? '#C62828' : '#2E7D32', // Green for High, Red otherwise
                          color: '#FFFFFF',          // White text
                          fontWeight: 'bold',        // Bold font for text
                          borderRadius: '16px',      // Fully rounded
                          height: '16px',            // Adjust height
                          fontSize: '10px',          // Font size
                        }}
                      />
                      <Typography sx={{ position: 'absolute', fontSize: '12px', bottom: '8px', right: '8px', color: '#fff' }}>ID: {data?.customReportID}</Typography>
                      {data?.overdueTaskCount > 0 && <WatchLaterIcon sx={{ fontSize: '28px', color: '#CC0000', position: 'absolute', right: '8px', bottom: '-34px' }} />}
                      {/* <img src={cardHeadBG} sx={{position:'absolute', top:'-60px', left:'0px'}} /> */}
                    </Box>
                  </Grid>)}

                {title === "All" && (

                  <Grid item xs={12}>
                    <Box sx={{
                      borderRadius: '12px', backgroundColor: '#09A2E3', height: '72px', padding: '8px', mb: '32px', position: 'relative',
                      backgroundImage: `url(${cardHeadBG})`,
                      backgroundSize: "200%",
                      backgroundPosition: '-300px -100px',
                    }}>
                      <Grid container spacing={0} justifyContent="space-between">
                        <Grid item>
                          <Typography sx={{ color: '#ffffff', fontWeight: '600', fontSize: '14px' }}>{data?.moduleName === "Incident Management" ? "Incident" : data?.moduleName}</Typography>
                        </Grid>

                        {mode === "action" &&
                          <Grid item>
                            <Tooltip title="Take Action" arrow>
                              <img src={actionWhiteIcon} alt='actionIcon' style={{ bgColor: "#09A2E3", width: '20px', height: '20px', cursor: 'pointer' }}
                              />
                            </Tooltip>
                          </Grid>
                        }
                      </Grid>

                      <Box sx={{
                        position: 'absolute',
                        border: '8px solid white',
                        left: '16px',
                        bottom: '-25px',
                        zIndex: 10,
                        borderRadius: '50%',
                        backgroundColor: 'white',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '50px',
                        height: '50px',
                        padding: '0px',
                      }}>
                        {getIcon(title, data)}
                      </Box>
                      <Typography sx={{ position: 'absolute', fontSize: '12px', bottom: '8px', right: '8px', color: '#fff' }}>ID: {data?.customReportId}</Typography>
                      {data?.overdueTaskCount > 0 && <WatchLaterIcon sx={{ fontSize: '28px', color: '#CC0000', position: 'absolute', right: '8px', bottom: '-34px' }} />}
                      {data?.Severity && <Grid item sx={{ position: 'absolute', right: '8px', bottom: '-54px' }}>
                        <SeverityTag severity={data?.Severity == 0 ? "N/A" :data?.Severity} />
                      </Grid>}
                    </Box>
                  </Grid>
                )}


                {title === "Incident Management" ? (

                  <>
                    <Grid item xs={12}>
                      <Grid container spacing={0} justifyContent="space-between">
                        <Box display='flex' flexDirection='column' gap={0.2}>
                          <Box display='flex' flexDirection='row' gap={tab === "Completed" ? 1 : tab === "My History" ? 2 : 1}>
                            <Typography sx={{ color: '#1F242F', fontSize: '12px', fontWeight: '400' }}>Incident Type</Typography>
                            <Typography sx={{ color: '#1F242F', fontSize: '12px', fontWeight: '600' }}>{data?.["Incident Type"]}</Typography>

                          </Box>
                          <Box display='flex' flexDirection='row' gap={tab === "Completed" ? 1 : tab === "My History" ? 2 : 1}>
                            <Typography sx={{ color: '#1F242F', fontSize: '12px', fontWeight: '400' }}>Incident Date</Typography>
                            <Typography sx={{ color: '#6B7387', fontSize: '12px', fontWeight: '400' }}>{formatDateToIndianLocale(data?.["Incident Date"])}</Typography>

                          </Box>
                          <Box display='flex' flexDirection='row' gap={tab === "Completed" ? 4 : tab === "My History" ? 5 : 4}>
                            <Typography sx={{ color: '#1F242F', fontSize: '12px', fontWeight: '400' }}>Reporter</Typography>
                            <Typography sx={{ color: '#6B7387', fontSize: '12px', fontWeight: '400' }}>{data?.reportingOfficerName}</Typography>

                          </Box>
                          <Box display='flex' flexDirection='row' gap={tab === "Completed" ? 2 : tab === "My History" ? 1 : 7}>
                            <Typography sx={{ color: '#1F242F', fontSize: '12px', fontWeight: '400' }}>{tab === "My History" ? "Last Action By" : tab === "Completed" ? "Approved By" : "Role"}</Typography>
                            <Box display="flex" flexDirection="column" gap={0}>
                              <Typography sx={{ color: '#6B7387', fontSize: '12px', fontWeight: '400' }}>
                                {data?.role}
                              </Typography>

                              <Box display="flex" alignItems="center">
                                {Array.isArray(data?.email) ? (
                                  <>
                                    <Typography sx={{ color: '#6B7387', fontSize: '12px', fontWeight: '400' }}>
                                      {data.email[0]}
                                    </Typography>

                                    {data.email.length > 1 && (
                                      <LightGreyTooltip
                                        title={
                                          <Box sx={{ backgroundcolor: "#F2F2F200" }}>
                                            {data.email.slice(1).map((email, index) => (
                                              <Typography key={index} sx={{ fontSize: '12px' }}>
                                                {email}
                                              </Typography>
                                            ))}
                                          </Box>
                                        }

                                        placement="right"
                                        arrow
                                      >
                                        <Typography
                                          sx={{
                                            color: '#1976D2',
                                            fontSize: '12px',
                                            fontWeight: '500',
                                            marginLeft: '6px',
                                            cursor: 'pointer',
                                          }}
                                        >
                                          +{data.email.length - 1}
                                        </Typography>
                                      </LightGreyTooltip>
                                    )}
                                  </>
                                ) : (
                                  // Fallback when data.email is a string or undefined
                                  <Typography sx={{ color: '#6B7387', fontSize: '12px', fontWeight: '400' }}>
                                    {data?.email || 'No email available'}
                                  </Typography>
                                )}
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                        <Grid item >
                        <SeverityTag severity={data?.Severity == 0 ? "N/A" :data?.Severity} />
                        </Grid>

                      </Grid>

                    </Grid>
                    <Grid item xs={12}>
                      {(data?.personnelDetail === 0 && data?.equipmentDetail === 0 && data?.vehicleDetail === 0 && data?.ltiDetail === 0) ?

                        <Box style={{height:"40px" }}></Box>
                         :
                        <Box display="flex" alignItems="center" gap={1} mb="8px" mt="6px">
                          {data?.personnelDetail != 0 && (<img src={person_outline} alt='personicon' />)}
                          {data?.equipmentDetail != 0 && (<img src={setting_outline} alt='settingicon' />)}
                          {data?.vehicleDetail != 0 && (<img src={car_outline} alt='vehicleIcon' />)}
                          {data?.ltiDetail != 0 && (
                            <Box
                              alignItems="center"
                              sx={{
                                border: "1px solid #4591F5", // Gilt blue border color
                                bgcolor: "#4591F526",
                                borderRadius: "4px",
                                padding: "1.5px 2.5px", // optional padding for the box
                              }}
                            >
                              <Typography
                                variant='caption'
                                sx={{
                                  color: '#4591F5', // Gilt blue color for the text
                                  fontWeight:600
                                }}
                              >
                                LTI
                              </Typography>
                            </Box>
                          )}

                        </Box>
                      }
                    </Grid>

                    <Divider sx={{ width: "100%", mb: "4px" }} />

                    <Box display="flex" flexDirection="row" gap={2} mb="8px" alignItems="center">
                      {Array.isArray(data?.status) ? (
                        data.status.map((status, index) => (
                          <React.Fragment key={index}>
                            {getStatusChip(status)}
                          </React.Fragment>
                        ))
                      ) : (
                        <Typography variant="body2" sx={{ color: '#423B50' }}>
                          No status available
                        </Typography>
                      )}
                    </Box>

                    <Grid item xs={12}>
                      <Box display="flex" alignItems="center" justifyContent="flex-end" gap={1}>
                        <HistoryOutlinedIcon fontSize='12px' sx={{ color: '#09A2E3', verticalAlign: 'middle' }} />
                        <Typography sx={{ color: '#09A2E3', fontSize: '10px', fontStyle: 'italic' }}>
                          {formatDateToIndianLocale(data?.lastCreatedLog)}
                        </Typography>
                      </Box>
                    </Grid>

                  </>

                ) : title === "Loss Control" ? (
                  <>


                    <Grid item xs={12}>
                      <Box display='flex' flexDirection='column' gap={0.2}>
                        <Box display='flex' flexDirection='row' gap={tab === "Completed" ? 3 : tab === "My History" ? 4 : 1}>
                          <Typography sx={{ color: '#1F242F', fontSize: '12px', fontWeight: '400' }}>Discipline</Typography>
                          <Typography sx={{ color: '#1F242F', fontSize: '12px', fontWeight: '600' }}>{data?.discipline}</Typography>

                        </Box>
                        <Box display='flex' flexDirection='row' gap={tab === "Completed" ? 3 : tab === "My History" ? 4 : 1}>
                          <Typography sx={{ color: '#1F242F', fontSize: '12px', fontWeight: '400' }}>Tour Date</Typography>
                          <Typography sx={{ color: '#6B7387', fontSize: '12px', fontWeight: '400' }}>{formatDateToIndianLocale(data?.["Tour Date"])}</Typography>

                        </Box>
                        <Box display='flex' flexDirection='row' gap={tab === "Completed" ? 3 : tab === "My History" ? 4 : 1}>
                          <Typography sx={{ color: '#1F242F', fontSize: '12px', fontWeight: '400' }}>Executive</Typography>
                          <Typography sx={{ color: '#6B7387', fontSize: '12px', fontWeight: '400' }}>{data?.executiveName}</Typography>

                        </Box>
                        <Box display='flex' flexDirection='row' gap={tab === "Completed" ? 1.5 : tab === "My History" ? 1 : 4.5}>
                          <Typography sx={{ color: '#1F242F', fontSize: '12px', fontWeight: '400' }}>{tab === "My History" ? "Last Action By" : tab === "Completed" ? "Approved By" : "Role"}</Typography>
                          <Box display="flex" flexDirection="column" gap={0}>
                            <Typography sx={{ color: '#6B7387', fontSize: '12px', fontWeight: '400' }}>
                              {data?.role}
                            </Typography>

                            <Box display="flex" alignItems="center">
                              {Array.isArray(data?.email) ? (
                                <>
                                  <Typography sx={{ color: '#6B7387', fontSize: '12px', fontWeight: '400' }}>
                                    {data.email[0]}
                                  </Typography>

                                  {data.email.length > 1 && (
                                    <LightGreyTooltip
                                      title={
                                        <Box sx={{ backgroundcolor: "#F2F2F200" }}>
                                          {data.email.slice(1).map((email, index) => (
                                            <Typography key={index} sx={{ fontSize: '12px' }}>
                                              {email}
                                            </Typography>
                                          ))}
                                        </Box>
                                      }

                                      placement="right"
                                      arrow
                                    >
                                      <Typography
                                        sx={{
                                          color: '#1976D2',
                                          fontSize: '12px',
                                          fontWeight: '500',
                                          marginLeft: '6px',
                                          cursor: 'pointer',
                                        }}
                                      >
                                        +{data.email.length - 1}
                                      </Typography>
                                    </LightGreyTooltip>
                                  )}
                                </>
                              ) : (
                                // Fallback when data.email is a string or undefined
                                <Typography sx={{ color: '#6B7387', fontSize: '12px', fontWeight: '400' }}>
                                  {data?.email || 'No email available'}
                                </Typography>
                              )}
                            </Box>
                          </Box>


                        </Box>

                      </Box>
                    </Grid>


                    <Grid item xs={12}>
                      <Box display="flex" flexDirection="row" alignItems="center" gap={2} mb="8px">
                        {/* Progress bar container */}
                        <Typography variant="body2" sx={{ color: '#525966' }}>
                          {data?.totalUnsatisfactoryCount > 0
                            ? `${((data?.completedTaskCount / data?.totalUnsatisfactoryCount) * 100).toFixed(0)}%`
                            : '100%'}
                        </Typography>
                        <Box sx={{ flexGrow: 1 }}>
                          <LinearProgress
                            variant="determinate"
                            value={data?.totalUnsatisfactoryCount > 0
                              ? `${((data?.completedTaskCount / data?.totalUnsatisfactoryCount) * 100).toFixed(0)}`
                              : 100}
                            sx={{
                              borderRadius: 5,
                              height: 5, // Adjust the height of the progress bar
                              backgroundColor: 'rgba(0, 127, 106, 0.1)', // Light green background
                              '& .MuiLinearProgress-bar': {
                                backgroundColor: '#4BC16D', // Green progress color
                              },
                            }}
                          />
                        </Box>
                        <Typography variant="body2" sx={{ color: '#525966' }}>
                          {data?.completedTaskCount}/{data?.totalUnsatisfactoryCount}
                        </Typography>
                      </Box>
                    </Grid>
                    <Box display="flex" flexDirection="row" gap={2} mb="8px" alignItems="center">
                      {Array.isArray(data?.status) ? (
                        data.status.map((status, index) => (
                          <React.Fragment key={index}>
                            {getStatusChip(status)}
                          </React.Fragment>
                        ))
                      ) : (
                        // Fallback if data.Status is not an array (you can handle this however you want)
                        <Typography variant="body2" sx={{ color: '#423B50' }}>
                          No status available
                        </Typography>
                      )}

                      {data?.status?.includes("New_Survey") && (
                        <Typography variant="body2" sx={{ color: '#423B50', float: "right-end" }}>
                          Recommendations {data?.totalUnsatisfactoryCount}
                        </Typography>
                      )}
                    </Box>

                    <Grid item xs={12}>
                      <Box display="flex" alignItems="center" justifyContent="flex-end" gap={1}>
                        <HistoryOutlinedIcon fontSize='12px' sx={{ color: '#09A2E3', verticalAlign: 'middle' }} />
                        <Typography sx={{ color: '#09A2E3', fontSize: '10px', fontStyle: 'italic' }}>
                          {data?.lastCreatedLog ? formatDateToIndianLocale(data?.lastCreatedLog) : ""}
                        </Typography>
                      </Box>
                    </Grid>

                  </>
                ) : title === 'All' ? (
                  <>
                    <Grid item xs={12}>
                      <Box display="flex" alignItems="center" gap={1} mb="4px">
                        {Object.keys(data).map((key) => {
                          if (key.includes("Due Date") || key.includes("Tour Date") || (key.includes("Incident Date"))) {
                            return (
                              <React.Fragment key={key}>
                                <Typography sx={{ color: '#1F242F', fontSize: '12px', minWidth: '55px' }}>{key}</Typography>
                                <Typography sx={{ color: '#6B7387', fontSize: '12px' }}>{formatDateToIndianLocale(data[key])}</Typography>
                              </React.Fragment>
                            );
                          }
                          return null;
                        })}
                      </Box>
                      <Box display="flex" alignItems="center" gap={0} mb="4px">
                        <Typography sx={{ color: '#1F242F', fontSize: '12px', minWidth: data?.hasOwnProperty('Incident Type') ? '80px' : '65px', }}>
                          Location
                        </Typography>
                        <Typography sx={{ color: '#6B7387', fontSize: '12px', flex: 1 }}>
                          {data?.locationName}
                        </Typography>
                      </Box>
                      <Box display='flex' flexDirection='row' gap={0}>
                        <Typography sx={{ color: '#525966', fontSize: '12px', fontWeight: '400', minWidth: data?.hasOwnProperty('Incident Type') ? '80px' : '65px', }}>Role</Typography>
                        <Box display="flex" flexDirection="column" gap={0}>
                          <Typography sx={{ color: '#6B7387', fontSize: '12px', fontWeight: '400' }}>
                            {data?.role}
                          </Typography>

                          <Box display="flex" alignItems="center">
                            {Array.isArray(data?.email) ? (
                              <>
                                <Typography sx={{ color: '#6B7387', fontSize: '12px', fontWeight: '400' }}>
                                  {data.email[0]}
                                </Typography>

                                {data.email.length > 1 && (
                                  <LightGreyTooltip
                                    title={
                                      <Box sx={{ backgroundcolor: "#F2F2F200" }}>
                                        {data.email.slice(1).map((email, index) => (
                                          <Typography key={index} sx={{ fontSize: '12px' }}>
                                            {email}
                                          </Typography>
                                        ))}
                                      </Box>
                                    }

                                    placement="right"
                                    arrow
                                  >
                                    <Typography
                                      sx={{
                                        color: '#1976D2',
                                        fontSize: '12px',
                                        fontWeight: '500',
                                        marginLeft: '6px',
                                        cursor: 'pointer',
                                      }}
                                    >
                                      +{data.email.length - 1}
                                    </Typography>
                                  </LightGreyTooltip>
                                )}
                              </>
                            ) : (
                              // Fallback when data.email is a string or undefined
                              <Typography sx={{ color: '#6B7387', fontSize: '12px', fontWeight: '400' }}>
                                {data?.email || 'No email available'}
                              </Typography>
                            )}
                          </Box>
                        </Box>
                      </Box>
                      <Divider sx={{ mb: "4px" }} />
                      <Box display="flex" alignItems="center" gap={0} mb="4px">
                        <div variant="body2" sx={{ color: '#423B50' }}>{Array.isArray(data?.status) ? (
                          data.status.map((status, index) => (
                            <React.Fragment key={index}>
                              {getStatusChip(status)}
                            </React.Fragment>
                          ))
                        ) : (
                          // Fallback if data.Status is not an array (you can handle this however you want)
                          <Typography variant="body2" sx={{ color: '#423B50' }}>
                            No status available
                          </Typography>
                        )}</div>
                      </Box>


                    </Grid>

                  </>
                ) : title === 'CAPA Management' ? (
                  <>
                    <Grid item xs={12}>
                      <Box display='flex' flexDirection='column' gap={0.2}>
                        <Box display='flex' flexDirection='row' gap={tab === "Completed" ? 3 : tab === "My History" ? 3 : 2}>
                          <Typography sx={{ color: '#1F242F', fontSize: '12px', fontWeight: '400' }}>CAPA Type</Typography>
                          <Typography sx={{ color: '#1F242F', fontSize: '12px', fontWeight: '600' }}>{data?.capaType}</Typography>

                        </Box>
                        <Box display='flex' flexDirection='row' gap={tab === "Completed" ? 3 : tab === "My History" ? 2 : 1}>
                          <Typography sx={{ color: '#1F242F', fontSize: '12px', fontWeight: '400' }}>Source Type</Typography>
                          <Typography sx={{ color: '#6B7387', fontSize: '12px', fontWeight: '400' }}> {data?.moduleName}</Typography>
                        </Box>
                        <Box display='flex' flexDirection='row' gap={tab === "Completed" ? 3 : tab === "My History" ? 4 : 2.5}>
                          <Typography sx={{ color: '#1F242F', fontSize: '12px', fontWeight: '400' }}>Source ID</Typography>
                          <Typography sx={{ color: '#6B7387', fontSize: '12px', fontWeight: '400' }}>{data?.customReportID}</Typography>
                        </Box>
                        <Box display='flex' flexDirection='row' gap={tab === "Completed" ? 3 : tab === "My History" ? 2.5 : 1}>
                          <Typography sx={{ color: '#1F242F', fontSize: '12px', fontWeight: '400' }}>Assigned To</Typography>
                          <Typography sx={{ color: '#6B7387', fontSize: '12px', fontWeight: '400' }}>{data?.capaOwnerName}</Typography>
                        </Box>
                        <Box display='flex' flexDirection='row' gap={tab === "Completed" ? 3 : tab === "My History" ? 4 : 3}>
                          <Typography sx={{ color: '#1F242F', fontSize: '12px', fontWeight: '400' }}>Due Date</Typography>
                          <Typography sx={{ color: '#6B7387', fontSize: '12px', fontWeight: '400' }}>{formatDateToIndianLocale(data?.["dueDate"])}</Typography>

                        </Box>

                      </Box>
                      <Divider sx={{ my: "8px" }} />
                    </Grid>
                    <Box display="flex" flexDirection="row" gap={2} mb="8px" alignItems="center">
                      {Array.isArray(data?.status) ? (
                        data.status.map((status, index) => (
                          <React.Fragment key={index}>
                            {getStatusChip(status)}
                          </React.Fragment>
                        ))
                      ) : (
                        // Fallback if data.Status is not an array (you can handle this however you want)
                        <Typography variant="body2" sx={{ color: '#423B50' }}>
                          {getStatusChip(data.status)}
                        </Typography>
                      )}
                    </Box>



                    <Grid item xs={12}>
                      <Box display="flex" alignItems="center" justifyContent="flex-end" gap={1}>
                        <HistoryOutlinedIcon fontSize='12px' sx={{ color: '#09A2E3', verticalAlign: 'middle' }} />
                        <Typography sx={{ color: '#09A2E3', fontSize: '10px', fontStyle: 'italic' }}>
                          {formatDateToIndianLocale(data?.lastCreatedLog)}
                        </Typography>
                      </Box>
                    </Grid>

                  </>
                ) : (
                  <>
                  </>
                )}


              </Grid>
            </Paper>
          </Grid>
        ))
      )}
    </>
  );
}
