export const capitalizeFirstChar = (str) => {
  if (!str) return '';
  const string = String(str); // Ensure the input is a string
  return string.charAt(0).toUpperCase() + string.slice(1);
};

 


  export const formatDate = (dateString) => {
    const date = new Date(dateString);
  
    // Get the day, month, and year
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'short' }); // Get the month name
    const year = date.getFullYear();
  
    return `${day} ${month} ${year}`;
  };


  export const formatTableDate = (dateString) => {
    if (!dateString) return "";  // Handle empty or null date
    const date = new Date(dateString);
    return date.toLocaleDateString('en-GB', {
      day: 'numeric',
      month: '2-digit',
      year: 'numeric'
    });
  };


  export const formatDateToIndianLocale=(timestamp)=> {
    const date = new Date(timestamp);
    return date.toLocaleString('en-IN', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
     
    });
  }

  export const formatDateToIndianLocale2 = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString('en-IN', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true, // Enables AM/PM format
    });
  };

  export const convertMillisecondsToDate =(ms) =>{
    const date = new Date(ms);
    
    const day = String(date.getDate()).padStart(2, '0'); // Ensure two digits
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const year = date.getFullYear();
    
    return `${day}-${month}-${year}`;
  }






  export const removeUnderScoreInString = (status) =>{
    if (Array.isArray(status)) {
      // If the status is an array, format each string in the array
      return status
        .map((item) =>
          item
            .toLowerCase()
            .replace(/_/g, ' ')
            .replace(/\b\w/g, (char) => char.toUpperCase())
        )
        .join(', '); // Join the array into a single string separated by commas
    }
  
    // If it's a single string, format it directly
    return status
      .toLowerCase()
      .replace(/_/g, ' ')
      .replace(/\b\w/g, (char) => char.toUpperCase());
  }


  export const formatUpdateTime = (updatedAt)=> {
    const date = new Date(updatedAt);
    const options = {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    };
    const formattedDate = date.toLocaleDateString('en-GB', options); // Output example: "02 May 2024"
  
    return formattedDate;
  }



  export const convertToMilliseconds = (dateString)=> {
    if (!dateString) return null;
  
    // Split date and time
    const [datePart, timePart] = dateString.split(" ");
  
    // Parse the date part (DD-MM-YYYY)
    const [day, month, year] = datePart.split("-").map(Number);
  
    // Parse the time part if available, otherwise default to 00:00:00
    const [hours = 0, minutes = 0, seconds = 0] = timePart
      ? timePart.split(":").map(Number)
      : [0, 0, 0];
  
    // Create a new Date object in UTC
    const date = new Date(Date.UTC(year, month - 1, day, hours, minutes, seconds));
  
    // Validate the Date object
    if (isNaN(date.getTime())) {
      console.error(`Invalid date format: ${dateString}`);
      return null;
    }
  
    return date.getTime(); // Return timestamp in milliseconds
  }
  


  export const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.split(",")[1]); // Extract the base64 part
      reader.onerror = (error) => reject(error);
    });
  };

  export const mapDataToFormFields = (data) => {
    const formFieldMappings = {
      'CAPA Type': 'capaType',
      'CAPA Owner': 'capaOwner',
      'CAPA Priority': 'capaPriority',
      'Due Date': 'dueDate',
      'Area': 'area',
      'Sub Area': 'subArea',
      'CAPA Description': 'capaDescription',
    };
  
    const updatedData = {};
  
    // Loop through the form field mappings to transform keys
    Object.keys(formFieldMappings).forEach((formFieldName) => {
      if (data[formFieldMappings[formFieldName]]) {
        updatedData[formFieldName] = data[formFieldMappings[formFieldName]];
      }
    });
  
    return updatedData;
  };
  
  