import React, { useEffect, useState } from "react";
import { Stepper, Step, StepLabel, Box, Grid } from "@mui/material";
import SelectSiteLocation from "../Components/LossControl/SelectSiteLocation";
import ChooseDiscipline from "../Components/LossControl/ChooseDiscipline";
import Observations from "../Components/LossControl/Observations";
import ReviewAndSubmit from "../Components/LossControl/ReviewAndSubmit";
import { v4 as uuidv4 } from "uuid";
import { useLocation } from "react-router-dom";
import { getLossControlDraft } from "../Apis/apiCall";

export default function LossControlPage() {
  const steps = [
    "Select Site Location",
    "Choose Discipline",
    "Observations",
    "Review and Submit",
  ];
  const [activeStep, setActiveStep] = useState(0);
  const [draftedData, setDraftedData] = useState(null);

  /*                        Generating UniqueID for Tour                        */

  const draftID = uuidv4();
  const location = useLocation();

  useEffect(() => {
    if (location.state && location.state.data) {
      fetchDraftedData(location.state.data.draftID);
      setActiveStep(2);
    }
  }, [location.state]);

  const fetchDraftedData = async (id) => {
    try {
      const res = await getLossControlDraft(id);
      setDraftedData(res.data.data);
    } catch (error) {
      alert("error fetching drafted data, please retry!");
    }
  };

  const handleNext = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  return (
    <Box component="main" sx={{ flexGrow: 1, width: "100%" }}>
      <Grid container spacing={2} mb={3} sx={{ width: "100%" }}>
        <Grid item xs={12}>
          <Stepper activeStep={activeStep}>
            {steps?.map((label, index) => (
              <Step key={index} active={activeStep === index}>
                <StepLabel
                  sx={{
                    "& .MuiStepLabel-label": {
                      color: activeStep === index ? "#09A2E3" : "black",
                      fontSize: "16px",
                    },
                  }}
                >
                  {label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Grid>
        <Grid item xs={12}>
          {activeStep === 0 && <SelectSiteLocation handleNext={handleNext} />}
          {activeStep === 1 && (
            <ChooseDiscipline handleNext={handleNext} handleBack={handleBack} />
          )}
          {activeStep === 2 && (
            <Observations
              handleNext={handleNext}
              handleBack={handleBack}
              draftID={draftID}
              draftedData={draftedData}
            />
          )}
          {activeStep === 3 && <ReviewAndSubmit draftedData={draftedData} />}
        </Grid>
      </Grid>
    </Box>
  );
}
