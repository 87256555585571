import React, { useEffect, useState } from 'react';
import { Tabs, Tab, Box, Switch, Typography, Grid, Divider, Chip,Card } from '@mui/material';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import GavelIcon from '@mui/icons-material/Gavel';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import MenuIcon from '@mui/icons-material/Menu';
import EngineeringIcon from '@mui/icons-material/Engineering';
import Slider from "react-slick";
import { styled } from '@mui/system';
import CardDrawer from './CardDrawer';
import DraggableImageDialog from './DraggableImageDialog';
import GppMaybeOutlinedIcon from "@mui/icons-material/GppMaybeOutlined";
import PersonIcon from "@mui/icons-material/Person";
import GavelOutlinedIcon from "@mui/icons-material/GavelOutlined";
import CurrencyRupeeOutlinedIcon from "@mui/icons-material/CurrencyRupeeOutlined";
import MenuBookOutlinedIcon from "@mui/icons-material/MenuBookOutlined";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import TroubleshootIcon from "@mui/icons-material/Troubleshoot";
import AddToDriveIcon from "@mui/icons-material/AddToDrive";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import RootCauseCard from '../Components/RootCauseCard';
import { convertMillisecondsToDate } from "../utils/helper";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import CAPADetailTable from "../Components/CAPADetailTable";
import {cardHeadBG} from "../constant";

// Slider settings
const settings = {
    dots: true, // Show dots below the slider
    infinite: false, // Infinite looping of slides
    speed: 400, // Transition speed
    slidesToShow: 1, // Show 2 slides if subtitle, otherwise 1
    slidesToScroll: 1,
    arrows: false,
    responsive: [
        {
            breakpoint: 1100, // At screen widths below 1100px
            settings: {
                slidesToShow: 1, // Show 1 slide below 1100px
                slidesToScroll: 1,
                infinite: true,
                dots: true
            }
        },
        {
            breakpoint: 600, // At screen widths below 600px
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 1
            }
        },
        {
            breakpoint: 480, // At screen widths below 480px
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
};

// const settings2 = {
//     dots: true, // Show dots below the slider
//     infinite: false, // Infinite looping of slides
//     speed: 400, // Transition speed
//     slidesToShow: 2, // Show 2 slides if subtitle, otherwise 1
//     slidesToScroll: 2,
//     arrows: false,
//     responsive: [
//         {
//             breakpoint: 1100, // At screen widths below 1100px
//             settings: {
//                 slidesToShow: 1, // Show 1 slide below 1100px
//                 slidesToScroll: 1,
//                 infinite: true,
//                 dots: true
//             }
//         },
//         {
//             breakpoint: 600, // At screen widths below 600px
//             settings: {
//                 slidesToShow: 1,
//                 slidesToScroll: 1,
//                 initialSlide: 1
//             }
//         },
//         {
//             breakpoint: 480, // At screen widths below 480px
//             settings: {
//                 slidesToShow: 1,
//                 slidesToScroll: 1
//             }
//         }
//     ]
// };

const StyledBox = styled(Box)({
    '.slick-dots': {
        position: 'absolute',
        bottom: '-22px'
    },
    '.slick-slide': {
        paddingRight: "12px",
        maxHeight: '184px'
    },

});

const tabStyle = {
    padding: '6px 16px',  // Reduces padding
    minHeight: '32px',  // Adjusts height of each tab
    // width: '132px',
    borderTop: '1px solid #ccc',  // Border on top
    // borderRight: index === tabData.length - 1 ? 'none' : '1px solid #ccc',  // Border on right
    borderRight: '1px solid #ccc',  // Border on right
    borderRadius: '0px',  // No rounded corners
    margin: '0px',  // No space between tabs
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            {...other}
            style={{ height: '300px', overflowY: 'auto' }} // Enable vertical scrolling
        >
            <Box
                px={3}
                pl={3}
                sx={{
                    height: '100%',
                    overflowY: 'scroll', // Keep scroll functionality
                    scrollbarWidth: 'none', // Hide scrollbar in Firefox
                    '&::-webkit-scrollbar': {
                        display: 'none', // Hide scrollbar in Chrome, Safari, Edge
                    },
                }}
            >
                <p>{children}</p>
            </Box>
        </div>
    );
}


function TabPanel2(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            {...other}
        //style={{ height: '300px', overflowY: 'auto' }} // Enable vertical scrolling
        >
            <Box
                px={3}
                pl={3}
                sx={{
                    height: '100%',
                    overflowY: 'scroll', // Keep scroll functionality
                    scrollbarWidth: 'none', // Hide scrollbar in Firefox
                    '&::-webkit-scrollbar': {
                        display: 'none', // Hide scrollbar in Chrome, Safari, Edge
                    },
                }}
            >
                {children}
            </Box>
        </div>
    );
}

export default function IncidentViewTabSection({ data, title, level, mode }) {
    console.log("rahul", data, level);
    const [outerTab, setOuterTab] = useState(0);
    const [step, setStep] = useState(0);
    // States for nested tabs (second-level tabs)
    const [value1, setValue1] = useState(0);
    const [value2, setValue2] = useState(0);
    const [value3, setValue3] = useState(0);

    const [openDialog, setOpenDialog] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);

    useEffect(()=>{
        setStep(0);
    },[outerTab])



    // Handle change functions for outer and inner tabs
    const handleOuterChange = (event, newValue) => {
        setOuterTab(newValue);
    };



    const handleChange1 = (event, newValue) => {
        setValue1(newValue);
    };

    const handleChange2 = (event, newValue) => {
        setValue2(newValue);
    };

    const handleChange3 = (event, newValue) => {
        setValue3(newValue);
    };





    const handleClickImage = (image) => {
        setSelectedImage(image);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setSelectedImage(null);
    };

    const handleStepchange = (value) => {
        setStep(value);
    };

    const getFileType = (filename) => {
        const extension = filename?.split('.').pop().toLowerCase();
        if (['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'].includes(extension)) {
            return 'image';
        } else if (['pdf'].includes(extension)) {
            return 'pdf';
        } else if (['mp4', 'mov', 'webm', 'ogg', 'avi'].includes(extension)) {
            return 'video';
        }
        return 'unknown'; // Return 'unknown' for unsupported file types
    };

    const resources = [
        ...(data?.PersonnelDetail || []).filter(item => item), // Check if PersonnelDetail exists and filter out falsy values
        ...(data?.VehicleDetail || []).filter(item => item),  // Check if VehicleDetail exists and filter out falsy values
        ...(data?.EquipmentDetail || []).filter(item => item) // Check if EquipmentDetail exists and filter out falsy values
    ];

    const cardData = (data?.rootCauseAnalysis && typeof data.rootCauseAnalysis === 'object')
        ? [
            ...data.rootCauseAnalysis.rootCause.map(cause => ({
                title: "Root Cause",  // Static title for rootCause
                causeType: cause.cause,
                description: cause.description
            })),
            ...data.rootCauseAnalysis.indirectCause.map(cause => ({
                title: "Indirect Cause",  // Static title for indirectCause
                causeType: cause.cause,
                description: cause.description
            })),
            ...data.rootCauseAnalysis.immediateCause.map(cause => ({
                title: "Immediate Cause",  // Static title for immediateCause
                causeType: cause.cause,
                description: cause.description
            }))
        ]
        : [];  // Fallback to an empty array if rootCauseAnalysis is not an object or is missing

    console.log(cardData);

    //----------------regulatory section tab secion 3 last one--------------

    const renderTabs3 = (value, handleChange) => (
        <>
            <Tabs
                value={value}
                onChange={handleChange}
                aria-label="icon tabs"
                variant="fullWidth"
                textColor="primary"
                indicatorColor="primary"
                sx={{
                    borderBottom: 1,
                    borderColor: 'divider',
                    minHeight: '32px',  // Adjusts the height of the tab bar
                }}
            >
                {/*genaral Tab */}

                <Tab
                    icon={
                        <>
                            {value === 0 ? (
                                <Box display='flex' alignItems='center' sx={{ position: 'relative', width: '100%' }}>
                                    <GavelOutlinedIcon />
                                    <Typography variant='body2' sx={{ color: '#09A2E3', ml: '4px' }}>Regulatory</Typography>
                                    <Switch size="small"
                                        checked={true} disabled />
                                </Box>
                            ) : (
                                <Box display='flex' alignItems='center' gap={0.5}>
                                    <GavelOutlinedIcon />
                                    <Typography variant='body2' sx={{ color: '#525966' }}>Regulatory</Typography>
                                </Box>
                            )}
                        </>
                    }
                    iconPosition="start"
                    sx={{ ...tabStyle, backgroundColor: value === 0 ? 'rgba(9, 162, 227, 0.12)' : 'transparent' }}
                />

                {/* Resources Tab */}
                <Tab
                    icon={
                        <>
                            {value === 1 ? (
                                <Box display='flex' alignItems='center' sx={{ position: 'relative', width: '100%' }}>
                                    <PersonIcon />
                                    <Typography variant='body2' sx={{ color: '#09A2E3', ml: '4px' }}>Responsibility</Typography>
                                    <Switch size="small"
                                        checked={true} disabled />
                                </Box>
                            ) : (
                                <Box display='flex' alignItems='center' gap={0.5}>
                                    <PersonIcon />
                                    <Typography variant='body2' sx={{ color: '#525966' }}>Responsibility</Typography>
                                </Box>
                            )}
                        </>
                    }
                    iconPosition="start"
                    sx={{ ...tabStyle, backgroundColor: value === 1 ? 'rgba(9, 162, 227, 0.12)' : 'transparent' }}
                />

                {/* Evidence Tab */}
                {/* CAPA Tab */}
                <Tab
                    icon={
                        <>
                            {value === 2 ? (
                                <Box display='flex' alignItems='center' sx={{ position: 'relative', width: '100%' }}>
                                    <CurrencyRupeeOutlinedIcon />
                                    <Typography variant='body2' sx={{ color: '#09A2E3', ml: '4px' }}>Compensation</Typography>
                                    <Switch size="small"
                                        checked={true} disabled />
                                </Box>
                            ) : (
                                <Box display='flex' alignItems='center' gap={0.5}>
                                    <CurrencyRupeeOutlinedIcon />
                                    <Typography variant='body2' sx={{ color: '#525966' }}>Compensation</Typography>
                                </Box>
                            )}
                        </>
                    }
                    iconPosition="start"
                    sx={{ ...tabStyle, backgroundColor: value === 2 ? 'rgba(9, 162, 227, 0.12)' : 'transparent' }}
                />

                {/* Regulatory Tab */}
                <Tab
                    icon={
                        <>
                            {value === 3 ? (
                                <Box display='flex' alignItems='center' sx={{ position: 'relative', width: '100%' }}>
                                    <AddAPhotoIcon />
                                    <Typography variant='body2' sx={{ color: '#09A2E3', ml: '4px' }}>Evidence</Typography>
                                    <Switch size="small"
                                        checked={true} disabled />
                                </Box>
                            ) : (
                                <Box display='flex' alignItems='center' gap={0.5}>
                                    <AddAPhotoIcon />
                                    <Typography variant='body2' sx={{ color: '#525966' }}>Evidence</Typography>
                                </Box>
                            )}
                        </>
                    }
                    iconPosition="start"
                    sx={{ ...tabStyle, backgroundColor: value === 3 ? 'rgba(9, 162, 227, 0.12)' : 'transparent', borderRight: 'none' }}
                />

            </Tabs>

            <TabPanel>

                {/* General tab panel */}
                {value === 0 ?
                    (
                        <>
                            <Grid container spacing={1} sx={{ px: "24px" }}>
                                <Grid item xs={12} >
                                    <Grid container spacing={1} sx={{ lineHeight: 0.2 }}>
                                        {data && data?.regulatoryInfo ? (
                                            <>
                                                <Grid item xs={12} md={12}>
                                                    <Box display="flex" justifyContent="center" gap={2} alignItems="center" marginBottom={1} sx={{ zIndex: "1300" }}>
                                                        <FiberManualRecordIcon onClick={() => handleStepchange(0)} sx={{ color: step === 0 ? "#0AA2E3" : "#AEAEB1", fontSize: '12px' }} />
                                                        <FiberManualRecordIcon onClick={() => handleStepchange(1)} sx={{ color: step === 1 ? "#0AA2E3" : "#AEAEB1", fontSize: '12px' }} />
                                                    </Box>
                                                </Grid>
                                                {step === 0 && (
                                                    <>
                                                        {/* Regulatory Info details */}
                                                        <Grid item xs={6}>
                                                            <Typography variant='body2' sx={{ color: "#1E1E1E", fontWeight: 600 }}>Action taken to prevent similar accident:</Typography>
                                                            <Typography variant='caption' sx={{ color: "#09A2E3" }}>
                                                                {data?.regulatoryInfo?.["Action taken to prevent similar accident"] || "N/A"}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography variant='body2' sx={{ color: "#1E1E1E", fontWeight: 600 }}>Date of Immediate last Accident:</Typography>
                                                            <Typography variant='caption' sx={{ color: "#09A2E3" }}>
                                                                {convertMillisecondsToDate(data?.regulatoryInfo?.["Date of Immediate last Accident"]) || "N/A"}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography variant='body2' sx={{ color: "#1E1E1E", fontWeight: 600 }}>Fatalities Count (Contract personnel):</Typography>
                                                            <Typography variant='caption' sx={{ color: "#09A2E3" }}>
                                                                {data?.regulatoryInfo?.["Fatalities Count (Contract personnel)"] || "N/A"}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography variant='body2' sx={{ color: "#1E1E1E", fontWeight: 600 }}>How accident could have been avoided?</Typography>
                                                            <Typography variant='caption' sx={{ color: "#09A2E3" }}>
                                                                {data?.regulatoryInfo?.["How accident could have been avoided?"] || "N/A"}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography variant='body2' sx={{ color: "#1E1E1E", fontWeight: 600 }}>Immediate last accident Type:</Typography>
                                                            <Typography variant='caption' sx={{ color: "#09A2E3" }}>
                                                                {data?.regulatoryInfo?.["Immediate last accident Type"] || "N/A"}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography variant='body2' sx={{ color: "#1E1E1E", fontWeight: 600 }}>Incident Cause:</Typography>
                                                            <Typography variant='caption' sx={{ color: "#09A2E3" }}>
                                                                {data?.regulatoryInfo?.["Incident Cause"] || "N/A"}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography variant='body2' sx={{ color: "#1E1E1E", fontWeight: 600 }}>Injuries Count (Contract personnel):</Typography>
                                                            <Typography variant='caption' sx={{ color: "#09A2E3" }}>
                                                                {data?.regulatoryInfo?.["Injuries Count (Contract personnel)"] || "N/A"}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography variant='body2' sx={{ color: "#1E1E1E", fontWeight: 600 }}>Injuries Count (Employee):</Typography>
                                                            <Typography variant='caption' sx={{ color: "#09A2E3" }}>
                                                                {data?.regulatoryInfo?.["Injuries Count (Employee)"] || "N/A"}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography variant='body2' sx={{ color: "#1E1E1E", fontWeight: 600 }}>No. of people affected by gas:</Typography>
                                                            <Typography variant='caption' sx={{ color: "#09A2E3" }}>
                                                                {data?.regulatoryInfo?.["No. of people affected by gas"] || "N/A"}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography variant='body2' sx={{ color: "#1E1E1E", fontWeight: 600 }}>Reason for categorization:</Typography>
                                                            <Typography variant='caption' sx={{ color: "#09A2E3" }}>
                                                                {data?.regulatoryInfo?.["Reason for categorization"] || "N/A"}
                                                            </Typography>
                                                        </Grid>

                                                    </>
                                                )}

                                                {step === 1 &&
                                                    <>
                                                        <Grid item xs={5}>
                                                            <Typography variant='body2' sx={{ color: "#1E1E1E", fontWeight: 600 }}>Recommendation by ISO or any other:</Typography>
                                                            <Typography variant='caption' sx={{ color: "#09A2E3" }}>
                                                                {data?.regulatoryInfo?.["Recommendation by ISO or any other"] || "N/A"}
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={7}>
                                                            <Typography variant='body2' sx={{ color: "#1E1E1E", fontWeight: 600 }}>Whether internal investigation report to OISD:</Typography>
                                                            <Typography variant='caption' sx={{ color: "#09A2E3" }}>
                                                                {data?.regulatoryInfo?.["Whether internal investigation report to OISD"] || "N/A"}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={5}>
                                                            <Typography variant='body2' sx={{ color: "#1E1E1E", fontWeight: 600 }}>State whether danger is still present:</Typography>
                                                            <Typography variant='caption' sx={{ color: "#09A2E3" }}>
                                                                {data?.regulatoryInfo?.["State whether danger is still present"] || "N/A"}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={7}>
                                                            <Typography variant='body2' sx={{ color: "#1E1E1E", fontWeight: 600 }}>Whether internal investigation report is submitted to PNGRB:</Typography>
                                                            <Typography variant='caption' sx={{ color: "#09A2E3" }}>
                                                                {data?.regulatoryInfo?.["Whether internal investigation report is submitted to PNGRB"] || "N/A"}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={5}>
                                                            <Typography variant='body2' sx={{ color: "#1E1E1E", fontWeight: 600 }}>Sector:</Typography>
                                                            <Typography variant='caption' sx={{ color: "#09A2E3" }}>
                                                                {data?.regulatoryInfo?.["Sector"] || "N/A"}
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={7}>
                                                            <Typography variant='body2' sx={{ color: "#1E1E1E", fontWeight: 600 }}>Whether same incident happen at same location in the past:</Typography>
                                                            <Typography variant='caption' sx={{ color: "#09A2E3" }}>
                                                                {data?.regulatoryInfo?.["Whether same incident happen at same location in the past"] || "N/A"}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={5}>
                                                            <Typography variant='body2' sx={{ color: "#1E1E1E", fontWeight: 600 }}>Loss of Manshifts (in hours):</Typography>
                                                            <Typography variant='caption' sx={{ color: "#09A2E3" }}>
                                                                {data?.regulatoryInfo?.["loss of Manshifts (in hours)"] || "N/A"}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={7}>
                                                            <Typography variant='body2' sx={{ color: "#1E1E1E", fontWeight: 600 }}>Loss of production (in tonnes):</Typography>
                                                            <Typography variant='caption' sx={{ color: "#09A2E3" }}>
                                                                {data?.regulatoryInfo?.["loss of production (in tonnes)"] || "N/A"}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={5}>
                                                            <Typography variant='body2' sx={{ color: "#1E1E1E", fontWeight: 600 }}>Was the incident avoidable?</Typography>
                                                            <Typography variant='caption' sx={{ color: "#09A2E3" }}>
                                                                {data?.regulatoryInfo?.["was the incident avoidable?"] || "N/A"}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={7}>
                                                            <Typography variant='body2' sx={{ color: "#1E1E1E", fontWeight: 600 }}>Whether Plant shut down/ caused outrage of the facility?</Typography>
                                                            <Typography variant='caption' sx={{ color: "#09A2E3" }}>
                                                                {data?.regulatoryInfo?.["whether Plant shut down/ cause outrage of the facility?"] || "N/A"}
                                                            </Typography>
                                                        </Grid>
                                                    </>
                                                }
                                            </>



                                        ) : (
                                            <Typography variant='body2' sx={{ color: 'red' }}>No data available</Typography>
                                        )}
                                    </Grid>

                                </Grid>

                            </Grid>
                        </>
                    ) : value === 1 ? (
                        <>
                            {data?.incidentResponsibility?.length > 0 ? (<CardDrawer cardData={data?.incidentResponsibility} subtitle={"Incident Info"} title={"IncidentResponsibility"} />) : (
                                <Typography variant='body2' sx={{ color: 'red' }}>No data available</Typography>
                            )}
                        </>
                    ) : value === 2 ? (
                        <>
                            {data?.compensationInfo?.length > 0 ? (<CardDrawer cardData={data?.compensationInfo} subtitle={"Incident Info"} title={"CompensationInfo"} />) : (
                                <Typography variant='body2' sx={{ color: 'red' }}>No data available</Typography>
                            )}
                        </>
                    ) : value === 3 ? (
                        <>
                            {data?.investigationEvidence?.length > 0 ? (<CardDrawer cardData={data?.investigationEvidence} subtitle={"Incident Info"} title={"InvestigationEvidence"} />) : (
                                <Typography variant='body2' sx={{ color: 'red' }}>No data available</Typography>
                            )}

                        </>
                    ) : (
                        <></>
                    )}
            </TabPanel>
        </>
    );

    //----------------migitable tab secion 2 one --------------
    const renderTabs2 = (value, handleChange) => (
        <>
            <Tabs
                value={value}
                onChange={handleChange}
                aria-label="icon tabs"
                variant="fullWidth"
                textColor="primary"
                indicatorColor="primary"
                sx={{
                    borderBottom: 1,
                    borderColor: 'divider',
                    minHeight: '32px',  // Adjusts the height of the tab bar
                }}
            >
                {/*genaral Tab */}

                <Tab
                    icon={
                        <>
                            {value === 0 ? (
                                <Box display='flex' alignItems='center' sx={{ position: 'relative', width: '100%' }}>
                                    <GppMaybeOutlinedIcon />
                                    <Typography variant='body2' sx={{ color: '#09A2E3', ml: '4px' }}>Mitigation</Typography>
                                    <Switch size="small"
                                        checked={true} disabled />
                                </Box>
                            ) : (
                                <Box display='flex' alignItems='center' gap={0.5}>
                                    <GppMaybeOutlinedIcon />
                                    <Typography variant='body2' sx={{ color: '#525966' }}>Mitigation</Typography>
                                </Box>
                            )}
                        </>
                    }
                    iconPosition="start"
                    sx={{ ...tabStyle, backgroundColor: value === 0 ? 'rgba(9, 162, 227, 0.12)' : 'transparent' }}
                />

                {/* Resources Tab */}
                <Tab
                    icon={
                        <>
                            {value === 1 ? (
                                <Box display='flex' alignItems='center' sx={{ position: 'relative', width: '100%' }}>
                                    <MenuBookOutlinedIcon />
                                    <Typography variant='body2' sx={{ color: '#09A2E3', ml: '4px' }}>Lessons </Typography>
                                    <Switch size="small"
                                        checked={true} disabled />
                                </Box>
                            ) : (
                                <Box display='flex' alignItems='center' gap={0.5}>
                                    <MenuBookOutlinedIcon />
                                    <Typography variant='body2' sx={{ color: '#525966' }}>Lessons</Typography>
                                </Box>
                            )}
                        </>
                    }
                    iconPosition="start"
                    sx={{ ...tabStyle, backgroundColor: value === 1 ? 'rgba(9, 162, 227, 0.12)' : 'transparent' }}
                />

                {/* Evidence Tab */}
                {/* CAPA Tab */}
                <Tab
                    icon={
                        <>
                            {value === 2 ? (
                                <Box display='flex' alignItems='center' sx={{ position: 'relative', width: '100%' }}>
                                    <TroubleshootIcon />
                                    <Typography variant='body2' sx={{ color: '#09A2E3', whiteSpace: "nowrap" }}>Root Cause </Typography>
                                    <Switch size="small"
                                        checked={true} disabled />
                                </Box>
                            ) : (
                                <Box display='flex' alignItems='center' gap={0.5}>
                                    <TroubleshootIcon />
                                    <Typography variant='body2' sx={{ color: '#525966' }}>Root Cause </Typography>
                                </Box>
                            )}
                        </>
                    }
                    iconPosition="start"
                    sx={{ ...tabStyle, backgroundColor: value === 2 ? 'rgba(9, 162, 227, 0.12)' : 'transparent' }}
                />

                {/* Regulatory Tab */}
                <Tab
                    icon={
                        <>
                            {value === 3 ? (
                                <Box display='flex' alignItems='center' sx={{ position: 'relative', width: '100%' }}>
                                    <SupervisedUserCircleIcon />
                                    <Typography variant='body2' sx={{ color: '#09A2E3', ml: '4px' }}>Witness</Typography>
                                    <Switch size="small"
                                        checked={true} disabled />
                                </Box>
                            ) : (
                                <Box display='flex' alignItems='center' gap={0.5}>
                                    <SupervisedUserCircleIcon />
                                    <Typography variant='body2' sx={{ color: '#525966' }}>Witness</Typography>
                                </Box>
                            )}
                        </>
                    }
                    iconPosition="start"
                    sx={{ ...tabStyle, backgroundColor: value === 3 ? 'rgba(9, 162, 227, 0.12)' : 'transparent' }}
                />


                <Tab
                    icon={
                        <>
                            {value === 4 ? (
                                <Box display='flex' alignItems='center' sx={{ position: 'relative', width: '100%' }}>
                                    <AddToDriveIcon />
                                    <Typography variant='body2' sx={{ color: '#09A2E3', ml: '4px' }}>Factors</Typography>
                                    <Switch size="small"
                                        checked={true} disabled />
                                </Box>
                            ) : (
                                <Box display='flex' alignItems='center' gap={0.5}>
                                    <AddToDriveIcon />
                                    <Typography variant='body2' sx={{ color: '#525966' }}>Factors</Typography>
                                </Box>
                            )}
                        </>
                    }
                    iconPosition="start"
                    sx={{ ...tabStyle, backgroundColor: value === 4 ? 'rgba(9, 162, 227, 0.12)' : 'transparent', borderRight: 'none' }}
                />

            </Tabs>

            <TabPanel>

                {/* General tab panel */}
                {value === 0 ?
                    (
                        <>
                            {data?.mitigationMeasures?.length > 0 ? (<CardDrawer cardData={data.mitigationMeasures} subtitle={"Incident Info"} title={"MitigationMeasures"} />) : (
                                <Typography variant='body2' sx={{ color: 'red' }}>No data available</Typography>
                            )}
                        </>
                    ) : value === 1 ? (
                        <>
                            {data?.lessonsLearned?.length > 0 ? (<CardDrawer cardData={data?.lessonsLearned} subtitle={"Incident Info"} title={"LessonsLearned"} />) : (
                                <Typography variant='body2' sx={{ color: 'red' }}>No data available</Typography>
                            )}
                        </>
                    ) : value === 2 ? (
                        <>
                            {cardData?.length > 0 ? (<CardDrawer cardData={cardData} subtitle={"RootCauseCard"} />) : (
                                <Typography variant='body2' sx={{ color: 'red' }}>No data available</Typography>
                            )}
                        </>
                    ) : value === 3 ? (
                        <>
                            {data?.witnessStatement?.length > 0 ? (<CardDrawer cardData={data?.witnessStatement} subtitle={"Incident Info"} title={"WitnessStatement"} />) : (
                                <Typography variant='body2' sx={{ color: 'red' }}>No data available</Typography>
                            )}
                        </>
                    ) :
                        value === 4 ? (
                            <>
                                {data?.contributingFactor?.length > 0 ? (<CardDrawer cardData={data?.contributingFactor} subtitle={"Incident Info"} title={"ContributingFactor"} />) : (
                                    <Typography variant='body2' sx={{ color: 'red' }}>No data available</Typography>
                                )}
                            </>
                        ) : (
                            <></>
                        )}
            </TabPanel>
        </>
    );


    const renderMedia = (file, index) => {
        const fileType = getFileType(file?.files[0]);  // Assuming file?.files[0]?.name contains the file name
        const fileUrl = file?.files[0]; // The URL in the `files[]` array
        const category = file?.Category; // Assuming file.Category is the media category (e.g., "Photo", "Video", "Document")

        // Check if fileUrl exists before rendering
        if (!fileUrl) {
            console.error('File URL is missing for file:', file);
            return null;  // Return null if no file URL is found
        }

        // Render based on category and file type
        if (category === "Photo" && fileType === 'image') {
            // For images, use the URL directly in the 'src' attribute
            return (
                <img
                    src={fileUrl}
                    alt={`Slide ${index}`}
                    style={{ width: '80%', borderRadius: '4px', height: '170px',cursor: 'pointer' }}
                    onClick={() => handleClickImage(fileUrl)}  // Assuming `handleClickImage` is defined
                />
            );
        } else if (category === "Document" && fileType === 'pdf') {
            // For PDFs, use the 'embed' tag to display the document
            return (
                <embed
                    src={fileUrl}
                    type="application/pdf"
                    style={{ width: '100%', height: '170px', borderRadius: '4px', cursor: 'pointer' }}
                    onClick={() => handleClickImage(fileUrl)}  // Handle click (define your function)
                />
            );
        } else if (category === "Video" && fileType === 'video') {
            // For videos, use the 'video' tag to embed the video
            return (
                <video
                    controls
                    style={{ width: '100%', borderRadius: '4px', cursor: 'pointer' , height: '170px'}}
                    onClick={() => handleClickImage(fileUrl)}  // Handle click
                >
                    <source src={fileUrl} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            );
        }

        // If no match for category or file type, return null
        return null;
    };

    const renderTabs1 = (value, handleChange) => (
        <>
            <Tabs
                value={value}
                onChange={handleChange}
                aria-label="icon tabs"
                variant="fullWidth"
                textColor="primary"
                indicatorColor="primary"
                sx={{
                    borderBottom: 1,
                    borderColor: 'divider',
                    minHeight: '32px',  // Adjusts the height of the tab bar
                }}
            >
                {/*genaral Tab */}

                <Tab
                    icon={
                        <>
                            {value === 0 ? (
                                <Box display='flex' alignItems='center' sx={{ position: 'relative', width: '100%' }}>
                                    <PersonOutlineOutlinedIcon />
                                    <Typography variant='body2' sx={{ color: '#09A2E3', ml: '4px' }}>General</Typography>
                                    <Switch size="small"
                                        checked={true} disabled />
                                </Box>
                            ) : (
                                <Box display='flex' alignItems='center' gap={0.5}>
                                    <PersonOutlineOutlinedIcon />
                                    <Typography variant='body2' sx={{ color: '#525966' }}>General</Typography>
                                </Box>
                            )}
                        </>
                    }
                    iconPosition="start"
                    sx={{ ...tabStyle, backgroundColor: value === 0 ? 'rgba(9, 162, 227, 0.12)' : 'transparent' }}
                />

                {/* Resources Tab */}
                <Tab
                    icon={
                        <>
                            {value === 1 ? (
                                <Box display='flex' alignItems='center' sx={{ position: 'relative', width: '100%' }}>
                                    <EngineeringIcon />
                                    <Typography variant='body2' sx={{ color: '#09A2E3', ml: '4px' }}>Resources</Typography>
                                    <Switch size="small"
                                        checked={true} disabled />
                                </Box>
                            ) : (
                                <Box display='flex' alignItems='center' gap={0.5}>
                                    <EngineeringIcon />
                                    <Typography variant='body2' sx={{ color: '#525966' }}>Resources</Typography>
                                </Box>
                            )}
                        </>
                    }
                    iconPosition="start"
                    sx={{ ...tabStyle, backgroundColor: value === 1 ? 'rgba(9, 162, 227, 0.12)' : 'transparent' }}
                />

                {/* Evidence Tab */}
                {/* CAPA Tab */}
                <Tab
                    icon={
                        <>
                            {value === 2 ? (
                                <Box display='flex' alignItems='center' sx={{ position: 'relative', width: '100%' }}>
                                    <PendingActionsIcon />
                                    <Typography variant='body2' sx={{ color: '#09A2E3', ml: '4px' }}>CAPA</Typography>
                                    <Switch size="small"
                                        checked={true} disabled />
                                </Box>
                            ) : (
                                <Box display='flex' alignItems='center' gap={0.5}>
                                    <PendingActionsIcon />
                                    <Typography variant='body2' sx={{ color: '#525966' }}>CAPA</Typography>
                                </Box>
                            )}
                        </>
                    }
                    iconPosition="start"
                    sx={{ ...tabStyle, backgroundColor: value === 2 ? 'rgba(9, 162, 227, 0.12)' : 'transparent' }}
                />

                {/* Regulatory Tab */}
                <Tab
                    icon={
                        <>
                            {value === 3 ? (
                                <Box display='flex' alignItems='center' sx={{ position: 'relative', width: '100%' }}>
                                    <GavelIcon />
                                    <Typography variant='body2' sx={{ color: '#09A2E3', ml: '4px' }}>Regulatory</Typography>
                                    <Switch size="small"
                                        checked={true} disabled />
                                </Box>
                            ) : (
                                <Box display='flex' alignItems='center' gap={0.5}>
                                    <GavelIcon />
                                    <Typography variant='body2' sx={{ color: '#525966' }}>Regulatory</Typography>
                                </Box>
                            )}
                        </>
                    }
                    iconPosition="start"
                    sx={{ ...tabStyle, backgroundColor: value === 3 ? 'rgba(9, 162, 227, 0.12)' : 'transparent', borderRight: 'none' }}
                />

            </Tabs>

            <TabPanel>

                {/* General tab panel */}
                {value === 0 ?
                    (
                        <>
                            <Grid container spacing={2} sx={{ px: "24px" }}> {/* Increased spacing for better layout */}
                                {/* Left Section */}
                                <Grid item xs={12} md={12}>
                                    <Grid container spacing={1} sx={{ lineHeight: 0.2 }}>
                                        {data && data ? (
                                            <>

                                                <Grid item xs={12} md={12}>
                                                    <Box display="flex" justifyContent="center" gap={2} alignItems="center" marginBottom={1} sx={{ zIndex: "1300" }}>
                                                        <FiberManualRecordIcon onClick={() => handleStepchange(0)} sx={{ color: step === 0 ? "#0AA2E3" : "#AEAEB1", fontSize: '12px' }} />
                                                        <FiberManualRecordIcon onClick={() => handleStepchange(1)} sx={{ color: step === 1 ? "#0AA2E3" : "#AEAEB1", fontSize: '12px' }} />
                                                        <FiberManualRecordIcon onClick={() => handleStepchange(2)} sx={{ color: step === 2 ? "#0AA2E3" : "#AEAEB1", fontSize: '12px' }} />
                                                    </Box>
                                                </Grid>
                                                {step === 0 &&
                                                    <>
                                                        <Grid item xs={3}>
                                                            <Typography variant='body2' sx={{ color: "#1E1E1E", fontWeight: 600 }} >Incident Time</Typography>
                                                            <Typography variant='caption' sx={{ color: "#09A2E3" }}>
                                                                {convertMillisecondsToDate(data?.["Incident Time"]) || "N/A"}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <Typography variant='body2' sx={{ color: "#1E1E1E", fontWeight: 600 }} >Reporting Time</Typography>
                                                            <Typography variant='caption' sx={{ color: "#09A2E3" }}>
                                                                {convertMillisecondsToDate(data?.["Report Time"]) || "N/A"}
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={3}>
                                                            <Typography variant='body2' sx={{ color: "#1E1E1E", fontWeight: 600 }} >Location</Typography>
                                                            <Typography variant='caption' sx={{ color: "#09A2E3" }}>
                                                                {data?.["Incident Location"] || "N/A"}
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={3}>
                                                            <Typography variant='body2' sx={{ color: "#1E1E1E", fontWeight: 600 }} >Department</Typography>
                                                            <Typography variant='caption' sx={{ color: "#09A2E3" }}>
                                                                {data?.Department || "N/A"}
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={3}>
                                                            <Typography variant='body2' sx={{ color: "#1E1E1E", fontWeight: 600 }} >Area</Typography>
                                                            <Typography variant='caption' sx={{ color: "#09A2E3" }}>
                                                                {data?.Area || "N/A"}
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={3}>
                                                            <Typography variant='body2' sx={{ color: "#1E1E1E", fontWeight: 600 }} >Sub Area</Typography>
                                                            <Typography variant='caption' sx={{ color: "#09A2E3" }}>
                                                                {data?.["Sub Area"] || "N/A"}
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={3}>
                                                            <Typography variant='body2' sx={{ color: "#1E1E1E", fontWeight: 600 }} >Activity</Typography>
                                                            <Typography variant='caption' sx={{ color: "#09A2E3" }}>
                                                                {data?.Activity || "N/A"}
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={3}>
                                                            <Typography variant='body2' sx={{ color: "#1E1E1E", fontWeight: 600 }} >Sub Activity</Typography>
                                                            <Typography variant='caption' sx={{ color: "#09A2E3" }}>
                                                                {data?.["Sub Activity"] || "N/A"}
                                                            </Typography>
                                                        </Grid>


                                                        <Grid item xs={3}>
                                                            <Typography variant='body2' sx={{ color: "#1E1E1E", fontWeight: 600 }} >
                                                                Operation Status
                                                            </Typography>
                                                            <Typography variant='caption' sx={{ color: "#09A2E3" }}>
                                                                {data?.["Operation Status"] ?? "N/A"}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <Typography variant='body2' sx={{ color: "#1E1E1E", fontWeight: 600 }} >
                                                                Immediate Last Accident
                                                            </Typography>
                                                            <Typography variant='caption' sx={{ color: "#09A2E3" }}>
                                                                {data?.["Immediate Last Accident"] ?? "N/A"}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <Typography variant='body2' sx={{ color: "#1E1E1E", fontWeight: 600 }} >
                                                                Shift
                                                            </Typography>
                                                            <Typography variant='caption' sx={{ color: "#09A2E3" }}>
                                                                {data?.Shift ?? "N/A"}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <Typography variant='body2' sx={{ color: "#1E1E1E", fontWeight: 600 }} >
                                                                Cause of Accident
                                                            </Typography>
                                                            <Typography variant='caption' sx={{ color: "#09A2E3" }}>
                                                                {data?.["Cause of Accident"] ?? "N/A"}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <Typography variant='body2' sx={{ color: "#1E1E1E", fontWeight: 600 }} >
                                                                Losses Type
                                                            </Typography>
                                                            <Typography variant='caption' sx={{ color: "#09A2E3" }}>
                                                                {data?.["Losses Type"] ?? "N/A"}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <Typography variant='body2' sx={{ color: "#1E1E1E", fontWeight: 600 }} >
                                                                Date of Accident
                                                            </Typography>
                                                            <Typography variant='caption' sx={{ color: "#09A2E3" }}>
                                                                {data?.["Date of Accident"] ? convertMillisecondsToDate(data?.["Date of Accident"]) : "N/A"}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography variant='body2' sx={{ color: "#1E1E1E", fontWeight: 600 }} >
                                                                General Info
                                                            </Typography>
                                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                                                                {Array.isArray(data?.GeneralInfo) && data?.GeneralInfo?.length > 0
                                                                    ? data?.GeneralInfo
                                                                        .filter(value =>
                                                                            typeof value === 'string' &&
                                                                            value.trim() !== '' &&
                                                                            value !== 'Other' // Exclude 'Others'
                                                                        )
                                                                        .map((value, index) => (
                                                                            <Chip
                                                                                key={index}
                                                                                label={value}
                                                                                size="small"

                                                                                sx={{
                                                                                    color: "#fff",
                                                                                    fontSize: '12px',
                                                                                    height: '28px', // Adjust height for better text visibility
                                                                                    lineHeight: '20px', // Set lineHeight for proper vertical alignment
                                                                                    padding: '0 8px', // Add padding for better spacing
                                                                                    bgcolor: "#09A2E3",
                                                                                    overflow: "visible",
                                                                                }} />
                                                                        ))
                                                                    : <Chip label="N/A" size="small" sx={{ fontSize: '10px', height: '24px' }} />
                                                                }

                                                                {/* Render 'Others' as a chip if it exists */}
                                                                {data?.Others && (
                                                                    <Chip
                                                                        label={data?.Others}
                                                                        size="small"
                                                                        sx={{
                                                                            color: "#fff",
                                                                            fontSize: '12px',
                                                                            height: '28px', // Adjust height for better text visibility
                                                                            lineHeight: '20px', // Set lineHeight for proper vertical alignment
                                                                            padding: '0 8px', // Add padding for better spacing
                                                                            bgcolor: "#09A2E3",
                                                                            overflow: "visible",
                                                                        }} />
                                                                )}
                                                            </Box>
                                                        </Grid>
                                                    </>
                                                }
                                                {step === 1 &&
                                                    // <Grid container spacing={1} sx={{ mt: 2, lineHeight: 0.2 }}>
                                                    <Grid container spacing={1} py={1} px={1}>
                                                        {/* Short Summary: Full-width row */}
                                                        <Grid item xs={12}>
                                                            <Typography variant="body2" sx={{ fontWeight: 600, color: "#09A2E3" }}>
                                                                Short Summary
                                                            </Typography>
                                                            <Typography variant="caption" sx={{ color: "#818181" }}>
                                                                {data && data?.["Incident Short Summary"] ? data["Incident Short Summary"] : "N/A"}
                                                            </Typography>
                                                        </Grid>

                                                        {/* Row with two summary cards, each taking up 6 columns */}
                                                        <Grid container item xs={12} spacing={2} >
                                                            {/* Card 1: Solid background color */}
                                                            <Grid item xs={6}>
                                                                <Card sx={{
                                                                    backgroundColor: 'rgba(9, 162, 227, 0.1)',
                                                                    padding: 2,
                                                                    minHeight: "150px",
                                                                    maxHeight: "150px",
                                                                    boxShadow: "none",
                                                                    borderRadius: "8px"
                                                                }}>
                                                                    <Typography variant="body2" sx={{ fontWeight: 600, color: "#1E1E1E" }}>
                                                                        Incident Summary
                                                                    </Typography>
                                                                    <Typography variant="caption" sx={{ color: "#818181" }}>
                                                                        {data && data?.["Incident Summary"] ? data["Incident Summary"] : "N/A"}
                                                                    </Typography>
                                                                </Card>
                                                            </Grid>

                                                            {/* Card 2: Background image */}
                                                            <Grid item xs={6}>
                                                                <Card sx={{
                                                                   minHeight: "150px",
                                                                   maxHeight: "150px",
                                                                    boxShadow: "none",
                                                                    borderRadius: "8px",
                                                                    position: "relative", // Set the card to relative positioning
                                                                    padding: 2,
                                                                    backgroundColor: '#09A2E3', // Set a background color for the overlay
                                                                    zIndex: 1, // Ensure the content appears above the background image
                                                                }}>
                                                                    {/* Background image */}
                                                                    <img
                                                                        src={cardHeadBG} // Replace with your image path
                                                                        alt="bg"
                                                                        style={{
                                                                            position: "absolute",
                                                                            bottom: "-410px",
                                                                            left: "-360px",
                                                                            width: "750px",
                                                                            height: "700px",
                                                                            zIndex: 0, // Ensure the image stays behind the card content
                                                                        }}
                                                                    />

                                                                    <Typography variant="body2" sx={{ fontWeight: 600, color: "#FFF" }}>
                                                                        Immediate Measures
                                                                    </Typography>
                                                                    <Typography variant="caption" sx={{ color: "#FFF" }}>
                                                                        {data && data?.["Immediate Measures"] ? data["Immediate Measures"] : "N/A"}
                                                                    </Typography>
                                                                </Card>

                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                }
                                                {step === 2 && (
                                                    <StyledBox>
                                                        {data?.EvidenceDetail?.length > 1 ? (
                                                            <Slider {...settings}>
                                                                {data?.EvidenceDetail?.map((file, index) => (
                                                                    <React.Fragment key={index}>
                                                                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                                                                            {renderMedia(file, index)}
                                                                            <Typography variant="caption" sx={{ mt: 2 }}>
                                                                                {file?.Description || "No description available"}
                                                                            </Typography>
                                                                        </Box>
                                                                    </React.Fragment>
                                                                ))}
                                                            </Slider>
                                                        ) : (
                                                            data?.EvidenceDetail?.map((file, index) => (
                                                                <Box key={index} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                                                                    {renderMedia(file, index)}
                                                                    <Typography variant='caption' sx={{ mt: 2 }}>
                                                                        {file?.Description || "No description available"}
                                                                    </Typography>
                                                                </Box>
                                                            ))
                                                        )}
                                                    </StyledBox>
                                                )}

                                            </>
                                        ) : (
                                            <Typography variant='body2' sx={{ color: 'red' }}>No data available</Typography>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>

                        </>
                    ) : value === 1 ? (
                        <>
                            {resources.length > 0 && (<CardDrawer cardData={resources} subtitle={"Incident Info"} title={"Resources"} />)}
                        </>
                    ) : value === 2 ? (
                        <>
                            {data.CAPADetail.length > 0 && (<CardDrawer cardData={data.CAPADetail} subtitle={"Incident Info"} title={"CAPA"} />)}
                        </>
                    ) : value === 3 ? (
                        <>
                            <Grid container spacing={1} sx={{ px: "24px", lineHeight: 0.5 }} >
                                <Grid container spacing={1} item xs={12} md={5} >
                                    <Grid item xs={12} >
                                        <Typography variant='body2' sx={{ color: '#1E1E1E', fontWeight: 600, }}>
                                            Distance from the face
                                        </Typography>
                                        <Typography variant='caption' sx={{ color: '#09A2E3' }}>
                                            {data?.Regulatory?.["Distance from the face"] || 'N/A'}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant='body2' sx={{ color: '#1E1E1E', fontWeight: 600, }}>
                                            Dip Number
                                        </Typography>
                                        <Typography variant='caption' sx={{ color: '#09A2E3' }}>
                                            {data?.Regulatory?.["Dip Number"] || 'N/A'}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant='body2' sx={{ color: '#1E1E1E', fontWeight: 600, }} >
                                            Pit/incline No.
                                        </Typography>
                                        <Typography variant='caption' sx={{ color: '#09A2E3' }}>
                                            {data?.Regulatory?.["Pit/incline No."] || 'N/A'}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant='body2' sx={{ color: '#1E1E1E', fontWeight: 600, }} >
                                            Seam
                                        </Typography>
                                        <Typography variant='caption' sx={{ color: '#09A2E3' }}>
                                            {data?.Regulatory?.["Seam"] || 'N/A'}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant='body2' sx={{ color: '#1E1E1E', fontWeight: 600, }} >
                                            Level
                                        </Typography>
                                        <Typography variant='caption' sx={{ color: '#09A2E3' }}>
                                            {data?.Regulatory?.["Level"] || 'N/A'}
                                        </Typography>
                                    </Grid>

                                </Grid>
                                <Grid item xs={12} md={7}>

                                    <Grid item xs={12} sx={{ mb: "8px" }}>
                                        <Typography variant='body2' sx={{ color: '#1E1E1E', fontWeight: 600, }} >
                                            District/Panel/Bench outside work District
                                        </Typography>
                                        <Typography variant='caption' sx={{ color: '#09A2E3' }}>
                                            {data?.Regulatory?.["District/Panel/Bench outside work District"] || 'N/A'}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant='body2' sx={{ color: '#1E1E1E', fontWeight: 600, }} >
                                            Details not covered above
                                        </Typography>
                                        <Typography variant='caption' sx={{ color: '#09A2E3' }}>
                                            {data?.Regulatory?.["Details not covered above"] || 'N/A'}
                                        </Typography>
                                    </Grid>
                                </Grid>

                            </Grid>
                        </>
                    ) : (
                        <></>
                    )}
            </TabPanel>
        </>
    );


    return (
        <Box
            sx={{
                width: "100%",
                maxHeight: '400px',
                //paddingY: "12px",
                backgroundColor: "#ffffff",
                borderRadius: "8px",
            }}
        >

            {data?.investigationReviewInput ? (
                <Tabs
                    value={outerTab}
                    onChange={handleOuterChange}
                    aria-label="basic tabs example"
                    textColor="primary"
                    indicatorColor="primary"
                    sx={{ maxHeight: '36px' }} // Adjust height as necessary
                >
                    <Tab
                        label={
                            <Typography variant='body1' sx={{ color: outerTab === 0 ? 'rgba(9, 162, 227, 1)' : "#525966", px: '24px' }}>
                                Incident info
                            </Typography>
                        }
                        sx={{ backgroundColor: 'transparent' }}
                    />

                    <Tab
                        label={
                            <Typography variant='body1' sx={{ color: outerTab === 1 ? 'rgba(9, 162, 227, 1)' : "#525966", px: '24px' }}>
                                Investigation Info
                            </Typography>
                        }
                        sx={{ backgroundColor: 'transparent' }}
                    />

                    <Tab
                        label={
                            <Typography variant='body1' sx={{ color: outerTab === 2 ? 'rgba(9, 162, 227, 1)' : "#525966", px: '24px' }}>
                                Regulatory Info
                            </Typography>
                        }
                        sx={{ backgroundColor: 'transparent' }}
                    />

                    {(level === "4" || level === "5") && (
                        <Tab
                            label={
                                <Typography variant='body1' sx={{ color: outerTab === 3 ? 'rgba(9, 162, 227, 1)' : "#525966", px: '24px' }}>
                                    CAPA details
                                </Typography>
                            }
                            sx={{ backgroundColor: 'transparent' }}
                        />
                    )}
                </Tabs>
            ) : (
                <Tabs
                    value={outerTab}
                    onChange={handleOuterChange}
                    aria-label="basic tabs example"
                    textColor="primary"
                    indicatorColor="primary"
                    sx={{ maxHeight: '36px' }} // Adjust height as necessary
                >
                    <Tab
                        label={
                            <Typography variant='body1' sx={{ color: outerTab === 0 ? 'rgba(9, 162, 227, 1)' : "#525966", px: '24px' }}>
                                Incident info
                            </Typography>
                        }
                        sx={{ backgroundColor: 'transparent' }}
                    />

                    {(level === "4" || level === "5") && (
                        <Tab
                            label={
                                <Typography variant='body1' sx={{ color: outerTab === 1 ? 'rgba(9, 162, 227, 1)' : "#525966", px: '24px' }}>
                                    CAPA details
                                </Typography>
                            }
                            sx={{ backgroundColor: 'transparent' }}
                        />
                    )}
                </Tabs>
            )}

            {/* Tab Panels */}
            {outerTab === 0 && (
                <TabPanel2>
                    {renderTabs1(value1, handleChange1)}
                </TabPanel2>
            )}

            {outerTab === 1 && data?.investigationReviewInput && (
                <TabPanel2>
                    {renderTabs2(value2, handleChange2)}
                </TabPanel2>
            )}

            {outerTab === 2 && (
                <TabPanel2>
                    {renderTabs3(value3, handleChange3)}
                </TabPanel2>
            )}

            {(outerTab === 3 && (level === "4" || level === "5")) && (
                <>
                    <Divider />
                    <Box sx={{ padding: '16px' }}>
                        <CAPADetailTable id={data?.id} level={level} mode={mode} />
                    </Box>
                </>
            )}

            {/* Show CAPA Table only if Investigation Review Input is missing and no other valid tab is selected */}
            {!data?.investigationReviewInput && outerTab === 1 && (level === "4" || level === "5") && (
                <>
                    <Divider />
                    <Box sx={{ padding: '16px' }}>
                        <CAPADetailTable id={data?.id} level={level} mode={mode} />
                    </Box>
                </>
            )}

            {selectedImage && (
                <DraggableImageDialog
                    imageSrc={selectedImage}
                    open={openDialog}
                    onClose={handleCloseDialog}
                />
            )}
        </Box>
    )
}
