
import {
  INCIDENTREPORT_UPDATE_FORM_FIELD,
  INCIDENTREPORT_ADD_CHECKBOX,REMOVE_CHECKBOX,
  INCIDENTREPORT_UPDATE_INCIDENT_DATE_TIME,
  INCIDENTREPORT_ADD_PERSONNEL_DETAIL,
  INCIDENTREPORT_ADD_VEHICLE_DETAIL,
  INCIDENTREPORT_ADD_EQUIPMENT_DETAIL,
  INCIDENTREPORT_ONCHANGE_DRAWER_FORM,
  INCIDENTREPORT_ADD_CAPA_DETAIL,
  INCIDENTREPORT_ADD_EVIDENCE_DETAIL,
  INCIDENTREPORT_UPDATE_SWITCH_STATE,
  INCIDENTREPORT_EDIT_CAPA_DETAIL,
  INCIDENTREPORT_DELETE_CARD_DETAIL,
  INCIDENTREPORT_SET_ALL_REPORT_DATA,
  INCIDENTREPORT_SET_LTI_DETAILS,
  INCIDENTREPORT_UPDATE_EVIDENCE_FILE,
  INCIDENTREPORT_ADD_LTI_DETAIL,
  INCIDENTREPORT_UPDATE_FILTER,
  INCIDENTREPORT_RESET_FILTER,
  INCIDENTREPORT_RESET_STATE,
  INCIDENTREPORT_SET_REPORT_FORM_DATA,
  INCIDENTREPORT_REMOVE_CHECKBOX
} from './constant';



export const setIncidentFormData = (data) => ({
  type: INCIDENTREPORT_SET_REPORT_FORM_DATA,
  payload: { data },
});

export const resetReportFormstate = () => ({
  type: INCIDENTREPORT_RESET_STATE,
});

export const resetFilter = () => ({
    type: INCIDENTREPORT_RESET_FILTER,
});

export const updateFormField = ({ fieldName, value }) => ({
  type: INCIDENTREPORT_UPDATE_FORM_FIELD,
  payload: { fieldName, value },
});

export const addCheckbox = (label) => ({
  type: INCIDENTREPORT_ADD_CHECKBOX,
  payload: label,
});

export const removeCheckbox = (label) => ({
  type: INCIDENTREPORT_REMOVE_CHECKBOX,
  payload: label,
});



export const updateIncidentDateTime = (label, value) => ({
  type: INCIDENTREPORT_UPDATE_INCIDENT_DATE_TIME,
  payload: { label, value },
});

export const addPersonnelDetail = (PersonnelDetail) => {
  return {
    type: INCIDENTREPORT_ADD_PERSONNEL_DETAIL,
    payload: PersonnelDetail
  };
};

export const addVehicleDetail = (vehicleDetail) => {
  return {
    type: INCIDENTREPORT_ADD_VEHICLE_DETAIL,
    payload: vehicleDetail
  };
};

export const addEquipmentDetail = (equipmentDetail) => {
  return {
    type: INCIDENTREPORT_ADD_EQUIPMENT_DETAIL,
    payload: equipmentDetail
  };
};


export const addEvidenceDetail = (evidenceDetail) => {
  return {
    type: INCIDENTREPORT_ADD_EVIDENCE_DETAIL,
    payload: evidenceDetail
  };
};

export const addCAPADetail = (CAPADetail) => {
  return {
    type: INCIDENTREPORT_ADD_CAPA_DETAIL,
    payload: CAPADetail
  };
};

export const addLTIDetail = (LTIDetail) => {
  return {
    type: INCIDENTREPORT_ADD_LTI_DETAIL,
    payload: LTIDetail
  };
};
 
export const handleChangeDrawerFormData = ({fieldName, value, title}) => ({
  type: INCIDENTREPORT_ONCHANGE_DRAWER_FORM,
  payload: { fieldName, value, title},
});

export const updateFilter = ({fieldName, moduleId, moduleName}) => {
  return {
    type: INCIDENTREPORT_UPDATE_FILTER,
    payload: { fieldName, moduleId, moduleName }
  };
};

export const updateSwitchState = (sectionName, value) => ({
  type: INCIDENTREPORT_UPDATE_SWITCH_STATE,
  payload: {
    sectionName,
    value,
  },
});

export const editDetail = ({id ,title}) => {
  return {
    type: INCIDENTREPORT_EDIT_CAPA_DETAIL,
    payload: { id ,title }  // Pass the ID of the LTI detail you want to edit
  };
};

export const deleteCardDetail = ({id ,title}) => {
  return {
    type: INCIDENTREPORT_DELETE_CARD_DETAIL,
    payload: { id ,title }  // Pass the ID of the LTI detail you want to edit
  };
};

export const setAllReportData = (data) => {
  return {
      type: INCIDENTREPORT_SET_ALL_REPORT_DATA,
      payload: data
  };
};


export const setLTIDetails = (ltiFormData) => {
  return {
    type: INCIDENTREPORT_SET_LTI_DETAILS,
    payload: ltiFormData, // Send the new LTI data as payload
  };
};
export const updateEvidenceFile = ({file,fileURL}) => ({
  type: INCIDENTREPORT_UPDATE_EVIDENCE_FILE,
  payload: {file,fileURL},
});



  